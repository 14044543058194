<template>
  <v-card-text>
    <v-container fluid>
      <v-row align="center">
        <v-col sm="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Búsqueda"
            outlined
            clearable
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-data-table
          class="table"
          :headers="headers"
          :items="equipos"
          :search="search"
          fixed-header
          disable-pagination
          hide-default-footer
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <!-- :items-per-page="30" -->
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>
          <template #[`item.TIPO_EQUIPO`]="{ item }">
            <span>{{ item.TIPO_EQUIPO ? item.TIPO_EQUIPO.NOMBRE : "" }}</span>
          </template>

          <template #[`item.FECHA_CREACION`]="{ item }">
            <span>{{ $date.formatDate(item.FECHA_CREACION,'DD/MM/YYYY') }}</span>
          </template>

          <template #[`item.MEDIOS`]="{ item }">
            <span>{{ item.MEDIO_EQUIPOs ? mediosEquipos(item.MEDIO_EQUIPOs) : "" }}</span>
          </template>

          <template #[`item.ACCIONES`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="showEditEquipoDialog(item)"
                >
                  <v-icon color="teal">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <!-- <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="toggleActivateMedio(item)"
                >
                  <v-icon :color="item.ACTIVO ? 'success' : 'gray'">{{ item.ACTIVO ? 'mdi-lock-open-check-outline' : 'mdi-lock-off-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.ACTIVO ? 'Desactivar' : 'Activar' }}</span>
            </v-tooltip> -->
          </template>
        </v-data-table>
      </v-row>
    </v-container>

    <vx-dialog-add-edit-equipo
      :show="showDialog"
      :isEdit="isEdit"
      :editedEquipo="editEquipo"
      @cancelar="showDialog = false"
      @addEquipo="acceptAddEquipo"
      @editEquipo="acceptEditEquipo"
    />
  </v-card-text>
</template>

<script>
import { mapActions } from 'vuex'
import DialogAddEditEquipo from './DialogAddEditEquipo.vue'

import constants from '../../helpers/constants'

export default {
  components: {
    'vx-dialog-add-edit-equipo': DialogAddEditEquipo
  },

  props: {
    showDialogAddEdit: Boolean
  },

  data: () => ({
    headers: [
      { text: 'Nombre equipo', value: 'NOMBRE', align: 'left', sortable: true },
      { text: 'Tipo', value: 'TIPO_EQUIPO', align: 'left', sortable: true },
      { text: 'Descripción', value: 'DESCRIPCION', align: 'left', sortable: true },
      { text: 'Fecha de creación', value: 'FECHA_CREACION', align: 'left', sortable: true },
      { text: 'Medios', value: 'MEDIOS', align: 'left', sortable: true },
      { text: 'Acciones', value: 'ACCIONES', align: 'center', width: '10%', sortable: false }
    ],

    sortBy: 'NOMBRE',
    sortDesc: false,

    search: '',
    editEquipo: {},
    isEdit: false,
    showDialog: false
  }),

  watch: {
    showDialogAddEdit () {
      this.showDialog = this.showDialogAddEdit
      console.log('watch showDialogAddEdit', this.showDialog, this.showDialogAddEdit)
    }
  },

  computed: {
    equipos () {
      return this.$store.getters['equipo/equipos']
    },

    tiposEquipo () {
      return this.$store.getters['equipo/tiposEquipo']
    },

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }

  },

  methods: {
    ...mapActions('equipo', ['addEquipo', 'updateEquipo', 'deleteEquipo']),

    showEditEquipoDialog (item) {
      this.editEquipo = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    toggleEnableMedio (medio) {
      if (medio.HABILITADO === true) {
        let msg = 'Deshabilitar el medio: ' + medio.MEDIO
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('medio/disableMedio', medio)
          }
        })
      } else {
        this.$store.dispatch('medio/enableMedio', medio)
      }
    },

    toggleActivateMedio (medio) {
      // if (medio.ACTIVO === true) {
      //   let msg = 'Desactivar el medio: ' + medio.MEDIO
      //   this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
      //     if (result) {
      //       this.$store.dispatch('medio/deactivateMedio', medio)
      //     }
      //   })
      // } else {
      //   this.$store.dispatch('medio/activateMedio', medio)
      // }
    },

    acceptAddEquipo (equipo) {
      this.addEquipo(equipo)
      this.showDialog = false
    },

    acceptEditEquipo (equipo) {
      this.updateEquipo(equipo)
      this.showDialog = false
      console.log('edit', equipo)
    },

    mediosEquipos (mediosEquipos) {
      // console.log('equipos', this.equipos)
      // console.log('mediosEquipos', mediosEquipos)
      return mediosEquipos.map(x => x.MEDIO.MEDIO).join(', ')
    }

  }
}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
  max-height: calc(100vh - 215px)
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */
</style>
