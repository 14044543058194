var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Datos incendio")]),_c('v-progress-circular',{directives:[{name:"show",rawName:"v-show",value:(_vm.updatingEstadoIncendio || _vm.updatingNivelIncendio),expression:"updatingEstadoIncendio || updatingNivelIncendio"}],staticClass:"ml-4",attrs:{"indeterminate":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","absolute":"","disabled":_vm.disabledByRol},on:{"click":function($event){_vm.showDialogEditIncendio = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1)]}}])},[_c('span',[_vm._v("Editar incendio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showAddNiveles),expression:"showAddNiveles"}],style:({marginRight: '50px'}),attrs:{"fab":"","small":"","color":"secondary","disabled":_vm.disableAddNivel || _vm.disabledByRol,"bottom":"","right":"","absolute":""},on:{"click":_vm.changeVisibilityNivelIncendio}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-car-brake-fluid-level")])],1)]}}])},[(_vm.disableAddNivel)?_c('span',[_vm._v("Es necesario finalizar el anterior nivel")]):_c('span',[_vm._v("Añadir nivel incendio")])])],1),_c('v-card-text',[_c('div',[_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v("Municipio: "+_vm._s(_vm.incendio.MUNICIPIO))]),_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v("Localidad: "+_vm._s(_vm.incendio.LOCALIDAD))]),_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v("Coordenadas: "+_vm._s(_vm.incendio.LATITUD)+", "+_vm._s(_vm.incendio.LONGITUD))]),_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v("Detección: "+_vm._s(_vm.deteccion.DETECCION))]),_c('p',{staticClass:"subtitle-1 black--text"},[_vm._v("Observaciones: "+_vm._s(_vm.incendio.OBSERVACIONES))])]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.incendio.ESTADOS,"hide-default-footer":""},scopedSlots:_vm._u([{key:"header.ACCIONES",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showIncendioEstadoEdit()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar estado incendio")])])]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.FECHA",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA)))])]}},{key:"item.ESTADO",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":item.TIPO_INCENDIO_ESTADO.COLOR,"dark":""}},[_vm._v(" "+_vm._s(item.TIPO_INCENDIO_ESTADO.INCENDIO_ESTADO)+" ")])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[(_vm.showAddEstado(item))?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disableFinalizar},on:{"click":function($event){return _vm.setEstadoIncendio(item.TIPO_INCENDIO_ESTADO)}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-plus")])],1):_vm._e()],1)]}}],null,true)},[(_vm.disableFinalizar)?_c('span',[_vm._v("Es necesario finalizar las actuaciones de los medios "),_c('br'),_vm._v("y finalizar el ultimo nivel del incendio")]):_c('span',[_vm._v("Añadir estado incendio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.showRemoveEstado(item)),expression:"showRemoveEstado(item)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.deleteEstadoIncendio()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar estado incendio")])])]}}],null,true)}),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNivelesTable),expression:"showNivelesTable"}],attrs:{"headers":_vm.headers2,"items":_vm.incendio.NIVELES,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.NIVEL",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"chip",attrs:{"color":item.TIPO_INCENDIO_NIVEL.COLOR,"dark":""}},[_vm._v(" "+_vm._s(item.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL)+" ")])]}},{key:"item.FECHA_INICIO",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date.formatDate(item.FECHA_INICIO,'DD/MM/YYYY HH:mm')))])]}},{key:"item.FECHA_FIN",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date.formatDate(item.FECHA_FIN,'DD/MM/YYYY HH:mm')))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.showEditNivelDialog(item,index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar nivel incendio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-inline-block"},'div',attrs,false),on),[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.showRemoveNivel(item)),expression:"showRemoveNivel(item)"}],attrs:{"icon":"","disabled":_vm.disabledByRol},on:{"click":function($event){return _vm.deleteNivelIncendio()}}},[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-minus")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar el nivel")])])]}}],null,true)})],1)],1),_c('vx-dialog-edit-incendio',{attrs:{"show":_vm.showDialogEditIncendio,"incendio":_vm.incendio},on:{"aceptar":_vm.aceptarEditIncendio,"cancelar":function($event){_vm.showDialogEditIncendio = false}}}),_c('vx-dialog-add-nivel-incendio',{attrs:{"show":_vm.showAddNivelIncendioDialog,"idIncendio":_vm.idIncendio},on:{"aceptar":_vm.changeVisibilityNivelIncendio,"cancelar":_vm.changeVisibilityNivelIncendio,"redrawIncendio":_vm.redrawIncendio}}),_c('vx-dialog-edit-nivel-incendio',{attrs:{"show":_vm.showEditNivelIncendioDialog,"idIncendio":_vm.incendio.ID_INCENDIO,"dataNivel":_vm.dataNivel},on:{"close":function($event){_vm.showEditNivelIncendioDialog = false},"redrawIncendio":_vm.redrawIncendio}}),_c('vx-dialog-edit-incendio-estado',{attrs:{"show":_vm.showDialogEstadoEdit,"incendio":_vm.incendio},on:{"close":function($event){_vm.showDialogEstadoEdit = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }