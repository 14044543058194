import request from '../request'

export default {
  deleteImagenPersonal: function (data) {
    return request.delete('personal_imagen/' + data.ID_PERSONAL + '/' + data.ID_PERSONAL_IMAGEN)
  },
  getImagenesPersonal: function (data) {
    return request.get('personal_imagen/' + data)
  },
  setImagenesPersonal: function (data) {
    return request.post('personal_imagen/' + data.ID_PERSONAL, data.imagenes)
  }
}
