import request from '../request'

export default {
  getEquipos: function () {
    return request.get('equipo')
  },
  addEquipo: function (data) {
    return request.post('equipo', data)
  },
  updateEquipo: function (data) {
    return request.put(`equipo/${data.ID_EQUIPO}`, data)
  },
  deleteEquipo: function (data) {
    return request.delete(`equipo/${data.ID_EQUIPO}`)
  },
  getTiposEquipo: function () {
    return request.get('tipo_equipo')
  }
}
