import api from '@/api'
import DateService from '@/helpers/DateService'
import Vue from 'vue'

let Q = require('q')

const state = {
  equipos: [],
  tiposEquipo: []
}

const getters = {
  equipos: (state) => {
    return state.equipos
  },
  tiposEquipo: (state) => {
    return state.tiposEquipo
  },
  getEquipoByID: (state) => (idEquipo) => {
    let equipo = null

    if (idEquipo) {
      equipo = state.equipos.find(x => x.ID_EQUIPO.equalsIgnoreCase(idEquipo))
    }
    return equipo
  }
}

const mutations = {
  SET_EQUIPOS (state, equipos) {
    state.equipos = equipos
  },
  SET_TIPOS_EQUIPO (state, tiposEquipo) {
    state.tiposEquipo = tiposEquipo
  },
  SOCKET_addEquipo (state, data) {
    state.equipos.push(data)
    console.log('socket add equipo')
    this.notify('Nuevo equipo', `Se ha añadido el equipo ${data.NOMBRE}`)
  },
  SOCKET_updateEquipo (state, data) {
    let positionEquipo = state.equipos.findIndex(x => x.ID_EQUIPO.equalsIgnoreCase(data.ID_EQUIPO))
    console.log('socket update equipo')
    if (positionEquipo !== -1) {
      state.equipos[positionEquipo] = data
      this.notify('Equipo actualizado', `Se ha actualizado el equipo ${data.NOMBRE}`)
    }
    Vue.set(state.equipos, positionEquipo, data)
  }
}

const actions = {
  async fetchEquipos ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.equipo.getEquipos()
      // console.log('MEDIOS', response.data.filter(x => x.MEDIO === '113'))
      commit('SET_EQUIPOS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async addEquipo ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.equipo.addEquipo(data)

      if (response.status === 200) {
        // pass object data to render most updated info
        commit('SOCKET_addEquipo', response.data)
        this._vm.$socket.emit('addEquipo', response.data)
      }

      deferred.resolve()
    } catch (err) {
      this.notify('Error al añadir equipo', 'error')
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async updateEquipo ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.equipo.updateEquipo(data)
      if (response.status === 200) {
        // pass object data to render most updated info
        commit('SOCKET_updateEquipo', response.data)
        this._vm.$socket.emit('updateEquipo', response.data)
      }

      deferred.resolve()
    } catch (err) {
      this.notify('Error al editar equipo', 'El nombre del equipo ya existe', 'error')
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async deleteEquipo ({ commit }, data) {
    let deferred = Q.defer()

    try {
      const response = await api.equipo.deleteEquipo(data)

      if (response.status === 200) {
        commit('SOCKET_deleteEquipo', data)
        this._vm.$socket.emit('deleteEquipo', data)
      }

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  },
  async fetchTiposEquipo ({ commit }) {
    let deferred = Q.defer()

    try {
      const response = await api.equipo.getTiposEquipo()
      commit('SET_TIPOS_EQUIPO', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
