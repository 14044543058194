import request from './request'
import auth from './auth'

import actuaciones from './actuaciones'
import imagenSatelite from './imagenSatelite'
import imagenIncendio from './imagenIncendio'
import incendio from './incendio'
import medio from './medio'
import parametro from './parametro'
import planificacion from './planificacion'
import proxy from './proxy'
import simulacion from './simulacion'
import infraestructura from './infraestructura'
import equipo from './equipo'
import personalMedioTipo from './personalMedioTipo'
import personal from './personal'
import personalCategoria from './personalCategoria'
import imagenInfraestructura from './imagenInfraestructura'
import demarcacion from './demarcacion'
import personalTipo from './personalTipo'
import tituloProfesional from './tituloProfesional'
import organismo from './organismo'
import imagenPersonal from './imagenPersonal'

import others from './others'

export default {
  request,

  auth,

  actuaciones,
  imagenIncendio,
  imagenSatelite,
  incendio,
  medio,
  parametro,
  planificacion,
  proxy,
  infraestructura,
  simulacion,
  equipo,
  personalMedioTipo,
  personal,
  personalCategoria,
  imagenInfraestructura,
  demarcacion,
  personalTipo,
  tituloProfesional,
  organismo,
  imagenPersonal,

  others
}
