import { setDefaultOptions, loadModules } from 'esri-loader'
import store from '@/store/index'
import constants from './constants'
import * as symbology from './GraphicsSimbology'
import VueInst from '../main'
import JSZip from 'jszip'

// import MedioPopup from '../components/map/popup/MedioPopup'
// import Vue from 'vue'

import proj4 from 'proj4'

import DateService from './DateService'
import Vue from 'vue'
import api from '../api'

setDefaultOptions({ css: true })

let Q = require('q')

/**
 * Global variables
 */
let map
let mapView

let overviewMap
let mapViewOverview

// #region MAP
let ExaggeratedElevationLayerSubClass
let elevationLayer
export const createMap = async () => {
  try {
    const [Map] = await loadModules(['esri/Map'])

    await initExaggeratedElevationLayerSubClass()
    elevationLayer = new ExaggeratedElevationLayerSubClass()
    map = new Map({
      ground: {
        layers: [elevationLayer]
      },
      basemap: 'satellite'
    })

    overviewMap = new Map({
      ground: {
        layers: [elevationLayer]
      },
      basemap: 'gray-vector'
    })

    await registerToken()
    await use2D()
    await createLayers()
    await drawContorno()
    await reorderLayers()

    addContornoToOverview()

    await setSearchWidget()
    await compassWidget()
    await legendWidget()

    redrawMedios()
    redrawIncendios()
    redrawInfraestructuras()
  } catch (err) {
    VueInst.$log.error(err)
  }
}

const addContornoToOverview = () => {
  let layerContorno = Object.assign({}, map.findLayerById('layer-contorno'))

  layerContorno.opacity = 1

  // overviewMap.add(layerContorno)
}

let draw
export const drawPolygonAreaToTrackMedios = async () => {
  try {
    const [Draw] = await loadModules(['esri/views/draw/Draw'])
    const [Point] = await loadModules(['esri/geometry/Point'])
    const [projection] = await loadModules(['esri/geometry/projection'])
    const [Polygon] = await loadModules(['esri/geometry/Polygon'])

    let poligono = null
    let poligonoGraphic

    let layer = map.findLayerById('layer-drawPolygon')

    if (!draw) {
      // Se inicializa si no lo está ya
      draw = new Draw({
        view: mapView
      })
    }
    let action = draw.create('polygon')

    return new Promise((resolve, reject) => {
      // PolygonDrawAction.vertex-add
      // Fires when user clicks, or presses the "F" key.
      // Can also be triggered when the "R" key is pressed to redo.
      action.on('vertex-add', async function (evt) {
        let lastVertex = evt.vertices[evt.vertexIndex]
        let point = new Point({
          x: lastVertex[0],
          y: lastVertex[1]
        })

        if (!poligono) {
          poligono = new Polygon({
            spatialReference: {
              wkid: 3857
            }
          })
          poligono.addRing([point, point])
        } else {
          let ringLength = poligono.rings[0].length
          poligono.insertPoint(0, ringLength - 1, point)
        }
        let poligonoGrafico = await createPolygonGraphic(evt.vertices)
        layer.graphics.removeAll()
        layer.graphics.push(poligonoGrafico)
      })

      // Fires when the pointer moves over the view
      action.on('cursor-update', async function (evt) {
        let poligonoGrafico = await createPolygonGraphic(evt.vertices)
        layer.graphics.removeAll()
        layer.graphics.push(poligonoGrafico)
      })

      // Add a graphic representing the completed polygon
      // when user double-clicks on the view or presses the "C" key
      action.on('draw-complete', async function (evt) {
        let geometry4326 = projection.project(poligono, { wkid: 4326 })
        let coordenadasPoligono = '('

        for (let i = 0; i < geometry4326.rings[0].length; i++) {
          let coordenadas = String(geometry4326.rings[0][i]).split(',')
          if (i === geometry4326.rings[0].length - 1) {
            coordenadasPoligono += coordenadas[0] + ' ' + coordenadas[1] + ')'
          } else {
            coordenadasPoligono += coordenadas[0] + ' ' + coordenadas[1] + ', '
          }
        }

        mapView.goTo(poligono, {
          duration: 3000,
          easing: 'in-expo'
        })
        layer.graphics.removeAll()
        resolve(coordenadasPoligono)
      })
    })
  } catch (err) {
    VueInst.$log.error(err)
    throw err
  }
}
export function cancelDrawingPolygon () {
  let layer = map.findLayerById('layer-drawPolygon')
  if (draw) {
    draw.reset()
    layer.graphics.removeAll()
  }
}

async function createPolygonGraphic (vertices) {
  let deferred = Q.defer()
  try {
    const [Polygon, Graphic] = await loadModules([
      'esri/geometry/Polygon',
      'esri/Graphic'
    ])
    let geometry = new Polygon({
      hasZ: false,
      hasM: true,
      rings: vertices,
      spatialReference: { wkid: 3857 }
    })

    let poligonoGraphic = new Graphic({
      geometry: geometry,
      symbol: {
        type: 'simple-fill', // autocasts as SimpleFillSymbol
        color: 'purple',
        style: 'solid',
        outline: {
          // autocasts as SimpleLineSymbol
          color: 'white',
          width: 1
        }
      }
    })

    deferred.resolve(poligonoGraphic)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}
async function initExaggeratedElevationLayerSubClass () {
  try {
    const [ElevationLayer, BaseElevationLayer] = await loadModules([
      'esri/layers/ElevationLayer',
      'esri/layers/BaseElevationLayer'
    ])

    ExaggeratedElevationLayerSubClass = BaseElevationLayer.createSubclass({
      properties: {
        id: 'elevation-layer',
        exaggeration: 1
      },

      // The load() method is called when the layer is added to the map prior to it being rendered in the view.
      load: function () {
        this._elevation = new ElevationLayer({
          id: 'elevation-layer',
          url: 'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer'
        })

        // wait for the elevation layer to load before resolving load()
        this.addResolvingPromise(
          this._elevation.load().then(() => {
            // get tileInfo, spatialReference and fullExtent from the elevation service
            // this is required for elevation services with a custom spatialReference
            this.tileInfo = this._elevation.tileInfo
            this.spatialReference = this._elevation.spatialReference
            this.fullExtent = this._elevation.fullExtent
          })
        )

        return this
      },

      // Fetches the tile(s) visible in the view
      fetchTile: function (level, row, col, options) {
        // calls fetchTile() on the elevationlayer for the tiles
        // visible in the view
        return this._elevation.fetchTile(level, row, col, options).then(
          function (data) {
            const exaggeration = this.exaggeration
            // `data` is an object that contains the
            // the width and the height of the tile in pixels,
            // and the values of each pixel
            for (let i = 0; i < data.values.length; i++) {
              // Multiply the given pixel value
              // by the exaggeration value
              data.values[i] = data.values[i] * exaggeration
            }

            return data
          }.bind(this)
        )
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const use2D = async () => {
  // Crea el viewMap 2d
  try {
    const [MapView] = await loadModules(['esri/views/MapView'])

    let newMapView = new MapView({
      map: map,
      container: 'mapNode',
      constraints: {
        rotationEnabled: false
      },
      popupEnabled: true
    })

    newMapView.ui.remove('zoom')

    newMapView.ui.padding = { top: 70, left: 0, right: 15, bottom: 24 }
    onMapViewChange(newMapView)

    mapViewOverview = new MapView({
      container: 'overviewDiv',
      map: overviewMap,
      constraints: {
        rotationEnabled: false,
        maxScale: 2311162.2171545// 4622324.434309
      }
    })

    mapViewOverview.ui.components = []

    mapViewOverview.when(() => {
      mapView.when(() => {
        setup()
      })
    })

    store.dispatch('map/setMap3D', false)
  } catch (err) {
    VueInst.$log.error(err)
  }
}

async function setup () {
  const [Graphic,
    reactiveUtils, promiseUtils] = await loadModules(['esri/Graphic', 'esri/core/reactiveUtils', 'esri/core/promiseUtils'])

  const extent3Dgraphic = new Graphic({
    geometry: null,
    symbol: {
      type: 'simple-fill',
      color: [0, 0, 0, 0.5],
      outline: null
    }
  })
  mapViewOverview.graphics.add(extent3Dgraphic)

  const extentDebouncer = promiseUtils.debounce(async () => {
    if (mapView.stationary) {
      await mapViewOverview.goTo({
        center: mapView.center,
        scale:
        mapView.scale *
          2 *
          Math.max(
            mapView.width / mapViewOverview.width,
            mapView.height / mapViewOverview.height
          )
      })
    }
  })

  reactiveUtils.watch(
    () => mapView.extent,
    (extent) => {
      // Sync the overview map location
      // whenever the 3d view is stationary
      extent3Dgraphic.geometry = extent
    },
    {
      initial: true
    }
  )

  mapView.watch('stationary', (isStationary) => {
    if (isStationary) {
      extentDebouncer()
    }
  })
}

export const getCaptureMapOverview = async (layer) => {
  return await getCaptureMapFeatures(mapViewOverview.graphics, layer)
}

export const measureOverview = async () => {
  return await measurePerimetros(mapViewOverview.graphics)
}

export const use3D = async () => {
  try {
    const [SceneView] = await loadModules(['esri/views/SceneView'])

    closeMapPopups() // Si no se deseleccionan los graficos antes de cambiar de mapview, da error

    let newMapView = new SceneView({
      map: map,
      container: 'mapNode',
      constraints: {
        rotationEnabled: false
      }
    })

    // newMapView.ui.components = (['attribution'])
    let navigation = newMapView.ui.find('navigation-toggle')
    newMapView.ui.remove('zoom')

    newMapView.ui.move(navigation, 'top-right')
    // newMapView.ui.move(compass, 'top-right')
    newMapView.ui.remove('compass')
    newMapView.ui.remove('legend')

    // newMapView.ui.padding = { top: 378, left: 0, right: 60, bottom: 24 }

    onMapViewChange(newMapView)
    store.dispatch('map/setMap3D', true)
  } catch (err) {
    VueInst.$log.error(err)
  }
}

function onMapViewChange (newMapView) {
  let extent =
    mapView && mapView.extent
      ? mapView.extent
      : store.getters['map/paramsComunidad'].EXTENT

  // Destruir contenedor anterior
  if (mapView) {
    // mapView.container = null
    mapView = null
  }
  mapView = newMapView

  setExtentMap(extent)

  clearMeasurement()

  setSearchWidget()

  compassWidget()
  updateWidgetView()

  updateRendererViento()

  assignMapViewEvents()
}

export const getMapView = () => {
  return mapView
}

export const getMapViewOverviewGraphics = () => {
  return mapViewOverview.graphics
}

export const createMarcoCapturaGraphic = async function (params) {
  const [Graphic, Extent] = await loadModules(['esri/Graphic', 'esri/geometry/Extent', 'esri/views/MapView', 'esri/core/reactiveUtils'])

  const extent = new Extent({
    xmin: params.xmin,
    ymin: params.ymin,
    xmax: params.xmax,
    ymax: params.ymax,
    spatialReference: { wkid: 4326 }
  })

  const graphic = new Graphic({
    geometry: extent,
    symbol: {
      type: 'simple-fill',
      color: [0, 0, 0, 0.5],
      outline: null
    }
  })

  return graphic
}

export const establecerEscala = (nuevaEscala) => {
  mapView.constraints = {
    snapToZoom: false
  }
  mapView.goTo({
    scale: nuevaEscala
  },
  {
    duration: 1000, // Ajusta la duración a 2 segundos (2000 ms)
    easing: 'ease-in-out' // Puedes experimentar con diferentes curvas de aceleración
  }).catch(function (error) {
    console.error('Error ajustando la escala:', error)
  })
}

export const capturarPantalla = (frameBounds) => {
  let deferred = Q.defer()

  const options = {
    width: frameBounds.width * 5,
    height: frameBounds.height * 5,
    area: {
      x: frameBounds.x,
      y: frameBounds.y,
      width: frameBounds.width,
      height: frameBounds.height
    }
  }

  mapView
    .takeScreenshot(options)
    .then((screenshot) => {
      deferred.resolve(screenshot.dataUrl)
    })
    .catch((error) => {
      console.error('Error al capturar la pantalla:', error)
    })

  mapView.constraints = {
    snapToZoom: true
  }

  return deferred.promise
}

export const getMap = () => {
  return map
}
// #endregion

// #region MAPVIEW EVENTS
let graphicHighlight = null
let trackHighlight = []

function assignMapViewEvents () {
  // Evento Emit ChangeExtent (Compartir mapa)
  let pointerDown = false

  mapView.watch('extent', (event) => {
    if (event) {
      store.dispatch('shareMap/changeMapExtent', {
        xmax: event.xmax,
        xmin: event.xmin,
        ymax: event.ymax,
        ymin: event.ymin,
        wkid: event.spatialReference.wkid,
        viewpoint: mapView.viewpoint
      })
    }
  })

  // Scale
  mapView.watch('scale', (event) => {
    VueInst.$eventHub.$emit('escalaMap', event)

    // closeMapPopups()
  })

  // Move mouse -> update coordinates
  mapView.on('pointer-move', (event) => {
    if (!pointerDown) {
      sendCoordinates(mapView.toMap(event))
    }
  })

  mapView.on('click', (event) => {
    // Enviar coordenadas para representarlas en el MapaWeb
    sendCoordinates(mapView.toMap(event))

    // Right click -> Context Menu
    if (event.button !== 0) {
      let position = {
        x: event.x,
        y: event.y
      }
      let attributes = {}
      attributes.X = event.mapPoint.x
      attributes.Y = event.mapPoint.y
      attributes.LATITUDE = event.mapPoint.latitude
      attributes.LONGITUDE = event.mapPoint.longitude
      VueInst.$eventHub.$emit('showContextMenu', {
        attributes: attributes,
        position: position
      })
    }

    // Click -> tooltip layer
    mapView
      .hitTest(event)
      .then((response) => {
        let showCustomTooltip = false
        let showSimpleTooltip = false
        let layerId
        let graphicsFilter = response.results.filter((x) => {
          // console.log(x)
          if (event.button === 0) {
            const layer = x.graphic.layer

            if (layer) {
              layerId = layer.id

              // Capas con tooltip custom
              let layersCustomTooltip = [
                'layer-medios',
                'layer-medios-inactivos',
                'layer-track',
                'layer-incendios'
              ]
              let showCustomTooltip = layersCustomTooltip.includes(layerId)
              if (
                layerId === 'layer-track' &&
                x.graphic.symbol.type === 'simple-line'
              ) {
                // Si es la linea del track, no mostrar
                showCustomTooltip = false
              }

              if (showCustomTooltip) {
                return true
              }

              // Capas con coordenadas
              // ['layer-avion', 'layer-helicoptero', 'layer-pick-up']
              let layersWithPopup = [
                'layer-autobomba',
                'layer-bomberos',
                'layer-BRIF',
                'layer-central-comunicaciones',
                'layer-repetidor-comunicaciones',
                'layer-reten',
                'layer-SOS',
                'layer-torreta-vigilancia',
                'layer-estaciones-meteorologicas',
                'layer-punto-agua-aer',
                'layer-punto-agua-ter',
                'layer-punto-encuentro',
                'layer-montes-utilidad',
                'layer-emergencias',
                'layer-imagen-satelite',
                'layer-infraestructuras-propias',
                'layer-perimetros'
                // 'layer-vector-imagen-satelite'
              ]

              showSimpleTooltip = layersWithPopup.includes(layerId)
            }

            return showSimpleTooltip
          }
        })

        if (graphicsFilter.length > 0) {
          let graphic = graphicsFilter[0].graphic
          if (layerId === 'layer-perimetros' && graphicsFilter.length > 1) {
            // Encuentra el objeto con el AREA más pequeño
            const smallestAreaObject = graphicsFilter.reduce((min, current) => {
              return current.graphic.attributes.AREA < min.graphic.attributes.AREA ? current : min
            })

            // Obtén la posición del objeto con el AREA más pequeño
            const position = graphicsFilter.indexOf(smallestAreaObject)
            graphic = graphicsFilter[position].graphic
          }

          let highlightGraphic = []
          // highlightGraphic.push(graphicsFilter[0].graphic)
          highlightGraphic.push(graphic)

          if (layerId === 'layer-contorno' || layerId === 'layer-track') {
            let layer = map.findLayerById('layer-track')
            for (let i of layer.graphics) {
              if (i.attributes.ID_MEDIO === graphic.attributes.ID_MEDIO) {
                highlightGraphic.push(i)
              }
            }
          }

          mapView.whenLayerView(graphic.layer).then((layerView) => {
            for (let i of highlightGraphic) {
              graphicHighlight = layerView.highlight(i)
              trackHighlight.push(graphicHighlight)
            }
          })

          let position = {
            x: event.x,
            y: event.y + 3
          }

          let attributes = JSON.parse(JSON.stringify(graphic.attributes))

          if (showCustomTooltip) {
            attributes.LATITUDE = graphic.geometry.latitude
            attributes.LONGITUDE = graphic.geometry.longitude
            // VueInst.$eventHub.$emit('showPopup', { capa: graphic.layer.id, attributes: attributes, position: position })
          }

          if (attributes) {
            let popupData = {
              layer: graphic.layer.id,
              attributes: attributes,
              position: position
            }
            VueInst.$eventHub.$emit('showPopup', popupData)
          }
        }
      })
      .catch((err) => {
        VueInst.$log.error(err)
        // VueInst.$eventHub.$emit('showPopup', false)
      })
  })

  mapView.on('pointer-up', (event) => {
    pointerDown = false
  })
  mapView.on('pointer-down', (event) => {
    pointerDown = true
    closeMapPopups()
  })

  // Popup functions
  mapView.popup.on('trigger-action', (event) => {
    // TODO: Esto es para los popups de ArcGIS con los medios, no se usa
    if (event.action.id === 'measure-this') {
      // console.log('hola')
    }
  })
}

function closeMapPopups () {
  // Cierra el popup / context menu y deselecciona el grafico seleccionado
  VueInst.$eventHub.$emit('showPopup', false)
  VueInst.$eventHub.$emit('showContextMenu', false)

  if (graphicHighlight) {
    graphicHighlight.remove()
    graphicHighlight = null
  }

  if (trackHighlight.length > 0) {
    for (let i = 0; i < trackHighlight.length; i++) {
      trackHighlight[i].remove()
    }
  }
}
function sendCoordinates (point) {
  if (point) {
    let X = point.x.toFixed(2)
    let Y = point.y.toFixed(2)
    let LAT = point.latitude.toFixed(4).toString().replace('.', ',')
    let LON = point.longitude.toFixed(4).toString().replace('.', ',')
    let LATGMS = convertirLatLonGMS(point.latitude, 'LATITUDE').valor
    let LONGMS = convertirLatLonGMS(point.longitude, 'LONGITUDE').valor
    let ETRS89 = convertirETRS89(X, Y)
    let X_ETRS89 = ETRS89[0].toFixed(0).toString().replace('.', ',')
    let Y_ETRS89 = ETRS89[1].toFixed(0).toString().replace('.', ',')

    let coordenadas = {
      X: X,
      Y: Y,
      LATITUDE: LAT,
      LONGITUDE: LON,
      LATITUDE_GMS: LATGMS,
      LONGITUDE_GMS: LONGMS,
      X_ETRS89: X_ETRS89,
      Y_ETRS89: Y_ETRS89
    }
    VueInst.$eventHub.$emit('coordenadasMap', coordenadas)
  }
}
// #endregion

// #region LAYERS
export const reorderLayers = async () => {
  let layersAdd = store.getters['map/layersAdd']
  let layersMap = map.layers.items

  for (let i = 0; i < layersAdd.length; i++) {
    let layerInMap = layersMap.find((x) => x.id === layersAdd[i].id)

    if (layerInMap) {
      map.reorder(layerInMap, layersAdd[i].posicion)
    }
  }
}

export const createLayers = async () => {
  let thePromises = []
  let layerStore = store.getters['map/layersAdd']

  for (let i = 0; i < layerStore.length; i++) {
    let layerItem = layerStore[i]

    /* if (layerItem.type === 'WMS') {
      thePromises.push(createWMS(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else if (layerItem.type === 'wmtsLayer') {
      thePromises.push(createWMTS(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else if (layerItem.type === 'geoJsonPuntos') {
      thePromises.push(createGeoJsonPuntos(layerItem).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible, posicion: layerItem.posicion, addListLayer: layerItem.addListLayer})
      }))
    } else */ if (layerItem.type === 'WMS') {
      thePromises.push(
        createWMSNative(layerItem).then((layer) => {
          layer.load().then(loaded => {
            map.add(loaded)
            let sublayers = layer.allSublayers.items.map((item) => ({
              id: layer.id,
              title: item.title,
              sublayerId: item.id,
              visible: layer.allSublayers.items.length <= 1
            })).reverse()

            store.dispatch('map/addLayer', {
              id: layerItem.id,
              type: layerItem.type,
              title: layerItem.title,
              visible: layerItem.visible,
              sublayers: sublayers,
              addListLayer: layerItem.addListLayer,
              opacity: layerItem.opacity,
              posicion: layerItem.posicion,
              url: layerItem.baseUrl
            })
          })
        })
      )
    } else if (layerItem.type === 'geoJson') {
      thePromises.push(
        createGeoJsonLayer(layerItem
        ).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer,
            url: layerItem.url
          })
        })
      )
    } else if (layerItem.type === 'featureLayer') {
      thePromises.push(
        createFeatureLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer,
            renderer: layer.renderer,
            fields: layer.fields,
            definitionExpression: layer.definitionExpression,
            labelsVisible: layer.labelsVisible,
            legendEnabled: layerItem.legendEnabled,
            objectIdField: layerItem.objectIdField,
            orderBy: layerItem.orderBy
          })
        })
      )
    } else if (layerItem.type === 'graphicsLayer') {
      thePromises.push(
        createGraphicsLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer
          })
        })
      )
    } else if (layerItem.type === 'vectorTileLayer') {
      thePromises.push(
        createVectorTileLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer
          })
        })
      )
    } else if (layerItem.type === 'MediaLayer') {
      thePromises.push(
        createMediaLayer(layerItem).then((layer) => {
          map.add(layer)
          store.dispatch('map/addLayer', {
            id: layerItem.id,
            source: layerItem.source,
            title: layerItem.title,
            visible: layerItem.visible,
            opacity: layerItem.opacity,
            posicion: layerItem.posicion,
            addListLayer: layerItem.addListLayer
          })
        })
      )
    } else if (layerItem.type === 'groupLayer') {
      thePromises.push(
        createGroupLayer(layerItem).then((layer) => {
          // We load the layer first for retrieve sublayers info like titles. Otherwise sublayers is undefined
          layer.loadAll().then((layerLoaded) => {
            map.add(layerLoaded)
            let sublayers = layer.allLayers.items.map((item) => ({
              id: layer.id,
              title: item.title,
              sublayerId: item.layerId,
              visible: false
            })).reverse()
            let copyLayerItem = { ...layerItem }
            store.dispatch('map/addLayer', {
              id: copyLayerItem.id,
              type: copyLayerItem.type,
              source: copyLayerItem.source,
              title: copyLayerItem.title,
              visible: copyLayerItem.visible,
              sublayers: sublayers,
              opacity: copyLayerItem.opacity,
              posicion: copyLayerItem.posicion,
              addListLayer: copyLayerItem.addListLayer
            })
          })
        })
      )
    }
    /* else if (layer.type === 'simif') {
      thePromises.push(createWMSSimif(layer).then((layer) => {
        map.add(layer)
        store.dispatch('addLayer', {id: layerItem.id, title: layerItem.title, visible: layerItem.visible})
      }))
    } */
  }

  return Q.all(thePromises)
}
export const setExageration3D = (exageration) => {
  let layerEx = map.ground.layers.getItemAt(0)

  if (layerEx) {
    let layer = new ExaggeratedElevationLayerSubClass({
      exaggeration: exageration,
      url: layerEx.url
    })
    map.ground.layers.remove(layerEx)
    map.ground.layers.add(layer)
    // layer.exaggeration = exageration
  }
}
export const setVisibilityLayer = (id, visibility) => {
  if (map) {
    let layer = map.findLayerById(id)
    if (layer) {
      layer.visible = visibility
    }
  }
}

export const setVisibilitySubLayer = (idLayer, sublayer, visibility) => {
  if (map) {
    let layer = map.findLayerById(idLayer)
    if (layer.allSublayers) {
      // Arcgis WMS
      let sublayerIndex = layer.allSublayers.items.findIndex((x) => x.id === sublayer.sublayerId)
      layer.allSublayers.items[sublayerIndex].visible = visibility
    } else if (layer.allLayers) {
      // Arcgis Group Layers
      let sublayerIndex = layer.allLayers.items.findIndex((x) => x.layerId === sublayer.sublayerId)
      if (sublayerIndex !== -1) {
        layer.allLayers.items[sublayerIndex].visible = visibility
      }
    }
  }
}

export const setOpacityLayer = (id, opacity) => {
  if (map) {
    let layer = map.findLayerById(id)

    if (layer) {
      layer.opacity = opacity
    }
  }
}

export const setDefinitionExpressionLayer = (id, definitionExpression) => {
  if (map) {
    let layer = map.findLayerById(id)

    if (layer) {
      layer.definitionExpression = definitionExpression
    }
  }
}

export const setLabelsLayer = (id, labelsVisible) => {
  if (map) {
    let layer = map.findLayerById(id)

    if (layer) {
      layer.labelsVisible = labelsVisible
    }
  }
}

async function createGroupLayer (layerItem) {
  let deferred = Q.defer()
  try {
    const [GroupLayer, FeatureLayer, SpatialReference] = await loadModules([
      'esri/layers/GroupLayer',
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference'
    ])
    let groupLayer = new GroupLayer({
      id: layerItem.id,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity,
      portalItem: {
        id: layerItem.idItem
      }
    })
    deferred.resolve(groupLayer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createFeatureLayer (layerItem) {
  let deferred = Q.defer()
  try {
    const [FeatureLayer, SpatialReference] = await loadModules([
      'esri/layers/FeatureLayer',
      'esri/geometry/SpatialReference'
    ])

    let layer
    if (layerItem.url) {
      layer = new FeatureLayer({
        id: layerItem.id,
        url: layerItem.url,
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        spatialReference: SpatialReference.WGS84,
        outFields: ['*'],
        legendEnabled: layerItem.legendEnabled !== undefined ? layerItem.legendEnabled : true, // al ser un boolean se necesita poner el distinto de undefined
        orderBy: layerItem.orderBy
      })
    } else if (layerItem.idItem) {
      layer = new FeatureLayer({
        id: layerItem.id,
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        spatialReference: SpatialReference.WebMercator,
        outFields: ['*'],
        portalItem: {
          id: layerItem.idItem
        },
        layerId: layerItem.sublayerId,
        legendEnabled: layerItem.legendEnabled !== undefined ? layerItem.legendEnabled : true, // al ser un boolean se necesita poner el distinto de undefined
        orderBy: layerItem.orderBy
      })
    } else if (layerItem.id === 'layer-infraestructuras-propias') {
      layer = new FeatureLayer({
        id: layerItem.id,
        source: [],
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        objectIdField: 'ID',
        geometryType: 'point',
        spatialReference: SpatialReference.WGS84,
        renderer: await renderInfraestructuraLayer(),
        labelingInfo: await featureLayerlabels('NOMBRE'),
        labelsVisible: layerItem.labelsVisible,
        outFields: ['*'],
        fields: layerItem.fields

      })
    } else if (layerItem.id === 'layer-viento') {
      const is3D = store.getters['map/isMap3D']

      layer = new FeatureLayer({
        id: layerItem.id,
        source: [],
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        objectIdField: 'ID',
        render: {},
        geometryType: 'point',
        spatialReference: SpatialReference.WGS84,
        fields: layerItem.fields,
        renderer: is3D ? await getWindRenderer3D() : await getWindRenderer2D(),
        elevationInfo: {
          mode: 'relative-to-ground',
          offset: 50,
          unit: 'meters'
        },
        popupTemplate: {
          title: 'Viento',
          content: (feature) => {
            return `
            <table>
              <tr>
                <td>Dirección</td>
                <td>${feature.graphic.attributes.direction}</td>
              </tr>
              <tr>
                <td>Velocidad</td>
                <td>${feature.graphic.attributes.speed}</td>
              </tr>
            </table>`
          }
        },
        legendEnabled: layerItem.legendEnabled !== undefined ? layerItem.legendEnabled : true, // al ser un boolean se necesita poner el distinto de undefined
        orderBy: layerItem.orderBy
      })
    } else {
      layer = new FeatureLayer({
        id: layerItem.id,
        source: [],
        title: layerItem.title,
        visible: layerItem.visible,
        opacity: layerItem.opacity,
        objectIdField: 'ID',
        geometryType: 'point',
        spatialReference: SpatialReference.WGS84,
        fields: layerItem.fields,
        renderer: await renderMedioLayer(),
        labelingInfo: await featureLayerlabels('ALIAS'),
        definitionExpression: layerItem.definitionExpression,
        labelsVisible: layerItem.labelsVisible,
        outFields: ['*'],
        legendEnabled: layerItem.legendEnabled !== undefined ? layerItem.legendEnabled : true, // al ser un boolean se necesita poner el distinto de undefined
        orderBy: layerItem.orderBy
      })
    }

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphicsLayer (layerItem) {
  let deferred = Q.defer()

  try {
    const [GraphicsLayer] = await loadModules(['esri/layers/GraphicsLayer'])

    let layer = new GraphicsLayer({
      id: layerItem.id,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createVectorTileLayer (layerItem) {
  let deferred = Q.defer()

  try {
    const [VectorTileLayer] = await loadModules([
      'esri/layers/VectorTileLayer'
    ])

    let layer = new VectorTileLayer({
      id: layerItem.id,
      url: layerItem.url,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity,
      style: layerItem.style
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createMediaLayer (layerItem) {
  let deferred = Q.defer()

  try {
    const [MediaLayer] = await loadModules(['esri/layers/MediaLayer'])

    let layer = new MediaLayer({
      id: layerItem.id,
      source: [layerItem.source],
      title: layerItem.title,
      visible: layerItem.visible
      // blendMode: "color-dodge",
      // blendMode: "vivid-light",
      // blendMode: "screen",
      // blendMode: "reflect",
      // blendMode: 'overlay'
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createWMS (layerItem) {
  let deferred = Q.defer()

  try {
    const [WMSLayer] = await loadModules(['esri/layers/WMSLayer'])

    let layer = new WMSLayer({
      id: layerItem.id,
      url: layerItem.baseUrl,
      popupEnabled: layerItem.popupEnabled,
      featureInfoFormat: layerItem.featureInfoFormat,
      featureInfoUrl: layerItem.featureInfoUrl,
      sublayers: layerItem.sublayers,
      fetchFeatureInfoFunction: async (query) => {
        const [Graphic, esriRequest] = await loadModules([
          'esri/Graphic',
          'esri/request'
        ])
        // Explicitly request a GeoJSON response.
        query.info_format = 'application/json'

        // Request the first five features (if any).
        query.feature_count = 5

        // Perform the web request.
        const { data } = await esriRequest(layerItem.featureInfoUrl, { query })

        // Convert each GeoJSON feature into an Esri graphic.
        return data.features.map(
          (feature) =>
            new Graphic({
              attributes: feature.properties,

              // Define a popup template to format field names and values in a table.
              popupTemplate: {
                title: layerItem.title,
                content: [{
                  type: 'fields',
                  fieldInfos: layerItem.sublayerFieldInfos
                }]
              }
            })

        )
      },
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity
    })
    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createWMSNative (layerItem) {
  let deferred = Q.defer()
  try {
    const [WMSLayer] = await loadModules(['esri/layers/WMSLayer'])
    let layer = new WMSLayer({
      id: layerItem.id,
      version: layerItem.version,
      url: layerItem.url,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity
    })
    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

function getMinSpeed (features) {
  return features.reduce((min, current) =>
    min.properties.speed < current.properties.speed ? min : current
  ).properties.speed
}

function getMaxSpeed (features) {
  return features.reduce((max, current) =>
    max.properties.speed > current.properties.speed ? max : current
  ).properties.speed
}

let maxSpeed = null// getMaxSpeed(features)
let minSpeed = null// getMinSpeed(features)
async function getWindRenderer2D () {
  return {
    type: 'simple',

    symbol: {
      type: 'simple-marker',
      path: 'M14.5,29 23.5,0 14.5,9 5.5,0z',
      color: 'white',
      angle: 0,
      size: 10,
      outline: {
        color: [255, 255, 255, 0.5],
        width: 0
      }
    },
    visualVariables: [
      {
        type: 'rotation',
        field: 'direction',
        rotationType: 'geographic'
      },
      {
        type: 'color',
        field: 'speed',
        stops: [
          { value: minSpeed ? minSpeed.toFixed(2) : minSpeed, color: '#0010d9' },
          { value: maxSpeed ? (maxSpeed / 2).toFixed(2) : maxSpeed, color: '#0080ff' },
          { value: maxSpeed ? maxSpeed.toFixed(2) : maxSpeed, color: '#00ffff' }
        ],
        valueExpressionTitle: 'Intensidad viento',
        valueExpression: '3'

      },

      {
        type: 'size',
        field: 'speed',
        minDataValue: 0,
        maxDataValue: 5,
        minSize: 17,
        maxSize: 17,
        legendOptions: {
          showLegend: false
        }

      }
    ]
  }
}

async function getWindRenderer3D () {
  return {
    type: 'simple',
    symbol: {
      type: 'point-3d',
      symbolLayers: [
        {
          type: 'object',
          material: { color: '#a38a8a' },
          resource: { primitive: 'tetrahedron' }, // cone
          tilt: 90,
          width: 75,
          depth: 150,
          height: 5
        }
      ]
    },
    visualVariables: [
      {
        type: 'rotation',

        // the tetrahedron points south by default, so we need to subtract 180 degrees
        valueExpression: '$feature.direction',
        axis: 'heading'
      },
      {
        type: 'color',
        field: 'speed',
        stops: [
          { value: minSpeed ? minSpeed.toFixed(2) : minSpeed, color: '#0010d9' },
          { value: maxSpeed ? (maxSpeed / 2).toFixed(2) : maxSpeed, color: '#0080ff' },
          { value: maxSpeed ? maxSpeed.toFixed(2) : maxSpeed, color: '#00ffff' }
        ]
      },
      {
        type: 'size',
        field: 'speed',
        axis: 'depth',
        stops: [
          { value: minSpeed, size: 25 },
          { value: maxSpeed, size: 50 }
        ]
      },
      {
        type: 'size',
        field: 'speed',
        axis: 'height',
        stops: [
          { value: minSpeed, size: 50 },
          { value: maxSpeed, size: 400 }
        ]
      },
      {
        type: 'size',
        field: 'speed',
        axis: 'width',
        stops: [
          { value: minSpeed, size: 50 },
          { value: maxSpeed, size: 100 }
        ]
      }
    ]
  }
}

async function createGeoJsonLayer (layerItem) { //, is3d = false
  let deferred = Q.defer()

  try {
    const [GeoJSONLayer] = await loadModules(['esri/layers/GeoJSONLayer'])

    let aux = layerItem.url[0]
    let url = layerItem.url

    if (layerItem.url.length === 0) {
      aux = {
        type: 'FeatureCollection',
        name: 'polygon',
        crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: [
                [
                  [100.0, 0.0]
                ]
              ]
            },
            properties: {
              Shape_Leng: 100,
              Shape_Area: null,
              VALUE: null
            }
          }
        ]
      }

      let blob = new Blob([JSON.stringify(aux)], { // layerItem.url | geojson
        type: 'application/json'
      })

      // URL reference to the blob
      url = URL.createObjectURL(blob)
    }

    // create new geojson layer using the blob url
    let layer = new GeoJSONLayer({
      url: url, // Using Object as attribute value for GeoJSON features is not supported.
      id: layerItem.id,
      title: layerItem.title,
      visible: layerItem.visible,
      opacity: layerItem.opacity
      // renderer: await getWindRenderer2D()
    })

    deferred.resolve(layer)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createGraphicsPoligonos (response) {
  let deferred = Q.defer()

  try {
    const [Polygon] = await loadModules(['esri/geometry/Polygon'])

    let features = response.features.map(function (feature, i) {
      return {
        geometry: new Polygon({
          hasZ: false,
          hasM: false,
          rings: feature.geometry.rings,
          spatialReference: {
            wkid: 3857
          }
        }),
        attributes: {
          // Nombre: feature.properties.Nombre
        }
      }
    })
    deferred.resolve(features)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}
// #endregion

// #region INCENDIOS
export const redrawIncendios = async () => {
  // console.time('REDRAWINCENDIOS')
  if (map) {
    let layer = map.findLayerById('layer-incendios')

    if (layer) {
      let graphicsIncendios = await createGraphicsIncendios()
      layer.graphics.items = graphicsIncendios
    }
  }

  // console.timeEnd('REDRAWINCENDIOS')
}
async function createGraphicsIncendios () {
  let deferred = Q.defer()

  try {
    const [Point] = await loadModules(['esri/geometry/Point'])

    let svgPath = constants.incendioSVGPath

    let incendios = store.getters['incendio/incendios']
    let estadosIncendio = store.getters['incendio/tiposIncendioEstado']
    let sources = []
    let color = null

    for (let i = 0; i < incendios.length; i++) {
      let incendio = incendios[i]
      if (incendio.NIVELES.length > 0 && !incendio.NIVELES[0].FECHA_FIN) {
        color = incendio.NIVELES[0].TIPO_INCENDIO_NIVEL.COLOR
      } else {
        color = estadosIncendio.find(
          (x) =>
            x.ID_TIPO_INCENDIO_ESTADO ===
            incendios[i].ESTADOS[0].ID_TIPO_INCENDIO_ESTADO
        ).COLOR
      }

      let attributes = {
        ID_INCENDIO: incendio.ID_INCENDIO,
        NOMBRE: incendio.NOMBRE,
        MUNICIPIO: incendio.MUNICIPIO,
        LOCALIDAD: incendio.LOCALIDAD,
        OBSERVACIONES: incendio.OBSERVACIONES,
        ID_TIPO_DETECCION: incendio.ID_TIPO_DETECCION
      }
      let geometry = new Point({
        x: incendio.LONGITUD,
        y: incendio.LATITUD
      })

      // let popupTemplate = {
      //   title: 'Incendio {MUNICIPIO}',
      //   content: [{
      //     type: 'fields',
      //     fieldInfos: [{
      //       fieldName: 'OBSERVACIONES',
      //       label: 'Observaciones'
      //     }]
      //   }]
      // }

      sources.push({
        // Circulo blanco de fondo
        geometry: geometry,
        attributes: attributes,
        symbol: {
          type: 'simple-marker',
          style: 'circle',
          color: 'white',
          size: '32px',
          outline: {
            color: 'white'
          }
        }
      })
      sources.push({
        // Icono
        geometry: geometry,
        attributes: attributes,
        symbol: {
          type: 'simple-marker',
          path: svgPath,
          color: color,
          size: '25px',
          outline: {
            color: color
          }
        }
        // popupTemplate: popupTemplate
      })
      sources.push({
        // Texto
        geometry: geometry,
        attributes: attributes,
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'black',
          haloColor: 'white',
          haloSize: '2px',
          text: incendios[i].NOMBRE
            ? incendios[i].NOMBRE
            : incendios[i].MUNICIPIO,
          xoffset: 0,
          yoffset: -22,
          font: {
            // autocasts as new Font()
            size: 8,
            family: 'Arial Unicode MS',
            weight: 'bold'
          }
        }
      })
    }
    deferred.resolve(sources)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}
export const paintPositionIncendio = async (point) => {
  try {
    const [Graphic, Point] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point'
    ])

    mapView.graphics = []

    if (point) {
      let symbol = {
        type: 'simple-marker',
        style: 'x',
        color: 'white',
        size: '15px',
        outline: {
          color: 'red',
          width: '2px'
        }
      }

      let geometry = new Point({
        x: parseFloat(point.LONGITUD),
        y: parseFloat(point.LATITUD)
      })

      let graphic = new Graphic({
        geometry: geometry,
        symbol: symbol
      })

      mapView.graphics.add(graphic)
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// #endregion

export const redrawInfraestructuras = async () => {
  if (map) {
    let layerlayerInfraestructurasPropias = map.findLayerById('layer-infraestructuras-propias')

    if (layerlayerInfraestructurasPropias) {
      let graphicsInfraestructuras = await createGraphicsInfraestructuras()

      const { features } = await layerlayerInfraestructurasPropias.queryFeatures()
      await layerlayerInfraestructurasPropias.applyEdits({
        addFeatures: graphicsInfraestructuras, // new updated features
        deleteFeatures: features // delete the old features
      })
    }
  }
}

async function createGraphicsInfraestructuras () {
  let deferred = Q.defer()
  const [Graphic, Point] = await loadModules([
    'esri/Graphic',
    'esri/geometry/Point'
  ])
  try {
    let infraestructuras = store.getters['infraestructura/infraestructuras'].filter(infra => infra.X && infra.Y)
    let tiposInfraestructuras = store.getters['infraestructura/tipos']
    let categoriasInfraestructuras = store.getters['infraestructura/categorias']
    let municipiosInfraestructuras = store.getters['infraestructura/municipios']
    let provinciasInfraestructuras = store.getters['infraestructura/provincias']
    let responsablesInfraestructuras = store.getters['infraestructura/responsables']
    let sources = []
    for (let i = 0; i < infraestructuras.length; i++) {
      let infraestructura = infraestructuras[i]
      let tipo = tiposInfraestructuras.find(x => x.ID_INFRAESTRUCTURA_TIPO === infraestructura.ID_INFRAESTRUCTURA_TIPO)
      let municipio = municipiosInfraestructuras.find(x => x.ID_MUNICIPIO === infraestructura.ID_MUNICIPIO)

      let attributes = {
        ID_INFRAESTRUCTURA: infraestructura.ID_INFRAESTRUCTURA,
        TIPO: tipo ? tipo.NOMBRE : null,
        CATEGORIA: categoriasInfraestructuras.find(x => x.ID_INFRAESTRUCTURA_CATEGORIA === tipo.ID_INFRAESTRUCTURA_CATEGORIA)
          ? categoriasInfraestructuras.find(x => x.ID_INFRAESTRUCTURA_CATEGORIA === tipo.ID_INFRAESTRUCTURA_CATEGORIA).NOMBRE
          : null,
        MUNICIPIO: municipio ? municipio.NOMBRE : null,
        PROVINCIA: provinciasInfraestructuras.find(x => x.ID_INE_PROVINCIA === municipio.ID_PROVINCIA)
          ? provinciasInfraestructuras.find(x => x.ID_INE_PROVINCIA === municipio.ID_PROVINCIA).LITERAL
          : null,
        RESPONSABLE: responsablesInfraestructuras.find(x => x.ID_ORGANISMO === infraestructura.ID_ORGANISMO)
          ? responsablesInfraestructuras.find(x => x.ID_ORGANISMO === infraestructura.ID_ORGANISMO).ORGANISMO
          : null,
        NOMBRE: infraestructura.NOMBRE,
        FECHA_ALTA: infraestructura.FECHA_ALTA,
        FECHA_BAJA: infraestructura.FECHA_BAJA,
        BORRADO: infraestructura.BORRADO,
        DESCRIPCION: infraestructura.DESCRIPCION,
        DIRECION: infraestructura.DIRECION,
        CODIGO: infraestructura.CODIGO,
        icon: constants.iconosTiposInfraestructuras.find(icono => icono.TIPO === tipo.NOMBRE).URL + '.png'
      }
      let latLong = convertirETRS89ToLatLon(infraestructura.X, infraestructura.Y)
      let geometry = new Point({
        x: latLong[0],
        y: latLong[1]
      })

      let graphic = new Graphic({
        geometry: geometry,
        attributes: attributes
      })
      sources.push(graphic)
    }
    deferred.resolve(sources)
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function renderInfraestructuraLayer () {
  const [UniqueValueRenderer, PictureMarkerSymbol] = await loadModules([
    'esri/renderers/UniqueValueRenderer',
    'esri/symbols/PictureMarkerSymbol'
  ])
  let renderer = new UniqueValueRenderer({
    field: 'TIPO',
    defaultSymbol: new PictureMarkerSymbol({
      url: 'icons/interrogacion_0.png', // URL por defecto si no hay coincidencia
      width: '40px',
      height: '40px'
    })
  })
  constants.iconosTiposInfraestructuras.forEach(async icono => {
    let value = icono.URL + '.png'
    renderer.addUniqueValueInfo({
      value: icono.TIPO,
      symbol: await createSymbolInfraestructuras('icons/' + value)
    })
  })

  return renderer
}

async function createSymbolInfraestructuras (icono) {
  const [PictureMarkerSymbol] = await loadModules([
    'esri/symbols/PictureMarkerSymbol'
  ])
  return new PictureMarkerSymbol({
    url: icono, // URL por defecto si no hay coincidencia
    width: '40px',
    height: '40px'
  })
}

// #region MEDIOS
let isPaintingMedios = false // Evitar que pinte multiples veces // TODO: dara problemas con hermes?

let pintaMedios = false
let pintaPersonal = false
export const redrawMedios = async (pintaMediosParam, pintaPersonalParam) => {
  if (pintaMediosParam) {
    pintaMedios = true
  }

  if (pintaPersonalParam) {
    pintaPersonal = true
  }

  if (!isPaintingMedios && pintaMedios && pintaPersonal) {
    isPaintingMedios = true

    // console.time('REDRAWMEDIOS')
    if (map) {
      let layerMediosActivos = map.findLayerById('layer-medios')

      if (
        layerMediosActivos
      ) {
        const [Query] = await loadModules([
          'esri/rest/support/Query'
        ])

        let graphicsMedios = await createGraphicsMedios()

        graphicsMedios.sort((a, b) => a.attributes.FECHA > b.attributes.FECHA)

        let query = layerMediosActivos.createQuery()
        query.where = '1=1'

        const { features } = await layerMediosActivos.queryFeatures(query)
        let graphicsAdd = []
        let graphicsActualizar = []
        let graphicsEliminar = []

        graphicsMedios.forEach(g => {
          let feature = features.find(f => String(f.attributes.ISSI) === String(g.attributes.ISSI))
          if (!feature) {
            graphicsAdd.push(g)

            return
          }

          let attributeChanged = g.attributes.FECHA !== feature.attributes.FECHA || g.attributes.ACTIVO !== feature.attributes.ACTIVO || g.attributes.icon !== feature.attributes.icon
          let coordsChanged = g.geometry.X !== feature.geometry.X || g.geometry.Y !== feature.geometry.Y
          if (attributeChanged || coordsChanged) {
            g.attributes.ID = feature.attributes.ID
            graphicsActualizar.push(g)
          }
        })

        graphicsEliminar = features.filter(a => !graphicsMedios.some(b => String(b.attributes.ISSI) === String(a.attributes.ISSI)))

        await layerMediosActivos.applyEdits({
          addFeatures: graphicsAdd, // new updated features
          updateFeatures: graphicsActualizar,
          deleteFeatures: graphicsEliminar // delete the old features
        })

        pintaMedios = false
        pintaPersonal = false
      }
    }
    // console.timeEnd('REDRAWMEDIOS')

    isPaintingMedios = false
  }
}

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

async function renderMedioLayer () {
  const [UniqueValueRenderer, PictureMarkerSymbol] = await loadModules([
    'esri/renderers/UniqueValueRenderer',
    'esri/symbols/PictureMarkerSymbol'
  ])

  let renderer = new UniqueValueRenderer({
    field: 'icon',
    defaultSymbol: new PictureMarkerSymbol({
      url: 'icons/interrogacion_0.png', // URL por defecto si no hay coincidencia
      width: '40px',
      height: '40px'
    })
  })

  constants.iconosTiposMedios.forEach(async icono => {
    for (let i = 0; i < 5; i++) {
      let value = icono.URL + '_' + i + '.png'
      renderer.addUniqueValueInfo({
        value: value,
        symbol: await createSymbolMedio('icons/' + value)
      })
    }
  })

  constants.iconosFilterISSI.forEach(async icono => {
    for (let i = 0; i < 5; i++) {
      let value = icono.URL + '_' + i + '.png'
      renderer.addUniqueValueInfo({
        value: value,
        symbol: await createSymbolMedio('icons/' + value)
      })
    }
  })

  for (let i = 0; i < 5; i++) {
    let value = 'interrogacion_' + i + '.png'
    renderer.addUniqueValueInfo({
      value: value,
      symbol: await createSymbolMedio('icons/' + value)
    })
  }

  return renderer
}

async function createSymbolMedio (icono) {
  const [PictureMarkerSymbol] = await loadModules([
    'esri/symbols/PictureMarkerSymbol'
  ])

  return new PictureMarkerSymbol({
    url: icono, // URL por defecto si no hay coincidencia
    width: '40px',
    height: '40px'
  })
}

async function featureLayerlabels (labelAtribute) {
  const [LabelClass] = await loadModules([
    'esri/layers/support/LabelClass'
  ])

  const statesLabelClass = new LabelClass({
    labelExpressionInfo: { expression: '$feature.' + labelAtribute },
    labelPlacement: 'below-center',
    symbol: {
      type: 'text', // autocasts as new TextSymbol()
      color: 'black',
      haloColor: 'white',
      haloSize: '2px',
      font: {
        // autocasts as new Font()
        size: 8,
        family: 'Arial Unicode MS',
        weight: 'bold'
      }
    }
  })
  return [statesLabelClass]
}

let drawLines = false
export function drawLineas (draw) {
  drawLines = draw
}

async function createGraphicsMedios () {
  let deferred = Q.defer()

  try {
    let medios = store.getters['medio/medios']
    let personales = store.getters['personal/personales'].filter(x => x.GEO_MEDIO_DISPOSITIVO && x.GEO_MEDIO_DISPOSITIVO.GEO_POS_ACTUAL)

    let posSinMedios = store.getters['medio/posSinMedios']

    let sourcesMedios = []

    // Posiciones sin medios asociados
    posSinMedios.forEach(async pos => {
      let graficos = await createGraphicPosSinMedio(pos)
      sourcesMedios.push(graficos)
    })

    // Posiciones personales
    personales.forEach(async personal => {
      let graficos = await createGraphicPersonal(personal)
      sourcesMedios.push(graficos)
    })

    medios.filter(x => x.ULT_POSICION && x.ULT_POSICION.LATITUD && x.ULT_POSICION.LONGITUD && x.MEDIO_DISPOSITIVOS).forEach(async medio => {
      sourcesMedios.push(...(await createGraphicMedio(medio)))
    })

    deferred.resolve(
      sourcesMedios
    )
  } catch (err) {
    VueInst.$log.error(err)
  }
  return deferred.promise
}

async function createGraphicMedio (medio) {
  const [Point, Graphic] = await loadModules([
    'esri/geometry/Point',
    'esri/Graphic'
  ])

  let estadosMedios = constants.estadosMedio

  let lastPosition = medio.ULT_POSICION

  // Icono segun tipo
  let icon = constants.iconosTiposMedios.find((x) => x.TIPO === medio.TIPO)
    ? constants.iconosTiposMedios.find((x) => x.TIPO === medio.TIPO).URL
    : 'autobomba'

  let tipo = constants.iconosTiposMedios.find((x) => x.TIPO === medio.TIPO).TIPO

  // Color segun estado
  let color = estadosMedios.find((x) => x.ID === medio.ESTADO).COLOR
  let colorId = estadosMedios.find((x) => x.ID === medio.ESTADO).ID

  let alias = medio.ALIAS

  if (!alias) {
    alias = medio.NOMBRE ? medio.NOMBRE : medio.MEDIO
  }

  // Atributos
  let attr = {
    ID_MEDIO: medio.ID_MEDIO,
    NOMBRE: medio.NOMBRE,
    ALIAS: alias,
    ZONA: medio.ZONA,
    GUARDIAS: medio.GUARDIAS,
    TELEFONO_INTERNO: medio.TELEFONO_INTERNO,
    TELEFONO_EXTERNO: medio.TELEFONO_EXTERNO,
    // FECHA: DateService.formatDate(medio.FECHA, 'DD/MM/YYYY HH:mm'),
    // TIPO: medio.TIPO,
    CATEGORIA: medio.CATEGORIA,
    MEDIO: medio.MEDIO,
    HORA_ENTRADA: medio.HORA_ENTRADA,
    HORA_SALIDA: medio.HORA_SALIDA,

    ID_DISPOSITIVO: lastPosition.ID_GEO_DISPOSITIVO,
    CODIGO_EXTERNO: lastPosition.CODIGO_EXTERNO,
    FECHA: DateService.formatDate(lastPosition.FECHA, 'DD/MM/YYYY HH:mm'),
    PROVEEDOR: lastPosition.PROVEEDOR,

    ISSI: lastPosition.CODIGO_EXTERNO,
    TIPO: tipo || 'autobomba'
  }

  // Punto
  let geometry = new Point({
    x: lastPosition.LONGITUD,
    y: lastPosition.LATITUD
  })

  // Linea a incendio
  let graphicLine = null

  // Rombo emergencia
  let graphicRombo = {
    geometry: geometry,
    attributes: attr,
    symbol: {
      type: 'simple-marker',
      style: 'diamond',
      color: 'yellow',
      size: '52px',
      outline: {
        color: 'black',
        width: '4px'
      }
    }
  }

  if ((medio.ESTADO === constants.estadosMedioIncendio.AVISO || medio.ESTADO === constants.estadosMedioIncendio.ACTUANDO) && drawLines) {
    graphicLine = await createGraphicLinesMedioToIncendio(medio, color)
  }

  let graphicResult = constants.verMultiplesDispositivos ? await createGraphicMediosGeodispositivos(medio, attr, icon, colorId, graphicLine, graphicRombo) : await createGraphicMediosUnicoGeodispositivo(medio, attr, icon, colorId, lastPosition, graphicLine, geometry, graphicRombo)

  return graphicResult
}

async function createGraphicLinesMedioToIncendio (medio, color) {
  let incendios = store.getters['incendio/incendios']

  let incendio = incendios.find(x => x.ID_INCENDIO === medio.ID_INCENDIO)

  // Solo pinta linea para aviso y llegada inc
  let graphicLine = {
    geometry: {
      type: 'polyline',
      paths: [
        [medio.ULT_POSICION.LONGITUD, medio.ULT_POSICION.LATITUD],
        [incendio.LONGITUD, incendio.LATITUD]
      ]
    },
    attributes: {
      notShowMaptip: true
    },
    symbol: {
      type: 'simple-line',
      style: 'short-dot',
      color: color,
      width: 2
    }
  }

  return graphicLine
}

async function createGraphicMediosGeodispositivos (medio, attr, icon, colorId, graphicLine, graphicRombo) {
  const [Point, Graphic] = await loadModules([
    'esri/geometry/Point',
    'esri/Graphic'
  ])

  let now = VueInst.$date.now()
  let tiempoInactivo = parseInt(
    store.getters['parametro/getParametro']('TIEMPO_POS_INACTIVO')
  )

  let fechaMargenInactivo = VueInst.$date.subtract(
    now,
    tiempoInactivo,
    'minute'
  )

  let sourcesMedios = []

  for (let j = 0; j < medio.MEDIO_DISPOSITIVOS.length; j++) {
    let dispositivo = medio.MEDIO_DISPOSITIVOS[j]

    // Atributos del dispositivo
    let attrDisp = JSON.parse(JSON.stringify(attr))
    attrDisp.ID_DISPOSITIVO = dispositivo.ID_DISPOSITIVO
    attrDisp.CODIGO_EXTERNO = dispositivo.CODIGO_EXTERNO
    attrDisp.ISSI = dispositivo.CODIGO_EXTERNO

    attrDisp.FECHA = DateService.formatDate(
      dispositivo.FECHA,
      'DD/MM/YYYY HH:mm'
    )
    attrDisp.PROVEEDOR = dispositivo.PROVEEDOR

    let geometryDisp = new Point({
      x: dispositivo.LONGITUD,
      y: dispositivo.LATITUD
    })

    let lastPosFecha = VueInst.$date.parseDate(dispositivo.FECHA)
    let graficos = []

    if (medio.EN_EMERGENCIA) {
      let graphicRomboDisp = new Graphic(graphicRombo)
      graphicRomboDisp.geometry = geometryDisp
      graficos.push(graphicRomboDisp)
    }

    let iconDispositivo = icon
    if (!iconDispositivo) {
      iconDispositivo = 'tecnico_extincion'
    }
    iconDispositivo =
      iconDispositivo +
      (lastPosFecha >= fechaMargenInactivo ? '_' + colorId : '_4') +
      '.png'

    attrDisp.icon = iconDispositivo
    attrDisp.ACTIVO = lastPosFecha < fechaMargenInactivo && !medio.ID_INCENDIO ? 0 : 1
    attrDisp.PRIORIDAD = lastPosFecha < fechaMargenInactivo ? 0 : 3

    // Graphic principal
    let graphic = {
      geometry: geometryDisp,
      attributes: attrDisp,
      symbol: {
        type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
        url: 'icons/' + iconDispositivo,
        width: '40px',
        height: '40px'
      }
      // popupTemplate: popupTemplate
    }
    graficos.push(graphic)

    if (graphicLine) graficos.push(graphicLine)

    sourcesMedios.push(...graficos)
  }

  return sourcesMedios
}

async function createGraphicMediosUnicoGeodispositivo (medio, attr, icon, colorId, lastPosition, graphicLine, geometry, graphicRombo) {
  let now = VueInst.$date.now()
  let tiempoInactivo = parseInt(
    store.getters['parametro/getParametro']('TIEMPO_POS_INACTIVO')
  )

  let fechaMargenInactivo = VueInst.$date.subtract(
    now,
    tiempoInactivo,
    'minute'
  )

  let sourcesMedios = []

  let lastPosFecha = VueInst.$date.parseDate(lastPosition.FECHA)
  let graficos = []

  if (medio.EN_EMERGENCIA) {
    graficos.push(graphicRombo)
  }

  // Color del icono
  if (!icon) {
    icon = 'tecnico_extincion'
  }
  icon =
              icon +
              (lastPosFecha >= fechaMargenInactivo ? '_' + colorId : '_4') +
              '.png'

  attr.icon = icon
  attr.ACTIVO = lastPosFecha < fechaMargenInactivo && !medio.ID_INCENDIO ? 0 : 1
  attr.PRIORIDAD = lastPosFecha < fechaMargenInactivo ? 0 : 3

  // Graphic principal
  let graphic = {
    geometry: geometry,
    attributes: attr,
    symbol: {
      type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
      // url: constants.server.URLSERVER + '/images/icons/' + icon,
      url: 'icons/' + icon,
      width: '40px',
      height: '40px'
    }
    // popupTemplate: popupTemplate
  }
  graficos.push(graphic)

  if (graphicLine) graficos.push(graphicLine)

  sourcesMedios.push(...graficos)

  return sourcesMedios
}

async function createGraphicPosSinMedio (pos) {
  const [Point] = await loadModules([
    'esri/geometry/Point'
  ])
  let now = VueInst.$date.now()
  let tiempoInactivo = parseInt(
    store.getters['parametro/getParametro']('TIEMPO_POS_INACTIVO')
  )

  let fechaMargenInactivo = VueInst.$date.subtract(
    now,
    tiempoInactivo,
    'minute'
  )

  let lastPosFecha = VueInst.$date.parseDate(pos.FECHA)

  let estadosMedios = constants.estadosMedio

  let tetra = String(pos.GEO_MEDIO_DISPOSITIVO.GEO_DISPOSITIVO.CODIGO_EXTERNO).substring(0, 4)
  let iconoISSI = constants.iconosFilterISSI.find((x) => x.RANGO.includes(tetra))
  // Buscar icono por refijo de ISSI
  let icon = iconoISSI && iconoISSI.URL !== ''
    ? iconoISSI.URL
    : 'interrogacion'

  let colorId = estadosMedios[0].ID

  let geoDispositivo = pos.GEO_MEDIO_DISPOSITIVO.GEO_DISPOSITIVO
  let attr = {
    ALIAS: geoDispositivo.CODIGO_EXTERNO,
    ID_DISPOSITIVO: geoDispositivo.ID_GEO_DISPOSITIVO,
    CODIGO_EXTERNO: geoDispositivo.CODIGO_EXTERNO,
    FECHA: DateService.formatDate(pos.FECHA, 'DD/MM/YYYY HH:mm'),
    PROVEEDOR: geoDispositivo.PROVEEDOR.NOMBRE,
    ACTIVO: lastPosFecha < fechaMargenInactivo ? 0 : 1,
    PRIORIDAD: lastPosFecha < fechaMargenInactivo ? -1 : 1,
    NOMBRE: geoDispositivo.CODIGO_EXTERNO,
    ISSI: geoDispositivo.CODIGO_EXTERNO,
    TIPO: iconoISSI && iconoISSI.TIPO ? iconoISSI.TIPO : 'Desconocido'
  }

  let geometry = new Point({
    x: pos.LONGITUD,
    y: pos.LATITUD
  })

  // Color del icono
  icon += (VueInst.$date.parseDate(pos.FECHA) >= fechaMargenInactivo ? '_' + colorId : '_4') +
          '.png'

  attr.icon = icon
  // Graphic principal
  let graphic = {
    geometry: geometry,
    attributes: attr,
    symbol: {
      type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
      // url: constants.server.URLSERVER + '/images/icons/' + icon,
      url: 'icons/' + icon,
      width: '40px',
      height: '40px'
    }
    // popupTemplate: popupTemplate
  }

  return graphic
}

async function createGraphicPersonal (personal) {
  const [Point] = await loadModules([
    'esri/geometry/Point'
  ])
  let now = VueInst.$date.now()
  let tiempoInactivo = parseInt(
    store.getters['parametro/getParametro']('TIEMPO_POS_INACTIVO')
  )
  let tiempoDesactivado = parseInt(
    store.getters['parametro/getParametro']('TIEMPO_POS_DESACTIVADO')
  )

  let fechaMargenDesactivado = VueInst.$date.subtract(
    now,
    tiempoDesactivado,
    'day'
  )

  let fechaMargenInactivo = VueInst.$date.subtract(
    now,
    tiempoInactivo,
    'minute'
  )

  let lastPosFecha = VueInst.$date.parseDate(personal.GEO_MEDIO_DISPOSITIVO.GEO_POS_ACTUAL.FECHA)

  let estadosMedios = constants.estadosMedio

  let icon = 'agente_forestal'

  let colorId = estadosMedios[0].ID

  let geoDispositivo = personal.GEO_MEDIO_DISPOSITIVO.GEO_DISPOSITIVO
  let attr = {
    ALIAS: personal.ALIAS ? personal.ALIAS : personal.NOMBRE,
    NOMBRE: personal.NOMBRE,
    APELLIDOS: personal.APELLIDOS,
    TELEFONO: personal.TELEFONO,
    ID_DISPOSITIVO: geoDispositivo.ID_GEO_DISPOSITIVO,
    CODIGO_EXTERNO: geoDispositivo.CODIGO_EXTERNO,
    FECHA: DateService.formatDate(personal.GEO_MEDIO_DISPOSITIVO.GEO_POS_ACTUAL.FECHA, 'DD/MM/YYYY HH:mm'),
    PROVEEDOR: geoDispositivo.PROVEEDOR.NOMBRE,
    ID_PERSONAL: personal.ID_PERSONAL,
    ACTIVO: lastPosFecha < fechaMargenInactivo ? 0 : 1,
    PRIORIDAD: lastPosFecha < fechaMargenInactivo ? 0 : 2,
    ISSI: geoDispositivo.CODIGO_EXTERNO,
    TIPO: icon
  }

  let position = personal.GEO_MEDIO_DISPOSITIVO.GEO_POS_ACTUAL

  let geometry = new Point({
    x: position.LONGITUD,
    y: position.LATITUD
  })

  // Color del icono
  icon += (VueInst.$date.parseDate(position.FECHA) >= fechaMargenInactivo ? '_' + colorId : '_4') +
          '.png'

  attr.icon = icon
  // Graphic principal
  let graphic = {
    geometry: geometry,
    attributes: attr,
    symbol: {
      type: 'picture-marker', // autocasts as new PictureMarkerSymbol()
      // url: constants.server.URLSERVER + '/images/icons/' + icon,
      url: 'icons/' + icon,
      width: '40px',
      height: '40px'
    }
    // popupTemplate: popupTemplate
  }

  return graphic
}
// #endregion

// #region TRACKS
export const drawTrack = async function (
  idMedio,
  medio,
  positions,
  activateGOTO
) {
  if (map) {
    let layer = map.findLayerById('layer-track')
    let newPositions = await calcularVelocidad(positions)

    let graphics = await createGraphicsTrack(idMedio, medio, newPositions)
    layer.addMany(graphics)
    if (activateGOTO) {
      mapView.goTo(graphics, {
        duration: 3000,
        easing: 'in-expo'
      })
    }

    layer.visible = true
  }
}

export const hideTrack = function (idMedio) {
  if (map) {
    let layer = map.findLayerById('layer-track')
    layer.removeMany(
      layer.graphics.items.filter((x) => x.attributes.ID_MEDIO === idMedio)
    )
    layer.visible = true
  }
}

export const exportTrack = function (data) {
  if (map) {
    let layer = map.findLayerById('layer-track')

    let points = layer.graphics.items.filter(
      (x) => x.attributes.ID_MEDIO === data.idMedio
    )
    points.sort((a, b) => (a.attributes.FECHA > b.attributes.FECHA ? 1 : -1))

    let fechas = points.map((x) => x.attributes.FECHA).filter((x) => x)
    let fechaInicio = fechas[0]

    // Construir GEOJSON
    let features = [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: points
            .map((x) => x.geometry)
            .map((x) => [x.longitude, x.latitude])
        },
        properties: {
          MEDIO: data.medio
        }
      }
    ]

    for (let i = 0; i < points.length; i++) {
      if (!points[i].attributes.VELOCIDAD) {
        points[i].attributes.VELOCIDAD = 0
      }
      features.push({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            points[i].geometry.longitude,
            points[i].geometry.latitude
          ]
        },
        properties: points[i].attributes
      })
    }

    let geoJson = {
      type: 'FeatureCollection',
      features: features
    }

    let nombre = data.medio + '_' + fechaInicio

    // Fichero SHP
    try {
      const shpwrite = require('shp-write-update')
      let options = {
        folder: 'Track_' + data.medio,
        types: {
          point: 'PUNTOS_' + nombre,
          polygon: 'POLIGONOS' + nombre,
          line: 'LINEAS' + nombre,
          polyline: 'POLYLINE_' + nombre
        }
      }
      shpwrite.download(geoJson, options)
    } catch (err) {
      console.error('Error generando shp', err)
    }

    // Fichero KML
    try {
      const tokml = require('tokml')
      let kml = tokml(geoJson)
      let element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(kml)
      )
      element.setAttribute('download', 'Track_' + nombre + '.kml')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    } catch (err) {
      console.error('Error generando kml', err)
    }
  }
}
async function createGraphicsTrack (idMedio, medio, posiciones) {
  let deferred = Q.defer()

  try {
    const [Point, ObjectSymbol3DLayer, PointSymbol3D] = await loadModules([
      'esri/geometry/Point',
      'esri/symbols/ObjectSymbol3DLayer',
      'esri/symbols/PointSymbol3D'
    ])
    let rgbRandomArrow = [
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255)
    ]
    let is3D = store.getters['map/isMap3D']
    let rgbRandom = [
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255)
    ]
    posiciones.reverse() // Poner en orden creciente

    let sources = []
    let pathPosiciones = []
    for (let i = 0; i < posiciones.length; i++) {
      let rumbo = 0

      if (i < posiciones.length - 1) {
        // Calcula el rumbo para todas las posiciones menos para la ultima
        rumbo = calcularRumbo(
          posiciones[i].LATITUD,
          posiciones[i].LONGITUD,
          posiciones[i + 1].LATITUD,
          posiciones[i + 1].LONGITUD
        )
      }
      rumbo = (rumbo + 180) % 360 // La flecha original apunta hacia abajo

      pathPosiciones.push([posiciones[i].LONGITUD, posiciones[i].LATITUD])

      if (is3D) {
        sources.push({
          // Cada punto
          geometry: new Point({
            x: posiciones[i].LONGITUD,
            y: posiciones[i].LATITUD
          }),
          attributes: {
            ID_MEDIO: idMedio,
            MEDIO: medio,
            FECHA: posiciones[i].FECHA,
            VELOCIDAD: posiciones[i].VELOCIDAD
          },
          symbol: new PointSymbol3D({
            symbolLayers: [
              new ObjectSymbol3DLayer({
                anchor: 'relative',
                anchorPosition: { x: 0, y: 0, z: -0.5 },

                width: 200,
                height: 200,
                tilt: 85,
                heading: rumbo,
                resource: {
                  primitive: 'tetrahedron'
                },
                material: {
                  color: '#FFD700'
                }
              })
            ]
          })
        })
      } else {
        sources.push({
          // Cada punto
          geometry: new Point({
            x: posiciones[i].LONGITUD,
            y: posiciones[i].LATITUD
          }),
          attributes: {
            ID_MEDIO: idMedio,
            MEDIO: medio,
            FECHA: posiciones[i].FECHA,
            VELOCIDAD: posiciones[i].VELOCIDAD
          },
          symbol: {
            type: 'simple-marker',
            path: 'M14.5,29 23.5,0 14.5,9 5.5,0z',
            angle: rumbo,
            color: rgbRandomArrow,
            size: '10px',
            outline: {
              color: rgbRandomArrow
            }
          }
        })

        // Etiqueta del medio sobre el punto
        // se añade cada 3 puntos, para no sobrecargar
        if (i % 3 === 0) {
          sources.push({
            // Cada punto
            geometry: new Point({
              x: posiciones[i].LONGITUD,
              y: posiciones[i].LATITUD
            }),
            attributes: {
              ID_MEDIO: idMedio,
              MEDIO: medio,
              FECHA: posiciones[i].FECHA,
              VELOCIDAD: posiciones[i].VELOCIDAD,
              IS_TAG: true
            },
            symbol: {
              type: 'text', // autocasts as new TextSymbol()
              color: rgbRandomArrow,
              haloColor: 'black',
              haloSize: '0.75px',
              text: medio,
              yoffset: 6,
              font: {
                // autocasts as new Font()
                size: 10,
                family: 'Arial Unicode MS'
              }
            }
          })
        }
      }
    }
    sources.unshift({
      // Linea
      geometry: {
        type: 'polyline',
        paths: pathPosiciones
      },
      attributes: {
        ID_MEDIO: idMedio
      },
      symbol: {
        type: 'simple-line',
        style: 'short-dot',
        color: rgbRandom,
        width: 1
      }
    })

    deferred.resolve(sources)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function calcularVelocidad (puntos) {
  // TODO: hacer en backend
  let deferred = Q.defer()

  try {
    const [Point, geometryEngine, SpatialReference, webMercatorUtils] =
      await loadModules([
        'esri/geometry/Point',
        'esri/geometry/geometryEngine',
        'esri/geometry/SpatialReference',
        'esri/geometry/support/webMercatorUtils'
      ])

    let sr = new SpatialReference({ wkid: 102100 })
    for (let i = 0; i < puntos.length; i++) {
      if (i === 0) {
        puntos[i].VELOCIDAD = 0
      } else if (i < puntos.length - 1) {
        let posPoint = webMercatorUtils.lngLatToXY(
          puntos[i].LONGITUD,
          puntos[i].LATITUD
        )
        let posFollowPoint = webMercatorUtils.lngLatToXY(
          puntos[i + 1].LONGITUD,
          puntos[i + 1].LATITUD
        )

        let point = new Point({
          x: posPoint[0],
          y: posPoint[1],
          spatialReference: sr
        })

        let followPoint = new Point({
          x: posFollowPoint[0],
          y: posFollowPoint[1],
          spatialReference: sr
        })

        let distancia = geometryEngine.distance(
          point,
          followPoint,
          'kilometers'
        )

        let fechaPoint = DateService.parseDate(puntos[i].FECHA)
        let fechaFollowPoint = DateService.parseDate(puntos[i + 1].FECHA)

        let tiempo = fechaPoint.diff(fechaFollowPoint, 'seconds') / 3600

        let velocidad = distancia / tiempo

        puntos[i].VELOCIDAD = Math.trunc(velocidad)
      }
    }
    deferred.resolve(puntos)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

function toRadians (degrees) {
  return (degrees * Math.PI) / 180
}

function toDegrees (radians) {
  return (radians * 180) / Math.PI
}
function calcularRumboX (startLat, startLng, destLat, destLng) {
  // TODO: hacer en backend
  startLat = toRadians(startLat)
  startLng = toRadians(startLng)
  destLat = toRadians(destLat)
  destLng = toRadians(destLng)

  let x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
  let brng = toDegrees(x)
  return (brng + 360) % 360
}
function calcularRumboY (startLat, startLng, destLat, destLng) {
  // TODO: hacer en backend
  startLat = toRadians(startLat)
  startLng = toRadians(startLng)
  destLat = toRadians(destLat)
  destLng = toRadians(destLng)

  let y = Math.sin(destLng - startLng) * Math.cos(destLat)
  let brng = toDegrees(y)
  return (brng + 360) % 360
}
function calcularRumbo (startLat, startLng, destLat, destLng) {
  // TODO: hacer en backend
  startLat = toRadians(startLat)
  startLng = toRadians(startLng)
  destLat = toRadians(destLat)
  destLng = toRadians(destLng)

  let y = Math.sin(destLng - startLng) * Math.cos(destLat)
  let x =
    Math.cos(startLat) * Math.sin(destLat) -
    Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng)
  let brng = Math.atan2(y, x)
  brng = toDegrees(brng)
  return (brng + 360) % 360
}
// #endregion

// #region PLANES_OPERACIONES
let planOpSVM = null

export const initPlanOperacionesSketchViewModel = async function (data) {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel'
    ])
    let callbackCreate = data.callbackCreate
    let callbackUpdate = data.callbackUpdate
    let callbackDelete = data.callbackDelete

    let layer = map.findLayerById('layer-plan-operaciones')

    planOpSVM = new SketchViewModel({
      view: mapView,
      layer: layer
    })
    planOpSVM.on(['create', 'delete', 'update'], async (evt) => {
      // Arrow logic
      if (
        isDrawingArrow &&
        evt.type === 'create' &&
        evt.graphic &&
        evt.graphic.geometry.type === 'polyline'
      ) {
        if (evt.state === 'active') {
          if (evt.graphic.geometry.paths[0].length === 3) {
            // Si esta dibujando flecha y tiene 3 puntos (2 reales) terminar el dibujo
            planOpSVM.complete()
          }
        }
      }

      if (evt.state === 'complete' && evt.type === 'create') {
        // Crear grafico
        let attributes = {
          ID_GRAFICO: VueInst.$uuid.createUUID()
        }

        evt.graphic.attributes = JSON.parse(JSON.stringify(attributes)) // Hack para quitar el __observer__ que causa un bucle infinito a arcgis
        let arrowColor = [
          Math.floor(Math.random() * 255),
          Math.floor(Math.random() * 255),
          Math.floor(Math.random() * 255)
        ]

        let geometry
        let type = evt.graphic.geometry.type
        let symbol
        if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'simple-marker'
        ) {
          // Punto
          // console.log('Punto')
          let defaultSymbol = planOpSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          symbol = {
            type: defaultSymbol.type,
            color: defaultSymbol.color,
            // 'angle': defaultSymbol.angle,
            size: defaultSymbol.size,
            style: defaultSymbol.style
          }
        } else if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'picture-marker'
        ) {
          // Icono
          // console.log('Icono')
          let defaultSymbol = planOpSVM.pointSymbol
          attributes.LABEL = {
            label: defaultSymbol.label,
            src: defaultSymbol.url
          }

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]

          type = 'icon'

          // Simbolo que añade una circunferencia al icono, en caso de ser medio externo
          // mediante la variable 'tipoLinea' para reutilizar
          symbol = {
            type: 'cim',
            // CIM Line Symbol
            data: {
              type: 'CIMSymbolReference',
              symbol: {
                type: 'CIMPointSymbol',
                symbolLayers: [
                  {
                    // arrow symbol
                    type: 'CIMVectorMarker',
                    enable: tipoLinea,
                    size: 30,
                    frame: {
                      xmin: 2.5,
                      ymin: 2.5,
                      xmax: 14,
                      ymax: 14
                    },
                    markerGraphics: [
                      {
                        type: 'CIMMarkerGraphic',
                        geometry: {
                          rings: [
                            [
                              [8.5, 0.2],
                              [7.06, 0.33],
                              [5.66, 0.7],
                              [4.35, 1.31],
                              [3.16, 2.14],
                              [2.14, 3.16],
                              [1.31, 4.35],
                              [0.7, 5.66],
                              [0.33, 7.06],
                              [0.2, 8.5],
                              [0.33, 9.94],
                              [0.7, 11.34],
                              [1.31, 12.65],
                              [2.14, 13.84],
                              [3.16, 14.86],
                              [4.35, 15.69],
                              [5.66, 16.3],
                              [7.06, 16.67],
                              [8.5, 16.8],
                              [9.94, 16.67],
                              [11.34, 16.3],
                              [12.65, 15.69],
                              [13.84, 14.86],
                              [14.86, 13.84],
                              [15.69, 12.65],
                              [16.3, 11.34],
                              [16.67, 9.94],
                              [16.8, 8.5],
                              [16.67, 7.06],
                              [16.3, 5.66],
                              [15.69, 4.35],
                              [14.86, 3.16],
                              [13.84, 2.14],
                              [12.65, 1.31],
                              [11.34, 0.7],
                              [9.94, 0.33],
                              [8.5, 0.2]
                            ]
                          ]
                        },
                        symbol: {
                          // black fill for the arrow symbol
                          type: 'CIMPolygonSymbol',
                          symbolLayers: [
                            {
                              type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                              enable: true,
                              width: 2,
                              color: [10, 10, 10, 255]
                            }
                          ]
                        }
                      }
                    ]
                  },
                  {
                    type: 'CIMPictureMarker',
                    enable: true,
                    url: defaultSymbol.url,
                    size: 40 // 60
                  }
                ]
              }
            }
          }
          evt.graphic.symbol = symbol
        } else if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'text'
        ) {
          // Texto
          let defaultSymbol = planOpSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          type = 'text'
          symbol = {
            type: 'text',
            color: defaultSymbol.arrowColor,
            text: defaultSymbol.text,
            xoffset: 0,
            yoffset: 0,
            haloColor: 'white',
            haloSize: '20px',
            font: {
              size: defaultSymbol.font.size,
              family: 'Arial Unicode MS',
              weight: 'bold'
            }
          }
        } else if (
          !isDrawingArrow &&
          evt.graphic.geometry.type === 'polyline'
        ) {
          // Linea
          if (!tipoLinea) {
            // Lineas antiguas
            let defaultSymbol = planOpSVM.polylineSymbol

            geometry = evt.graphic.geometry.paths[0]
            symbol = {
              type: defaultSymbol.type,
              color: defaultSymbol.color,
              width: defaultSymbol.width,
              style: defaultSymbol.style,
              join: defaultSymbol.join,
              miterLimit: defaultSymbol.miterLimit
            }
          } else {
            // Lineas nuevas
            let g = await makeCustomLine(
              evt.graphic.geometry.paths[0],
              tipoLinea
            )

            geometry = evt.graphic.geometry.paths[0]
            evt.graphic.symbol = g.symbol
            symbol = g.symbol
          }
          attributes.LABEL = planOpSVM.polylineSymbol.label
        } else if (isDrawingArrow || evt.graphic.geometry.type === 'polygon') {
          // Poligonos y flecha
          if (!tipoLinea) {
            // Poligonos y flechas old
            let defaultSymbol = planOpSVM.polygonSymbol

            if (isDrawingArrow) {
              // Convertir la linea a flecha y crear el graphic
              isDrawingArrow = false
              let g = await makeArrow(
                evt.graphic.geometry.paths[0],
                defaultSymbol.color
              )
              evt.graphic.geometry = g.geometry
              evt.graphic.symbol = g.symbol
              type = 'polygon'
            }

            geometry = evt.graphic.geometry.rings[0]
            symbol = {
              type: defaultSymbol.type,
              color: defaultSymbol.color,
              style: defaultSymbol.style,
              outline: {
                color: defaultSymbol.outline.color,
                width: defaultSymbol.outline.width
              }
            }
          } else {
            // New poligons
            let g = await makeCustomPolygon(
              evt.graphic.geometry.rings[0],
              tipoLinea
            )

            geometry = evt.graphic.geometry.rings[0]

            evt.graphic.geometry = g.geometry
            evt.graphic.symbol = g.symbol
            // type = 'polyline'
            type = 'polygon'
            symbol = g.symbol
          }

          attributes.LABEL = planOpSVM.polygonSymbol.label
        }

        let graphic = {
          // TODO: poner los mismos nombres que en BBDD y cambiar en backend en (plan_operaciones addGraphics)
          attributes: attributes,
          coordenadas: geometry,
          type: type,
          symbol: symbol
        }

        callbackCreate(graphic)
      } else if (evt.state === 'complete' && evt.type === 'update') {
        // Actualizar grafico
        let graphic = evt.graphics[0]
        let geom = graphic.geometry

        let newGeometryGraphic
        if (geom.type === 'point') {
          newGeometryGraphic = [geom.x, geom.y]
        } else if (geom.type === 'polyline') {
          newGeometryGraphic = geom.paths[0]
        } else if (geom.type === 'polygon') {
          newGeometryGraphic = geom.rings[0]
        }

        // geometry.type = graphic.geometry.type
        let newGraphic = {
          ATRIBUTOS: graphic.attributes,
          GEOMETRIA: newGeometryGraphic
        }

        callbackUpdate(newGraphic)
      } else if (evt.type === 'delete') {
        // Borrar grafico
        callbackDelete(evt.graphics[0].attributes.ID_GRAFICO)
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const paintEventPlanOperaciones = (event) => {
  let typeEvent = event.function
  let symbolColor = event.color ? event.color : [255, 0, 0, 0.5]

  isDrawingArrow = false
  tipoLinea = undefined

  if (typeEvent === 'cancel') {
    planOpSVM.cancel()
  } else if (typeEvent === 'point') {
    planOpSVM.pointSymbol = {
      type: 'simple-marker',
      style: 'circle',
      color: symbolColor,
      size: '16px',
      outline: {
        color: symbolColor,
        width: 3
      }
    }
  } else if (typeEvent === 'polyline') {
    if (event.custom) {
      tipoLinea = event.type_line
    } else {
      planOpSVM.polylineSymbol.color = symbolColor
      planOpSVM.polylineSymbol.style = event.type_line
        ? event.type_line
        : 'solid'
    }
    planOpSVM.polylineSymbol.label = event.labelPolyline
  } else if (typeEvent === 'polygon') {
    if (event.custom) {
      tipoLinea = event.type_polygon
    } else {
      planOpSVM.polygonSymbol.color = symbolColor
      planOpSVM.polygonSymbol.outline = {
        color: symbolColor,
        width: 2
      }
    }
    planOpSVM.polygonSymbol.label = event.labelPolygon
  } else if (typeEvent === 'icon') {
    typeEvent = 'point'
    tipoLinea = event.medio_externo
    planOpSVM.pointSymbol = {
      type: 'picture-marker',
      url: event.src,
      label: event.label,
      width: '35px',
      height: '35px'
    }
  } else if (typeEvent === 'text') {
    typeEvent = 'point'
    planOpSVM.pointSymbol = {
      type: 'text',
      color: symbolColor,
      text: event.text,
      xoffset: 0,
      yoffset: 0,
      haloColor: 'white',
      haloSize: '20px',
      font: {
        size: event.size,
        family: 'Arial Unicode MS',
        weight: 'bold'
      }
    }
  } else if (typeEvent === 'arrow') {
    typeEvent = 'polyline'
    isDrawingArrow = true

    planOpSVM.polygonSymbol.color = symbolColor
    planOpSVM.polygonSymbol.outline.color = symbolColor
  }
  planOpSVM.create(typeEvent)
}

export const stopPlanOperacionesSketchViewModel = function () {
  if (planOpSVM == null) return
  let layer = map.findLayerById('layer-plan-operaciones')
  layer.removeAll()

  planOpSVM.cancel()
  planOpSVM.destroy()
}

let isDrawingArrow = false
let tipoLinea
// Crea un poligono con forma de flecha a partir de una linea de dos puntos
async function makeArrow (geom, color) {
  let deferred = Q.defer()

  try {
    const [Point, Polygon, Graphic, GeometryEngine] = await loadModules([
      'esri/geometry/Point',
      'esri/geometry/Polygon',
      'esri/Graphic',
      'esri/geometry/geometryEngine'
    ])

    const start = new Point({ x: geom[0][0], y: geom[0][1] })
    const end = new Point({ x: geom[1][0], y: geom[1][1] })

    const dist = start.distance(end)
    const dy = end.y - start.y
    const dx = end.x - start.x
    let rads = Math.atan2(dx, dy)

    if (rads < 0) {
      rads = Math.abs(rads)
    } else {
      rads = 2 * Math.PI - rads
    }

    const degrees = (rads * 180) / Math.PI

    const width = 0.2 * dist // 1/5, can adjust

    const bottomLeft = [start.x - width / 10, start.y]
    const bottomRight = [start.x + width / 10, start.y]
    const midLeft = [bottomLeft[0], [bottomLeft[1] + dist - width]]
    const midRight = [bottomRight[0], [bottomRight[1] + dist - width]]
    const edgeLeft = [midLeft[0] - width / 2, midLeft[1]]
    const edgeRight = [midRight[0] + width / 2, midRight[1]]
    const point = [start.x, start.y + dist]

    const rings = [
      point,
      edgeLeft,
      midLeft,
      bottomLeft,
      bottomRight,
      midRight,
      edgeRight
    ]

    const poly = new Polygon({
      rings: [rings],
      spatialReference: { wkid: 102100 }
    })

    const rotatedPoly = GeometryEngine.rotate(poly, degrees, start)
    const symbol = {
      type: 'simple-fill',
      color: color,
      outline: {
        color: color,
        width: '2'
      }
    }

    deferred.resolve(
      new Graphic({
        geometry: rotatedPoly,
        symbol
      })
    )
  } catch (err) {
    deferred.reject(err)
    VueInst.$log.error(err)
  }

  return deferred.promise
}

// Crea una linea custom
// url: Es el tipo de linea seleccionado por el usuario
async function makeCustomLine (geom, url) {
  let deferred = Q.defer()

  try {
    const [Graphic, Polyline] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Polyline'
    ])

    const poly = new Polyline({
      paths: geom,
      spatialReference: { wkid: 102100 }
    })

    const symbol = symbology[url]
    deferred.resolve({
      geometry: poly,
      symbol
    })
  } catch (err) {
    deferred.reject(err)
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function makeCustomPolygon (geom, url) {
  let deferred = Q.defer()

  try {
    const [Polygon, Graphic] = await loadModules([
      'esri/geometry/Polygon',
      'esri/Graphic'
    ])

    const poly = new Polygon({
      rings: geom,
      spatialReference: { wkid: 102100 }
    })
    const symbol = symbology[url]
    deferred.resolve({
      geometry: poly,
      symbol
    })
  } catch (err) {
    deferred.reject(err)
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphicsDraw (graphicList) {
  let deferred = Q.defer()

  try {
    const [Graphic, Point, Polyline, Polygon, GeometryEngine] =
      await loadModules([
        'esri/Graphic',
        'esri/geometry/Point',
        'esri/geometry/Polyline',
        'esri/geometry/Polygon',
        'esri/geometry/geometryEngine'
      ])

    let graphicsDraw = graphicList
    let geometry
    let graphics = []
    for (let i = 0; i < graphicsDraw.length; i++) {
      let tipo = graphicsDraw[i].type || graphicsDraw[i].TIPO // TODO: esto no mola
      let coords = graphicsDraw[i].coordenadas || graphicsDraw[i].GEOMETRIA
      let simbolo = graphicsDraw[i].symbol || graphicsDraw[i].SIMBOLO
      let atributos = graphicsDraw[i].attributes || graphicsDraw[i].ATRIBUTOS
      atributos = JSON.parse(JSON.stringify(atributos)) // HACK __observer__

      if (tipo === 'point' || tipo === 'icon' || tipo === 'text') {
        geometry = new Point({
          x: coords[0],
          y: coords[1],
          spatialReference: { wkid: 3857 }
        })
      } else if (tipo === 'polyline') {
        geometry = new Polyline({
          hasZ: false,
          hasM: true,
          paths: coords,
          spatialReference: { wkid: 3857 }
        })

        atributos.LONGITUD = GeometryEngine.geodesicLength(geometry)
      } else if (tipo === 'polygon') {
        geometry = new Polygon({
          hasZ: false,
          hasM: true,
          rings: coords,
          spatialReference: { wkid: 3857 }
        })

        atributos.LONGITUD = GeometryEngine.geodesicLength(geometry)
        atributos.AREA = Math.abs(GeometryEngine.geodesicArea(geometry))
      }

      let graphic = new Graphic({
        geometry: geometry,
        symbol: simbolo,
        attributes: atributos
      })
      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}
function sortGraphics (graphics) {
  return graphics.sort((a, b) => {
    if (a.geometry.type === 'point') {
      return 1
    }
    if (b.geometry.type === 'point') {
      return -1
    }
    if (a.geometry.type === 'polyline' && b.geometry.type === 'polygon') {
      return 1
    }
    if (b.geometry.type === 'polyline' && a.geometry.type === 'polygon') {
      return -1
    }
    return 1
  })
}

export const drawGraphicsPlanOperaciones = async function (graphicList) {
  let deferred = Q.defer()

  let layer = map.findLayerById('layer-plan-operaciones')
  layer.removeAll()

  let graphics = await createGraphicsDraw(graphicList)
  let graphicsSorted = sortGraphics(graphics)
  layer.addMany(graphicsSorted)

  deferred.resolve()

  return deferred.promise
}
// #endregion

// #region PERIMETROS ********************************************************************************************************************************************************************
let planPSVM = null
let colorPerimetro = [255, 10, 10, 255]
export const initPerimetroSketchViewModel = async function (data) {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel'
    ])
    let callbackCreate = data.callbackCreate
    let callbackUpdate = data.callbackUpdate
    let callbackDelete = data.callbackDelete

    let layer = map.findLayerById('layer-perimetros')

    planPSVM = new SketchViewModel({
      view: mapView,
      layer: layer
    })
    planPSVM.on(['create', 'delete', 'update'], async (evt) => {
      if (
        evt.type === 'create' &&
        evt.graphic &&
        evt.graphic.geometry.type === 'polyline'
      ) {
        if (evt.state === 'active') {
          if (evt.graphic.geometry.paths[0].length === 3) {
            // Si esta dibujando flecha y tiene 3 puntos (2 reales) terminar el dibujo
            planPSVM.complete()
          }
        }
      }

      if (evt.state === 'complete' && evt.type === 'create') {
        // Crear grafico
        let attributes = {
          ID_GRAFICO: VueInst.$uuid.createUUID()
        }

        evt.graphic.attributes = JSON.parse(JSON.stringify(attributes)) // Hack para quitar el __observer__ que causa un bucle infinito a arcgis
        let geometry
        let type = evt.graphic.geometry.type
        let symbol

        if (type === 'polygon') {
          let g = await makeCustomPolygon(
            evt.graphic.geometry.rings[0],
            tipoLineaPerimetro
          )

          cambiarColor(g.symbol)
          geometry = evt.graphic.geometry

          evt.graphic.geometry = g.geometry
          evt.graphic.symbol = g.symbol
          type = 'polygon'
          symbol = g.symbol

          attributes.LABEL = planPSVM.polygonSymbol.label
        }

        let graphic = {
          // TODO: poner los mismos nombres que en BBDD y cambiar en backend en (plan_operaciones addGraphics)
          attributes: attributes,
          geometry: geometry,
          type: type,
          symbol: symbol
        }
        callbackCreate(graphic)
      } else if (evt.state === 'complete' && evt.type === 'update') {
        // Actualizar grafico
        let graphic = evt.graphics[0]

        let newGraphic = {
          attributes: graphic.attributes,
          geometry: graphic.geometry
        }

        callbackUpdate(newGraphic)
      } else if (evt.type === 'delete') {
        // Borrar grafico
        callbackDelete(evt.graphics[0].attributes.ID_GRAFICO)
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}
const cambiarColor = (objeto) => {
  for (let key in objeto) {
    if (key === 'color') {
      objeto[key] = colorPerimetro
    } else if (typeof objeto[key] === 'object') {
      cambiarColor(objeto[key])
    }
  }
}
let tipoLineaPerimetro
export const paintEventPerimetro = (event) => {
  let typeEvent = event.function

  tipoLineaPerimetro = undefined
  colorPerimetro = [255, 10, 10, 255]

  if (typeEvent === 'cancel') {
    planPSVM.cancel()
  } else if (typeEvent === 'polygon') {
    if (event.custom) {
      colorPerimetro = event.color
      // Esto es para pasar de gamma de 0-1 a 0-255
      colorPerimetro[3] *= 255
      tipoLineaPerimetro = event.type_line // aunque funcione como polígono, todo viene desde una línea
      event.label.color = event.color
      planPSVM.polygonSymbol.label = event.label
    }
  }
  planPSVM.create(typeEvent)
}

export const stopPerimeterSketchViewModel = function () {
  if (planPSVM == null) return
  let layer = map.findLayerById('layer-perimetros')
  layer.removeAll()

  planPSVM.cancel()
  planPSVM.destroy()
}

async function createGraphicsDrawPerimeter (graphicList) {
  let deferred = Q.defer()

  try {
    const [Graphic, Polygon, Point, Polyline] =
      await loadModules([
        'esri/Graphic',
        'esri/geometry/Polygon',
        'esri/geometry/Point',
        'esri/geometry/Polyline'
      ])

    let graphicsDrawPerimetro = graphicList
    let geometry
    let graphics = []

    for (let i = 0; i < graphicsDrawPerimetro.length; i++) {
      let tipo = graphicsDrawPerimetro[i].type
      let coords = graphicsDrawPerimetro[i].geometry
      let simbolo = graphicsDrawPerimetro[i].symbol
      let atributos = graphicsDrawPerimetro[i].attributes
      const individual = await measurePerimetros([graphicsDrawPerimetro[i]])

      Object.assign(atributos, {
        AREA: individual.area,
        PERIMETRO: individual.perimetro
      })

      atributos = JSON.parse(JSON.stringify(atributos)) // HACK __observer__
      if (tipo === 'polygon') {
        geometry = new Polygon({
          hasZ: false,
          hasM: true,
          rings: coords.rings[0],
          spatialReference: { wkid: 3857 }
        })
      }

      if (tipo === 'point') {
        geometry = new Point({
          latitude: coords.y,
          longitude: coords.x,
          spatialReference: { wkid: 3857 }
        })
      }

      if (tipo === 'polyline') {
        geometry = new Polyline({
          hasZ: false,
          hasM: true,
          paths: coords.paths,
          spatialReference: { wkid: 3857 }
        })
      }

      const is3D = store.getters['map/isMap3D']
      if (is3D && tipo === 'polygon') {
        const outline = simbolo.data.symbol.symbolLayers.find(symbolLayer => 'color' in symbolLayer)

        if (atributos.LABEL.style === 'perimetroActivo') {
          simbolo = {
            type: 'simple-fill',
            color: [outline.color[0], outline.color[1], outline.color[2], 0.01],
            outline: {
              color: outline.color,
              width: outline.width
            }
          }
        }
        if (atributos.LABEL.style === 'perimetroEstabilizado') {
          simbolo = {
            type: 'simple-fill',
            color: [outline.color[0], outline.color[1], outline.color[2], 0.01],
            outline: {
              color: outline.color,
              width: outline.width
            }
          }

          let rings = coords.rings
          let numDivisions = 30
          let lineLength = 50

          let lineSymbolLayer = {
            type: 'polygon-3d',
            symbolLayers: [{
              type: 'line',
              size: outline.width,
              material: {
                color: outline.color
              }
            }]
          }

          rings.forEach(function (path) {
            for (let i = 0; i < path.length - 1; i++) {
              let startPoint = path[i]
              let endPoint = path[i + 1]

              // Calcular la longitud del segmento
              let dx = endPoint[0] - startPoint[0]
              let dy = endPoint[1] - startPoint[1]
              let segmentLength = Math.sqrt(dx * dx + dy * dy)

              // Dividir el segmento en divisiones iguales
              for (let j = 0; j < numDivisions; j++) {
                let fraction = j / numDivisions
                let midPoint = [
                  startPoint[0] + fraction * dx,
                  startPoint[1] + fraction * dy,
                  startPoint[2] + fraction * (endPoint[2] - startPoint[2])
                ]

                // Dirección perpendicular
                let unitDx = dx / segmentLength
                let unitDy = dy / segmentLength
                let offsetX = -unitDy * lineLength
                let offsetY = unitDx * lineLength

                // Crear la línea perpendicular
                let linePath = [
                  [midPoint[0] - offsetX, midPoint[1] - offsetY, midPoint[2]],
                  [midPoint[0] + offsetX, midPoint[1] + offsetY, midPoint[2]]
                ]

                let line = new Polygon({
                  hasZ: false,
                  hasM: true,
                  rings: [linePath],
                  spatialReference: { wkid: 3857 }
                })

                let lineGraphic = new Graphic({
                  geometry: line,
                  symbol: lineSymbolLayer,
                  attributes: atributos
                })

                graphics.push(lineGraphic)
              }
            }
          })

          // simbolo = {
          // type: 'polygon-3d',
          // symbolLayers: [
          //  {
          //    type: 'fill',
          //    material: {
          //      color: [outline.color.r, outline.color.g, outline.color.b, 0.01]
          //    },
          //    outline: {
          //      color: outline.color,
          //      size: outline.width
          //    }
          //  },
          //  {
          //    type: 'line',
          //    material: { color: outline.color },
          //    size: 10, // Ancho de las líneas perpendiculares
          //    cap: 'round',
          //    join: 'round',
          //    pattern: {
          //      type: 'style',
          //      style: 'dash',
          //      size: 20 // Tamaño del patrón (ajusta según lo que necesites)
          //    }
          //  }
          // ]
          // }
        }

        if (atributos.LABEL.style === 'perimetroPotencial') {
          simbolo = {
            type: 'simple-fill',
            color: [outline.color[0], outline.color[1], outline.color[2], 0.01],
            outline: {
              type: 'simple-line',
              color: outline.color,
              width: outline.width,
              style: 'long-dash'
            }
          }
        }
      }

      let graphic = new Graphic({
        geometry: geometry,
        symbol: simbolo,
        attributes: atributos
      })
      graphics.push(graphic)
    }
    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function perimeterGeoJsonToGraphicsPerimeter (geojson) {
  let deferred = Q.defer()

  try {
    const [Polygon, Polyline, Point] =
      await loadModules([
        'esri/geometry/Polygon',
        'esri/geometry/Polyline',
        'esri/geometry/Point'
      ])

    let geometry
    let graphics = []
    let forma
    let simbolo
    for (let i = 0; i < geojson.features.length; i++) {
      if (!geojson.features[i].geometry) {
        continue
      }
      let tipo = geojson.features[i].geometry.type.toLowerCase()
      let coords = geojson.features[i].geometry.coordinates
      let atributos = geojson.features[i].properties

      if (tipo === 'polygon') {
        geometry = new Polygon({
          hasZ: false,
          hasM: true,
          rings: coords,
          spatialReference: { wkid: 3857 }
        })
        forma = await crearPoligonoPerimetro(geometry)
        simbolo = forma.symbol
      }
      if (tipo === 'point') {
        geometry = new Point({
          longitude: coords[0],
          latitude: coords[1],
          coords: coords
        })
        forma = await crearPuntoPerimetro(geometry)
        simbolo = forma.symbol
      }

      if (tipo === 'linestring' || tipo === 'polyline') {
        geometry = new Polyline({
          hasZ: false,
          hasM: true,
          paths: coords,
          spatialReference: { wkid: 3857 }
        })
        forma = await crearLineaPerimetro(geometry)
        simbolo = forma.symbol
        tipo = geometry.type
      }
      atributos.ID_GRAFICO = VueInst.$uuid.createUUID()
      let graphic = {
        attributes: atributos,
        geometry: geometry,
        type: tipo,
        symbol: simbolo
      }
      graphics.push(graphic)
    }
    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const drawGraphicsPerimetro = async function (graphicList) {
  let deferred = Q.defer()
  let layer = map.findLayerById('layer-perimetros')
  closeMapPopups()
  let graphics = await createGraphicsDrawPerimeter(graphicList)
  // layer.removeAll()
  layer.addMany(graphics)
  deferred.resolve()

  return deferred.promise
}

export const removeGraphicsPerimetro = async function (graphicList) {
  let deferred = Q.defer()
  let layer = map.findLayerById('layer-perimetros')
  closeMapPopups()

  let list = graphicList.map(graphic => graphic.attributes.ID_GRAFICO)
  let graphics = layer.graphics.items.filter(graphic => list.includes(graphic.attributes.ID_GRAFICO))
  layer.removeMany(graphics)

  deferred.resolve()

  return deferred.promise
}

export const getIncendioVisiblePerimetros = async function (graphics) {
  try {
    let deferred = Q.defer()

    let layerID = map.findLayerById('layer-perimetros')
    let array = []

    graphics.forEach(async (graphic) => {
      if (layerID.title === 'Gráficos Perímetros' && layerID.graphics.length > 0) {
        for (const layerGraphic of layerID.graphics) {
          const intersects = await intersectGraphic(layerGraphic, graphic)
          if (intersects) {
            array.push(layerGraphic)
          }
        }
      } else {
        deferred.resolve([])
      }
      deferred.resolve(array)
    })
    return deferred.promise
  } catch (err) {
    VueInst.$log.error(err)
  }
}

// #region CREACION DE FORMAS
async function crearPuntoPerimetro (geometry) {
  let forma = {
    coordenadas: {
      longitude: geometry.longitude,
      latitude: geometry.latitude
    },
    symbol: {
      type: 'simple-marker',
      color: [Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), 0.5],
      size: 6,
      outline: {
        type: 'simple-line',
        color: [50, 50, 50, 255],
        width: 1,
        style: 'solid'
      }
    }
  }
  return forma
}

async function crearPoligonoPerimetro (geometry) {
  let forma = {
    aggregateGeometries: null,
    geometry: geometry,
    symbol: {
      type: 'simple-fill',
      color: [Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), 0.5],
      style: 'solid',
      outline: {
        type: 'simple-line',
        color: [50, 50, 50, 255],
        width: 1,
        style: 'solid'
      }
    },
    attributes: {},
    popupTemplate: null
  }
  return forma
}

async function crearLineaPerimetro (geometry) {
  let forma = {
    aggregateGeometries: null,
    geometry: geometry,
    color: 'lightblue',
    symbol: {
      type: 'simple-line',
      color: [Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), Math.floor(Math.random() * 256), 1],
      width: 3,
      style: 'solid'
    },
    attributes: {},
    popupTemplate: null
  }
  return forma
}

export async function fileToGeoJSON (file) {
  let deferred = Q.defer()

  const zipFile = file
  const formData = new FormData()
  if (zipFile) {
    // Crear una instancia de JSZip y leer los archivos desde el .zip
    const zip = new JSZip()
    zip.loadAsync(zipFile).then(zipContent => {
      // Obtener los datos de los archivos SHP, DBF, PRJ y SHX
      const shpPromise = zipContent.file(Object.keys(zipContent.files).find((a) => a.includes('.shp'))).async('arraybuffer')
      const dbfPromise = zipContent.file(Object.keys(zipContent.files).find((a) => a.includes('.dbf'))).async('arraybuffer')
      const prjPromise = zipContent.file(Object.keys(zipContent.files).find((a) => a.includes('.prj'))).async('string')
      const shxPromise = zipContent.file(Object.keys(zipContent.files).find((a) => a.includes('.shx'))).async('arraybuffer')

      // Procesar los datos una vez que se han leído todos los archivos
      Promise.all([shpPromise, dbfPromise, prjPromise, shxPromise])

        .then(async ([shpArrayBuffer, dbfArrayBuffer, prjString, shxArrayBuffer]) => {
          // #region Creación de los blob, se añaden todos a un formData
          const blob1 = new Blob([shpArrayBuffer])
          const blob2 = new Blob([dbfArrayBuffer])
          const blob3 = new Blob([prjString])
          const blob4 = new Blob([shxArrayBuffer])
          formData.append('shp', blob1)
          formData.append('dbf', blob2)
          formData.append('prj', blob3)
          formData.append('shx', blob4)
          // #endregion

          // Llamada a la api pasando el objeto formData
          let respuesta = await api.incendio.addImportedPerimetro(formData) // Obtenemos en respuesta el archivo geojson del backend
          try {
            let graphics = await perimeterGeoJsonToGraphicsPerimeter(respuesta.data) // Método que llama a la creación de los gráficos
            let layer = map.findLayerById('layer-perimetros')
            layer.removeAll()
            layer.addMany(graphics)
            deferred.resolve(graphics)
          } catch (error) {
            console.error('Error en la respuesta, vuelve a intentarlo', error)
          }
        })
        .catch(error => {
          console.error('Error al leer los archivos', error)
          deferred.reject()
        })
    })
  }
  return deferred.promise
}
// #endregion

// #endregion *******************************************************************************************************************************************************************

// #region SIMULACION
let simulacionSVM = null
// let simulacionGraphics = []
// let simulationGraphics.isocronas = []
// let longitudLlamaGraphics = []
// let intensidadLlamaGraphics = []
// let velocidadPropagacionGraphics = []

let simulationGraphics = {
  simulacion: [],
  isocronas: [],
  longitudLlama: [],
  intensidadLlama: [],
  velocidadPropagacion: [],
  graficosSimulacion: []
}

export const initSimulacionSketchViewModel = async function (data) {
  // TODO:
  try {
    const [SketchViewModel, projection] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel',
      'esri/geometry/projection'
    ])
    let event = data.event
    let callback = data.callback

    let color

    switch (data.categoria) {
      case 'foco':
        color = [255, 0, 0, 0.5]
        break
      case 'combustible':
        color = [183, 244, 216, 0.5]
        break
      case 'zonaNoCombustible':
        color = [15, 10, 222, 0.5]
        break
    }

    let layer = map.findLayerById('layer-sketch-simulacion')

    simulacionSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
      pointSymbol: {

        type: 'simple-marker',
        color: color,
        size: 6

      },
      polylineSymbol: {
        type: 'simple-line',
        style: 'solid',
        color: color,
        width: 2

      },
      polygonSymbol: {
        type: 'simple-fill',
        style: 'solid',
        color: color

      }
    })
    simulacionSVM.on(['create', 'delete', 'update'], async function (evt) {
      let symbol, coordinates
      let geometry = null
      if (evt.state === 'complete' && evt.type === 'create') {
        await projection.load()
        geometry = projection.project(evt.graphic.geometry, {
          wkid: 25830
        })

        if (
          evt.graphic.geometry.type === 'point' &&
          evt.graphic.symbol.type === 'simple-marker'
        ) {
          symbol = simulacionSVM.pointSymbol
          coordinates = [geometry.x, geometry.y]
        } else if (evt.graphic.geometry.type === 'polyline') {
          symbol = simulacionSVM.polylineSymbol
          coordinates = geometry.paths[0]
        } else if (evt.graphic.geometry.type === 'polygon') {
          symbol = simulacionSVM.polygonSymbol
          coordinates = geometry.rings[0]
        }

        let graphic = {
          coordinates: coordinates,
          type: evt.graphic.geometry.type,
          symbol: symbol
        }
        callback(graphic)
      }
    })

    // Create graphic
    simulacionSVM.create(event)
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const addPointElement = async function (data) {
  let layer = map.findLayerById('layer-sketch-simulacion')

  let callback = data.callback

  const geometry = {
    type: 'point',
    latitude: data.latitude,
    longitude: data.longitude
  }

  let color

  switch (data.categoria) {
    case 'foco':
      color = [255, 0, 0, 0.5]
      break
    case 'combustible':
      color = [183, 244, 216, 0.5]
      break
    case 'zonaNoCombustible':
      color = [15, 10, 222, 0.5]
      break
  }

  let punto = {
    geometry: geometry,
    // attributes: null, // attributes,
    symbol: {
      type: 'simple-marker',
      color: color,
      size: 6,
      outline: {
        type: 'simple-line',
        color: [50, 50, 50, 255],
        width: 1,
        style: 'solid'
      }
    }
  }

  layer.add(punto)
  callback(punto)
}

export const drawSimulationElements = async function (elements) {
  elements = JSON.parse(JSON.stringify(elements))

  let layer = map.findLayerById('layer-sketch-simulacion')
  // layer.graphics.removeAll()

  for (let i = 0; i < elements.length; i++) {
    let forma

    if (elements[i].geometry.symbol.type === 'esriSMS' || elements[i].geometry.symbol.type === 'simple-marker') {
      forma = await crearPunto(elements[i].latitud, elements[i].longitud, elements[i].categoria)
    }

    if (elements[i].geometry.symbol.type === 'esriSLS') {
      forma = await crearLinea(elements[i].geometry.coordinates, elements[i].categoria)
    }

    if (elements[i].geometry.symbol.type === 'esriSFS') {
      forma = await crearPoligono(elements[i].geometry.coordinates, elements[i].categoria)
    }
    layer.add(forma)
  }
}

async function crearPunto (lat, lon, categoria) {
  const geometry = {
    type: 'point',
    latitude: lat,
    longitude: lon
  }

  let color

  switch (categoria) {
    case 'foco':
      color = [255, 0, 0, 0.5]
      break
    case 'combustible':
      color = [183, 244, 216, 0.5]
      break
    case 'zonaNoCombustible':
      color = [15, 10, 222, 0.5]
      break
  }

  let forma = {
    geometry: geometry,
    symbol: {
      type: 'simple-marker',
      color: color,
      size: 6,
      outline: {
        type: 'simple-line',
        color: [50, 50, 50, 255],
        width: 1,
        style: 'solid'
      }
    }
  }
  return forma
}

async function crearLinea (paths, categoria) {
  const [Polyline] = await loadModules(['esri/geometry/Polyline'])
  let geometry = new Polyline({
    paths: paths,
    spatialReference: {
      wkid: 25830
    }
  })

  let color

  switch (categoria) {
    case 'foco':
      color = [255, 0, 0, 0.5]
      break
    case 'combustible':
      color = [183, 244, 216, 0.5]
      break
    case 'zonaNoCombustible':
      color = [15, 10, 222, 0.5]
      break
  }

  let forma = {
    aggregateGeometries: null,
    geometry: geometry,
    symbol: {
      type: 'simple-line',
      color: color,
      width: 2,
      style: 'solid'
    },
    attributes: {},
    popupTemplate: null
  }

  return forma
}

async function crearPoligono (rings, categoria) {
  const [Polygon] = await loadModules(['esri/geometry/Polygon'])
  let geometry = new Polygon({
    rings: rings,
    spatialReference: {
      wkid: 25830
    }
  })

  let color

  switch (categoria) {
    case 'foco':
      color = [255, 42, 4, 0.5]
      break
    case 'combustible':
      color = [183, 244, 216, 0.5]
      break
    case 'zonaNoCombustible':
      color = [15, 10, 222, 0.5]
      break
  }

  let forma = {
    aggregateGeometries: null,
    geometry: geometry,
    symbol: {
      type: 'simple-fill',
      color: color,
      style: 'solid',
      outline: {
        type: 'simple-line',
        color: [50, 50, 50, 255],
        width: 1,
        style: 'solid'
      }
    },
    attributes: {},
    popupTemplate: null
  }

  return forma
}

export const clearSimulacionSketch = function () {
  let layer = map.findLayerById('layer-sketch-simulacion')
  layer.removeAll()
}
export const stopSimulacionSketchViewModel = function () {
  simulacionSVM.cancel()
}

export const convertListGeometryToLatLon = listgeometry => {
  const newList = []
  for (const geometry of listgeometry) {
    const newGeom = []
    for (const point of geometry) {
      const newpoint = convertirETRS89ToLatLon(point[0], point[1])

      const temp = newpoint[0]
      newpoint[0] = newpoint[1]
      newpoint[1] = temp

      newGeom.push(newpoint)
    }
    newList.push(newGeom)
  }

  return newList
}

export const paintSimulacion = async function (simulacion) {
  let deferred = Q.defer()

  if (map) {
    try {
      let response
      if (simulationGraphics[simulacion.id].length === 0) {
        switch (simulacion.id) {
          case 'simulacion':
            response = await api.simulacion.getSimulacion(
              simulacion.sim.ID_SIMULACION,
              28530
            )

            if (response.data.simStatus) {
              // Hacer que salga error cuando el simStatus no sea correcto
              deferred.resolve(response.data.simStatus)
              return deferred.promise
            }

            simulationGraphics.simulacion = await createGraphicsSimulacion(response.data)
            simulationGraphics.isocronas = await createGraphicsIsocronas(response.data)
            simulationGraphics.graficosSimulacion = simulacion.sim.GRAFICOS ? await createAuxiliarGraphicsSimulacion(simulacion.sim.GRAFICOS) : []

            break
          case 'longitudLlama':
            response = await api.simulacion.getFlameLength(
              simulacion.sim.ID_SIMULACION,
              28530
            )
            simulationGraphics[simulacion.id] = await createGraphics(response.data)
            break
          case 'intensidadLlama':
            response = await api.simulacion.getIntensity(
              simulacion.sim.ID_SIMULACION,
              28530
            )
            simulationGraphics[simulacion.id] = await createGraphics(response.data)
            break
          case 'velocidadPropagacion':
            response = await api.simulacion.getSpreadRate(
              simulacion.sim.ID_SIMULACION,
              28530
            )
            simulationGraphics[simulacion.id] = await createGraphics(response.data)
            break
          default:
            console.error('Layer not found')
            break
        }
      }
      store.dispatch('shareMap/paintSimulation', { response: response, simulacion: simulacion })
      paintSimulacionLayer(simulacion)
      centerSimulacion()
      deferred.resolve()
    } catch (err) {
      console.error('Error: ', err)
      deferred.reject(err)
    }
  } else {
    deferred.reject()
  }

  return deferred.promise
}

function paintSimulacionLayer (simulacion) {
  let layerSimulacion = map.findLayerById('layer-simulacion')
  layerSimulacion.removeAll()
  let layerIsocronas = map.findLayerById('layer-isocronas')
  layerIsocronas.removeAll()
  let layerGraficosSimulacion = map.findLayerById('layer-graficos-simulacion')
  layerGraficosSimulacion.removeAll()

  if (simulacion.id === 'simulacion') {
    simulationGraphics.isocronas.sort(comparador)

    layerSimulacion.graphics.items = [simulationGraphics.simulacion[0]] // Solo pinta el primer anillo
    layerIsocronas.graphics.items = simulationGraphics.isocronas.filter(
      (x) => x.attributes.Elapsed_Mi <= simulacion.sim.INTERVALO
    )

    layerGraficosSimulacion.graphics.items = simulationGraphics.graficosSimulacion

    layerGraficosSimulacion.visible = true
    layerIsocronas.visible = true
  } else {
    layerSimulacion.graphics.items = simulationGraphics[simulacion.id]
  }

  layerSimulacion.visible = true
}

async function createAuxiliarGraphicsSimulacion (data) {
  let deferred = Q.defer()

  try {
    let graphics = []
    for (let i = 0; i < data.length; i++) {
      switch (data[i].TIPO_GEOMETRIA) {
        case 'polyline':
          graphics.push(await crearLinea(JSON.parse(data[i].GEOMETRIA), data[i].TIPO_GRAFICO))
          break
        case 'polygon':
          graphics.push(await crearPoligono(JSON.parse(data[i].GEOMETRIA), data[i].TIPO_GRAFICO))
          break
      }
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphicsSimulacion (response) {
  let deferred = Q.defer()

  try {
    const [Polygon, Graphic, GeometryEngine] = await loadModules([
      'esri/geometry/Polygon',
      'esri/Graphic',
      'esri/geometry/geometryEngine'
    ])

    let featuresJson = response.features

    // Agrupar por ElapsedMin
    let groups = featuresJson.reduce((groups, item) => {
      let elapsedMin = item.properties.Elapsed_Mi
      const group = groups[elapsedMin] || []
      group.push(item)
      groups[elapsedMin] = group
      return groups
    }, {})

    let initColor = [255, 255, 0] // Amarillo
    let endColor = [255, 0, 0] // Rojo
    let colorGradient = getColorGradient(
      initColor,
      endColor,
      Object.keys(groups).length
    )

    // Crear las geometrias juntando los poligonos del mismo ElapsedMin
    let mergedFeatures = []
    Object.entries(groups).forEach(([k, v]) => {
      let features = v

      let geometry = new Polygon({
        hasZ: false,
        hasM: false,
        rings: features.map((x) => x.geometry.coordinates).flat(),
        spatialReference: {
          wkid: 4326
        }
      })

      mergedFeatures.push({
        geometry: geometry,
        properties: v[0].properties
      })
    })

    // Crear los graficos
    let graphics = []
    for (let i = 0; i < mergedFeatures.length; i++) {
      let f = mergedFeatures[i]
      let geometry = f.geometry

      if (i > 0) {
        let prevFeature = mergedFeatures[i - 1]
        let prevGeometry = prevFeature.geometry
        geometry = GeometryEngine.difference(geometry, prevGeometry)

        if (!geometry) {
          continue
        }
      }

      let color = colorGradient[i]
      let symbol = {
        type: 'simple-fill',
        color: [color[0], color[1], color[2], 1],
        style: 'solid',
        outline: {
          color: 'white',
          width: 0
        }
      }

      let popupTemplate = {
        title: 'Simulación',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'Elapsed_Mi',
                label: 'Elapsed Mins'
              }
            ]
          }
        ]
      }

      let graphic = new Graphic({
        geometry: geometry,
        attributes: f.properties, // attributes
        symbol: symbol,
        popupTemplate: popupTemplate
      })

      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphics (response) {
  let deferred = Q.defer()
  try {
    const [Polygon, Graphic] = await loadModules([
      'esri/geometry/Polygon',
      'esri/Graphic'
    ])

    let featuresJson = response.features

    let initColor = [255, 255, 0] // Amarillo
    let endColor = [255, 0, 0] // Rojo
    let colorGradient = getColorGradient(
      initColor,
      endColor,
      featuresJson.length
    )

    // Crear las geometrias juntando los poligonos del mismo ElapsedMin
    let mergedFeatures = []
    featuresJson.forEach((v) => {
      let features = v
      let geometry = new Polygon({
        hasZ: false,
        hasM: false,
        rings: features.geometry.coordinates,
        spatialReference: {
          wkid: 4326
        }
      })
      mergedFeatures.push({
        geometry: geometry,
        properties: v.properties
      })
    })

    // Crear los graficos
    let graphics = []
    for (let i = 0; i < mergedFeatures.length; i++) {
      let f = mergedFeatures[i]
      let geometry = f.geometry

      let color = colorGradient[i]
      let symbol = {
        type: 'simple-fill',
        color: f.properties.fill ? f.properties.fill : [color[0], color[1], color[2], 1],
        style: 'solid',
        outline: {
          color: 'white',
          width: 0
        }
      }

      let popupTemplate = {
        title: 'Simulación',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'UpperValue',
                label: 'Valor por encima'
              },
              {
                fieldName: 'lowerValue',
                label: 'Valor por debajo'
              }
            ]
          }
        ]
      }

      let graphic = new Graphic({
        geometry: geometry,
        attributes: f.properties, // attributes
        symbol: symbol,
        popupTemplate: popupTemplate
      })

      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

async function createGraphicsIsocronas (response) {
  let deferred = Q.defer()

  try {
    const [Point, Polygon, Graphic, GeometryEngine] = await loadModules([
      'esri/geometry/Point',
      'esri/geometry/Polygon',
      'esri/Graphic',
      'esri/geometry/geometryEngine'
    ])

    let featuresJson = response.features

    // Agrupar por ElapsedMin
    let groups = featuresJson.reduce((groups, item) => {
      let elapsedMin = item.properties.Elapsed_Mi
      const group = groups[elapsedMin] || []
      group.push(item)
      groups[elapsedMin] = group
      return groups
    }, {})

    let initColor = [255, 255, 0] // Amarillo
    let endColor = [255, 0, 0] // Rojo
    let colorGradient = getColorGradient(
      initColor,
      endColor,
      Object.keys(groups).length
    )

    // Crear las geometrias juntando los poligonos del mismo ElapsedMin
    let mergedFeatures = []
    Object.entries(groups).forEach(([k, v]) => {
      let features = v

      let geometry = new Polygon({
        hasZ: false,
        hasM: false,
        rings: features.map((x) => x.geometry.coordinates).flat(),
        spatialReference: {
          wkid: 4326
        }
      })

      mergedFeatures.push({
        geometry: geometry,
        properties: v[0].properties
      })
    })

    // Crear los graficos
    let graphics = []
    for (let i = 0; i < mergedFeatures.length; i++) {
      let f = mergedFeatures[i]
      let geometry = f.geometry

      if (i > 0) {
        let prevFeature = mergedFeatures[i - 1]
        let prevGeometry = prevFeature.geometry
        geometry = GeometryEngine.difference(geometry, prevGeometry)

        if (!geometry) {
          continue
        }
      }

      let color = colorGradient[i]
      let symbol = {
        type: 'simple-fill',
        color: [color[0], color[1], color[2], 1],
        style: 'solid',
        // Isocronas de colores
        outline: {
          color: 'white',
          width: 1
        }
      }
      let popupTemplate = {
        title: 'Simulación',
        content: [
          {
            type: 'fields',
            fieldInfos: [
              {
                fieldName: 'Elapsed_Mi',
                label: 'Elapsed Mins'
              }
            ]
          }
        ]
      }

      let graphic = new Graphic({
        geometry: geometry,
        attributes: f.properties, // attributes
        symbol: symbol
      })

      let punto = new Point({
        x: geometry.rings[0][0][0],
        y: geometry.rings[0][0][1]
      })

      let graphicText = new Graphic({
        geometry: punto,
        attributes: f.properties, // attributes
        symbol: {
          type: 'text', // autocasts as new TextSymbol()
          color: 'black',
          haloColor: 'white',
          haloSize: '1px',
          text: f.properties.Elapsed_Mi,
          xoffset: 0,
          yoffset: 0,
          font: {
            // autocasts as new Font()
            size: 8,
            family: 'Arial Unicode MS',
            weight: 'bold'
          }
        },
        popupTemplate: popupTemplate
      })

      graphics.push(graphicText)
      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const updateSimulacionTime = function (elapsedMin) {
  if (map) {
    // Simulacion
    let layer = map.findLayerById('layer-simulacion')
    layer.removeAll()

    let graphics = simulationGraphics.simulacion.filter(
      (x) => x.attributes.Elapsed_Mi <= elapsedMin
    )
    layer.addMany(graphics)
    layer.visible = true

    // Isocronas
    layer = map.findLayerById('layer-isocronas')
    layer.removeAll()

    graphics = simulationGraphics.isocronas.filter(
      (x) => x.attributes.Elapsed_Mi <= elapsedMin
    )
    layer.addMany(graphics)

    loadModules(['esri/geometry/geometryEngine']).then(([geometryEngine]) => {
      let superficie = 0
      for (let i = 0; i < graphics.length; i++) {
        let geometry = graphics[i].geometry
        superficie += Math.abs(geometryEngine.geodesicArea(geometry))
      }
      superficie = superficie / 10000 // hectareas
      VueInst.$eventHub.$emit(
        'superficieSimulacion',
        Math.round(superficie * 100) / 100
      )
    })
  }
}

export const centerSimulacion = function () {
  if (map) {
    let layer = map.findLayerById('layer-simulacion')
    mapView.goTo(layer.graphics.items, {
      duration: 3000,
      easing: 'in-expo'
    })
  }
}

export const clearSimulacion = function () {
  if (map) {
    let layer = map.findLayerById('layer-simulacion')
    layer.removeAll()
    layer = map.findLayerById('layer-isocronas')
    layer.removeAll()
    layer = map.findLayerById('layer-graficos-simulacion')
    layer.removeAll()
    layer = map.findLayerById('layer-viento')

    layer.queryFeatures().then((result) => {
      const { features } = result

      layer.applyEdits({
        deleteFeatures: features // delete the old features
      })
    })

    simulationGraphics = {
      simulacion: [],
      isocronas: [],
      longitudLlama: [],
      intensidadLlama: [],
      velocidadPropagacion: [],
      graficosSimulacion: []
    }
  }
}

export const exportSimulacion = async function (simulacion) {
  let deferred = Q.defer()

  if (map) {
    let response = await api.simulacion.getSimulacion(
      simulacion.ID_SIMULACION,
      28530
    )

    if (response.data.simStatus) {
      // Hacer que salga error cuando el simStatus no sea correcto
      deferred.resolve(response.data.simStatus)
      return deferred.promise
    }

    let features = response.data.features

    let geoJson = {
      type: 'FeatureCollection',
      features: features
    }

    let nombre = new Date(simulacion.FECHA_CREACION).toLocaleDateString().replaceAll('/', '-')

    // Fichero SHP
    try {
      const shpwrite = require('shp-write-update')
      let options = {
        folder: 'Track_' + nombre,
        types: {
          polygon: 'POLIGONOS_' + nombre
        }
      }
      shpwrite.download(geoJson, options)
    } catch (err) {
      console.error('Error generando shp', err)
    }

    // Fichero KML
    try {
      const tokml = require('tokml')
      let kml = tokml(geoJson)
      let element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(kml)
      )
      element.setAttribute('download', 'Track_' + nombre + '.kml')
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    } catch (err) {
      console.error('Error generando kml', err)
    }
  }
}

// Funcion para exportar simulacion a pdf
export const paintSimulacionFinal = async function (simulacion) {
  let deferred = Q.defer()

  if (map) {
    let layer = map.findLayerById('layer-simulacion')

    try {
      let response = await api.simulacion.getSimulacion(
        simulacion.ID_SIMULACION,
        28530
      )

      if (response.data.simStatus) {
        // Hacer que salga error cuando el simStatus no sea correcto
        deferred.resolve(response.data.simStatus)
        return deferred.promise
      }

      let graphics = await createGraphicsIsocronas(response.data)
      graphics.sort(comparador)

      simulationGraphics.simulacion = graphics

      layer.graphics.items = simulationGraphics.simulacion
      await mapView.goTo(simulationGraphics.simulacion, {
        duration: 3000,
        easing: 'in-expo'
      })
      layer.visible = true

      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }
  } else {
    deferred.reject()
  }

  return deferred.promise
}

/**
 * @param {*} from => [r, g, b, a]
 * @param {*} to => [r, g, b, a]
 * @param {*} steps => n
 */
function getColorGradient (from, to, totalNumberOfColors) {
  if (totalNumberOfColors === 0) {
    return [[0, 0, 0]]
  }
  if (totalNumberOfColors === 1) {
    return [from]
  }
  if (totalNumberOfColors === 2) {
    return [from, to]
  }

  let diffR = to[0] - from[0]
  let diffG = to[1] - from[1]
  let diffB = to[2] - from[2]
  // let diffA = to[3] - from[3]

  let steps = totalNumberOfColors - 1

  let stepR = diffR / steps
  let stepG = diffG / steps
  let stepB = diffB / steps
  // let stepA = diffA / steps

  let gradient = []
  gradient.push(from)
  for (let i = 1; i < steps; ++i) {
    let r = Math.round(from[0] + stepR * i)
    let g = Math.round(from[1] + stepG * i)
    let b = Math.round(from[2] + stepB * i)
    // let a = Math.round(from[3] + stepA * i)

    gradient.push([r, g, b])
  }
  gradient.push(to)

  return gradient
}
// #endregion

// #region VIENTOS
export const paintViento = async function (simulacion) {
  let deferred = Q.defer()

  let intervalo = simulacion.time ? simulacion.time : 0
  if (map) {
    try {
      let horaSim = VueInst.$date.parseDate(simulacion.sim.FECHA_INICIO).subtract(2, 'hours').add(intervalo, 'minutes').format('MM-DD-YYYY_HH00')
      let response

      try {
        response = (await api.simulacion.getJsonWindNinja(
          simulacion.sim.ID_SIMULACION,
          horaSim
        )).data
      } catch (error) {
        console.log('Simulacion sin vientos')
        deferred.resolve()
      }

      store.dispatch('shareMap/paintViento', response)

      await paintVientoLayer(response)
      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }
  } else {
    deferred.reject()
  }
  return deferred.promise
}

export const paintVientoLayer = async function (response) {
  maxSpeed = parseInt(response.maxSpeed)
  minSpeed = parseInt(response.minSpeed)
  const graphic = await featuresVientos(response.features)

  let layer = map.findLayerById('layer-viento')

  const { features } = await layer.queryFeatures()

  await layer.applyEdits({
    addFeatures: graphic, // new updated features
    deleteFeatures: features // delete the old features
  })

  if (features.length === 0) {
    const is3D = store.getters['map/isMap3D']

    layer.renderer = is3D ? await getWindRenderer3D() : await getWindRenderer2D()
  }
}

async function featuresVientos (features) {
  const [Graphic, Point] = await loadModules([
    'esri/Graphic',
    'esri/geometry/Point'
  ])

  let graphics = []

  features.forEach((element) => {
    let symbol = {
      type: 'simple-marker',
      style: 'triangle',
      outline: {
        color: 'yellow',
        width: '3px'
      }
    }

    let geometry = new Point({
      x: element.geometry.coordinates[0],
      y: element.geometry.coordinates[1]
    })

    let graphic = new Graphic({
      attributes: element.properties,
      geometry: geometry,
      symbol: symbol
    })

    graphics.push(graphic)
  })

  return graphics
}

async function updateRendererViento () {
  let layer = map.findLayerById('layer-viento')

  if (layer) {
    let is3D = store.getters['map/isMap3D']

    if (!is3D) {
      layer.renderer = await getWindRenderer3D()
    } else {
      layer.renderer = await getWindRenderer2D()
    }
  }
}
// #endregion VIENTOS

// #region IMAGEN SATELITE

export const drawImagenSatelite = async function (data, url) {
  if (map) {
    let layer = map.findLayerById('layer-imagen-satelite')

    let graphic = await createGraphicsImagenSatelite(data, url)

    layer.source.elements.removeAll() // Para que solo haya una imagen cada vez
    await layer.source.elements.add(graphic)

    layer.visible = true
  }
}

// Funcion para introducir valores en la capa de imagen satelite
async function createGraphicsImagenSatelite (data, url) {
  let deferred = Q.defer()

  try {
    const [ImageElement, ExtentAndRotationGeoreference, Extent] =
      await loadModules([
        'esri/layers/support/ImageElement',
        'esri/layers/support/ExtentAndRotationGeoreference',
        'esri/geometry/Extent'
      ])

    let source = null

    let aux = convertir4326to3857(data.LONGITUD, data.LATITUD)

    source = new ImageElement({
      image: url,
      // image: 'http://localhost:3000/satelite_imagen/B43D98CF-14A6-471E-9F00-6B225D435B3B/9751129_B43D98CF-14A6-471E-9F00-6B225D435B3B_14-7-2022_RGB',
      georeference: new ExtentAndRotationGeoreference({
        extent: new Extent({
          spatialReference: {
            wkid: 3857 // 4326
          },
          xmin: aux[0] - 1113.1949134293125 * 1.369 * 7, // 1113.1949134293125 * 1.35
          ymin: aux[1] - 1113.1949134293125 * 1.369 * 7, // El 5 viene del offset de la imagen(0.05) * 100
          xmax: aux[0] + 1113.1949134293125 * 1.369 * 7,
          ymax: aux[1] + 1113.1949134293125 * 1.369 * 7
        })
      })
    })

    deferred.resolve(source)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

// export const drawGeoJsonImagenSatelite = async function (geoJson) {
//   if (map) {
//     let layer = map.findLayerById('layer-vector-imagen-satelite')
//     layer.destroy()

//     let graphic = await createGraphicsImagenSateliteGeoJson(geoJson)

//     layer.url = graphic

//     createGeoJsonLayer(layer).then((layer) => {
//       map.add(layer)
//       store.dispatch('map/addLayer', {
//         id: layer.id,
//         title: layer.title,
//         visible: layer.visible,
//         opacity: layer.opacity,
//         posicion: layer.posicion,
//         addListLayer: layer.addListLayer,
//         url: layer.url
//       })
//     })
//   }
// }

// Funcion para introducir valores en la capa de imagen satelite
async function createGraphicsImagenSateliteGeoJson (geoJson) {
  let deferred = Q.defer()

  try {
    let blob = new Blob([JSON.stringify(geoJson)], {
      // layerItem.url | geojson
      type: 'application/json'
    })

    // URL reference to the blob
    let url = URL.createObjectURL(blob)

    deferred.resolve(url)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const hideImagenSatelite = function () {
  if (map) {
    let layer = map.findLayerById('layer-imagen-satelite')
    layer.source.elements.removeAll()
    layer.visible = false
  }
}

// export const hideImagenSateliteGeoJSON = function () {
//   if (map) {
//     let layer = map.findLayerById('layer-vector-imagen-satelite')
//     // layer.url.elements.removeAll()

//     layer.destroy()

//     layer.url = []

//     createGeoJsonLayer(layer).then((layer) => {
//       map.add(layer)
//       store.dispatch('map/addLayer', {
//         id: layer.id,
//         title: layer.title,
//         visible: layer.visible,
//         opacity: layer.opacity,
//         posicion: layer.posicion,
//         addListLayer: layer.addListLayer,
//         url: layer.url
//       })
//     })
//   }
// }

// #endregion

// #region CONVERT_COORDINATES
export const convertirGMSLatLon = (gmsLat, gmsLon) => {
  let partsLat = gmsLat.split(/[^\d\w]+/)
  let partsLon = gmsLon.split(/[^\d\w]+/)
  let lat = convertirDMSToDD(
    Number(partsLat[0]),
    Number(partsLat[1]),
    Number(partsLat[2] + '.' + partsLat[3]),
    partsLat[4]
  )
  let lng = convertirDMSToDD(
    Number(partsLon[0]),
    Number(partsLon[1]),
    Number(partsLon[2] + '.' + partsLon[3]),
    partsLon[4]
  )
  return [lat, lng]
}
function convertirDMSToDD (degrees, minutes, seconds, direction) {
  let dd = degrees + minutes / 60 + seconds / (60 * 60)

  if (
    direction === 'S' ||
    direction === 's' ||
    direction === 'W' ||
    direction === 'w'
  ) {
    dd = dd * -1
  } // Don't do anything for N or E
  return dd
}
export const convertirLatLonGMS = (valor, tipo) => {
  let grados = Math.abs(parseInt(valor))
  let minutos = (Math.abs(valor) - grados) * 60
  let segundos = minutos
  minutos = Math.abs(parseInt(minutos))
  segundos = Math.round((segundos - minutos) * 60 * 1000000) / 1000000
  let signo = valor < 0 ? -1 : 1
  let direccion =
    tipo === 'LATITUDE' ? (signo > 0 ? 'N' : 'S') : signo > 0 ? 'E' : 'W'

  if (isNaN(direccion)) {
    grados = grados * signo
  }

  return {
    grados: grados,
    minutos: minutos,
    segundos: segundos,
    direccion: direccion,
    valor:
      grados +
      'º ' +
      minutos.toString().padStart(2, '0') +
      "' " +
      segundos.toFixed(2).toString().padStart(5, '0').replace('.', ',') +
      '" ' +
      (isNaN(direccion) ? ' ' + direccion : '')
  }
}

export const convertirETRS89 = (x, y) => {
  let zone = constants.HUSO_UTM
  let firstProjection = 'EPSG:3857'
  let secondProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs '
  return proj4(firstProjection, secondProjection, [
    parseFloat(x),
    parseFloat(y)
  ])
}
export const convertirLatLonToETRS89 = (lat, lon) => {
  let zone = constants.HUSO_UTM
  let firstProjection = 'EPSG:4326'
  let secondProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs '
  return proj4(firstProjection, secondProjection, [
    parseFloat(lon),
    parseFloat(lat)
  ])
}
export const convertirETRS89ToLatLon = (x, y) => {
  let zone = constants.HUSO_UTM
  let firstProjection =
    '+proj=utm +zone=' + zone + ' +ellps=GRS80 +units=m +no_defs'
  let secondProjection = 'EPSG:4326'
  return proj4(firstProjection, secondProjection, [
    parseFloat(x),
    parseFloat(y)
  ])
}

export const convertirMercXYToLatLon = (x, y) => {
  let zone = constants.HUSO_UTM
  let firstProjection =
    '+proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0 +units=m +k=1.0 +nadgrids=@null +no_defs'
  let secondProjection = 'EPSG:4326'
  return proj4(firstProjection, secondProjection, [
    parseFloat(x),
    parseFloat(y)
  ])
}

function convertir4326to3857 (lon, lat) {
  let x = (lon * 20037508.34) / 180
  let y = Math.log(Math.tan(((90 + lat) * Math.PI) / 360)) / (Math.PI / 180)
  y = (y * 20037508.34) / 180
  return [x, y]
}
// #endregion

// #region TOKEN
function getToken () {
  return new Promise((resolve, reject) => {
    let tokenData = constants.ArcGISToken

    const params = new FormData()
    params.append('client_id', tokenData.client_id)
    params.append('client_secret', tokenData.client_secret)
    params.append('grant_type', tokenData.grant_type)

    /* let params = {
      client_id: tokenData.client_id,
      client_secret: tokenData.client_secret,
      grant_type: tokenData.grant_type
    } */

    VueInst.$http
      .post(tokenData.urlToken, params)
      .then((response) => {
        let accessToken = response.data.access_token

        resolve(accessToken)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
async function registerToken () {
  const [IdentityManager] = await loadModules([
    'esri/identity/IdentityManager'
  ])
  let urlServices = constants.ArcGISToken.serverLayer
  if (IdentityManager.findCredential(urlServices) === undefined) {
    let accessToken = await getToken()
    let response = await IdentityManager.registerToken({
      server: constants.ArcGISToken.serverLayer,
      token: accessToken
    })
    console.log('response register login', response)
  }
}
// #region MAP MOVEMENT
export const setBaseMap = async (basemap) => {
  try {
    const [Basemap, WMTSLayer, WebTileLayer] = await loadModules([
      'esri/Basemap',
      'esri/layers/WMTSLayer',
      'esri/layers/WebTileLayer'
    ])

    setVisibilityLayer('hybrid-reference-layer', basemap.id === 'base4')

    if (basemap.id === 'base6') {
      // IDERioja
      let baseLayer = new WebTileLayer({
        urlTemplate: basemap.map
      })

      let tileBasemap = new Basemap({
        baseLayers: [baseLayer],
        id: basemap.id,
        title: basemap.label
      })

      map.basemap = tileBasemap
    } else if (basemap.id === 'base7') {
      // Mapa base OneAtlas
      let baseLayer = new WMTSLayer({
        url: basemap.map,
        copyright:
          '<a target=_top href="http://www.intelligence-airbusds.com"> Airbus DS, Inc</a>'
      })
      let tileBasemap = new Basemap({
        baseLayers: [baseLayer],
        id: basemap.id,
        title: basemap.label
      })

      map.basemap = tileBasemap
    } else {
      map.basemap = basemap.map
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// Metodo antiguo: lo añade a cada mapview/sceneview segun se cambia
/* export const setSearchWidget = async () => {
  try {
    const [Search, Locator, Extent] = await loadModules(['esri/widgets/Search', 'esri/tasks/Locator', 'esri/geometry/Extent'])

    // Filtrar locator por extent de zona
    let paramsComunidad = store.getters['map/paramsComunidad']
    let extent = paramsComunidad ? new Extent(paramsComunidad.EXTENT) : null
    let searchFilter = {
      geometry: extent
    }

    let searchWidget = new Search({
      sources: [{
        locator: new Locator({ url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer' }),
        countryCode: 'ES',
        singleLineFieldName: 'SingleLine',
        name: 'Geocoding España',
        localSearchOptions: {
          minScale: 300000,
          distance: 50000
        },
        filter: searchFilter,
        placeholder: 'Buscar',
        maxResults: 3,
        maxSuggestions: 6,
        suggestionsEnabled: true,
        minSuggestCharacters: 0,
        enableInfoWindow: false,
        popupEnabled: false
      }],
      view: mapView,
      includeDefaultSources: false,
      id: 'search'
    })

    // Add the search widget to the top right corner of the view if not exist
    if (!mapView.ui.find('search')) {
      mapView.ui.add(searchWidget, {
        position: 'top-right'
      })
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
} */

// Metodo nuevo: crea uno al principio en la toolbar del mapa
let searchWidget = null
export const setSearchWidget = async () => {
  let deferred = Q.defer()

  try {
    // Update Locator to match 4.24 API
    const [Search, Locator, Extent] = await loadModules([
      'esri/widgets/Search',
      'esri/rest/locator',
      'esri/geometry/Extent'
    ])

    // Filtrar locator por extent de zona
    let paramsComunidad = store.getters['map/paramsComunidad']
    let extent = paramsComunidad ? new Extent(paramsComunidad.EXTENT) : null
    let searchFilter = {
      geometry: extent
    }
    if (!searchWidget) {
      searchWidget = new Search({
        sources: [
          {
            // locator: new Locator({ url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer' }),
            url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
            countryCode: 'ES',
            singleLineFieldName: 'SingleLine',
            name: 'Geocoding España',
            localSearchOptions: {
              minScale: 300000,
              distance: 50000
            },
            filter: searchFilter,
            placeholder: 'Buscar topónimos',
            maxResults: 3,
            maxSuggestions: 6,
            suggestionsEnabled: true,
            minSuggestCharacters: 0,
            enableInfoWindow: false,
            popupEnabled: false
          }
        ],
        view: mapView,
        container: 'searchWidget',
        includeDefaultSources: false,
        id: 'search'
      })
    }
    deferred.resolve()
  } catch (err) {
    VueInst.$log.error(err)
    deferred.reject(err)
  }

  return deferred.promise
}

export const setZoom = (value) => {
  let newZoom = mapView.zoom + value
  mapView.goTo({
    target: mapView.center,
    zoom: newZoom
  })
}
export const setExtent = (extent) => {
  setExtentMap(extent)
}

async function setExtentMap (extent) {
  try {
    const [Extent, SpatialReference] = await loadModules([
      'esri/geometry/Extent',
      'esri/geometry/SpatialReference'
    ])

    let properties = {
      xmin: extent.xmin,
      ymin: extent.ymin,
      xmax: extent.xmax,
      ymax: extent.ymax
    }

    if (extent.wkid) {
      properties.spatialReference = new SpatialReference({ wkid: extent.wkid })
    } else {
      properties.spatialReference = extent.spatialReference
    }

    if (extent.viewpoint) {
      extent.viewpoint.targetGeometry.type = 'point'
      mapView.viewpoint = extent.viewpoint
    }

    mapView.extent = new Extent(properties)
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const setCenterMap = (data) => {
  if (mapView) {
    mapView.goTo({
      target: data.target,
      zoom: data.zoom
    })
  }
}
/*
export const centerCompass = () => {
  if (mapView.rotation !== undefined) {
    mapView.rotation = 0
  } else {
    mapView.goTo({
      heading: 0
    })
  }
}
*/
// #endregion

// #region CAPTURE MAP
export const captureMap = () => {
  let deferred = Q.defer()

  let pixelRatio = 3
  mapView
    .takeScreenshot({
      width: mapView.width * pixelRatio,
      height: mapView.height * pixelRatio,
      format: 'jpg'
    })
    .then((screenshot) => {
      deferred.resolve(screenshot.dataUrl)
    })

  return deferred.promise
}

export const captureOverviewMap = async () => {
  let pixelRatio = 3

  let screenshot = await mapViewOverview
    .takeScreenshot({
      width: mapViewOverview.width * pixelRatio,
      height: mapViewOverview.height * pixelRatio,
      // area: store.getters['map/paramsComunidad'].RINGS,
      format: 'jpg'
    })

  return screenshot.dataUrl
}

export const setCenterAndCaptureMap = async (data) => {
  let deferred = Q.defer()

  if (mapView) {
    await mapView.goTo({
      target: data.target,
      zoom: data.zoom
    })
    setTimeout(async () => {
      let image = await captureMap(mapView)
      deferred.resolve(image)
    }, 500)
  }

  return deferred.promise
}

export const getCaptureMapFeatures = async (graphics, layer) => {
  try {
    let deferred = Q.defer()

    let layerID = map.findLayerById(layer)
    let array = []

    if (layerID.visible === true && layerID.type === 'feature') {
      graphics.forEach(async (graphic) => {
        const query = layerID.createQuery()
        graphic.geometry = graphic.geometry ? graphic.geometry : JSON.parse(graphic.GEOMETRIA)
        query.spatialRelationship = 'intersects'
        query.returnGeometry = true
        query.outFields = ['*']

        try {
          const result = await layerID.queryFeatures(query)

          let coordMin = convertirETRS89(graphic.geometry.xmin, graphic.geometry.ymin)
          let coordMax = convertirETRS89(graphic.geometry.xmax, graphic.geometry.ymax)
          coordMin = convertirETRS89ToLatLon(coordMin[0], coordMin[1])
          coordMax = convertirETRS89ToLatLon(coordMax[0], coordMax[1])
          let x = [coordMin[0], coordMax[0]]
          let y = [coordMin[1], coordMax[1]]

          const filteredFeatures = result.features.filter(feature => (
            feature.geometry.x >= x[0] && feature.geometry.x <= x[1] &&
          feature.geometry.y >= y[0] && feature.geometry.y <= y[1]
          ))

          const attr = filteredFeatures.map(function (feature) { return feature.attributes })
          deferred.resolve(attr)
        } catch (err) {
          VueInst.$log.error(err)
        }
      })
    } else if (layerID.visible === true && layerID.type === 'graphics') {
      graphics.forEach(async (graphic) => {
        if (layerID.title === 'Gráficos Perímetros' && layerID.graphics.length > 0) {
          for (const layerGraphic of layerID.graphics) {
            const intersects = await intersectGraphic(layerGraphic, graphic)
            if (intersects && layerGraphic.attributes.LABEL) {
              let datos = {
                tipo: layerGraphic.attributes.LABEL.label,
                color: layerGraphic.attributes.LABEL.color,
                icon: layerGraphic.attributes.LABEL.src
              }
              array.push(datos)
            }
          }
        } else if (layerID.title === 'Gráficos Plan Operaciones' && layerID.graphics.length > 0) {
          for (const layerGraphic of layerID.graphics) {
            const intersects = await intersectGraphic(layerGraphic, graphic)
            if (intersects && layerGraphic.attributes.LABEL) {
              let datos = {
                plan: layerGraphic.geometry.type,
                tipo: layerGraphic.attributes.LABEL.label,
                icon: layerGraphic.attributes.LABEL.src
              }
              array.push(datos)
            }
          }
        } else {
          deferred.resolve([])
        }
        deferred.resolve(array)
      })
    } else {
      deferred.resolve([])
    }
    return deferred.promise
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const intersectGraphic = async (layerGraphic, graphic) => {
  const [Extent, Polygon, Polyline, Point, geometryEngine] = await loadModules([
    'esri/geometry/Extent',
    'esri/geometry/Polygon',
    'esri/geometry/Polyline',
    'esri/geometry/Point',
    'esri/geometry/geometryEngine'
  ])
  let geometry
  let extent = new Extent({
    xmin: graphic.geometry.xmin,
    ymin: graphic.geometry.ymin,
    xmax: graphic.geometry.xmax,
    ymax: graphic.geometry.ymax,
    spatialReference: { wkid: 102100 }
  })

  if (layerGraphic.geometry.type === 'polygon') {
    geometry = new Polygon({
      rings: layerGraphic.geometry.rings,
      spatialReference: { wkid: 102100 }
    })
  } else if (layerGraphic.geometry.type === 'point') {
    geometry = new Point({
      x: layerGraphic.geometry.x,
      y: layerGraphic.geometry.y,
      spatialReference: { wkid: 102100 }
    })
  } else if (layerGraphic.geometry.type === 'polyline') {
    geometry = new Polyline({
      paths: layerGraphic.geometry.paths,
      spatialReference: { wkid: 102100 }
    })
  } else {
    return false
  }

  return geometryEngine.intersects(geometry, extent)
}
// #endregion

// #region MEASUREMENT
let measurementTool
export const measure = async (type) => {
  try {
    const [Measurement] = await loadModules(['esri/widgets/Measurement'])

    if (!measurementTool) {
      // Se inicializa si no lo está ya
      measurementTool = new Measurement({ areaUnit: 'hectares' }) // areaUnit:

      mapView.ui.add(measurementTool, 'bottom-right')
      measurementTool.view = mapView
    }

    if (type === 'distance') {
      measurementTool.activeTool =
        mapView.type.toUpperCase() === '2D' ? type : 'direct-line'
    } else if (type === 'area') {
      measurementTool.activeTool = type
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
export const clearMeasurement = (type) => {
  if (measurementTool) {
    measurementTool.clear()
    measurementTool = null
  }
}
// #endregion

// #region CONTORNO
export const drawContorno = async () => {
  let contornoGraphic = await createGraphicsContorno()

  let layerContorno = map.findLayerById('layer-contorno')
  layerContorno.graphics.items = contornoGraphic
}

async function createGraphicsContorno () {
  let deferred = Q.defer()

  try {
    const [Graphic, Polygon, Extent, GeometryEngine, geometryEngineAsync] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Polygon',
      'esri/geometry/Extent',
      'esri/geometry/geometryEngine',
      'esri/geometry/geometryEngineAsync'
    ])

    let symbol = {
      type: 'simple-fill',
      color: [157, 198, 216, 1], // 0.3
      width: 1
    }

    let worldExtent = new Extent({
      xmin: -20037508.34278924, // -20037508.3427892430765884088807
      ymin: -19971868.8804086,
      xmax: 20037508.34278924, // 20037508.3427892430765884088807
      ymax: 19971868.8804086,
      spatialReference: {
        wkid: 3857
      }
    })

    let worldPolygon = Polygon.fromExtent(worldExtent)

    let comunidad = {
      type: 'polygon',
      rings: store.getters['map/paramsComunidad'].RINGS,
      spatialReference: {
        wkid: 102100
      }
    }
    // PROBLEMA DE CARGA LENTA
    let buffer = await geometryEngineAsync.buffer(comunidad, 10000)
    store.dispatch('map/setBufferComunidad', buffer) // Guardar poligono buffer

    let contorno = await geometryEngineAsync.difference(worldPolygon, comunidad)
    let contornoGraphic = new Graphic({
      geometry: contorno,
      symbol: symbol
    })

    deferred.resolve([contornoGraphic])
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const pointInBuffer = async (lat, lon) => {
  let deferred = Q.defer()

  try {
    const [Point, GeometryEngine, projection] = await loadModules([
      'esri/geometry/Point',
      'esri/geometry/geometryEngine',
      'esri/geometry/projection'
    ])

    let point = new Point(lon, lat)
    point = projection.project(point, { wkid: 3857 })
    let buffer = store.getters['map/bufferComunidad']
    const contains = GeometryEngine.contains(buffer, point)

    deferred.resolve(contains)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}
// #endregion

// #region COORDS_MAP
export const drawPointCoords = async (lat, lon) => {
  try {
    const [Graphic, Point] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point'
    ])

    mapView.graphics = []

    if (lat && lon) {
      let symbol = {
        type: 'simple-marker',
        style: 'cross',
        outline: {
          color: 'yellow',
          width: '3px'
        }
      }

      let geometry = new Point({
        x: lon,
        y: lat
      })

      let graphic = new Graphic({
        geometry: geometry,
        symbol: symbol
      })
      mapView.graphics.add(graphic)
      setTimeout(deletePointCoords, 60 * 1000)
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
function deletePointCoords () {
  mapView.graphics = []
}
// #endregion

// #region SHARE MAP SKETCH VIEW MODEL
let shareMapSVM = null
export const initShareMapSketchViewModel = async () => {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel'
    ])

    let layer = map.findLayerById('layer-drawSketcher')

    shareMapSVM = new SketchViewModel({
      view: mapView,
      layer: layer
    })

    shareMapSVM.on(['create', 'delete', 'update'], async (evt) => {
      if (evt.state === 'complete' && evt.type === 'create') {
        let graphic

        let attributes = {
          ID: VueInst.$uuid.createUUID()
        }

        let color = [255, 100, 100, 0.4] // REMINDER: si no se pone el color en este formato, al convertirse a JSON el alpha se 'rompe'

        if (evt.graphic.geometry.type === 'multipoint') {
          // eslint-disable-next-line no-unreachable-loop
          for (let i = 0; i < evt.graphic.geometry.points.length; i++) {
            graphic = {
              attributes: attributes,
              coordenadas: evt.graphic.geometry.points[i],
              type: 'point',
              symbol: {
                type: shareMapSVM.pointSymbol.type,
                color: color, // shareMapSVM.pointSymbol.color,
                angle: shareMapSVM.pointSymbol.angle,
                size: shareMapSVM.pointSymbol.size,
                style: shareMapSVM.pointSymbol.style
              }
            }
            store.dispatch('shareMap/addGraphics', graphic)
          }
        } else if (evt.graphic.geometry.type === 'point') {
          graphic = {
            attributes: attributes,
            coordenadas: [evt.graphic.geometry.x, evt.graphic.geometry.y],
            type: evt.graphic.geometry.type,
            symbol: {
              type: shareMapSVM.pointSymbol.type,
              color: color, // shareMapSVM.pointSymbol.color,
              size: shareMapSVM.pointSymbol.size,
              style: shareMapSVM.pointSymbol.style
            }
          }
        } else if (evt.graphic.geometry.type === 'polyline') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.paths[0],
            type: 'polyline',
            symbol: {
              type: shareMapSVM.polylineSymbol.type,
              color: color, // shareMapSVM.polylineSymbol.color,
              width: shareMapSVM.polylineSymbol.width,
              style: shareMapSVM.polylineSymbol.style,
              join: shareMapSVM.polylineSymbol.join,
              miterLimit: shareMapSVM.polylineSymbol.miterLimit
            }
          }
        } else if (evt.graphic.geometry.type === 'polygon') {
          graphic = {
            attributes: attributes,
            coordenadas: evt.graphic.geometry.rings[0],
            type: 'polygon',
            symbol: {
              type: shareMapSVM.polygonSymbol.type,
              color: color, // shareMapSVM.polygonSymbol.color,
              style: shareMapSVM.polygonSymbol.style,
              outline: {
                color: 'white',
                width: 0
              }
            }
          }
        }

        store.dispatch('shareMap/addGraphics', graphic)
      } else if (evt.type === 'delete') {
        store.dispatch('shareMap/deleteGraphics', evt.graphics) // TODO:
      } else if (evt.state === 'complete' && evt.type === 'update') {
        let geometry = JSON.parse(JSON.stringify(evt.graphics[0].geometry))
        geometry.type = evt.graphics[0].geometry.type

        let g = {
          attributes: evt.graphics[0].attributes,
          geometry: geometry
        }
        store.dispatch('shareMap/updateGraphics', [g]) // TODO:
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const paintEventShareMap = (event) => {
  let layer = map.findLayerById('layer-drawSketcher')

  let typeEvent = event.function

  isDrawingArrow = false
  if (typeEvent === 'cancel') {
    shareMapSVM.cancel()
  } else if (typeEvent === 'reset') {
    layer.removeAll()
    shareMapSVM.reset()

    store.dispatch('shareMap/removeAllGraphics')
  } else {
    shareMapSVM.create(typeEvent)
  }
}

export const resetShareMapLayer = () => {
  let layer = map.findLayerById('layer-drawSketcher')
  layer.removeAll()
  if (shareMapSVM) {
    shareMapSVM.cancel()
  }
}

export const shareMapSimulacion = async function (data) {
  let deferred = Q.defer()

  if (map) {
    try {
      if (data.simulacion.id === 'simulacion') {
        simulationGraphics.simulacion = simulationGraphics.simulacion.length > 0 ? simulationGraphics.simulacion : await createGraphicsSimulacion(data.response.data)
        simulationGraphics.isocronas = simulationGraphics.isocronas.length > 0 ? simulationGraphics.isocronas : await createGraphicsIsocronas(data.response.data)

        if (data.simulacion.sim.GRAFICOS) {
          simulationGraphics.graficosSimulacion = simulationGraphics.graficosSimulacion.length > 0 ? simulationGraphics.graficosSimulacion : await createAuxiliarGraphicsSimulacion(data.simulacion.sim.GRAFICOS) // Probar que funcione
        }
      } else {
        simulationGraphics[data.simulacion.id] = simulationGraphics[data.simulacion.id].length > 0 ? simulationGraphics[data.simulacion.id] : await createGraphics(data.response.data)
      }

      paintSimulacionLayer(data.simulacion)

      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }
  } else {
    deferred.reject()
  }

  return deferred.promise
}

function comparador (a) {
  // Texto tiene que estar siempre antes
  if (a.symbol.type === 'text') {
    return 1
  }

  // En cualquier otro caso, va despues
  return -1
}

export const drawShareMapGraphics = async (isNotEditingMode) => {
  let graphicsList = store.getters['shareMap/graphics']
  let graphics = await createGraphicsDraw(graphicsList)

  if (isNotEditingMode) {
    shareMapSVM.updateOnGraphicClick = false
  } else {
    shareMapSVM.updateOnGraphicClick = true
  }

  let graphicsSorted = sortGraphics(graphics)

  let layer = map.findLayerById('layer-drawSketcher')
  layer.removeAll()
  layer.addMany(graphicsSorted)
}

export const stopShareMapSketchViewModel = function () {
  store.dispatch('shareMap/removeAllGraphics')

  shareMapSVM.cancel()
  shareMapSVM.destroy()
}
// #endregion

// #region ALINEACIONES CAMPBELL
let planACSVM = null

export const initAlineacionesCampbellSketchViewModel = async function (data) {
  try {
    const [SketchViewModel] = await loadModules([
      'esri/widgets/Sketch/SketchViewModel'
    ])
    let callbackCreate = data.callbackCreate
    let callbackUpdate = data.callbackUpdate
    let callbackDelete = data.callbackDelete

    let layer = map.findLayerById('layer-alineaciones-campbell')

    planACSVM = new SketchViewModel({
      view: mapView,
      layer: layer,
      defaultCreateOptions: { hasZ: false }
    })
    planACSVM.on(['create', 'delete', 'update'], async (evt) => {
      if (evt.state === 'complete' && evt.type === 'create') {
        // Crear grafico
        let attributes = {
          ID_GRAFICO: VueInst.$uuid.createUUID()
        }

        evt.graphic.attributes = JSON.parse(JSON.stringify(attributes)) // Hack para quitar el __observer__ que causa un bucle infinito a arcgis

        let geometry
        let type = evt.graphic.geometry.type
        let symbol
        if (evt.graphic.symbol.type === 'text') {
          // Texto
          let defaultSymbol = planACSVM.pointSymbol

          geometry = [evt.graphic.geometry.x, evt.graphic.geometry.y]
          type = 'text'
          symbol = {
            type: 'text',
            color: defaultSymbol.arrowColor,
            text: defaultSymbol.text,
            xoffset: 0,
            yoffset: 0,
            haloColor: 'white',
            haloSize: '5px',
            font: {
              size: defaultSymbol.font.size,
              family: 'Arial Unicode MS',
              weight: 'bold'
            }
          }
        } else if (evt.graphic.geometry.type === 'polygon') {
          // Poligonos
          let defaultSymbol = planACSVM.polygonSymbol

          geometry = evt.graphic.geometry.rings[0]
          symbol = {
            type: defaultSymbol.type,
            color: defaultSymbol.color,
            style: defaultSymbol.style,
            outline: {
              color: defaultSymbol.outline.color,
              width: defaultSymbol.outline.width
            }
          }
        }

        // Test
        // attributes.LONGITUD = GeometryEngine.geodesicLength(geometry)
        // attributes.AREA = Math.abs(GeometryEngine.geodesicArea(geometry))

        let graphic = {
          // TODO: poner los mismos nombres que en BBDD y cambiar en backend en (plan_operaciones addGraphics)
          attributes: attributes,
          coordenadas: geometry,
          type: type,
          symbol: symbol
        }

        callbackCreate(graphic)
      } else if (evt.state === 'complete' && evt.type === 'update') {
        // Actualizar grafico
        let graphic = evt.graphics[0]
        let geom = graphic.geometry

        let newGeometryGraphic
        if (geom.type === 'polygon') {
          newGeometryGraphic = geom.rings[0]
        } else {
          newGeometryGraphic = [geom.x, geom.y]
        }

        // geometry.type = graphic.geometry.type
        let newGraphic = {
          ATRIBUTOS: graphic.attributes,
          GEOMETRIA: newGeometryGraphic
        }

        callbackUpdate(newGraphic)
      } else if (evt.type === 'delete') {
        // Borrar grafico
        callbackDelete(evt.graphics[0].attributes.ID_GRAFICO)
      }
    })
  } catch (err) {
    VueInst.$log.error(err)
  }
}

export const paintEventAlineacionesCampbell = async (event) => {
  let typeEvent = event.function

  if (typeEvent === 'polygon') {
    planACSVM.polygonSymbol.color = event.color
    planACSVM.polygonSymbol.outline = {
      color: event.color,
      width: 2
    }
  } else if (typeEvent === 'text') {
    typeEvent = 'point'
    planACSVM.pointSymbol = {
      type: 'text',
      color: event.color,
      text: event.text,
      xoffset: 0,
      yoffset: 0,
      haloColor: 'white',
      haloSize: '20px',
      font: {
        size: event.size,
        family: 'Arial Unicode MS',
        weight: 'bold'
      }
    }
  }
  planACSVM.create(typeEvent)
}

export const cancelDrawingAlineaciones = function () {
  planACSVM.cancel()
}

export const stopAlineacionesCampbellSketchViewModel = function () {
  let layer = map.findLayerById('layer-alineaciones-campbell')
  layer.removeAll()

  store.dispatch('shareMap/stopAlineacionesCampbell')

  if (planACSVM == null) return
  planACSVM.cancel()
  planACSVM.destroy()
}

async function createGraphicsDrawAlineacionesCampbell (graphicList) {
  let deferred = Q.defer()

  try {
    const [Graphic, Point, Polygon, GeometryEngine] = await loadModules([
      'esri/Graphic',
      'esri/geometry/Point',
      'esri/geometry/Polygon',
      'esri/geometry/geometryEngine'
    ])

    let graphicsDraw = graphicList
    let geometry
    let graphics = []
    for (let i = 0; i < graphicsDraw.length; i++) {
      let tipo = graphicsDraw[i].type || graphicsDraw[i].TIPO // TODO: esto no mola
      let coords = graphicsDraw[i].coordenadas || graphicsDraw[i].GEOMETRIA
      let simbolo = graphicsDraw[i].symbol || graphicsDraw[i].SIMBOLO
      let atributos = graphicsDraw[i].attributes || graphicsDraw[i].ATRIBUTOS
      atributos = JSON.parse(JSON.stringify(atributos)) // HACK __observer__

      if (tipo === 'text') {
        geometry = new Point({
          x: coords[0],
          y: coords[1],
          spatialReference: { wkid: 3857 },
          hasZ: true
        })
      } else if (tipo === 'polygon') {
        geometry = new Polygon({
          hasZ: false, // Si se pone true, no funciona el 3D (no-sense)
          hasM: true,
          rings: coords,
          spatialReference: { wkid: 3857 }
        })

        // atributos.LONGITUD = GeometryEngine.geodesicLength(geometry)
        // atributos.AREA = Math.abs(GeometryEngine.geodesicArea(geometry))
      }

      let graphic = new Graphic({
        geometry: geometry,
        symbol: simbolo,
        attributes: atributos
      })

      graphics.push(graphic)
    }

    deferred.resolve(graphics)
  } catch (err) {
    VueInst.$log.error(err)
  }

  return deferred.promise
}

export const drawGraphicsAlineacionesCampbell = async function (graphicList) {
  let deferred = Q.defer()

  let layer = map.findLayerById('layer-alineaciones-campbell')
  layer.removeAll()

  store.dispatch('shareMap/paintAlineacionesCampbell', graphicList)

  let graphics = await createGraphicsDrawAlineacionesCampbell(graphicList)
  let graphicsSorted = sortGraphics(graphics)
  layer.addMany(graphicsSorted)

  deferred.resolve()

  return deferred.promise
}
// #endregion

// #region COMPASS WIDGET
let compass = null
export const compassWidget = async () => {
  try {
    const [Compass] = await loadModules(['esri/widgets/Compass'])

    if (!compass) {
      // Se inicializa si no lo está ya
      compass = new Compass({
        viewModel: { // autocasts as new CompassViewModel()
          view: mapView
        },
        id: 'compasss',
        container: 'compassWidget'
      })

      // mapView.ui.add(compass, 'bottom-right')
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}

let legend = null
function updateWidgetView () {
  const is3D = store.getters['map/isMap3D']
  if (compass) {
    compass.viewModel = { view: mapView }
  }

  if (legend) {
    legend.viewModel = { view: mapView }
    if (!is3D) {
      mapView.ui.add(legend, 'bottom-right')
    } else {
      mapView.ui.add(legend, 'bottom-right')
    }
  }

  if (planACSVM) {
    planACSVM.view = mapView
  }

  if (searchWidget) {
    searchWidget.view = mapView
  }
}
// #endregion

// #region LEGEND WIDGET
export const legendWidget = async () => {
  try {
    const [Legend, Expand] = await loadModules(['esri/widgets/Legend', 'esri/widgets/Expand'])

    if (!legend) {
      // Se inicializa si no lo está ya
      legend = new Expand({
        id: 'legend',
        content: new Legend({
          view: mapView,
          style: 'card', // other styles include 'classic'
          hideLayersNotInCurrentView: true
        }),
        expandIconClass: 'esri-icon-description',
        view: mapView,
        expanded: false
      })

      mapView.ui.add(legend, 'bottom-right')
    }
  } catch (err) {
    VueInst.$log.error(err)
  }
}
// #endregion

// #region areasIncendio

export const measurePerimetros = async (graficos) => {
  const [geometryEngine] = await loadModules([
    'esri/geometry/geometryEngine'
  ])

  let area = 0
  let perimetro = 0

  let x = []
  let y = []

  graficos.forEach((graphic) => {
    graphic.geometry = graphic.geometry ? graphic.geometry : JSON.parse(graphic.GEOMETRIA)
    area += geometryEngine.geodesicArea(graphic.geometry, 'square-meters')

    perimetro += geometryEngine.geodesicLength(graphic.geometry, 'kilometers')
    x.push(graphic.geometry.xmin)
    x.push(graphic.geometry.xmax)
    y.push(graphic.geometry.ymin)
    y.push(graphic.geometry.ymax)
  })

  area /= 10000

  const measure = { area: Math.round(area * 100) / 100, perimetro: Math.round(perimetro * 100) / 100, x, y }

  return measure
}

// #endregion

/*
async function test () {
  try {
    const [MapView] = await loadModules(['esri/views/MapView'])
  } catch (err) {
    VueInst.$log.error(err)
  }
}
*/
