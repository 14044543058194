var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Planes de operaciones")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","absolute":"","disabled":_vm.disabledByRol},on:{"click":_vm.openAddPlanDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir plan de operaciones")])])],1),_c('v-card-text',[_c('v-list',{attrs:{"expand":"","subheader":""}},[_vm._l((_vm.planesOperaciones),function(plan){return _c('v-list-item',{key:plan.ID_PLAN_OPERACIONES},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(plan.NOMBRE))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.formatDate(plan.FECHA)))])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.showDialog},on:{"click":function($event){plan.ID_PLAN_OPERACIONES === _vm.idPlanShowing ? _vm.mostrarPlan(null) : _vm.mostrarPlan(plan)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(_vm._s(plan.ID_PLAN_OPERACIONES === _vm.idPlanShowing ? 'mdi-eye-off' : 'mdi-eye'))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(plan.ID_PLAN_OPERACIONES === _vm.idPlanShowing ? 'Ocultar' : 'Visualizar'))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.showDialog || _vm.disabledByRol},on:{"click":function($event){return _vm.openEditPlanDialog(plan)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.showDialog || _vm.disabledByRol},on:{"click":function($event){return _vm.duplicarPlan(plan)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-content-duplicate")])],1)]}}],null,true)},[_c('span',[_vm._v("Duplicar")])])],1),_c('v-list-item-action',{staticClass:"mx-0"},[_c('v-menu',{attrs:{"bottom":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.showDialog}},Object.assign({}, onMenu, onTooltip)),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar como...")])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.exportarPlanSHP(plan)}}},[_c('v-list-item-title',[_vm._v("Descargar SHP")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportarPlanPDF(plan)}}},[_c('v-list-item-title',[_vm._v("Descargar PDF")])],1)],1)],1)],1)],1)}),(_vm.planesOperaciones && _vm.planesOperaciones.length <= 0)?_c('p',{staticStyle:{"margin":"16px 10px 10px 10px"},attrs:{"align":"center"}},[_vm._v("No hay ningún plan de operaciones.")]):_vm._e()],2)],1)],1),_c('vx-dialog-plan-operaciones',{attrs:{"show":_vm.showDialog,"idIncendio":_vm.idIncendio,"planSelected":_vm.planSelected,"isEdit":_vm.isEdit},on:{"open":function($event){_vm.showDialog = true},"close":function($event){_vm.showDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }