<template>
  <v-dialog v-model="show" persistent max-width="350px">
    <v-card>
      <v-card-title class="px-0 pt-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Copiar coordenadas</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-container class="mt-2">
          <v-row>
            <v-select v-model="tipoCoordenadas" :items="['Latitud / Longitud', 'X/Y ETRS89 30N', 'GMS']" label="Tipo coordenadas" outlined dense />
          </v-row>

          <!-- LATITUD LONGITUD -->
          <div v-if="tipoCoordenadas == 'Latitud / Longitud'">
            <v-row dense>
              <v-col>
                <v-text-field v-model="latitud" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.LATITUDE ? coordenadas.LATITUDE.replace(',', '.') : ''" disabled outlined dense />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field v-model="longitud" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.LONGITUDE ? coordenadas.LONGITUDE.replace(',', '.') : ''" disabled outlined dense />
              </v-col>
            </v-row>
          </div>

          <!-- X Y -->
          <div v-if="tipoCoordenadas == 'X/Y ETRS89 30N'">
            <v-row dense>
              <v-col>
                <v-text-field v-model="xETRS89" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.X_ETRS89 ? coordenadas.X_ETRS89 : ''" disabled outlined dense />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field v-model="yETRS89" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.Y_ETRS89 ? coordenadas.Y_ETRS89 : ''" disabled outlined dense />
              </v-col>
            </v-row>
          </div>

          <div v-if="tipoCoordenadas == 'GMS'">
            <v-row dense>
              <v-col>
                <v-text-field v-model="GMSLat" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.LATITUDE_GMS ? coordenadas.LATITUDE_GMS.replace(',', '.') : ''" disabled outlined dense />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field v-model="GMSLon" prepend-icon="mdi-map-marker" :label="coordenadas && coordenadas.LONGITUDE_GMS ? coordenadas.LONGITUDE_GMS.replace(',', '.') : ''" disabled outlined dense />
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        <v-btn text color="primary" @click="copiar">Copiar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    show: Boolean
  },
  data: () => ({
    tipoCoordenadas: 'Latitud / Longitud',
    copycoordinates: null,
    coordenadas: {},

    latitud: null,
    longitud: null,

    xETRS89: null,
    yETRS89: null,

    GMSLat: '',
    GMSLon: ''
  }),
  methods: {
    resetVariables () {
      this.tipoCoordenadas = 'Latitud / Longitud'
      this.copycoordinates = null
      this.xETRS89 = null
      this.yETRS89 = null
      this.latitud = null
      this.longitud = null
      this.GMSLat = null
      this.GMSLon = null
    },
    cerrar () {
      this.resetVariables()
      this.$emit('cerrar')
    },
    copiar () {
      if (this.tipoCoordenadas === 'X/Y ETRS89 30N') {
        this.copyCoordinates = this.coordenadas.X_ETRS89 + ',' + this.coordenadas.Y_ETRS89
      } else if (this.tipoCoordenadas === 'GMS') {
        this.copyCoordinates = this.coordenadas.LATITUDE_GMS.replace(',', '.') + ' ' + this.coordenadas.LONGITUDE_GMS.replace(',', '.')
      } else {
        this.copyCoordinates = this.coordenadas.LATITUDE.replace(',', '.') + ',' + this.coordenadas.LONGITUDE.replace(',', '.')
      }

      navigator.clipboard.writeText(this.copyCoordinates)

      this.cerrar()
    },
    async setNewCoordenadasMap (coordenadas) {
      this.coordenadas = coordenadas
    }
  },
  mounted () {
    this.$eventHub.$on('coordenadasMap', this.setNewCoordenadasMap)
  }
}
</script>

<style scoped>

</style>
