<template>
  <v-card tile height="100%" width="100%">
    <vx-dialog-add-turno :show="showAddTurno" @cancelar="showAddTurno = false" />
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title>Turnos</v-toolbar-title>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            x-small
            v-bind="attrs"
            v-on="on"
            fab
            right
            absolute
            color="secondary"
            @click="showAddTurno = true"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Añadir Turno</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row style="height: calc(50vh - 138px); overflow-y:auto;">
          <v-container>
            <v-row class="pa-0 ma-0" cols="12" v-for="turno in turnos" :key="turno.ID_TURNO">
              <v-col class="pa-1 ma-0" cols="7">{{ turno.NOMBRE }}</v-col>
              <v-col class="pa-1 ma-0" cols="5">{{ turno.HORA_ENTRADA }} - {{ turno.HORA_SALIDA }}</v-col>
              <!-- <v-col cols="1">
            <v-icon color="red">mdi-delete</v-icon>
          </v-col> -->
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DialogAddTurno from './DialogAddTurno.vue'
export default {
  components: {
    'vx-dialog-add-turno': DialogAddTurno
  },
  data () {
    return {
      showAddTurno: false
    }
  },
  computed: {
    ...mapGetters('medioPersonal', [
      'turnos'
    ])
  }
}
</script>

<style>

</style>
