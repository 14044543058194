var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"color":"primary","dark":"","app":"","height":"64px"}},[_c('div',{staticClass:"d-flex align-center mr-6"},[_c('img',{attrs:{"height":"45","src":require("../../assets/llama.svg"),"alt":"Logo"}})]),_c('v-toolbar-title',{staticClass:"hidden-md-and-down"},[_vm._v(" Incendios - "+_vm._s(_vm.paramsComunidad ? _vm.paramsComunidad.NOMBRE : '')+" ("+_vm._s(_vm.currentUsuario ? _vm.currentUsuario.USUARIO : '')+") ")]),_c('v-spacer'),(_vm.isMap)?_c('div',{staticClass:"d-flex"},[_vm._l((_vm.layerButtons),function(item){return _c('v-tooltip',{key:item.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vx-toggle-layer-button',{attrs:{"icon":item.icon,"layerId":item.layerId}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('vx-filter-menu',{attrs:{"icon":"mdi-filter"}})],1)]}}],null,false,2401331488)},[_c('span',[_vm._v("Filtros")])]),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}})],2):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"d-flex"},[_vm._l((_vm.getPathButtons),function(item){return _c('v-tooltip',{key:item.name,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","to":item.path,"disabled":item.disabled}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])}),_c('v-divider',{staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],attrs:{"icon":"","disabled":_vm.disabledByRol},on:{"click":function($event){_vm.showListadoIncendiosDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-playlist-edit")])],1)]}}],null,false,652575049)},[_c('span',[_vm._v("Listado de incendios")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],attrs:{"icon":"","disabled":_vm.disabledByRol},on:{"click":function($event){_vm.showSettingsDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cog")])],1)]}}],null,false,3041088454)},[_c('span',[_vm._v("Ajustes")])]),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$vuetify.breakpoint.mobile),expression:"!$vuetify.breakpoint.mobile"}],staticClass:"mx-3",attrs:{"vertical":""}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.initLogout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-logout-variant")])],1)]}}],null,false,351002144)},[_c('span',[_vm._v("Logout")])])],2):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,1620504925),model:{value:(_vm.showMobileMenu),callback:function ($$v) {_vm.showMobileMenu=$$v},expression:"showMobileMenu"}},[_c('v-card',{attrs:{"tile":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"primaryLight","dark":""}},[_c('v-toolbar-title',[_vm._v("Menú")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showMobileMenu = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list',[_vm._l((_vm.getPathButtons),function(item,i){return _c('v-list-item',{key:i,attrs:{"to":item.path},on:{"click":function($event){_vm.showMobileMenu = false}}},[_c('v-list-item-action',{attrs:{"disabled":item.disabled}},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{attrs:{"title":item.name}},[_vm._v(_vm._s(item.name))])],1)],1)}),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.initLogout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar sesión")])],1)],1)],2)],1)],1)],1):_vm._e(),_c('vx-listado-incendios-dialog',{attrs:{"show":_vm.showListadoIncendiosDialog},on:{"cerrar":function($event){_vm.showListadoIncendiosDialog = false}}}),_c('vx-settings-dialog',{attrs:{"show":_vm.showSettingsDialog},on:{"cerrar":function($event){_vm.showSettingsDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }