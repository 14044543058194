<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title style="width: 100%">Gestión de Personal</v-toolbar-title>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            fab
            small
            bottom
            right
            absolute
            color="secondary"
            @click="showAddPersonalDialog"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Personal</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container class="py-0 ma-0" fluid ref="firstContainer">
        <v-row>
          <v-col cols="10">
            <v-autocomplete
              v-model="tableFilters"
              return-object
              :items="headers.filter(x => x.text !== 'Acciones')"
              :item-text="(h) => {return h.text}"
              item-title="text"
              label="Mostrar campos"
              closable-chips
              multiple
              clearable
              dense
              outlined
            >
              <template #selection="{ item, index }">
                <v-chip v-if="index < maxVisible" :key="item.value" small>
                  {{ item.text }}
                </v-chip>
                <span v-else-if="index===maxVisible" :key="index" small>
                  {{ computedHeaders.length - maxVisible - 1 }} más
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="resetFilters()"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-btn
              icon
              color="teal"
              @click="mostrarFilAv = !mostrarFilAv;increaseFirstContainerHeight()"
            >
              <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
              <v-icon v-else>mdi-filter-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="downloadExcelPersonales()"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="d-flex align-center mt-0">
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Búsqueda general" v-model="search" />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Nombre" v-model="nombreSelected" />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Apellidos" v-model="apellidosSelected" />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Alias" v-model="aliasSelected" />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Teléfono" v-model="telefonoSelected" />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="ISSI" v-model="issiSelected" />
          </v-col>
          <v-col cols="1">
            <v-autocomplete
              v-model="estadoSelected"
              class="no-spacing"
              return-object
              :items="estadosPosibles()"
              :item-text="(estado) => {return estado.NOMBRE.substring(0, 1).toUpperCase() + estado.NOMBRE.substring(1, estado.NOMBRE.length).toLowerCase()}"
              item-title="NOMBRE"
              item-value="ID_MEDIO_ESTADO"
              label="Estado"
            />
          </v-col>
          <v-col cols="1">
            <v-checkbox class="no-spacing" label="Inactivos" v-model="activoSelected" />
          </v-col>
        </v-row>
        <v-row class="mt-0" v-show="mostrarFilAv">
          <v-col cols="2">
            <v-autocomplete
              v-model="baseSelected"
              class="no-spacing"
              return-object
              :items="basesItems()"
              :item-text="(base) => {return base.NOMBRE}"
              item-title="NOMBRE"
              item-value="ID_INFRAESTRUCTURA"
              label="Base"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="tituloProfSelected"
              class="no-spacing"
              return-object
              :items="tituloProfesionalItems()"
              :item-text="(titulo) => {return titulo.TITULO_PROFESIONAL}"
              item-title="TITULO_PROFESIONAL"
              item-value="ID_TITULO_PROFESIONAL"
              label="Título profesional"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="categoriaSelected"
              class="no-spacing"
              return-object
              :items="categoriasPersonalItems()"
              :item-text="(categoria) => {return categoria.CATEGORIA}"
              item-title="CATEGORIA"
              item-value="ID_PERSONAL_CATEGORIA"
              label="Categoria"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="tipoSelected"
              class="no-spacing"
              return-object
              :items="tiposPersonalFilter"
              :item-text="(tipo) => {return tipo.PERSONAL_TIPO}"
              item-title="TIPO"
              item-value="ID_PERSONAL_TIPO"
              label="Tipo"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Identificador" v-model="identificadorSelected" />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="ISSI posic." v-model="issiPosicSelected" />
          </v-col>
          <v-col cols="1">
            <v-checkbox
              class="no-spacing"
              label="Funcionario"
              :indeterminate="indetFun"
              v-model="funcionarioSelected"
              @click="funcionarioChkHandler()"
            />
          </v-col>
          <v-col cols="1">
            <v-checkbox
              class="no-spacing"
              label="Despachable"
              :indeterminate="indetDesp"
              v-model="despachableSelected"
              @click="despachableChkHandler()"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid ref="secondContainer">
        <v-data-table
          class="table"
          :headers="computedHeaders"
          :items="personalesFilter"
          fixed-header
          disable-pagination
          hide-default-footer
          :style="{ height: `calc(${availableSpace}vh)` }"
        >
          <template #[`item.DESPACHABLE`]="{ item }">
            <span>{{ item.DESPACHABLE ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.FUNCIONARIO`]="{ item }">
            <span>{{ item.FUNCIONARIO ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.TELEFONO`]="{ item }">
            <a :href="'tel:+34' + (item.TELEFONO ? item.TELEFONO.replaceAll(' ',''):null)">{{
              item.TELEFONO
            }}</a>
          </template>

          <template #[`item.TELEFONO2`]="{ item }">
            <a :href="'tel:+34' + (item.TELEFONO2 ? item.TELEFONO2.replaceAll(' ',''):null)">{{
              item.TELEFONO2
            }}</a>
          </template>

          <template #[`item.ACCIONES`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showEditPersonalDialog(item)"
                >
                  <v-icon color="teal">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showEditEstado(item)"
                >
                  <v-icon :color="item.ACTIVO ? 'success': 'red'">{{ item.ACTIVO ? 'mdi-domain': 'mdi-domain-off' }}</v-icon>
                </v-btn>
              </template>
              <span>Cambiar estado</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="showAddImagenesDialog(item)"
                >
                  <v-icon color="teal">mdi-camera-outline</v-icon>
                </v-btn>
              </template>
              <span>Fotos</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
    <vx-dialog-edit-estado
      :show="showDialogEstado"
      :editedPersonal="editedPersonal"
      @cancelar="showDialogEstado = false"
      @editEstado="acceptEditEstado"
    />
    <vx-dialog-add-edit-personal
      :show="showDialog"
      :isEdit="isEdit"
      :editedPersonal="editedPersonal"
      @cancelar="showDialog = false"
      @addPersonal="acceptAddPersonal"
      @editPersonal="acceptEditPersonal"
    />
    <vx-dialog-add-imagenes class="mt-4" :idPersonal="showDialogImagen?editedPersonal.ID_PERSONAL:null" :show="showDialogImagen" @cancelar="cancelAddImagenes" />
  </v-card>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import DialogAddEditPersonalVue from './DialogAddEditPersonal.vue'
import DialogEditEstadoVue from './DialogEditEstado.vue'
import DialogAddImagenesPersonal from './DialogAddImagenesPersonal.vue'
import constants from '../../helpers/constants'
import * as ExcelHelper from '../../helpers/ExcelHelper'

export default {
  components: {
    'vx-dialog-add-edit-personal': DialogAddEditPersonalVue,
    'vx-dialog-edit-estado': DialogEditEstadoVue,
    'vx-dialog-add-imagenes': DialogAddImagenesPersonal
  },
  data () {
    return {
      search: '',
      nombreSelected: null,
      apellidosSelected: null,
      issiSelected: null,
      telefonoSelected: null,
      aliasSelected: null,
      activoSelected: false,
      mostrarFilAv: false,
      estadoSelected: null,
      baseSelected: null,
      categoriaSelected: null,
      tipoSelected: null,
      tituloProfSelected: null,
      identificadorSelected: null,
      issiPosicSelected: null,
      funcionarioSelected: false,
      indetFun: true,
      funcionarioChkBoxCounter: 0,
      despachableSelected: false,
      indetDesp: true,
      despachableChkBoxCounter: 0,
      personalesFilter: [],
      tiposPersonalFilter: [],
      tableFilters: [],
      availableSpace: 0,
      headers: [
        { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, initialShow: true },
        { text: 'Apellidos', value: 'APELLIDOS', align: 'left', sortable: true, initialShow: true },
        { text: 'Alias', value: 'ALIAS', align: 'left', sortable: true, initialShow: true },
        { text: 'Título Profesional', value: 'TITULO_PROFESIONAL.TITULO_PROFESIONAL', align: 'left', sortable: true, initialShow: true },
        { text: 'Identificador', value: 'IDENTIFICADOR', align: 'left', sortable: true, initialShow: true },
        { text: 'Teléfono', value: 'TELEFONO', align: 'left', sortable: true, initialShow: true },
        { text: 'Teléfono 2', value: 'TELEFONO2', align: 'left', sortable: true, initialShow: false },
        { text: 'ISSI', value: 'ISSI', align: 'left', sortable: true, initialShow: true },
        { text: 'Base', value: 'INFRAESTRUCTURA.NOMBRE', align: 'left', sortable: true, initialShow: true },
        { text: 'Categoría', value: 'PERSONAL_TIPO.CATEGORIA.CATEGORIA', align: 'left', sortable: true, initialShow: true },
        { text: 'Tipo', value: 'PERSONAL_TIPO.PERSONAL_TIPO', align: 'left', sortable: true, initialShow: true },
        { text: 'DNI', value: 'DNI', align: 'left', sortable: true, initialShow: false },
        { text: 'Ident.', value: 'IDENTIFICADOR', align: 'left', sortable: true, initialShow: false },
        { text: 'Login', value: 'LOGIN', align: 'left', sortable: true, initialShow: false },
        { text: 'Correo', value: 'CORREO', align: 'left', sortable: true, initialShow: false },
        { text: 'Correo 2', value: 'CORREO2', align: 'left', sortable: true, initialShow: false },
        { text: 'ISSI Posic.', value: 'ISSI_POS', align: 'left', sortable: true, initialShow: false },
        { text: 'Funcionario', value: 'FUNCIONARIO', align: 'left', sortable: true, initialShow: false },
        { text: 'FU', value: 'FU', align: 'left', sortable: true, initialShow: false },
        { text: 'Municipio', value: 'INFRAESTRUCTURA.MUNICIPIO.NOMBRE', align: 'left', sortable: true, initialShow: false },
        { text: 'Provincia', value: 'INFRAESTRUCTURA.MUNICIPIO.PROVINCIA.LITERAL', align: 'left', sortable: true, initialShow: false },
        { text: 'Demarcación', value: 'INFRAESTRUCTURA.MUNICIPIO.DEMARCACION.DEMARCACION', align: 'left', sortable: true, initialShow: false },
        { text: 'Responsable', value: 'ORGANISMO.ORGANISMO', align: 'left', sortable: true, initialShow: false },
        { text: 'Descripción', value: 'DESCRIPCION', align: 'left', sortable: true, initialShow: false },
        { text: 'Estado', value: 'MEDIO_ESTADO.NOMBRE', align: 'left', sortable: true, initialShow: false },
        // { text: 'Activo', value: 'ACTIVO', align: 'left', sortable: true, initialShow: false },
        { text: 'Despachable', value: 'DESPACHABLE', align: 'left', sortable: true, initialShow: false },

        { text: 'Acciones', value: 'ACCIONES', align: 'right', width: '10%', sortable: false, initialShow: true }
      ],
      showDialog: false,
      showDialogEstado: false,
      showDialogImagen: false,
      isEdit: false,
      editedPersonal: {},
      maxVisible: 10
    }
  },
  computed: {
    ...mapGetters('medio', [
      'tiposMedio'
    ]),

    ...mapGetters('personal', [
      'personales'
    ]),

    ...mapGetters('tituloProfesional', [
      'titulosProfesionales'
    ]),

    ...mapGetters('estadosMedio', [
      'estados'
    ]),

    ...mapGetters('personalTipo', [
      'tiposPersonal'
    ]),

    ...mapGetters('personalCategoria', [
      'categoriasPersonal'
    ]),

    ...mapGetters('infraestructura', [
      'infraestructuras', 'tipos'
    ]),

    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Acciones') || this.tableFilters.includes(x))
    }
  },
  watch: {
    search () {
      this.filterPersonales()
    },
    personales () {
      this.filterPersonales()
    },
    nombreSelected () {
      this.filterPersonales()
    },
    apellidosSelected () {
      this.filterPersonales()
    },
    telefonoSelected () {
      this.filterPersonales()
    },
    issiSelected () {
      this.filterPersonales()
    },
    aliasSelected () {
      this.filterPersonales()
    },
    activoSelected () {
      this.filterPersonales()
    },
    estadoSelected () {
      this.filterPersonales()
    },
    baseSelected () {
      this.filterPersonales()
    },
    categoriaSelected () {
      this.tiposPersonalFilter = [...this.tiposPersonal].sort((a, b) => constants.collator.compare(a.PERSONAL_TIPO, b.PERSONAL_TIPO))
      // Si se selecciona categoria y tipo no existe filtra tipos
      if (this.categoriaSelected && this.categoriaSelected !== '') {
        if (!this.tipoSelected || this.tipoSelected === '') {
          this.tiposPersonalFilter = [...this.tiposPersonal].filter(tipo => tipo.CATEGORIA.CATEGORIA === this.categoriaSelected.CATEGORIA).sort((a, b) => constants.collator.compare(a.PERSONAL_TIPO, b.PERSONAL_TIPO))
        }
      }
      this.filterPersonales()
    },
    tipoSelected () {
      if (this.tipoSelected && this.tipoSelected !== '') {
        this.categoriaSelected = [...this.categoriasPersonal].find(categoria => categoria.CATEGORIA === this.tipoSelected.CATEGORIA.CATEGORIA)
      }
      this.filterPersonales()
    },
    tituloProfSelected () {
      this.filterPersonales()
    },
    identificadorSelected () {
      this.filterPersonales()
    },
    issiPosicSelected () {
      this.filterPersonales()
    }
  },
  methods: {
    ...mapActions('infraestructura', ['fetchInfraestructuras', 'addInfraestructura', 'updateInfraestructura']),

    ...mapActions('personal', ['addPersonal', 'updatePersonal']),

    ...mapActions('personalMedioTipo', ['updatePersonalMedioTipo']),

    async filterPersonales () {
      let personalesFilter = []
      if (this.personales !== undefined) {
        personalesFilter = JSON.parse(JSON.stringify(this.personales)).filter(x => x.ACTIVO === !this.activoSelected)

        if (this.search && this.search !== '') {
          personalesFilter = personalesFilter.filter(x => {
            return (
              (x.NOMBRE && x.NOMBRE.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.APELLIDOS && x.APELLIDOS.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.ALIAS && x.ALIAS.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.TITULO_PROFESIONAL && x.TITULO_PROFESIONAL.TITULO_PROFESIONAL && x.TITULO_PROFESIONAL.TITULO_PROFESIONAL.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.IDENTIFICADOR && x.IDENTIFICADOR.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.TELEFONO && x.TELEFONO.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.ISSI && x.ISSI.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.INFRAESTRUCTURA && x.INFRAESTRUCTURA.NOMBRE && x.INFRAESTRUCTURA.NOMBRE.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.PERSONAL_TIPO && x.PERSONAL_TIPO.CATEGORIA && x.PERSONAL_TIPO.CATEGORIA.CATEGORIA && x.PERSONAL_TIPO.CATEGORIA.CATEGORIA.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.PERSONAL_TIPO && x.PERSONAL_TIPO.PERSONAL_TIPO && x.PERSONAL_TIPO.PERSONAL_TIPO.toLowerCase().includes(this.search.toLowerCase()))
            )
          })
        }

        // Esto no creo que funcione con mas de un filtro - preguntar ander
        if (this.nombreSelected && this.nombreSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.NOMBRE.includes(this.nombreSelected))
        }

        if (this.apellidosSelected && this.apellidosSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.APELLIDOS.includes(this.apellidosSelected))
        }

        if (this.telefonoSelected && this.telefonoSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.TELEFONO !== null && x.TELEFONO.includes(this.telefonoSelected))
        }

        if (this.issiSelected && this.issiSelected !== '') {
          personalesFilter = personalesFilter.filter(x => (x.ISSI !== null && x.ISSI.includes(this.issiSelected)))
        }

        if (this.aliasSelected && this.aliasSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.ALIAS !== null && x.ALIAS.includes(this.aliasSelected))
        }

        if (this.estadoSelected) {
          personalesFilter = personalesFilter.filter(x => x.MEDIO_ESTADO.NOMBRE.includes(this.estadoSelected.NOMBRE))
        }

        if (this.baseSelected) {
          personalesFilter = personalesFilter.filter(x => x.INFRAESTRUCTURA.NOMBRE.includes(this.baseSelected.NOMBRE))
        }

        if (this.categoriaSelected) {
          personalesFilter = personalesFilter.filter(x => x.PERSONAL_TIPO.CATEGORIA.CATEGORIA.includes(this.categoriaSelected.CATEGORIA))
        }

        if (this.tipoSelected) {
          personalesFilter = personalesFilter.filter(x => x.PERSONAL_TIPO.PERSONAL_TIPO.includes(this.tipoSelected.PERSONAL_TIPO))
        }

        if (this.tituloProfSelected) {
          personalesFilter = personalesFilter.filter(x => x.TITULO_PROFESIONAL.TITULO_PROFESIONAL.includes(this.tituloProfSelected.TITULO_PROFESIONAL))
        }

        if (this.identificadorSelected !== null && this.identificadorSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.IDENTIFICADOR !== null && x.IDENTIFICADOR.includes(this.identificadorSelected))
        }

        if (this.issiPosicSelected !== null && this.issiPosicSelected !== '') {
          personalesFilter = personalesFilter.filter(x => x.ISSI_POS !== null && x.ISSI_POS.includes(this.issiPosicSelected))
        }

        if (!this.indetFun) {
          personalesFilter = personalesFilter.filter(x => x.FUNCIONARIO === this.funcionarioSelected)
        }
        if (!this.indetDesp) {
          personalesFilter = personalesFilter.filter(x => x.DESPACHABLE === this.despachableSelected)
        }

        this.personalesFilter = personalesFilter.sort((a, b) => constants.collator.compare(a.APELLIDOS, b.APELLIDOS))
      } else {
        await new Promise((resolve, reject) => setTimeout(resolve, 500))
        this.filterPersonales()
      }
    },
    // Se mete un counter para hacer que el v-checkbox emule toggle de 3 valores en vez de 2
    // Si se pudiese sacar de otra manera, quizá sea mejor
    funcionarioChkHandler () {
      this.funcionarioChkBoxCounter++
      this.indetFun = false
      if (this.funcionarioChkBoxCounter === 3) {
        this.indetFun = true
        this.funcionarioChkBoxCounter = 0
      }
      this.filterPersonales()
    },

    despachableChkHandler () {
      this.despachableChkBoxCounter++
      this.indetDesp = false
      if (this.despachableChkBoxCounter === 3) {
        this.indetDesp = true
        this.despachableChkBoxCounter = 0
      }
      this.filterPersonales()
    },

    acceptAddPersonal (personal) {
      this.showDialog = false
      this.addPersonal(personal)
      this.updateTiposMedios(personal)
    },
    acceptEditPersonal (personal) {
      this.showDialog = false
      this.updatePersonal(personal)
      this.updateTiposMedios(personal)
    },
    acceptEditEstado (personal) {
      this.showDialogEstado = false
      this.updatePersonal(personal)
    },

    updateTiposMedios (personal) {
      let personalMedioTipos = personal.TIPOS_MEDIOS.map(tipo => ({
        ID_PERSONAL_MEDIO_TIPO: tipo.ID_PERSONAL_MEDIO_TIPO,
        ID_MEDIO_TIPO: tipo.ID_MEDIO_TIPO,
        ID_PERSONAL: tipo.ID_PERSONAL,
        MEDIO_TIPO: this.tiposMedio.find(x => tipo.ID_MEDIO_TIPO === x.ID_MEDIO_TIPO),
        PERSONAL: this.personales.find(x => tipo.ID_PERSONAL === x.ID_PERSONAL)
      })
      )
      let objPersTipoAndId = {
        PERSONAL_MEDIO_TIPO: personalMedioTipos,
        ID_PERSONAL: personal.ID_PERSONAL
      }
      this.updatePersonalMedioTipo(objPersTipoAndId)
    },

    showAddPersonalDialog () {
      this.editedPersonal = null
      this.isEdit = false
      this.showDialog = true
    },
    showEditPersonalDialog (item) {
      this.editedPersonal = Object.assign({}, item)
      this.isEdit = true
      this.showDialog = true
    },
    showEditEstado (item) {
      this.editedPersonal = Object.assign({}, item)
      this.showDialogEstado = true
    },
    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)
    },
    estadosPosibles () {
      let estados = JSON.parse(JSON.stringify(this.estados))
      if (!this.activoSelected) {
        return estados.filter(x => x.MEDIO_CLASE.NOMBRE === 'PERSONAL' && x.NOMBRE === 'ACTIVO')
      }
      return estados.filter(x => x.MEDIO_CLASE.NOMBRE === 'PERSONAL' && x.NOMBRE !== 'ACTIVO')
    },
    tiposPersonalItems () {
      let tiposPersonales = JSON.parse(JSON.stringify(this.tiposPersonal))
      tiposPersonales = tiposPersonales.sort((a, b) => constants.collator.compare(a.PERSONAL_TIPO, b.PERSONAL_TIPO))
      this.tiposPersonalFilter = tiposPersonales
    },
    categoriasPersonalItems () {
      let categoriasPersonal = JSON.parse(JSON.stringify(this.categoriasPersonal))
      categoriasPersonal = categoriasPersonal.sort((a, b) => constants.collator.compare(a.CATEGORIA, b.CATEGORIA))
      return categoriasPersonal
    },
    tituloProfesionalItems () {
      let titulosProf = JSON.parse(JSON.stringify(this.titulosProfesionales))
      titulosProf = titulosProf.sort((a, b) => constants.collator.compare(a.TITULO_PROFESIONAL, b.TITULO_PROFESIONAL))
      return titulosProf
    },
    basesItems () {
      let collator = new Intl.Collator('es', { sensitivity: 'base' })
      // to do after fixed del fetch de infraestructuras
      // let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => this.isBase(infra))))
      let tiposBasesIds = this.tipos.filter(tipoInfra => this.isBase(tipoInfra)).map(tipoInfra => tipoInfra.ID_INFRAESTRUCTURA_TIPO)
      let bases = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => tiposBasesIds.includes(infra.ID_INFRAESTRUCTURA_TIPO))))
      bases = bases.sort((a, b) => collator.compare(a.NOMBRE, b.NOMBRE))
      return bases
    },
    isBase (infraestructuraTipo) {
      let infraTipo = infraestructuraTipo.NOMBRE
      if (infraTipo === 'Centro Comarcal' || infraTipo === 'Consejeria' || infraTipo === 'Base Medios Helitransportados' || infraTipo === 'Base Medios Terrestres' ||
        infraTipo === 'Sede' || infraTipo === 'Oficina') {
        return true
      }
      return false
    },

    increaseFirstContainerHeight () {
      // Forzar una actualización del DOM para reflejar la nueva altura
      this.$nextTick(() => {
        this.calculateAvailableSpace()
      })
    },
    calculateAvailableSpace () {
      // Obtener la posición y altura del primer v-container
      const firstContainer = this.$refs.firstContainer
      const firstContainerRect = firstContainer.getBoundingClientRect()
      const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

      // Obtener la altura del viewport
      const viewportHeight = window.innerHeight

      // Calcular el espacio disponible en vh
      this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
    },

    downloadExcelPersonales () {
      ExcelHelper.downloadJSON2CSV(this.computedHeaders.filter(x => x.value !== 'ACCIONES'), this.personalesFilter)
    },

    showAddImagenesDialog (item) {
      this.editedPersonal = Object.assign({}, item)
      this.showDialogImagen = true
    },
    cancelAddImagenes () {
      this.showDialogImagen = false
    }
  },
  mounted () {
    this.funcionarioChkBoxCounter = 0
    this.despachableChkBoxCounter = 0
    this.resetFilters()
    this.maxVisible = this.headers.filter(x => x.initialShow).length - 1
    this.tiposPersonalItems()
    this.filterPersonales()

    // Calcular el espacio disponible al montar el componente
    this.calculateAvailableSpace()
    // También puedes actualizar el espacio disponible si la ventana se redimensiona
    window.addEventListener('resize', this.calculateAvailableSpace)
  },
  beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
    window.removeEventListener('resize', this.calculateAvailableSpace)
  }
}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
.no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}
</style>
