import request from '../request'

export default {
  getPersonal: function () {
    return request.get('personal')
  },
  addPersonal: function (data) {
    return request.post('personal', data)
  },
  updatePersonal: function (data) {
    return request.put('personal', data)
  }
}
