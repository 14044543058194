<template>
  <v-dialog v-model="show" persistent width="700px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Medios</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-text-field v-model="search" class="mt-2" label="Buscar" append-icon="mdi-magnify" style="width: 200px" />

        <v-btn-toggle v-model="categoriasSelecionadas" color="primary" rounded multiple>
          <v-btn :key="tipo.index" v-for="tipo in categoriasMedios" small>{{ tipo.text }}</v-btn>
        </v-btn-toggle>

        <v-data-table
          v-model="mediosSelected" :headers="headers" :items="mediosOrder" item-key="ID_MEDIO" :search="search" height="400px" :item-class="getRowColor"
          show-select fixed-header disable-pagination hide-default-footer
        >
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>

          <template #[`header.data-table-select`]>
            <!-- Quitamos el boton de seleccionar todos -->
          </template>

          <template #[`item.data-table-select`]="{ item, isSelected, select }">
            <v-simple-checkbox :value="isSelected" @input="select($event)" v-if="!disabledPorFinDescanso(item)" :disabled="item.ESTADO == 1 || item.ESTADO == 2" :ripple="false" />

            <v-tooltip bottom v-else>
              <template #activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#c00d0d">mdi-sleep</v-icon>
              </template>
              <span>El descanso finaliza: {{ $date.formatDate(item.FECHA_FIN_DESCANSO, 'DD/MM/YYYY HH:mm') }}</span>
            </v-tooltip>
          </template>

          <template #[`item.MEDIO`]="{ item }">
            <v-tooltip bottom v-if="(item.ESTADO == 1 || item.ESTADO == 2) && item.ID_INCENDIO">
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">{{ item.MEDIO }}</span>
              </template>
              <span>Asignado a incendio: {{ $store.getters['incendio/getIncendioByID'](item.ID_INCENDIO) ? $store.getters['incendio/getIncendioByID'](item.ID_INCENDIO).MUNICIPIO : null }}</span>
            </v-tooltip>
            <span v-else>{{ item.MEDIO }}</span>
          </template>

          <!-- <template v-slot:item="{ item }">
            <tr v-bind:style="[item.ESTADO != 0 ? {
              'background-color': estadoMedios[item.ESTADO].COLOR,
              'border-color':  estadoMedios[item.ESTADO].COLOR
            } : disabledPorFinDescanso(item) ? {
              'background-color': '#aaaaaa',
              'border-color':  '#ff0000'
            } : '']">

              <td>
                <v-checkbox class="ma-0 pa-0" v-model="mediosSelected" :value="item" v-if="!disabledPorFinDescanso(item)" :disabled="item.ESTADO == 1 || item.ESTADO == 2" hide-details/>

                <v-tooltip bottom v-if="disabledPorFinDescanso(item)">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="unselectable" v-bind="attrs" v-on="on" color="#c00d0d">mdi-sleep</v-icon>
                  </template>
                  <span>{{ $date.formatDate(item.FECHA_FIN_DESCANSO, 'HH:mm DD/MM/YYYY') }}</span> CHECK CHECK CHECK
                </v-tooltip>
              </td>

              <td>
                <v-tooltip bottom v-if="item.ESTADO != 0">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ item.MEDIO }}</span>
                  </template>
                  <span>Asignado a incendio {{ $store.getters['incendio/getIncendioByID'](item.ID_INCENDIO).MUNICIPIO }}</span>  CHECK CHECK CHECK
                </v-tooltip>
                <span v-else>{{ item.MEDIO }}</span>
              </td>

              <td>{{ item.TIPO }}</td>
              <td>{{ item.HORA_ENTRADA }}</td>
              <td>{{ item.HORA_SALIDA }}</td>
            </tr>
          </template> -->
        </v-data-table>

        <v-container fluid>
          <v-form v-model="isValid">
            <v-row>
              <v-col>
                <vx-date-text-edit v-model="fechaAviso" label="Fecha aviso" icon="esri-icon-calendar" :min="dateMin" required />
              </v-col>
              <v-col>
                <vx-date-text-edit v-model="inicioJornada" label="Inicio de jornada" icon="esri-icon-calendar" required />
              </v-col>
              <v-col>
                <v-select v-model="idSector" :items="sectores" label="Sector" prepend-icon="esri-icon-description" item-text="SECTOR" item-value="ID_SECTOR" :rules="[globalRules.required]" />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar" :disabled="disableAdd">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constants from '../../../helpers/constants'

export default {
  props: {
    show: Boolean,
    idIncendio: String
  },

  data: () => ({
    isValid: false,

    search: '',

    categoriasMedios: [],

    // headers: [
    //   // { text: '', sortable: false },
    //   // { text: 'Id', value: 'ID_MEDIO', align: 'left', sortable: true },
    //   { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true },
    //   { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true },
    //   { text: 'Entrada', value: 'HORA_ENTRADA', align: 'left', sortable: true },
    //   { text: 'Salida', value: 'HORA_SALIDA', align: 'left', sortable: true }
    // ],

    fechaAviso: null,
    inicioJornada: null,

    dateMin: null,

    mediosSelected: [],

    idSector: null,

    mediosOrder: [],

    estadoMedios: [],

    categoriasSelecionadas: []
  }),

  computed: {
    ...mapGetters('medio', [
      'clasesMedio'
    ]),

    disableAdd () {
      return this.mediosSelected.length === 0 || !this.isValid
    },

    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    medios () {
      let medios = JSON.parse(JSON.stringify(this.$store.getters['medio/medios']))
      // Solo es posible añadir medios habilitados y activos
      medios = medios.filter(x => x.HABILITADO === true && x.ACTIVO === true)

      let incendios = this.$store.getters['incendio/incendios'] // TODO: esta chapuza puede dar problemas aun mayores si se oculta el incendio antes de que acabe el descanso
      // console.log('MEDIOS', medios.filter(x => x.MEDIO === '113'))
      /** TODO: Esta cosa super cutre se hace para tener la fecha de fin descanso mas tardia en DialogAddMedios */
      medios.forEach((m) => { // Añadir descanso a cada medio de la lista
        let fechaDescanso = this.$date.parseDate('2018-01-01 00:00:00.000')
        incendios.forEach((incendio) => {
          for (let i = 0; i < incendio.SECTORES.length; i++) {
            let sector = incendio.SECTORES[i]

            for (let j = 0; j < sector.MEDIOS.length; j++) {
              let medioSector = sector.MEDIOS[j]

              if (medioSector.ID_MEDIO.equalsIgnoreCase(m.ID_MEDIO)) {
                if (medioSector.FECHA_FIN_DESCANSO) {
                  let newFechaDescanso = this.$date.parseDate(medioSector.FECHA_FIN_DESCANSO, 'YYYY-MM-DDTHH:mm:ss.SSSZ')

                  if (newFechaDescanso > fechaDescanso) {
                    fechaDescanso = newFechaDescanso
                  }
                }
              }
            }
          }
        })

        if (fechaDescanso.format('YYYY/MM/DD HH:mm') !== '2018/01/01 00:00') {
          m.FECHA_FIN_DESCANSO = fechaDescanso
        }
      })

      return medios
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    },

    headers () {
      return [
        { text: 'Nombre', value: 'MEDIO', align: 'left', sortable: true },
        { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true },
        { text: 'Entrada', value: 'HORA_ENTRADA', align: 'left', sortable: true },
        { text: 'Salida', value: 'HORA_SALIDA', align: 'left', sortable: true },
        {
          text: 'Clase',
          value: 'CLASE',
          filter: value => {
            let categoria = this.categoriasMedios.find(element => element.value === value)

            return categoria && this.categoriasSelecionadas.includes(categoria.index)
          },
          align: 'left',
          sortable: true
        }
      ]
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.fechaAviso = this.$date.currentDate()
        this.inicioJornada = this.$date.currentDate()
        this.setCategoriasMedio()
        this.idSector = this.sectores[0].ID_SECTOR

        this.dateMin = this.$date.parseDate(this.incendio.FECHA_CREACION)

        this.orderMedios()
      }
    },

    medios () {
      this.orderMedios()
    }
  },

  methods: {
    setCategoriasMedio () {
      this.categoriasMedios = []
      this.categoriasSelecionadas = []

      this.clasesMedio.forEach((element, index) => {
        let categoria = {
          text: element.NOMBRE, value: element.NOMBRE, id: element.ID_MEDIO_CLASE, align: 'left', sortable: true, index: index
        }

        this.categoriasMedios.push(categoria)

        this.categoriasSelecionadas.push(index)
      })
    },

    disabledPorFinDescanso (medio) {
      let disabled = false

      if (medio.FECHA_FIN_DESCANSO) {
        let now = this.$date.now()
        let fechaFinDescanso = this.$date.parseDate(medio.FECHA_FIN_DESCANSO)
        disabled = fechaFinDescanso > now
      }
      return disabled
    },

    getRowColor (medio) { // TODO: esto es muy cutre pero no se como hacerlo de otra forma
      let rowClass = ''
      switch (medio.ESTADO) {
        case constants.estadosMedioIncendio.AVISO:
          rowClass = 'medioAviso'
          break
        case constants.estadosMedioIncendio.ACTUANDO:
          rowClass = 'medioLlegadaInc'
          break
        case constants.estadosMedioIncendio.REGRESO:
          rowClass = 'medioSalida'
          break
      }

      if (this.disabledPorFinDescanso(medio)) {
        rowClass = 'medioDescanso'
      }

      return rowClass
    },

    orderMedios () {
      let mediosOrder = JSON.parse(JSON.stringify(this.medios))
      /* for (let i = 0; i < mediosOrder.length; i++) { // TODO: revisar que es esto
        let m = mediosOrder[i]
        if (m.ESTADO === 3) {
          m.ESTADO = 0
        }
      } */

      mediosOrder.sort(function (a, b) {
        let aEstado = a.ESTADO
        let bEstado = b.ESTADO
        let aMedio = a.MEDIO.toUpperCase()
        let bMedio = b.MEDIO.toUpperCase()

        if (aEstado === bEstado) {
          return (aMedio < bMedio) ? -1 : (aMedio > bMedio) ? 1 : 0
        } else {
          return (aEstado < bEstado) ? -1 : 1
        }
      })

      this.mediosOrder = mediosOrder
    },

    cancelar () {
      this.$emit('cancelar')
      this.mediosSelected = []
    },

    aceptar () {
      let mediosIncendio = {
        ID_INCENDIO: this.idIncendio,
        ID_SECTOR: this.idSector,
        MEDIOS: []
      }

      for (let i = 0; i < this.mediosSelected.length; i++) {
        let idMedioSector = this.$uuid.createUUID()

        let tiempos = {
          tiempoJornada: this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION'),
          tiempoDescanso: this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
        }
        if (this.mediosSelected[i].TIPO === 'Retén Tragsa' || this.mediosSelected[i].TIPO === 'Cuadrilla Helitransportada') {
          tiempos = constants.tiemposTrabajoMediosReducido
        }

        let fechaAviso = this.$date.parseDate(this.fechaAviso)

        let inicioJornada = this.$date.parseDate(this.inicioJornada) // this.$date.parseDate(fechaAviso.format('DD/MM/YYYY') + ' ' + this.mediosSelected[i].HORA_ENTRADA) // TODO: Y que pasa si el inicio jornada fue el dia antes?

        let planificacion = {
          ID_PLANIFICACION_MEDIO: this.$uuid.createUUID(),
          ID_MEDIO_SECTOR: idMedioSector,
          INICIO_JORNADA: inicioJornada,
          TIEMPO_OPERATIVO: tiempos.tiempoJornada,
          TIEMPO_DESCANSO: tiempos.tiempoDescanso
        }

        let medioSector = {
          ID_MEDIO_SECTOR: idMedioSector,
          ID_SECTOR: this.idSector,
          ID_MEDIO: this.mediosSelected[i].ID_MEDIO,
          FECHA_AVISO: fechaAviso,
          PLANIFICACION: planificacion
        }
        mediosIncendio.MEDIOS.push(medioSector)
      }

      this.$emit('aceptar', mediosIncendio)
      this.mediosSelected = []
    }
  },

  created () {
    this.estadoMedios = constants.estadosMedio
  }
}
</script>

<style scoped>
</style>
