<template>
  <div>
    <!-- Dialog gestion -->
    <v-dialog v-model="show" persistent max-width="400">
      <v-snackbar v-model="showSnackBarError" bottom :timeout="3000" color="error">
        Este sector tiene medios asignados
      </v-snackbar>

      <v-card>
        <v-toolbar color="primary" dark dense>
          <v-toolbar-title>Sectores</v-toolbar-title>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="showDialogAdd = true">
                <v-icon color="primary">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Añadir sector</span>
          </v-tooltip>
        </v-toolbar>

        <v-card-text>
          <v-data-table class="pt-2" :headers="headers" :items="editSectores" hide-default-footer disable-sort>
            <template #no-data>
              <p>Datos no disponibles.</p>
            </template>

            <template #[`item.SECTOR`]="{ item }">
              {{ item.SECTOR }}
              <!-- <v-edit-dialog :return-value.sync="item.SECTOR" lazy>
                {{ item.SECTOR }}
                <template v-slot:input>
                  <v-text-field v-model="item.SECTOR" label="Nuevo nombre" autofocus/>
                </template>
              </v-edit-dialog> -->
            </template>

            <template #[`item.ACCIONES`]="{ item }">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="item.SECTOR === 'GENERAL'" @click="deleteSector(item)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <!-- <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
          <v-btn text color="green" @click="aceptar">Aceptar</v-btn> -->
          <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog crear -->
    <v-dialog v-model="showDialogAdd" persistent max-width="400">
      <v-card>
        <v-card-title class="pt-0 px-0">
          <v-toolbar color="primary" dark dense>
            <v-toolbar-title>Nuevo sector</v-toolbar-title>
          </v-toolbar>
        </v-card-title>

        <v-card-text>
          <v-form v-model="isValid" @submit.prevent="addSector">
            <v-text-field v-model="newSector.SECTOR" label="Sector" hide-details outlined :rules="[globalRules.required]" />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn text color="red" @click="cancelAdd">Cancelar</v-btn>
          <v-btn text color="green" :disabled="!isValid" @click="addSector">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    show: Boolean,
    idIncendio: String
  },

  data: () => ({
    showSnackBarError: false,
    headers: [
      { text: 'SECTOR', value: 'SECTOR', align: 'left', sortable: false },
      { text: 'ACCIONES', value: 'ACCIONES', align: 'center', width: '25%', sortable: false }
    ],

    editSectores: [],

    isValid: false,

    showDialogAdd: false,
    newSector: {
      SECTOR: null,
      MEDIOS: []
    }
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    }
  },

  watch: {
    show () {
      if (this.sectores) {
        this.editSectores = JSON.parse(JSON.stringify(this.sectores))
      }
    },

    sectores () {
      if (this.sectores) {
        this.editSectores = JSON.parse(JSON.stringify(this.sectores))
      }
    }
  },

  methods: {
    addSector () {
      this.newSector.ID_SECTOR = this.$uuid.createUUID()
      this.editSectores.push(this.newSector)

      this.saveSectores()

      this.cancelAdd()
    },

    cancelAdd () {
      this.showDialogAdd = false
      this.newSector = {
        SECTOR: null,
        MEDIOS: []
      }
    },

    deleteSector (item) {
      if (item.MEDIOS.length > 0) {
        this.showSnackBarError = true
      } else {
        let msg = 'Eliminando el sector: ' + item.SECTOR
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then(result => {
          if (result) {
            let pos = this.editSectores.indexOf(item)
            this.editSectores.splice(pos, 1)

            this.saveSectores()
          }
        })
      }
    },

    saveSectores () {
      this.$store.dispatch('incendio/changeSectoresIncendio', {
        ID_INCENDIO: this.idIncendio,
        SECTORES: this.editSectores
      })
    },

    cerrar () {
      this.$emit('close')
    }

    /* cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      this.$emit('aceptar', this.editSectores)
      this.cancelAdd()
    } */
  }
}
</script>

<style scoped>
</style>
