<template>
  <v-dialog v-model="show" persistent max-width="400">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Solicitud compartir pantalla</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <p class="pt-3 black--text body-1">El cliente {{ usuario }} quiere compartir la pantalla.</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  props: {
  },

  data: () => ({
    show: false,
    usuario: null
  }),

  computed: {
    requestShareMap () {
      return this.$store.getters['shareMap/requestShareMap']
    }
  },

  watch: {
    requestShareMap: {
      handler () {
        if (this.requestShareMap) {
          this.show = !this.requestShareMap.response
          this.usuario = this.requestShareMap.clientRequest.USUARIO
        } else {
          this.show = false
        }
      },
      deep: true
    }
  },

  methods: {
    cancelar () {
      ArcGIS.resetShareMapLayer()

      this.$store.dispatch('shareMap/cancelShareMapRequest', true)
    },
    aceptar () {
      this.$store.dispatch('shareMap/acceptShareMapRequest', true)
    }
  }
}
</script>

<style scoped>
</style>
