const state = {
  currentUsuario: null,

  usersLogged: []
}

const getters = {
  currentUsuario: (state) => state.currentUsuario,

  isCurrentUsuarioAdmin: (state) => {
    let isAdmin = false
    if (state.currentUsuario) {
      isAdmin = state.currentUsuario.ROL === 'Admin'
    }
    return isAdmin
  },

  usersLogged: (state) => {
    return state.usersLogged.filter(x => x.USUARIO !== state.currentUsuario.USUARIO)
  },

  getUserLoggedInMedioByID: (state) => (idMedioLogged) => {
    let user = state.usersLogged.find(x => x.ID_MEDIO === idMedioLogged)
    return !!user
  }
}

const mutations = {
  SET_CURRENT_USUARIO (state, currentUsuario) {
    state.currentUsuario = currentUsuario
  },

  SET_USERS_LOGGED (state, usersLogged) {
    state.usersLogged = usersLogged
  },

  SOCKET_usersLoggedIn (state, data) {
    let currentUsuario = state.currentUsuario

    if (!currentUsuario.ID_SOCKET) {
      for (let i = 0; i < data.length; i++) {
        if (currentUsuario.USUARIO === data[i].USUARIO) {
          currentUsuario.ID_SOCKET = data[i].ID_SOCKET
          state.currentUsuario = currentUsuario
        }
      }
    }

    state.usersLogged = data
  }
}

const actions = {
  userLoggedIn ({ state }) {
    this._vm.$socket.emit('loggedIn', state.currentUsuario)
  },

  setCurrentUsuario ({ commit }, currentUsuario) {
    commit('SET_CURRENT_USUARIO', currentUsuario)
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
