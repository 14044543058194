import api from '@/api'

let Q = require('q')

const state = {
  titulosProfesionales: []
}

const getters = {
  titulosProfesionales: (state) => {
    return state.titulosProfesionales
  }
}

const mutations = {
  SET_TITULOS_PROFESIONALES (state, titulosProfesionales) {
    state.titulosProfesionales = titulosProfesionales
  }
}

const actions = {
  async fetchTitulosProfesionales ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.tituloProfesional.getTitulosProfesionales()
      commit('SET_TITULOS_PROFESIONALES', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
