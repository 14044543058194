import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSocketIO from 'vue-socket.io' // TODO: https://www.npmjs.com/package/vue-socket.io-extended
import dayjs from 'dayjs'
import VueLogger from 'vuejs-logger'
import { VueMaskDirective } from 'v-mask'
import InputFacade from 'vue-input-facade'

import SocketIO from 'socket.io-client'

import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

import Notifications from 'vue-notification'

import DateTextEdit from './components/shared/DateTextEdit'
import TimeTextEdit from './components/shared/TimeTextEdit'

// import VueToastr from '@deveodk/vue-toastr'
// import '@deveodk/vue-toastr/dist/@deveodk/vue-toastr.css'
import VueToastr from 'vue-toastr'

import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import '@/plugins/StringPrototype'
import '@/plugins/validationRules'
import '@/plugins/dateService'
import '@/plugins/httpService'
import '@/plugins/uuidService'

import constants from './helpers/constants'

Vue.config.productionTip = false

// Log
const isProduction = process.env.NODE_ENV === 'production'
const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, options)

// Socket IO
Vue.use(new VueSocketIO({
  debug: false,
  connection: SocketIO(constants.socketBaseURL, { path: '/socket.io' }),
  options: {
    autoConnect: false
  },
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  }
}))

// Notifications
Vue.use(Notifications)

// DateTextEdit y TimeTextEdit component
Vue.component('vx-date-text-edit', DateTextEdit)
Vue.component('vx-time-text-edit', TimeTextEdit)

// Vue CTK DateTimePicker
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker)

// Masks
Vue.directive('mask', VueMaskDirective)
Vue.use(InputFacade)

// Toastr
/* Vue.use(VueToastr, {
  defaultPosition: 'toast-bottom-right',
  defaultType: 'info',
  defaultTimeout: 5000
}) */
Vue.use(VueToastr, {
  defaultTimeout: 5000,
  defaultProgressBar: false,
  defaultProgressBarValue: 0,
  defaultType: 'info',
  defaultPosition: 'toast-bottom-right',
  defaultCloseOnHover: true,
  // defaultStyle: { 'background-color': 'red' },
  defaultStyle: { 'font-family': 'Helvetica' },
  defaultClassNames: ['animated', 'zoomInUp']
})

// SweetAlert dialogs
const swalOptions = {
  reverseButtons: true,
  confirmButtonColor: '#00496f',
  cancelButtonColor: '#cccccc',
  confirmButtonText: 'Aceptar',
  cancelButtonText: 'Cancelar'
}
Vue.use(VueSweetalert2, swalOptions)

// Date filter
Vue.filter('formatDate', function (date) {
  if (date) {
    return dayjs(date).format('DD/MM/YYYY HH:mm')
  } else {
    return ''
  }
})

Vue.prototype.$eventHub = new Vue()

let vueInstance = new Vue({
  router,
  store,
  vuetify,
  sockets: {
    connect: () => {
      console.log('socket connected')
    },
    reconnect: () => {
      console.log('socket reconnect')
      store.dispatch('usuario/userLoggedIn', null)
    },
    disconnect: () => {
      console.log('socket disconnected')
    }
  },
  render: h => h(App)
})
vueInstance.$mount('#app')

export default vueInstance
