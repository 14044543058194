// modules/moduleA.js
import api from '@/api'

let Q = require('q')

const state = {
  categoriasPersonal: []
}

const getters = {
  categoriasPersonal: (state) => {
    return state.categoriasPersonal
  }
}

const mutations = {
  SET_CATEGORIAS_PERSONAL (state, categoriasPersonal) {
    state.categoriasPersonal = categoriasPersonal
  }
}

const actions = {
  async fetchCategoriasPersonal ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.personalCategoria.getCategoriasPersonal()
      commit('SET_CATEGORIAS_PERSONAL', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
