var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%"}},[_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Planificación medios terrestres")])],1),_c('v-card-text',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',[_c('v-select',{attrs:{"items":_vm.incendiosActivos,"item-value":"ID_INCENDIO","label":"Incendios","item-text":function (item) { return item.NOMBRE ? item.NOMBRE : item.MUNICIPIO; },"persistent-hint":"","return-object":""},model:{value:(_vm.incendioSelected),callback:function ($$v) {_vm.incendioSelected=$$v},expression:"incendioSelected"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Fecha incendio","readonly":""},model:{value:(_vm.fechaIncendio),callback:function ($$v) {_vm.fechaIncendio=$$v},expression:"fechaIncendio"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-text-field',{attrs:{"label":"Orto","readonly":""},model:{value:(_vm.orto),callback:function ($$v) {_vm.orto=$$v},expression:"orto"}})],1),_c('v-col',{attrs:{"sm":"1"}},[_c('v-text-field',{attrs:{"label":"Ocaso","readonly":""},model:{value:(_vm.ocaso),callback:function ($$v) {_vm.ocaso=$$v},expression:"ocaso"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Fecha/hora actual","readonly":""},model:{value:(_vm.fechaActual),callback:function ($$v) {_vm.fechaActual=$$v},expression:"fechaActual"}})],1),_c('v-col',{attrs:{"sm":"3"}},[_c('v-select',{attrs:{"items":_vm.filtros,"label":"Filtros","multiple":"","return-object":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v("(+"+_vm._s(_vm.filtrosSelected.length - 1)+" otros)")]):_vm._e()]}}]),model:{value:(_vm.filtrosSelected),callback:function ($$v) {_vm.filtrosSelected=$$v},expression:"filtrosSelected"}})],1)],1),_c('v-toolbar',{staticStyle:{"z-index":"2"},attrs:{"color":"primaryLight","dense":""}},[_c('v-tabs',{attrs:{"color":"white","background-color":"primaryLight","dark":"","slider-color":"white"},model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},_vm._l((_vm.tabs),function(t){return _c('v-tab',{key:t,attrs:{"ripple":""},on:{"click":function($event){return _vm.cambiarTab(t)}}},[_vm._v(" "+_vm._s(t)+" ")])}),1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","absolute":"","disabled":_vm.mediosDialog.length === 0 || _vm.disabledByRol},on:{"click":function($event){return _vm.abrirDialogAddMedio()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir medio")])])],1),_c('v-tabs-items',{model:{value:(_vm.tabSelected),callback:function ($$v) {_vm.tabSelected=$$v},expression:"tabSelected"}},[_c('v-tab-item',{key:"Tabla",staticStyle:{"height":"60vh","overflow":"auto !important"}},[_c('v-card',{attrs:{"flat":"","id":"tabla"}},[_c('v-card-text',[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center","width":"100%"}},[_c('v-btn',{attrs:{"disabled":!_vm.mediosAsignados || _vm.mediosAsignados.length === 0,"text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.capturaTabla(false)}}},[_c('v-icon',[_vm._v("mdi-file-pdf-box")])],1),_c('v-btn',{attrs:{"disabled":!_vm.mediosAsignados || _vm.mediosAsignados.length === 0,"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.capturaTabla(true)}}},[_c('v-icon',[_vm._v("mdi-share")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mediosAsignados,"single-expand":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("No hay medios asignados.")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [(!item.hide)?_c('tr',[_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.MEDIO))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.ACTUACION))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.TIPO))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.SECTOR.SECTOR))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.NUM_COMPONENTES))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.fechahora(item.INICIO_JORNADA)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(item.FECHA_INCORPORACION))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.hhmm(item.HORA_MOVILIZACION)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.hhmm(item.HORA_LLEGADA_INC)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.fechahora(item.FECHA_SALIDA_INC)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.fechahora(item.FECHA_MAX_ACTUACION)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.fechahora(item.FECHA_LLEGADA_BASE)))]),_c('td',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.fechahora(item.FECHA_FIN_DESCANSO)))]),_c('td',{staticClass:"text-xs-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isAddActuacionDisabled(item)},on:{"click":function($event){return _vm.abrirDialogAddActuacion(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Añadir actuación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.FECHA_INCORPORACION || item.TIENE_MAS_ACTUACIONES || _vm.disabledByRol},on:{"click":function($event){return _vm.abrirDialogEdit(item.ACTUACION > 1, item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.TIENE_MAS_ACTUACIONES || _vm.disabledByRol},on:{"click":function($event){return _vm.borrarMedioIncendio(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]):_c('tr')]}}])})],1)],1)],1),_c('v-tab-item',{key:"Gráfica",attrs:{"eager":""}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',[_c('div',{staticStyle:{"display":"inline-flex","align-items":"center","width":"100%"}},[_c('v-btn',{attrs:{"disabled":!_vm.mediosAsignados || _vm.mediosAsignados.length === 0,"text":"","icon":"","color":"grey"},on:{"click":function($event){return _vm.capturaGrafica()}}},[_c('v-icon',[_vm._v("mdi-camera-image")])],1),_c('v-btn',{attrs:{"disabled":!_vm.mediosAsignados || _vm.mediosAsignados.length === 0,"small":"","fab":"","icon":""},on:{"click":function($event){return _vm.sendGraficoTelegram()}}},[_c('v-icon',[_vm._v("mdi-share")])],1)],1),_c('div',{attrs:{"id":"timeline"}})])],1)],1)],1)],1)],1),_c('vx-dialog-add-medio-planificacion',{attrs:{"show":_vm.showDialogAdd,"editedItem":_vm.editedItem,"isEdit":_vm.isEdit,"medios":_vm.mediosDialog,"sectores":_vm.sectores,"idIncendioSelected":_vm.incendioSelected ? _vm.incendioSelected.ID_INCENDIO : null},on:{"cancelar":function($event){_vm.showDialogAdd = false},"aceptar":_vm.addMedioIncendio,"editar":_vm.editarMedioIncendio}}),_c('vx-dialog-add-actuacion',{attrs:{"show":_vm.showDialogActuacion,"editedItem":_vm.editedItem,"isEdit":_vm.isEdit,"sectores":_vm.sectores,"periodoAnterior":_vm.periodoAnterior},on:{"cancelar":function($event){_vm.showDialogActuacion = false},"aceptar":_vm.addActuacionMedio,"editar":_vm.editarActuacionMedio}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }