var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{ref:"drawer",staticStyle:{"z-index":"2"},attrs:{"width":_vm.navigation.width,"color":"#AAAAAACC","absolute":"","hide-overlay":"","stateless":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-btn',{attrs:{"dark":"","icon":""},on:{"click":_vm.exit}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.incendio ? (_vm.incendio.NOMBRE ? _vm.incendio.NOMBRE : _vm.incendio.LOCALIDAD) : "")+" ")]),_c('v-spacer'),_vm._l((_vm.buttons),function(button){return _c('v-tooltip',{key:button.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(button.visible),expression:"button.visible"}],staticClass:"me-3",attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":""},on:{"click":function($event){return _vm.clickBtn(button.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(_vm._s(button.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(button.label))])])}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","disabled":_vm.isButtonDisabled},on:{"click":_vm.generateInformePosiciones}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-chart-outline")])],1)]}}])},[_c('span',[_vm._v("Informe posiciones")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":""},on:{"click":_vm.generateExcelRecursos}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-excel-box")])],1)]}}])},[_c('span',[_vm._v("Informe de recursos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":""},on:{"click":_vm.generatePDFIncendio}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-file-pdf-box")])],1)]}}])},[_c('span',[_vm._v("Resumen del incendio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"me-3",attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":""},on:{"click":function($event){_vm.showDialogImagen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-camera")])],1)]}}])},[_c('span',[_vm._v("Subir imágenes")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":""},on:{"click":function($event){_vm.showDialogDeleteIncendio = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Eliminar incendio")])])],2),_c('v-container',{staticStyle:{"height":"calc(100vh - 112px)","overflow-y":"auto"},attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"color":"error","bottom":"","timeout":5000},model:{value:(_vm.showSnackBar),callback:function ($$v) {_vm.showSnackBar=$$v},expression:"showSnackBar"}},[_vm._v(" "+_vm._s(_vm.msgSnackBar)+" ")]),_c('v-snackbar',{attrs:{"bottom":"","timeout":-1},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-progress-circular',_vm._g({attrs:{"indeterminate":""}},attrs))]}}]),model:{value:(_vm.isSnackbarLoading),callback:function ($$v) {_vm.isSnackbarLoading=$$v},expression:"isSnackbarLoading"}},[_vm._v(" "+_vm._s(_vm.msgSnackbarLoading)+" ")]),_c('vx-datos-incendio',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected}}),_c('vx-datos-sectores',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected},on:{"changeSectoresSelected":_vm.changeSectoresSelected}}),_c('vx-datos-medios',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected,"sectoresSelected":_vm.sectoresSelected}}),_c('vx-datos-planes-operaciones',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected}}),_c('vx-datos-perimetros',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected}}),_c('vx-imagen-satelite',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected}}),_c('vx-dialog-track-fechas',{attrs:{"show":_vm.showDialogTrackAllMedios,"idIncendio":_vm.idIncendioSelected},on:{"aceptar":_vm.obtainAllTrackMedios,"cancelar":_vm.cancelTrackMedio}}),_c('vx-menu-alineaciones-campbell',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected}}),_c('vx-dialog-add-imagenes',{staticClass:"mt-4",attrs:{"idIncendio":_vm.idIncendioSelected,"show":_vm.showDialogImagen},on:{"cancelar":_vm.cancelAddImagenes}}),_c('vx-dialog-delete-incendio',{staticClass:"mt-4",attrs:{"incendio":_vm.incendio,"show":_vm.showDialogDeleteIncendio},on:{"eliminar":_vm.deletedIncendio,"cancelar":_vm.cancelDeleteIncendio}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }