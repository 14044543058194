<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Perímetros</v-toolbar-title>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn fab small v-bind="attrs" v-on="on" color="secondary" bottom right absolute @click="openAddPerimeterDialog" :disabled="disabledByRol">
              <v-icon color="primary">mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Añadir perímetro</span>
        </v-tooltip>
      </v-toolbar>

      <v-card-text>
        <v-list expand subheader>
          <v-list-item v-for="perimetro in perimetros" :key="perimetro.ID_PERIMETRO">
            <v-list-item-content>
              <v-list-item-title>{{ perimetro.NOMBRE }}</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(perimetro.FECHA) }} - Área: {{ perimetro.measure ? perimetro.measure.area :'0' }}ha - Perímetro {{ perimetro.measure ? perimetro.measure.perimetro : '0' }}km</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="showDialog" @click="idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? mostrarPerimetro('remove',perimetro) : mostrarPerimetro('add',perimetro)">
                    <v-icon color="black">{{ idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? 'mdi-eye-off' : 'mdi-eye' }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ idPerimetroShowing.includes(perimetro.ID_PERIMETRO) ? 'Ocultar' : 'Visualizar' }}</span>
              </v-tooltip>
            </v-list-item-action>

            <v-list-item-action>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" :disabled="showDialog || disabledByRol" @click="openEditPerimeterDialog(perimetro)">
                    <v-icon color="black">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
          <p v-if="perimetros && perimetros.length <= 0" align="center" style="margin: 16px 10px 10px 10px;">No hay ningún perímetro creado.</p>
        </v-list>
      </v-card-text>
    </v-card>

    <!--Desde aquí llamamos a todas las funciones necesarias-->
    <vx-dialog-menu-perimetro :show="showDialog" :idIncendio="idIncendio" :perimetroSelected="perimetroSelected" :isEdit="isEdit" @close="showDialog = false" />
  </div>
</template>

<script>
import * as ArcGIS from '../../../helpers/ArcGIS'
import DialogMenuPerimetro from './DialogMenuPerimetro'

let Q = require('q')

export default {
  props: { // con esto conseguimos la id del incendio donde estamos
    idIncendio: String
  },

  components: {
    'vx-dialog-menu-perimetro': DialogMenuPerimetro
  },

  data () {
    return {
      showDialog: false,
      isEdit: false,
      idPerimetroShowing: [],
      perimetroSelected: {}
    }
  },

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    perimetros () {
      let perimetros = []

      if (this.incendio) {
        perimetros = this.incendio.PERIMETRO
      }
      return perimetros
    },
    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    }

  },

  watch: {

    showPerimetros () {
      if (this.showPerimetros) {
        this.idPerimetroShowing = null
      }
    },

    perimetros () {
      if (this.idPerimetroShowing && this.perimetros) {
        let perimetro = this.perimetros.find(x => x.ID_PERIMETRO === this.idPerimetroShowing)
        if (perimetro) {
          let graficosPerimetro = this.perimetros.find(x => x.ID_PERIMETRO === this.idPerimetroShowing).GRAFICOS_PERIMETRO
          ArcGIS.drawGraphicsPerimetro(graficosPerimetro)
          ArcGIS.measurePerimetros(graficosPerimetro)
        }
      }
    }

  },

  methods: {
    formatDate (date) {
      return this.$date.formatDate(date, 'LLL')
    },

    openAddPerimeterDialog () {
      this.mostrarPerimetro(null)
      this.isEdit = false
      this.showDialog = true
    },

    openEditPerimeterDialog (perimetro) {
      this.mostrarPerimetro(null)
      this.perimetroSelected = perimetro
      this.isEdit = true
      this.showDialog = true
    },

    mostrarPerimetro (action, perimetro) {
      let deferred = Q.defer()

      this.perimetroSelected = perimetro

      if (action === 'add' && perimetro) {
        this.idPerimetroShowing.push(perimetro.ID_PERIMETRO)
        ArcGIS.drawGraphicsPerimetro(perimetro.GRAFICOS_PERIMETRO).then(() => {
          deferred.resolve()
        })
      } else if (action === 'remove' && perimetro) {
        let index = this.idPerimetroShowing.indexOf(perimetro.ID_PERIMETRO)
        if (index !== -1) {
          this.idPerimetroShowing.splice(index, 1) // Eliminar el elemento en el índice encontrado
        }

        ArcGIS.removeGraphicsPerimetro(perimetro.GRAFICOS_PERIMETRO).then(() => {
          deferred.resolve()
        })
      }
      return deferred.promise
    }

  }
}
</script>
