<template>
  <div ref="alineaciones-campbell">
    <v-card
      v-show="showAlineacionesCampbell"
      color="#537582CC"
      dark
      elevation="6"
      width="275px"
      style="position: absolute; right: 70px; bottom: 60px"
    >
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title class="text-symbology">Selecciona elemento</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="toggleMinimize">
          <v-icon>
            {{
              minimized ? "mdi-window-maximize" : "mdi-window-minimize"
            }}
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row align="center" justify="center" dense>
            <v-col v-for="item in esriButtons" :key="item.id">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  {{ item.label }}
                  <v-btn :class="{activeBTN: activeBtn === `${item.id}Button`}" icon v-on="on" @click="clickEsriSymbol(item.id)">
                    <v-icon small>{{ item.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ item.label }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <!-- Dialog text symbol -->
    <v-dialog v-model="showDialogText" persistent width="400px">
      <v-card>
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Seleccione Factores</v-toolbar-title>
        </v-toolbar>

        <v-card-text class="pa-5">
          <v-chip-group
            v-model="factores"
            multiple
          >
            <v-chip
              filter
              variant="outlined"
            >
              Viento
            </v-chip>
            <v-chip
              filter
              variant="outlined"
            >
              Pendiente
            </v-chip>
            <v-chip
              filter
              variant="outlined"
            >
              Orientación
            </v-chip>
          </v-chip-group>
        </v-card-text>

        <v-card-actions>
          <v-btn text color="red" @click="cancelTextDialog">Cancelar</v-btn>
          <v-btn
            text
            color="green"
            @click="acceptTextDialog"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Snackbar finalizar TODOS los dibujos -->
    <v-snackbar v-model="showSnackBar" button :timeout="-1">
      ¿Añadir alineaciones de Campbell?
      <template #action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click="finishDrawing">
          Aceptar
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar dibujo actual -->
    <v-snackbar v-model="isDrawing" button :timeout="-1">
      Haz click en el mapa.
      <template #action="{ attrs }">
        <v-btn text color="pink" v-bind="attrs" @click="cancelCurrentDrawing">
          Cancelar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  data: () => ({
    showAlineacionesCampbell: false,
    callback: null,

    minimized: false,

    showDialogText: false,
    textSelected: '',

    activeBtn: null,

    showSnackBar: false,
    isDrawing: false,

    graphics: [],

    esriButtons: [
      { id: 'polygon', label: 'Polígono', icon: 'esri-icon-polygon' },
      { id: 'text', label: 'Texto', icon: 'esri-icon-comment' }
    ],
    factores: []
  }),

  computed: {
  },

  methods: {
    showPanelAlineacionesCampbell (data) {
      // console.log('Data alineaciones: ', JSON.stringify(data))
      this.showAlineacionesCampbell = true
      this.showSnackBar = true

      this.callback = data.callback // Funcion a la que se va a llamar al finalizar, se recibe por el eventhub y se le envian los graphics
      this.graphics = data.graphics

      ArcGIS.initAlineacionesCampbellSketchViewModel({
        callbackCreate: this.onDrawCreated,
        callbackUpdate: this.onDrawUpdated,
        callbackDelete: this.onDrawDeleted
      })

      ArcGIS.drawGraphicsAlineacionesCampbell(this.graphics)
    },

    toggleMinimize () {
      this.minimized = !this.minimized
    },

    clickEsriSymbol (evt) {
      this.isDrawing = true
      this.activeBtn = evt + 'Button'

      if (evt === 'text') {
        this.showTextDialog()
      } else {
        this.paintEsriSymbol(evt)
      }
    },

    paintEsriSymbol (evt) {
      let event = {
        function: evt,
        text: this.textSelected,
        size: 12,
        color: [Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), Math.floor(Math.random() * 255), 0.5]
      }

      this.paint(event)
    },

    // #region TEXT SYMBOL
    showTextDialog () {
      this.showDialogText = true
      this.factores = []
    },

    acceptTextDialog () {
      this.showDialogText = false
      this.textSelected = this.factores.length + '/3'
      this.paintEsriSymbol('text')
    },

    cancelTextDialog () {
      this.showDialogText = false
      this.cancelCurrentDrawing()
    },
    // #endregion

    async paint (event) {
      this.cancelCurrentDrawing()
      this.isDrawing = true

      ArcGIS.paintEventAlineacionesCampbell(event)
    },

    // #region CALLBACKS ARCGIS
    onDrawCreated (data) {
      this.isDrawing = false
      this.activeBtn = null
      this.graphics.push(data)
    },
    onDrawUpdated (data) {
      let atributos = data.ATRIBUTOS
      let geometria = data.GEOMETRIA

      let index = this.graphics.findIndex(
        (x) => x.attributes.ID_GRAFICO === atributos.ID_GRAFICO
      ) // TODO: attributes por ATRIBUTOS

      if (index !== -1) {
        this.graphics[index].attributes = atributos // TODO: nombres parametros
        this.graphics[index].coordenadas = geometria // TODO: nombres parametros
      }
    },
    onDrawDeleted (idGrafico) {
      this.graphics = this.graphics.filter(
        (x) => x.attributes.ID_GRAFICO !== idGrafico
      )
    },
    // #endregion

    // Cancela el dibujo actual
    cancelCurrentDrawing () {
      this.isDrawing = false
      this.activeBtn = null

      ArcGIS.cancelDrawingAlineaciones()
    },

    // Finaliza el dibujo y lo envia de vuelta
    finishDrawing () {
      if (!this.showAlineacionesCampbell) return
      this.showAlineacionesCampbell = false
      this.showSnackBar = false
      this.isDrawing = false
      this.activeBtn = null

      ArcGIS.stopAlineacionesCampbellSketchViewModel()
      this.callback(this.graphics)
      this.graphics = []

      this.callback = null
    }
  },
  watch: {
  },

  mounted () {
    this.$eventHub.$on('showAlineacionesCampbell', this.showPanelAlineacionesCampbell)
    this.$eventHub.$on('showSymbology', this.finishDrawing)
  }
}
</script>

  <style scoped>
  .activeBTN {
    border: 2px solid red;
  }
  .activeBTNLabel {
    color: red;
  }

  </style>
