<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="pa-0 ma-0">
      <v-col cols="2" class="pa-1 ma-0">
        <v-row class="pa-0 ma-0">
          <v-card tile height="100%" width="100%">
            <v-toolbar color="primaryLight" dark dense>
              <v-toolbar-title>Filtros</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row class="pa-0 ma-0">
                  <v-col class="pa-0 ma-0" cols="5">
                    <VueCtkDateTimePicker
                      v-model="fechaSelected" no-value-to-custom-elem only-date
                      color="#00759e" button-now-translation="Ahora"
                      format="DD/MM/YYYY"
                    >
                      <v-text-field v-model="fechaSelected" label="Fecha" readonly />
                    </VueCtkDateTimePicker>
                  </v-col>
                  <v-col class="pa-0 ma-0 text-right" cols="7">
                    <v-btn-toggle v-model="categoriasSelecionadas" color="primary" rounded multiple>
                      <v-btn :key="i" v-for="(tipo,i) in categoriasMedios" x-small class="py-6 px-1">
                        <v-icon>{{ tipo.ICONO }}</v-icon>
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
                <v-checkbox style="" class="pa-0 ma-0 font-weight-bold" @click="selectAllCheckBox()" :label="checkAllFilters ? 'Seleccionar todo':'Deseleccionar todo'" />
                <v-row class="pa-0 ma-0" style="height: calc(50vh - 260px); overflow-y:auto;display: block;">
                  <v-checkbox style="" class="pa-1 ma-0" cols="12" v-for="tipo in tiposMedioFilter" :key="tipo.ID_MEDIO_TIPO" v-model="tiposMedioSelected" :value="tipo.TIPO" :label="tipo.TIPO" :hide-details="true" />
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row class="pa-0 ma-0 mt-4">
          <vx-turno />
        </v-row>
      </v-col>
      <v-col cols="10" class="pa-1 ma-0">
        <v-card tile height="100%">
          <v-card-text class="pa-0 ma-0">
            <v-container class="pa-0 ma-0" style="background-color: #ffd587;" fluid>
              <v-row class="pa-0 ma-0" style="width: calc(100% - 10px);">
                <v-col cols="2" class="header-main">
                  <b class="text-truncate text-wrap">Medio</b>
                </v-col>
                <v-col cols="2" class="current-day">
                  <b>{{ fechaSelected }} Personal</b>
                </v-col>
                <v-col cols="1" class="current-day">
                  <b>Turno</b>
                </v-col>
                <v-col cols="2" class="current-day">
                  <b>Hora entrada / salida</b>
                </v-col>
                <v-col cols="2" class="next-day">
                  <b>{{ $date.parseDate(fechaSelected, 'DD/MM/YYYY').add(1, 'days').format('DD/MM/YYYY') }} Personal</b>
                </v-col>
                <v-col cols="1" class="next-day">
                  <b>Turno</b>
                </v-col>
                <v-col cols="2" class="next-day">
                  <b>Hora entrada / salida</b>
                </v-col>
              </v-row>
            </v-container>
            <v-container fluid class="pa-0 ma-0" style="height: calc(100vh - 175px); overflow-y:auto; overflow-x: hidden;">
              <v-row v-for="medio in listadoGuardiasFiltrado" :key="medio.ID_MEDIO" class="pa-0 ma-0 medio-item" dense align="center">
                <v-col cols="2" class="text-body-2 text-no-wrap text-truncate">
                  {{ medio.MEDIO }}
                </v-col>
                <v-col cols="2" class="current-day-light">
                  <v-autocomplete v-if="!isBrifor(medio.CATEGORIA)" :disabled="medio.ID_PERSONAL !== null" class="guardia-select text-body-2" v-model="medio.MEDIO_PERSONAL_CURRENT_DATE.ID_PERSONAL" :items="filterByPersonal(medio, false)" label="Sin asignar" item-text="NOMBRE_COMPLETO" item-value="ID_PERSONAL" no-data-text="No hay personales asociados a este tipo de medio" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_CURRENT_DATE, false)" @input="updatePersonalOptionsCurrentDate" />
                  <v-text-field type="number" v-else v-model="medio.MEDIO_PERSONAL_CURRENT_DATE.NUMERO_COMPONENTES" class="guardia-select text-body-2" label="Sin componentes" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_CURRENT_DATE, false)" @input="updatePersonalOptionsCurrentDate" />
                </v-col>
                <v-col cols="1" class="current-day-light">
                  <v-autocomplete class="guardia-select text-body-2" v-model="medio.MEDIO_PERSONAL_CURRENT_DATE.ID_TURNO" :items="turnos" item-text="NOMBRE" item-value="ID_TURNO" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_CURRENT_DATE, false)" />
                </v-col>
                <v-col cols="2" class="current-day-light">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0" align="center">
                      <v-col class="pa-0 ma-0 " cols="10">
                        <v-text-field disabled v-model="medio.MEDIO_PERSONAL_CURRENT_DATE.FECHA_HORA_ENTRADA_SALIDA" dense outlined single-line autocomplete hide-details />
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="red" @click="remove(medio.MEDIO_PERSONAL_CURRENT_DATE)" v-if="medio.MEDIO_PERSONAL_CURRENT_DATE.state === 'update'">mdi-delete</v-icon>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="black" @click="extend(medio.MEDIO_PERSONAL_CURRENT_DATE)" v-if="medio.MEDIO_PERSONAL_CURRENT_DATE.state === 'update'">mdi-arrow-expand-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
                <v-col cols="2" class="next-day-light">
                  <v-autocomplete v-if="!isBrifor(medio.CATEGORIA)" :disabled="medio.ID_PERSONAL !== null" class="guardia-select text-body-2" v-model="medio.MEDIO_PERSONAL_NEXT_DATE.ID_PERSONAL" :items="filterByPersonal(medio, true)" label="Sin asignar" item-text="NOMBRE_COMPLETO" item-value="ID_PERSONAL" no-data-text="No hay personales asociados a este tipo de medio" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_NEXT_DATE, true)" @input="updatePersonalOptionsNextDate" />
                  <v-text-field type="number" v-else v-model="medio.MEDIO_PERSONAL_NEXT_DATE.NUMERO_COMPONENTES" class="guardia-select text-body-2" label="Sin componentes" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_NEXT_DATE, true)" @input="updatePersonalOptionsNextDate" />
                </v-col>
                <v-col cols="1" class="next-day-light">
                  <v-autocomplete class="guardia-select text-body-2" v-model="medio.MEDIO_PERSONAL_NEXT_DATE.ID_TURNO" :items="turnos" item-text="NOMBRE" item-value="ID_TURNO" dense outlined single-line autocomplete hide-details @change="saveDate(medio.MEDIO_PERSONAL_NEXT_DATE, true)" />
                </v-col>
                <v-col cols="2" class="next-day-light">
                  <v-container class="pa-0 ma-0">
                    <v-row class="pa-0 ma-0" align="center">
                      <v-col class="pa-0 ma-0" cols="10">
                        <v-text-field disabled v-model="medio.MEDIO_PERSONAL_NEXT_DATE.FECHA_HORA_ENTRADA_SALIDA" dense outlined single-line autocomplete hide-details />
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="red" @click="remove(medio.MEDIO_PERSONAL_NEXT_DATE)" v-if="medio.MEDIO_PERSONAL_NEXT_DATE.state === 'update'">mdi-delete</v-icon>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="1">
                        <v-icon color="black" @click="extend(medio.MEDIO_PERSONAL_NEXT_DATE)" v-if="medio.MEDIO_PERSONAL_NEXT_DATE.state === 'update'">mdi-arrow-expand-right</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <vx-dialog-extend-guardia
      :show="showExtend"
      :medioPersonalSelected="medioPersonalSelected"
      :isGuardiaPersonal="false"
      @cancelar="cancelExtendGuardia"
      @accept="acceptExtendGuardia"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import constant from '@/helpers/constants'
import Turnos from './Turnos.vue'
import DialogExtendGuardia from './DialogExtendGuardia.vue'

export default {
  components: {
    'vx-turno': Turnos,
    'vx-dialog-extend-guardia': DialogExtendGuardia
  },
  data () {
    return {
      fechaSelected: null,
      listadoGuardias: [],
      listadoGuardiasFiltrado: [],
      categoriasSelecionadas: [],
      categoriasMedios: [],
      tiposMedioAll: [],
      tiposMedioFilter: [],
      tiposMedioSelected: [],
      personalesCopyCurrentDate: [],
      personalesCopyNextDate: [],
      checkAllFilters: true,
      medioPersonalSelected: null,
      showExtend: false
    }
  },
  watch: {
    fechaSelected () {
      this.updateUI()
    },
    personal () {
      if (this.personalesCopyCurrentDate.length === 0) {
        const personales = [...this.$store.getters['personal/personales']]
        personales.forEach(personal => {
          personal.NOMBRE_COMPLETO = personal.NOMBRE + ' ' + personal.APELLIDOS
        })
        let collator = new Intl.Collator('es', { sensitivity: 'base' })
        personales.sort((a, b) => collator.compare(a.NOMBRE_COMPLETO, b.NOMBRE_COMPLETO))
        this.personalesCopyCurrentDate = [...personales]
        this.personalesCopyNextDate = [...personales]
      }
    },
    medios () {
      if (this.listadoGuardias.length === 0) {
        this.updateUI()
      }
    },
    categoriasSelecionadas () {
      // categoriasSelecionadas es un array de índices seleccionados [0,1,2]. Necesitamos saber qué categorias son esos índices.
      const filterCategoriasMediosSelected = this.categoriasMedios.filter(el => this.categoriasSelecionadas.includes(el.INDEX)).map(item => item.CATEGORIA)
      this.tiposMedioFilter = [...this.tiposMedioAll].filter(x => this.isIncludedInCategoria(filterCategoriasMediosSelected, x.CLASE))
      this.updateFilterMedios()
    },
    tiposMedioSelected () {
      this.updateFilterMedios()
    },
    medioPersonal: {
      handler () {
        this.updateUI()
      },
      deep: true
    }
    // tiposMedio () {
    //   this.tiposMedioFilter = [...this.tiposMedioAll]
    //   // this.tiposMedioSelected = this.tiposMedio.map(item => item.TIPO)
    // }
  },
  computed: {
    ...mapGetters('medio', [
      'medios', 'tiposMedio'
    ]),
    ...mapGetters('medioPersonal', [
      'turnos', 'medioPersonal'
    ]),
    ...mapGetters('personalMedioTipo', [
      'personalMedioTipo'
    ]),
    ...mapGetters('personal', [
      'personales'
    ])
  },
  methods: {
    ...mapActions('medioPersonal', ['addMedioPersonal', 'removeMedioPersonal']),
    ...mapActions('medio', ['fetchMedios']),

    isIncludedInCategoria (categoriasSelected, clase) {
      let isAereoAndSelected = categoriasSelected.includes('Aéreo') && this.isAereo(clase)
      let isTerrestreAndSelected = categoriasSelected.includes('Terrestre') && this.isTerrestre(clase)
      let isRecursoAndSelected = categoriasSelected.includes('Recurso') && this.isRecurso(clase)

      return isAereoAndSelected || isTerrestreAndSelected || isRecursoAndSelected
    },

    isTerrestre (clase) {
      return clase === constant.maestras.CLASE.MEDIO_TERRESTRE.NOMBRE
    },
    isAereo (clase) {
      return clase === constant.maestras.CLASE.MEDIO_AEREO.NOMBRE
    },
    isRecurso (clase) {
      let clasesRecurso = ['GRUPO', constant.maestras.CLASE.PERSONAL.NOMBRE, constant.maestras.CLASE.BRIGADA.NOMBRE]

      return clasesRecurso.includes(clase)
    },
    isBrifor (categoria) {
      return categoria === constant.maestras.CATEGORIA.BRIGADA_FORESTAL
    },

    async updateUI () {
      this.listadoGuardias = []
      const medios = this.$store.getters['medio/medios'].filter(x => x.GUARDIAS_DINAMICAS === true || !x.ID_PERSONAL)
      const medioPersonalCurrentDate = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY'))
      const medioPersonalNextDate = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY').add(1, 'days'))
      const tiposMedio = []

      medios.forEach(medio => {
        let matchTipo = tiposMedio.find(x => x.ID_MEDIO_TIPO === medio.ID_MEDIO_TIPO)
        if (matchTipo === undefined) {
          tiposMedio.push(({
            TIPO: medio.TIPO,
            ID_MEDIO_TIPO: medio.ID_MEDIO_TIPO
          }))
        }
        let matchCurrentDate = medioPersonalCurrentDate.find(x => x.ID_MEDIO === medio.ID_MEDIO)

        if (matchCurrentDate !== undefined) {
          medio.MEDIO_PERSONAL_CURRENT_DATE = {
            ID_MEDIO_PERSONAL: matchCurrentDate.ID_MEDIO_PERSONAL,
            ID_PERSONAL: matchCurrentDate.ID_PERSONAL,
            ID_MEDIO: matchCurrentDate.ID_MEDIO,
            FECHA: matchCurrentDate.FECHA,
            FECHA_ENTRADA: matchCurrentDate.FECHA_ENTRADA,
            FECHA_SALIDA: matchCurrentDate.FECHA_SALIDA,
            ID_TURNO: matchCurrentDate.ID_TURNO,
            FECHA_HORA_ENTRADA_SALIDA: this.getHoraEntradaSalidaFromDB(matchCurrentDate),
            NUMERO_COMPONENTES: matchCurrentDate.NUMERO_COMPONENTES,
            state: 'update'
          }
        } else {
          medio.MEDIO_PERSONAL_CURRENT_DATE = {
            ID_PERSONAL: medio.GUARDIAS_DINAMICAS ? null : medio.ID_PERSONAL,
            ID_MEDIO: medio.ID_MEDIO,
            FECHA: null,
            ID_TURNO: this.turnos.length === 1 ? this.turnos[0].ID_TURNO : null,
            FECHA_HORA_ENTRADA_SALIDA: this.turnos.length === 1 ? this.getHoraEntradaSalidaFromTurno(this.turnos[0].ID_TURNO) : '',
            NUMERO_COMPONENTES: medio.NUMERO_COMPONENTES < 1 ? null : medio.NUMERO_COMPONENTES,
            state: 'insert'
          }
        }

        let matchNextDate = medioPersonalNextDate.find(x => x.ID_MEDIO === medio.ID_MEDIO)
        if (matchNextDate !== undefined) {
          medio.MEDIO_PERSONAL_NEXT_DATE = {
            ID_MEDIO_PERSONAL: matchNextDate.ID_MEDIO_PERSONAL,
            ID_PERSONAL: matchNextDate.ID_PERSONAL,
            ID_MEDIO: matchNextDate.ID_MEDIO,
            FECHA: matchNextDate.FECHA,
            FECHA_ENTRADA: matchNextDate.FECHA_ENTRADA,
            FECHA_SALIDA: matchNextDate.FECHA_SALIDA,
            ID_TURNO: matchNextDate.ID_TURNO,
            FECHA_HORA_ENTRADA_SALIDA: this.getHoraEntradaSalidaFromDB(matchNextDate),
            NUMERO_COMPONENTES: matchNextDate.NUMERO_COMPONENTES,
            state: 'update'
          }
        } else {
          medio.MEDIO_PERSONAL_NEXT_DATE = {
            ID_PERSONAL: medio.GUARDIAS_DINAMICAS ? null : medio.ID_PERSONAL,
            ID_MEDIO: medio.ID_MEDIO,
            FECHA: null,
            ID_TURNO: this.turnos.length === 1 ? this.turnos[0].ID_TURNO : null,
            FECHA_HORA_ENTRADA_SALIDA: this.turnos.length === 1 ? this.getHoraEntradaSalidaFromTurno(this.turnos[0].ID_TURNO) : '',
            NUMERO_COMPONENTES: medio.NUMERO_COMPONENTES < 1 ? null : medio.NUMERO_COMPONENTES,
            state: 'insert'
          }
        }
        this.listadoGuardias.push(medio)
      })

      let collator = new Intl.Collator('es', { sensitivity: 'base' })

      this.listadoGuardias = this.listadoGuardias.sort((a, b) => collator.compare(a.MEDIO, b.MEDIO))

      this.listadoGuardiasFiltrado = this.listadoGuardias.filter(x => this.tiposMedioSelected.includes(x.TIPO))

      this.updatePersonalOptionsCurrentDate()
      this.updatePersonalOptionsNextDate()

      // Update medio tipo filter options
      this.tiposMedioAll = [...this.tiposMedio].filter(x => tiposMedio.filter(y => y.ID_MEDIO_TIPO === x.ID_MEDIO_TIPO).length > 0).sort((a, b) => collator.compare(a.TIPO, b.TIPO))

      this.tiposMedioFilter = this.tiposMedioAll

      // Evita que al updatear cambie el menu de filtros

      this.updateFilterMedios()
    },

    filterByPersonal (medio, next) {
      let itemsPersonal = next ? this.personalesCopyNextDate : this.personalesCopyCurrentDate
      let itemsPersTipo = this.personalMedioTipo.filter(x => x.ID_MEDIO_TIPO === medio.ID_MEDIO_TIPO)
      return itemsPersonal.filter(personal => itemsPersTipo.some(x => x.ID_PERSONAL === personal.ID_PERSONAL))
    },

    updateFilterMedios () {
      const filterCategoriasMediosSelected = this.categoriasMedios.filter(el => this.categoriasSelecionadas.includes(el.INDEX)).map(item => item.CATEGORIA)
      this.listadoGuardiasFiltrado = [...this.listadoGuardias.filter(x => this.tiposMedioSelected.includes(x.TIPO) && this.isIncludedInCategoria(filterCategoriasMediosSelected, x.CLASE))]
    },

    async saveDate (item, next) {
      const turnoSelected = this.turnos.find(x => x.ID_TURNO === item.ID_TURNO)
      let dateBaseMoment = this.$date.parseDate(this.fechaSelected, 'DD/MM/YYYY').startOf('day')
      dateBaseMoment = next ? dateBaseMoment.add(1, 'days') : dateBaseMoment

      const dateBaseFormatted = dateBaseMoment.format('DD/MM/YYYY')
      if (turnoSelected && item.ID_MEDIO) {
        item.ID_MEDIO_PERSONAL = item.ID_MEDIO_PERSONAL ? item.ID_MEDIO_PERSONAL : this.$uuid.createUUID()
        item.BORRADO = 0
        item.FECHA = dateBaseMoment
        item.FECHA_ENTRADA = this.$date.parseDate(dateBaseFormatted + ' ' + turnoSelected.HORA_ENTRADA)
        if (turnoSelected.HORA_SALIDA <= turnoSelected.HORA_ENTRADA) {
          let nextDay = dateBaseMoment.add(1, 'days').format('DD/MM/YYYY')
          item.FECHA_SALIDA = this.$date.parseDate(nextDay + ' ' + turnoSelected.HORA_SALIDA)
        } else {
          item.FECHA_SALIDA = this.$date.parseDate(dateBaseFormatted + ' ' + turnoSelected.HORA_SALIDA)
        }
        await this.addMedioPersonal([item])
        this.updateUI()
      }
    },

    async remove (MEDIO_PERSONAL) {
      await this.removeMedioPersonal(MEDIO_PERSONAL.ID_MEDIO_PERSONAL)
      MEDIO_PERSONAL.state = 'delete'
      // await this.fetchMedios()
      this.updateUI()
    },

    async extend (MEDIO_PERSONAL) {
      this.medioPersonalSelected = MEDIO_PERSONAL
      this.showExtend = true
      this.updateUI()
    },
    cancelExtendGuardia () {
      this.medioPersonalSelected = null
      this.showExtend = false
    },
    async acceptExtendGuardia (mediosPersonales) {
      await this.addMedioPersonal(mediosPersonales)
      this.cancelExtendGuardia()
      this.updateUI()
    },

    updatePersonalOptionsCurrentDate () {
      this.personalesCopyCurrentDate = this.personalesCopyCurrentDate.map(personal => ({
        ID_PERSONAL: personal.ID_PERSONAL,
        NOMBRE_COMPLETO: personal.NOMBRE_COMPLETO,
        disabled: this.listadoGuardias.find(x => x.MEDIO_PERSONAL_CURRENT_DATE.ID_PERSONAL === personal.ID_PERSONAL) !== undefined // this.selectedValues.includes(option)
      }))
    },
    updatePersonalOptionsNextDate () {
      this.personalesCopyNextDate = this.personalesCopyNextDate.map(personal => ({
        ID_PERSONAL: personal.ID_PERSONAL,
        NOMBRE_COMPLETO: personal.NOMBRE_COMPLETO,
        disabled: this.listadoGuardias.find(x => x.MEDIO_PERSONAL_NEXT_DATE.ID_PERSONAL === personal.ID_PERSONAL) !== undefined // this.selectedValues.includes(option)
      }))
    },
    getHoraEntradaSalidaFromDB (entry) {
      let day = this.$date.parseDate(entry.FECHA_ENTRADA).format('DD/MM/YYYY')
      let horaEntrada = this.$date.parseDate(entry.FECHA_ENTRADA).format('HH:mm')
      let horaSalida = this.$date.parseDate(entry.FECHA_SALIDA).format('HH:mm')
      return `${day} ${horaEntrada} - ${horaSalida}`
    },
    getHoraEntradaSalidaFromTurno (ID_TURNO) {
      const turnoSelected = this.turnos.find(x => x.ID_TURNO === ID_TURNO)
      let datetime = ''
      if (turnoSelected !== undefined) {
        let day = this.fechaSelected
        datetime = `${day} ${turnoSelected.HORA_ENTRADA} - ${turnoSelected.HORA_SALIDA}`
      }
      return datetime
    },
    selectAllCheckBox () {
      if (this.checkAllFilters) {
        this.tiposMedioSelected = this.tiposMedioFilter.map(x => x.TIPO)
      } else {
        // desmarcar todas las checkbox
        this.tiposMedioSelected = []
      }
      this.checkAllFilters = !this.checkAllFilters
      // quiza necesite updateGui()
    }
  },
  mounted () {
    this.fechaSelected = this.$date.now().startOf('day').format('DD/MM/YYYY')
    this.categoriasMedios = JSON.parse(JSON.stringify(constant.categoriasMedios))
    this.categoriasSelecionadas = this.categoriasMedios.map((item, index) => {
      return index
    })

    const personales = [...this.$store.getters['personal/personales']]
    personales.forEach(personal => {
      personal.NOMBRE_COMPLETO = personal.NOMBRE + ' ' + personal.APELLIDOS
    })
    let collator = new Intl.Collator('es', { sensitivity: 'base' })
    personales.sort((a, b) => collator.compare(a.NOMBRE_COMPLETO, b.NOMBRE_COMPLETO))
    this.personalesCopyCurrentDate = [...personales]
    this.personalesCopyNextDate = [...personales]
    this.tiposMedioSelected = []
  }
}
</script>

<style scoped>
.guardia-select .v-input__control {
  width: 100%
}
::v-deep.v-list-item--disabled{
  color: #ff9c9c !important
}
::v-deep.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #00b116 !important
}
.header-main {
  background: var(--v-primaryLight-base);
  color: white;
}
.medio-item {
  background-color: #e2f8ff
}
.current-day{
  background: #a7d8a7;
}
.next-day{
  background: #ffd587
}
.current-day-light{
  background: #e7ffe7
}
.next-day-light{
  background: #fff3de
}
</style>
