<template>
  <div class="fill-height">
    <div id="overviewDiv" :style="{ visibility: visibilityOverviewMap }">
      <div id="extentDiv" />
    </div>
    <div id="mapNode" @contextmenu="test" />

    <v-toolbar
      v-show="showToolbar"
      id="searchToolbar"
      class="ma-4"
      dense
      floating
      absolute
      rounded
      style="right: 0px"
    >
      <v-btn icon @click="toggleOverviewMap()">
        <v-icon :class="{ toggled: !isVisibleOverview }">{{ "mdi-map" }}</v-icon>
      </v-btn>
      <v-btn-toggle v-model="searchMode" class="mx-2" mandatory dense>
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" small>
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <span>Medios</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" small>
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>
          </template>
          <span>Topónimos</span>
        </v-tooltip>
      </v-btn-toggle>

      <v-autocomplete
        v-model="selectedSearchItem"
        :search-input.sync="search"
        class="searchBar notranslate"
        :items="searchResults"
        v-if="searchMode === 0"
        item-text="MEDIO"
        item-value="ID_MEDIO"
        return-object
        @change="searchClicked"
        label="Buscar medios"
        single-line
        append-icon="mdi-magnify"
        dense
        outlined
        hide-details
        clearable
      />
      <div id="searchWidget" class="searchBar" v-show="searchMode === 1" />

      <v-menu bottom left offset-y>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="showDialogSimulacion = true">
            <v-list-item-action>
              <v-icon>mdi-pine-tree-fire</v-icon>
            </v-list-item-action>

            <v-list-item-title>Simulación</v-list-item-title>
          </v-list-item>

          <v-list-item @click="showDialogEmergencias = true">
            <v-list-item-action>
              <v-icon>mdi-car-brake-alert</v-icon>
            </v-list-item-action>

            <v-list-item-title>Emergencias activas</v-list-item-title>
          </v-list-item>

          <v-list-item @click="showDialogGeofencing = true">
            <v-list-item-action>
              <v-icon>mdi-alert</v-icon>
            </v-list-item-action>

            <v-list-item-title>Geofencing</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <vx-incendios-cards :showCards="showIncendiosCards" @selectIncendio="selectIncendio" />

    <vx-context-menu @showAddIncendio="showAddIncendio" />
    <vx-dialog-add-incendio
      :show="showDialogAddIncendio"
      :pointData="addIncendioPoint"
      @cancelar="cancelarAddIncendio"
      @aceptar="addIncendio"
    />
    <vx-coordenadas-new />

    <vx-buttons-map
      :show="showButtonsMap"
      ref="mapButtons"
      @showSnackBarLoading="showSnackBarLoading"
      @showSnackBarError="showSnackBarError"
      @showSnackBarDibujando="showSnackBarDibujando"
      @showLayerList="showLayerList = true"
      @showSearchCoords="showSearchCoords = true"
      @showDialogSimulacion="showDialogSimulacion = true"
      @showDialogEmergencias="showDialogEmergencias = true"
      @showDialogGeofencing="showDialogGeofencing = true"
      @showResumenMedios="showResumenMedios = true"
    />
    <!-- @shareMap="toggleShareMap"  -->
    <vx-search-coords
      :show="showSearchCoords"
      @hide="showSearchCoords = false"
    />
    <vx-layer-list :show="showLayerList" @hide="showLayerList = false" />

    <vx-resumen-medios
      :show="showResumenMedios"
      @hide="showResumenMedios = false"
    />

    <vx-popup-manager />

    <!-- Simulacion -->
    <vx-simulacion-manager
      :showDialog="showDialogSimulacion"
      @openDialog="showDialogSimulacion = true"
      @closeDialog="showDialogSimulacion = false"
    />

    <vx-dialog-emergencias
      :show="showDialogEmergencias"
      @cerrar="showDialogEmergencias = false"
    />
    <vx-dialog-geofencing
      :show="showDialogGeofencing"
      @cerrar="showDialogGeofencing = false"
    />

    <vx-symbology />

    <vx-perimeter-symbology />

    <!-- Alineaciones de Campbell-->
    <vx-alineaciones-campbell />

    <!-- Compartir mapa -->
    <vx-share-map-manager />

    <vx-footer-map />

    <v-overlay :value="isLoading">
      <v-progress-circular
        color="primary"
        indeterminate
        size="100"
        width="10"
      />
    </v-overlay>

    <v-snackbar v-model="showLoadingSnackBar" bottom :timeout="-1">
      Descargando los datos del track
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="showErrorSnackBar"
      color="error"
      bottom
      :timeout="5000"
    >
      {{ errorMsg }}
    </v-snackbar>
    <v-snackbar
      v-model="showDibujandoSnackBar"
      button
      :timeout="-1"
      color="#28a745"
      elevation="24"
      width="700px"
      height="80px"
      style="margin-bottom: 15px"
    >
      Está usted dibujando
      <template #action="{ attrs }">
        <v-btn
          text
          color="white"
          v-bind="attrs"
          @click="cancelDrawPolygon"
          style="font-size: larger"
        >
          Cancelar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import * as ArcGIS from '../../helpers/ArcGIS'

import IncendioCards from './IncendioCards'

import ContextMenu from './ContextMenu'
import DialogAddIncendio from './DialogAddIncendio'
import CoordenadasNew from './CoordenadasNew'

import ButtonsMap from './ButtonsMap'
import SearchCoords from './SearchCoords'
import FooterMap from './FooterMap'
import LayerList from './LayerList'

import ResumenMedios from './ResumenMedios.vue'

import SimulacionManager from './simulador/SimulacionManager'
import DialogEmergencias from './DialogEmergencias'
import DialogGeofencing from './DialogGeofencing'

import PopupManager from './popup/PopupManager'
import Symbology from './Symbology'
import AlineacionesCampbell from './AlineacionesCampbell.vue'
import ShareMapManager from './sharemap/ShareMapManager'
import PerimeterSymbology from './PerimeterSymbology'
import infraestructura from '@/api/infraestructura'

export default {
  props: {
    showIncendiosCards: Boolean,
    showButtonsMap: Boolean,
    showToolbar: Boolean,
    showWidget: Boolean
  },
  name: 'Map',

  components: {
    'vx-incendios-cards': IncendioCards,

    'vx-context-menu': ContextMenu,
    'vx-coordenadas-new': CoordenadasNew,
    'vx-dialog-add-incendio': DialogAddIncendio,

    'vx-buttons-map': ButtonsMap,
    'vx-search-coords': SearchCoords,
    'vx-layer-list': LayerList,

    'vx-resumen-medios': ResumenMedios,

    'vx-popup-manager': PopupManager,
    'vx-simulacion-manager': SimulacionManager,
    'vx-dialog-emergencias': DialogEmergencias,
    'vx-dialog-geofencing': DialogGeofencing,

    'vx-share-map-manager': ShareMapManager,

    'vx-symbology': Symbology,

    'vx-perimeter-symbology': PerimeterSymbology,
    'vx-alineaciones-campbell': AlineacionesCampbell,

    'vx-footer-map': FooterMap
  },

  data: () => ({
    isLoading: null,

    showSearchCoords: false,
    showLayerList: false,

    showDialogAddIncendio: false,
    addIncendioPoint: null,

    searchMode: 0,
    search: '',
    selectedSearchItem: null,
    searchResults: [],

    showDialogSimulacion: false,
    showDialogEmergencias: false,
    showDialogGeofencing: false,

    showDibujandoSnackBar: false,
    showLoadingSnackBar: false,
    showErrorSnackBar: false,
    errorMsg: '',
    loadingMsg: ' ',

    showResumenMedios: false,

    showOverviewMap: false,
    isVisibleOverview: false
  }),

  computed: {
    ...mapGetters('incendio', ['incendios']),

    ...mapGetters('medio', ['medios']),

    ...mapGetters('personal', ['personales']),

    ...mapGetters('infraestructura', ['infraestructuras']),

    ...mapGetters('shareMap', ['mapExtent']),

    visibilityOverviewMap () {
      return this.showOverviewMap ? '' : 'hidden'
    }
  },

  watch: {
    incendios () {
      this.redrawIncendios()
    },

    medios () {
      this.redrawMedios(true, false)
    },

    personales () {
      this.redrawMedios(false, true)
    },
    infraestructuras () {
      this.redrawInfraestructuras()
    },

    search (val) {
      if (this.searchMode === 0) {
        let results = val
          ? this.medios.filter((x) =>
            x.MEDIO.toLowerCase().includes(val.toLowerCase())
          )
          : []
        this.searchResults = results.sort((a, b) =>
          a.MEDIO.localeCompare(b.MEDIO)
        )
      }
    },

    showWidget () {
      this.updateWidgetVisibility()
    }
  },

  methods: {
    ...mapActions('map', ['setMapLoading']),

    toggleOverviewMap () {
      this.showOverviewMap = !this.showOverviewMap
    },

    async initMap () {
      this.setMapLoading(true)

      try {
        await ArcGIS.createMap()
      } finally {
        this.setMapLoading(false)
      }
    },

    test (e) {
      // console.log(e)
    },

    redrawIncendios () {
      ArcGIS.redrawIncendios()
    },
    redrawMedios (medios, personal) {
      ArcGIS.redrawMedios(medios, personal)
    },
    redrawInfraestructuras () {
      ArcGIS.redrawInfraestructuras()
    },

    // Seleccionar incendio en las cards
    selectIncendio (idIncendio) {
      this.$emit('selectIncendio', idIncendio)

      let incendio = this.incendios.find((x) => x.ID_INCENDIO === idIncendio)
      if (incendio) {
        let center = {
          target: [incendio.LONGITUD, incendio.LATITUD],
          zoom: 15
        }
        ArcGIS.setCenterMap(center)
      }
    },
    cancelDrawPolygon () {
      this.showDibujandoSnackBar = false
      this.$refs.mapButtons.cancelDrawingPolygon()
      // this.$eventHub.$emit('cancelDrawingPolygonArea')
    },

    // Nuevo incendio
    showAddIncendio (pointData) {
      this.showDialogAddIncendio = true
      this.addIncendioPoint = pointData
    },
    cancelarAddIncendio () {
      this.showDialogAddIncendio = false
    },
    addIncendio (incendio) {
      this.showDialogAddIncendio = false
      this.$store.dispatch('incendio/addIncendio', incendio)
    },

    // Busquedas
    searchClicked (item) {
      if (!item) {
        return
      }

      if (this.searchMode === 0) {
        if (item.ULT_POSICION && this.isMedioDraw(item.ULT_POSICION.FECHA)) {
          ArcGIS.setCenterMap({
            target: [item.ULT_POSICION.LONGITUD, item.ULT_POSICION.LATITUD],
            zoom: 17
          })
        } else {
          this.$notify({
            title: `Medio ${item.MEDIO}`,
            text: 'No tiene última posición'
          })
        }
      }
    },

    isMedioDraw (tmedio) {
      let fecha = new Date(tmedio)
      let hoy = new Date()
      let diferencia = Math.abs(hoy - fecha)
      let dias = Math.ceil(diferencia / (1000 * 60 * 60 * 24))

      if (dias <= 30) {
        return true
      } else {
        return false
      }
    },

    showSnackBarDibujando () {
      this.showDibujandoSnackBar = !this.showDibujandoSnackBar
    },
    showSnackBarLoading () {
      this.showLoadingSnackBar = !this.showLoadingSnackBar
    },
    showSnackBarError (msg) {
      this.showErrorSnackBar = !this.showErrorSnackBar
      this.errorMsg = msg
    },
    updateWidgetVisibility () {
      const button = document.querySelector('.esri-widget--button')

      if (button) {
        button.style.display = this.showWidget ? 'flex' : 'none'
      }
    }
  },

  async created () {
    await this.$store.dispatch('map/fetchParamsComunidad')
    this.initMap()
  }
}
</script>

<style scoped>
::v-deep .notranslate {
  transform: none !important;
}

.searchBar {
  width: 250px;
  height: 40px;
  border-radius: 0;
  border: 1px solid #000;
}

::v-deep .searchBar .v-text-field .v-input__control .v-select__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

#mapNode {
  height: calc(100% - 25px); /* 26 px footer */
  z-index: 1;
}

.esri-attribution {
  background-color: #00486f85 !important;
  color: white;
}

/** Estilos botones 3D */
::v-deep .esri-ui-corner {
  display: flex;
  flex-direction: row;
}
::v-deep .esri-ui-corner .esri-component {
  display: flex;
  flex-direction: row;
  background-color: transparent;
}
::v-deep .esri-navigation-toggle:hover .esri-navigation-toggle__button,
.esri-navigation-toggle:focus .esri-navigation-toggle__button {
  background-color: #f5f5f5;
  color: #2e2e2e;
}
::v-deep .esri-navigation-toggle__button--rotate {
  background-color: #f5f5f5;
  margin-left: 8px;
  border-top: solid 0px !important;
  margin-top: 62px;
  margin-right: 48px;
}
::v-deep .esri-navigation-toggle__button--pan {
  background-color: #f5f5f5;
  margin-top: 62px;
  margin-right: 0px;
}
::v-deep .esri-widget--button {
  padding: 20px !important;
}

#overviewDiv {
  position: absolute;
  top: 70px;
  right: 60px;
  width: 300px;
  height: 200px;
  border: 1px solid black;
  z-index: 1;
  overflow: hidden;
  /* visibility:v-bind(visibilityOverviewMap); */
}

#extentDiv {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 2;
}
</style>
