<template>
  <div class="d-flex justify-space-around">
    <v-menu icon :close-on-content-click="false" :offset-y="true">
      <template #activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="filtro in filtros"
          :key="filtro.TIPO"
          :value="filtro.TIPO"
        >
          <v-list-item-action start>
            <v-checkbox v-model="filtro.checked" />
          </v-list-item-action>
          <v-list-item-title>{{ filtro.TIPO }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from '../../helpers/constants'

export default {
  props: {
    icon: String
  },

  data: () => ({
    toggled: true,
    selectedFilters: [],
    filtros: [],
    tipos: [
      'Dirección General de Emergencias 112',
      'Sanitarios',
      'Bomberos Cartagena',
      'Bomberos Murcia',
      'Embarcaciones - Barcos',
      'Bomberos CEIS (Consorcio Regional)',
      'Desconocido'
    ],
    queryOld: null
  }),

  watch: {
    filtros: {

      handler () {
        this.filtroString()
      },
      deep: true
    }
  },

  methods: {
    filtroString () {
      if (!this.queryOld || this.queryOld === null) {
        this.queryOld = ') AND TIPO NOT IN ( ' + this.filtros.map(elem => `'${elem.TIPO}'`).join(',') + ')'
      }

      let query = ') AND TIPO NOT IN ( '

      let filtrosChecked = this.filtros.filter(x => !x.checked)

      filtrosChecked.forEach((elem) => {
        query += "'" + elem.TIPO + "',"
      })

      if (filtrosChecked.length > 0) {
        query = query.slice(0, -1)
      }

      query += ')'

      this.$store.dispatch('map/setDefinitionExpression', {
        id: 'layer-medios',
        definitionExpression: this.queryOld,
        add: false
      })

      this.$store.dispatch('map/setDefinitionExpression', {
        id: 'layer-medios',
        definitionExpression: query,
        add: true
      })

      this.queryOld = query
    }
  },

  mounted () {
    let filtros = []
    // filtros.push(...constants.iconosTiposMedios)
    filtros.push(...constants.iconosFilterISSI)

    filtros.forEach(element => {
      element.checked = false
    })

    let desconocido = {
      TIPO: 'Desconocido',
      checked: false
    }
    filtros.push(desconocido)

    this.filtros = filtros

    // Esto evita que al cambiar de pag, los filtros exploten
    // Razón: al cambiar de pag this.queryOld se reseteaba y el replace de store/map SET_DEFINITION_EXPRESION_LAYER no funcionaba correctamente
    this.$store.dispatch('map/setDefinitionExpression', {
      id: 'layer-medios',
      definitionExpression: '(1=0 OR ACTIVO = 1 ) AND TIPO NOT IN ( ' + this.tipos.map(tipo => `'${tipo}'`).join(',') + ')',
      add: false,
      reset: true
    })
  }

}
</script>

<style scoped>
  .v-list{
  max-height:400px;/* or any height you want */
  overflow-y:auto;
}
</style>
