import api from '@/api'

let Q = require('q')

const state = {
  personalMedioTipo: [],
  medioTipo: [],
  personal: []
}

const getters = {
  personalMedioTipo: (state) => {
    return state.personalMedioTipo
  },
  medioTipo: (state) => {
    return state.medioTipo
  },
  personal: (state) => {
    return state.personal
  }
}

const mutations = {
  SET_PERSONAL_MEDIO_TIPO (state, personalMedioTipo) {
    state.personalMedioTipo = personalMedioTipo
  },
  SET_MEDIO_TIPO (state, medioTipo) {
    state.medioTipo = medioTipo
  },
  SET_PERSONAL (state, personal) {
    state.personal = personal
  },
  UPDATE_PERSONAL_MEDIO_TIPO (state, data) {
    state.personalMedioTipo = state.personalMedioTipo.filter(refs => refs.ID_PERSONAL !== data.ID_PERSONAL)
    if (data.PERSONAL_MEDIO_TIPO.length !== 0 && data.PERSONAL_MEDIO_TIPO) {
      state.personalMedioTipo.push(...JSON.parse(JSON.stringify(data.PERSONAL_MEDIO_TIPO)))
    }
  }
}

const actions = {
  async fetchPersonalMedioTipo ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.personalMedioTipo.getPersonalMedioTipo()
      commit('SET_PERSONAL_MEDIO_TIPO', response.data)

      deferred.resolve()
    } catch (err) {
      deferred.reject(err)
    }

    return deferred.promise
  },

  async updatePersonalMedioTipo ({ commit }, data) {
    try {
      commit('UPDATE_PERSONAL_MEDIO_TIPO', data)
    } catch (err) {
    }
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
