const notifications = store => {
  store.notify = function (title, message, type = null) {
    store._vm.$notify({
      title: title,
      text: message,
      type: type
    })
  }
}

export default notifications
