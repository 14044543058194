<template>
  <v-dialog v-model="show" persistent max-width="400">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Clientes conectados</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table v-model="clientesSelected" :headers="headers" :items="usersLogged" item-key="ID_SOCKET" show-select hide-default-footer disable-pagination>
          <template #no-data>
            <p>No hay usuarios conectados.</p>
          </template>

          <template #[`item.ISWEB`]="{ item }">
            <!-- TODO: ISWEB no se esta enviando bien -->
            <v-icon v-show="!item.ISWEB">mdi-cellphone</v-icon>
            <v-icon v-show="item.ISWEB">mdi-monitor</v-icon>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="clientesSelected.length <= 0" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    headers: [
      { text: 'Usuario', value: 'USUARIO', align: 'center', sortable: false },
      { text: 'Dispositivo', value: 'ISWEB', align: 'center', sortable: false }
    ],

    clientesSelected: []
  }),

  computed: {
    ...mapGetters('usuario', [
      'currentUsuario',
      'usersLogged'
    ])
  },

  methods: {
    cancelar () {
      this.$emit('cerrar')

      for (let i = 0; i < this.usersLogged.length; i++) {
        this.usersLogged[i].selected = false
      }

      this.$store.dispatch('shareMap/setShareMap', false) // TODO:
    },

    aceptar () {
      let clientes = []
      for (let i = 0; i < this.clientesSelected.length; i++) {
        let client = this.clientesSelected[i]
        clientes.push({ ID_SOCKET: client.ID_SOCKET, USUARIO: client.USUARIO, ESTADO: 'Pendiente' })
      }

      this.$emit('cerrar')

      this.$store.dispatch('shareMap/setUsersShareMap', clientes) // TODO:

      this.clientesSelected = []
    }
  }
}
</script>

<style scoped>
</style>
