import api from '@/api'

let Q = require('q')

const state = {
  tiposPersonal: []
}

const getters = {
  tiposPersonal: (state) => {
    return state.tiposPersonal
  }
}

const mutations = {
  SET_TIPOS_PERSONAL (state, tiposPersonal) {
    state.tiposPersonal = tiposPersonal
  }
}

const actions = {
  async fetchTiposPersonal ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.personalTipo.getTiposPersonal()
      commit('SET_TIPOS_PERSONAL', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
