import request from '../request'

export default {
  getParametros: function () {
    return request.get('parametro')
  },

  setParametro: function (key, data) {
    return request.post('parametro/' + key, data)
  }
}
