import constants from './constants'
import VueInst from '../main'
import * as ArcGIS from './ArcGIS'
import store from '@/store'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export const pdfSimulacion = async function (captura, simulacion, informe) {
  let docDefinition = {
    pageOrientation: 'portrait',
    content: [

    ],
    styles: {
      header: {
        fontSize: 18,
        margin: [0, 0, 0, 10],
        bold: true
      },
      subheader: {
        fontSize: 12
        // bold: true
      },
      quote: {
        italics: true
      },
      small: {
        fontSize: 8
      }
    }
  }
  docDefinition.content.push(constants.headerPDF)

  docDefinition.content.push(

    {
      text: (simulacion.NOMBRE ? simulacion.NOMBRE.trim() : 'Sin nombre') + ' - ' + VueInst.$date.parseDate(simulacion.FECHA_CREACION).format('YYYY/MM/DD HH:mm'),
      style: 'header'
    },
    {
      text: 'Inicio: ' + VueInst.$date.parseDate(simulacion.FECHA_INICIO).format('YYYY/MM/DD HH:mm') + '. Número de horas: ' + simulacion.NUM_HORAS + '. Intervalo: ' + simulacion.INTERVALO + ' min.' + '\n\n', style: 'subheader'
    },
    // plan.DESCRIPCION + '\n\n',
    {
      image: captura,
      width: 500
    }
  )

  let inputInfo = []

  inputInfo.push([
    'Tiempo (min)',
    'Temperatura (ºC)',
    'Humedad relativa (%)',
    'Precipitaciones (%)',
    'Velocidad del viento (km/h)',
    'Dirección del viento (º)',
    'Nubes (%)',
    'Humedad combustible muerto fino (%)',
    'Humedad combustible muerto medio (%)',
    'Humedad combustible muerto grueso (%)',
    'Humedad combustible vivo (%)',
    'Humedad vegetal (%)',
    'Área (ha)'
  ])

  informe.data.forEach(element => {
    delete element.Date
    element.index = element.index * simulacion.INTERVALO
    element.Temp = element.Temp.toFixed(2)
    element.Wind_speed = element.Wind_speed.toFixed(2)
    element.Area = element.Area.toFixed(2)
    inputInfo.push(Object.values(element))
  })

  docDefinition.content.push({
    layout: 'lightHorizontalLines', // optional
    table: {
      headerRows: 1,
      body: inputInfo
    },
    margin: [0, 15, 0, 20],
    alignment: 'center',
    width: '*',
    style: {
      fontSize: 5
    }
  })

  pdfMake.createPdf(docDefinition).download((simulacion.NOMBRE ? simulacion.NOMBRE.trim() : 'Sin nombre') + '_' + VueInst.$date.parseDate(simulacion.FECHA_CREACION).format('YYYY_MM_DD_hh_mm'))
}

export const pdfInforme = async function (captura, capturaOverview, frameBounds, measure, measurePerimetros, escala, municipio, paraje, titulo, graphics) {
  /**
   * Valores por defecto de un A4 apaisado
   */
  const heightPage = 595.28
  const widthPage = 831.89

  /**
   * Valores de estilos de la hoja
   */
  const pageMargin = 20
  const pageMarginLeft = 16
  const imageHeight = heightPage - (pageMargin * 2)
  const imageWidth = obtainCaptureWidth(imageHeight, frameBounds)
  const columnGap = 15
  const dataColumnWidth = widthPage - (pageMarginLeft + (pageMargin / 2)) - imageWidth - columnGap
  const capturaOverviewSize = 150
  const leyendaSize = 177
  let leyendaFontSize = 6
  /**
   * Marco del mapa y coordenadas
   */
  let measureETRS89 = convertirETRS89(measure) // Coordenadas a ETRS89 para usarlas en el mapa
  let axisMarks = [] // Marco del mapa con las marcas de las coordenadas
  let axisCoords = [] // Coordenadas del mapa

  axisMarks.push({ type: 'line', x1: 0, y1: 0, x2: imageWidth, y2: 0, lineWidth: 2, lineColor: 'black', lineCap: 'round' })
  axisMarks.push({ type: 'line', x1: 0, y1: 0, x2: 0, y2: imageHeight, lineWidth: 2, lineColor: 'black', lineCap: 'round' })
  axisMarks.push({ type: 'line', x1: 0, y1: imageHeight, x2: imageWidth, y2: imageHeight, lineWidth: 2, lineColor: 'black', lineCap: 'round' })
  axisMarks.push({ type: 'line', x1: imageWidth, y1: 0, x2: imageWidth, y2: imageHeight, lineWidth: 2, lineColor: 'black', lineCap: 'round' })
  let escalaMark = escalaMarks(escala)
  let escalaWidth = axisMarksFunction(axisMarks, axisCoords, measureETRS89.xETRS89[0], measureETRS89.xETRS89[1], imageHeight, imageWidth, 0, escalaMark, pageMargin, pageMarginLeft)
  axisMarksFunction(axisMarks, axisCoords, measureETRS89.yETRS89[0], measureETRS89.yETRS89[1], imageHeight, imageWidth, 1, escalaMark, pageMargin, pageMarginLeft)

  /**
   * 3D
   */
  const is3D = store.getters['map/isMap3D']

  /**
   * Leyenda
   */
  // featuresLayer
  let layerMediosItems = await ArcGIS.getCaptureMapFeatures(graphics, 'layer-medios')
  layerMediosItems.sort((a, b) => b.TIPO.length - a.TIPO.length)
  let layerInfraestructurasPropiasItems = await ArcGIS.getCaptureMapFeatures(graphics, 'layer-infraestructuras-propias')
  layerInfraestructurasPropiasItems.sort((a, b) => b.TIPO.length - a.TIPO.length)
  let featuresLayerItems = []
  featuresLayerItems.push(...layerMediosItems)
  featuresLayerItems.push(...layerInfraestructurasPropiasItems)

  // graphicsLayer
  let layerPerimetrosItems = await ArcGIS.getCaptureMapFeatures(graphics, 'layer-perimetros')
  layerPerimetrosItems.sort((a, b) => b.tipo.length - a.tipo.length)
  let layerPlanOperacionesItems = await ArcGIS.getCaptureMapFeatures(graphics, 'layer-plan-operaciones')
  layerPlanOperacionesItems.sort((a, b) => b.tipo.length - a.tipo.length)
  let graphicsLayerItems = []
  graphicsLayerItems.push(layerPerimetrosItems)
  graphicsLayerItems.push(layerPlanOperacionesItems)

  // Meto todos los elementos del mapa en un array leyenda
  let leyenda = await leyendaItems(featuresLayerItems, graphicsLayerItems)
  const tableContent = createTable(leyenda, leyendaFontSize)

  /**
   * Columna de datos
   */
  let dataColumn = {
    margin: [0, pageMargin - 1, 0, 0],
    width: dataColumnWidth,
    alignment: 'center',
    table: {
      heights: ['*'],
      widths: [dataColumnWidth],
      body: [
        [{
          alignment: 'center',
          columns: [
            {
              width: '55%',
              table: {
                widths: ['*'],
                body: [
                  [constants.logoRegionPDF]
                ]
              },
              layout: 'noBorders'
            },
            {
              width: '45%',
              table: {
                widths: ['*'],
                body: [
                  [constants.logoUDIFPDF]
                ]
              },
              layout: 'noBorders'
            }
          ],
          columnGap: 3
        }],
        [{ text: '', border: [false, false, false, false] }],
        [{
          columns: [{
            text: 'MUNICIPIO:',
            bold: true,
            alignment: 'center',
            fontSize: 7,
            width: 'auto'
          }, {
            text: municipio.toUpperCase(),
            color: 'red',
            bold: true,
            alignment: 'center',
            fontSize: 9
          }],
          columnGap: 3,
          border: [true, true, true, false]
        }],
        [{
          columns: [{
            text: 'PARAJE:',
            bold: true,
            alignment: 'center',
            fontSize: 7,
            width: '16%'
          }, {
            text: normalizarTexto(paraje.toUpperCase()),
            color: 'red',
            bold: true,
            alignment: 'center',
            fontSize: 9,
            width: '*'
          }],
          columnGap: 3,
          border: [true, false, true, true]
        }],
        [{ text: '', border: [false, false, false, false] }],
        [{
          image: capturaOverview,
          fit: [capturaOverviewSize, capturaOverviewSize],
          alignment: 'center'
        }],
        [{ text: '', border: [false, false, false, false] }],
        [{
          columns: [{
            text: 'TITULO:',
            bold: true,
            alignment: 'center',
            border: [true, true, true, false],
            fontSize: 7,
            width: '15%'
          }, {
            text: normalizarTexto(titulo.toUpperCase()),
            color: 'red',
            bold: true,
            alignment: 'center',
            border: [true, false, true, true],
            fontSize: 9,
            width: '*'
          }],
          columnGap: 3
        }],
        // [{ text: '', border: [false, false, false, false] }],
        [{
          columns: [{
            text: 'FECHA Y HORA:',
            bold: true,
            alignment: 'center',
            border: [true, true, true, false],
            fontSize: 7,
            width: 'auto'
          }, {
            text: new Date().toLocaleString('es-ES'),
            color: 'red',
            bold: true,
            border: [true, false, true, true],
            fontSize: 9,
            alignment: 'center'
          }],
          columnGap: 3
        }],
        [{ text: '', border: [false, false, false, false] }],
        [{
          table: {
            heights: ['*', leyendaSize],
            widths: [dataColumnWidth],
            body: [[
              {
                text: 'LEYENDA:',
                bold: true,
                margin: [0, 1, 0, 0],
                border: [true, true, true, false],
                fontSize: 7
              }
            ],
            leyenda.length > 0 && leyenda.length < 28
              ? [tableContent]
              : [{ text: '', height: leyendaSize, border: [true, false, true, true] }]
            ]
          },
          layout: 'noBorders'
        }],
        [{ text: '', border: [false, false, false, false] }],
        [
          {
            stack: [
              { text: '1:' + escala + '\n', fontSize: 9 },
              {
                // Texto alineado debajo del canvas
                table: {
                  widths: Array(4).fill('*'), // Número de columnas igual a los puntos de escala
                  body: [
                    [
                      { text: '0', alignment: 'center', fontSize: 6 },
                      { text: (escalaMark + (escalaMark * 0.2)) / 3, alignment: 'center', fontSize: 6 },
                      { text: ((escalaMark + (escalaMark * 0.2)) * 2) / 3, alignment: 'center', fontSize: 6 },
                      { text: escalaMark + (escalaMark * 0.2), alignment: 'center', fontSize: 6 }
                    ]
                  ]
                },
                layout: 'noBorders'
              },
              {
                image: canvasEscala(escalaWidth),
                alignment: 'center'
              }
            ]
          }
        ],
        // [{ text: '', border: [false, false, false, false] }],
        [
          {
            columns: [{
              image: await rotateBrujula(is3D),
              height: 30,
              width: 30,
              alignment: 'center',
              margin: [0, 0, 0, 0]
            }, {
              alignment: 'center',
              margin: [0, 0, 0, 0],
              text: 'Sistema de Coordenadas UTM\nElipsoide internacional\nDatum ETRS89-H30N\nBase cartográfica: ArcGIS World_Imagery',
              fontSize: 6
            }],
            columnGap: 3
          }
        ],
        // [{ text: '', border: [false, false, false, false] }],
        [{
          columns: [
            {
              width: '50%',
              alignment: 'start',
              text: 'REALIZADO POR:',
              fontSize: 7
            },
            constants.logoOrthemPDF
          ],
          border: [true, true, true, false]
        }],
        [{
          columns: [
            {
              text: 'Servicio de Prevención Selvícola y Defensa del Patrimonio Natural de la Región de Murcia. Exp 20/2015',
              alignment: 'start',
              fontSize: 6
            }
          ],
          border: [true, false, true, true]
        }]
        /*
          if (leyenda.length > 28) {
            docDefinition.content.push({
              columns: [
                {
                  width: '100%',
                  style: 'marginInforme',
                  alignment: 'center',
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: 'LEYENDA:',
                          bold: true,
                          margin: [0, 1, 0, 0],
                          border: [true, true, true, false]
                        }
                      ],
                      [
                        tableContent
                      ]

                    ]
                  }
                }
              ],
              pageBreak: 'before'
            })
          }
        */
      ]
    }
  }

  /**
   * Tabla perimetros
   */
  let dataPerimetros = {
    table: {
      heights: [10, 10],
      widths: [160],
      body: [
        [{ text: 'SUPERFICIE ESTIMADA: ' + measurePerimetros.area + ' ha', fontSize: 9, margin: [0, 2] }],
        [{ text: 'AREA ESTIMADA: ' + measurePerimetros.perimetro + ' km', fontSize: 9, margin: [0, 2] }]
      ]
    },
    absolutePosition: { x: pageMarginLeft + (pageMarginLeft / 3), y: imageHeight - (pageMargin + (pageMargin / 3)) } // Posiciona la tabla abajo a la derecha
  }

  let docDefinition = {
    pageOrientation: 'landscape',
    pageMargins: [pageMarginLeft, 0, pageMargin / 2, 0],
    content: [
      {
        columns: [
          {
            margin: [0, pageMargin, 0, 0],
            stack: [
              {
                stack: [
                  {
                    absolutePosition: { x: pageMarginLeft, y: pageMargin },
                    canvas: axisMarks
                  },
                  axisCoords
                ]
              },
              {
                image: captura,
                width: imageWidth

              },
              {
                stack: [
                  {
                    absolutePosition: { x: (pageMarginLeft + (pageMarginLeft / 3)), y: imageHeight - (pageMargin + (pageMargin / 3)) },
                    canvas: [
                      {
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 170, // Ancho del rectángulo igual al de la tabla
                        h: 40, // Alto del rectángulo igual al de la tabla
                        color: 'white' // Color del rectángulo
                      }
                    ]
                  }
                ]
              },
              dataPerimetros
            ],
            width: widthPage - (pageMarginLeft + (pageMargin / 2)) - dataColumnWidth - columnGap // Ajusta el ancho disponible para la imagen
          },
          dataColumn
        ],
        columnGap: columnGap
      }
    ]
  }

  pdfMake.createPdf(docDefinition).download('Posicionamiento_' + VueInst.$date.now().format('YYYY_MM_DD_hh_mm'))
}

// #region Funciones informeMapa
function obtainCaptureWidth (height, frameBounds) {
  // Metodo que obtiene la altura proporcional de la captura del mapa segun el ancho que se haya asignado
  let measureW = frameBounds.width
  let measureH = frameBounds.height

  let captH = (height * measureW) / measureH

  return captH
}

/* function fixCaptura (captura, width, height, heightFix, callback) {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.src = captura

    image.onload = () => {
      // Crear un canvas
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')

      // Establecer las dimensiones del canvas
      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight

      // Dibujar la parte deseada de la imagen en el canvas
      context.drawImage(image, 0, heightFix, image.naturalWidth, image.naturalHeight - ((heightFix) * 2), 0, 0, image.naturalWidth, image.naturalHeight - ((heightFix) * 2))

      // Llamar al callback con la imagen recortada
      callback(canvas.toDataURL())

      resolve(canvas.toDataURL())
    }

    image.onerror = (error) => {
      console.error('Error al cargar la imagen', error)
    }
  })
} */

function convertirETRS89 (measure) {
  let coords1ETRS89 = ArcGIS.convertirETRS89(measure.x[0], measure.y[1]) // esquina superior izquierda [xMin, yMax]
  let coords2ETRS89 = ArcGIS.convertirETRS89(measure.x[1], measure.y[0]) // esquina inferior derecha [xMax, yMin]
  let xETRS89 = [coords1ETRS89[0], coords2ETRS89[0]]
  let yETRS89 = [coords2ETRS89[1], coords1ETRS89[1]]

  const objeto = { xETRS89, yETRS89 }

  return objeto
}

function escalaMarks (escala) {
  let result
  let escalaInt = parseInt(escala, 10)
  let distance = 1000

  // 30 es la distancia minima para establecer las marcas del grafico
  // 1100 es la escala definida para esa marca
  result = Math.round(escalaInt * 30 / 1100)
  while ((Math.round(result / distance) * distance) === 0) {
    distance /= 2
  }
  result = Math.round(result / distance) * distance
  return Math.round(result / 10) * 10
}

function axisMarksFunction (axisMarks, axisCoords, axisMin, axisMax, height, width, x, escalaMark, topMargin, sideMargin) {
  let first = Math.ceil(axisMin / escalaMark) * escalaMark
  let last = Math.floor(axisMax / escalaMark) * escalaMark
  let axisDistance = axisMax - axisMin
  let position = 0
  let markDistance = 0

  // Para ajustar las alturas y los lados de las axisCoords hay que guiarse por los sideMargin y
  // los topMargin, ya que aqui debo aplicar de nuevo la posicion absoluta
  if (x === 0) { // ejes X
    let aux = 0
    for (let i = first; i <= last; i += escalaMark) {
      position = ((i - axisMin) * width) / axisDistance
      markDistance = position - aux
      aux = position
      axisMarks.push({ type: 'line', x1: position, y1: -3, x2: position, y2: 0, lineWidth: 1 })
      axisMarks.push({ type: 'line', x1: position, y1: height, x2: position, y2: height + 3, lineWidth: 1 })

      // x: Se le suma -> sideMargin y se le restará el valor aproximado de la mitad de su longitud: texto.length * fontsize * 0.27
      // y: se le ha aplicado -> topMargin*3 - 3(la linea vertical) - 4(espacio en blanco) - 9 (altura aprox de la etiqueta)
      axisCoords.push({ text: i, absolutePosition: { x: position + sideMargin - (i.toString().length * 6 * 0.28), y: topMargin - 3 - 4 - 6 }, fontSize: 6 })
      // x: Se le suma -> sideMargin*margenVariable: 50 y se le restará el valor aproximado de la mitad de su longitud texto.length * fontsize * 0.25
      // y: se le ha aplicado -> topMargin*3 + 3(la linea vertical) + 4(un espacio en blanco)
      axisCoords.push({ text: i, absolutePosition: { x: position + sideMargin - (i.toString().length * 6 * 0.28), y: height + topMargin + 3 + 4 }, fontSize: 6 })
    }
  } else { // ejes Y
    for (let i = first; i <= last; i += escalaMark) {
      position = ((axisMax - i) * height) / axisDistance
      axisMarks.push({ type: 'line', x1: 0, y1: position, x2: -3, y2: position, lineWidth: 1 })
      axisMarks.push({ type: 'line', x1: width, y1: position, x2: width + 3, y2: position, lineWidth: 1 })

      // x: Se le suma -> sideMargin*margenVariable: 50 - 3 (linea vertical) - 7 (anchura de la imagen) - 4 (espacio en blanco)
      // y: Se le suma -> 10(topMargin) - 15 (el valor que cuadra para que quede centrado)
      axisCoords.push({ image: writeRotatedText(i), fit: [50, 50], absolutePosition: { x: (sideMargin) - 3 - 7 - 2, y: position - 17 } })
      // x: Se le suma -> width + sideMargin*margenVariable: 50 + 3 (linea vertical) + 4 (espacio en blanco)
      // y: Se le suma -> 10(topMargin) - 15 (el valor que cuadra para que quede centrado)
      axisCoords.push({ image: writeRotatedText(i), fit: [50, 50], absolutePosition: { x: width + (sideMargin) + 3, y: position - 17 } })
    }
  }
  return markDistance
}

function writeRotatedText (text) {
  // Este metodo solo rota 90º el texto recibido y lo convierte a imagen
  let ctx
  let canvas = document.createElement('canvas')

  canvas.width = 36
  canvas.height = 270
  ctx = canvas.getContext('2d')
  ctx.font = '22pt Arial'
  ctx.save()
  ctx.translate(36, 270)
  ctx.rotate(-0.5 * Math.PI)
  ctx.fillStyle = '#000'
  ctx.fillText(text, 0, 0)
  ctx.restore()
  return canvas.toDataURL()
}
// #endregion

// #region Funciones informeContentData
/**
 * Funciones Col1
 */
function normalizarTexto (text) {
  // Metodo que mantiene siempre 2 lineas en las tablas
  if (text.length <= 27) {
    text = text + '\n '
  }

  return text.toUpperCase()
}

/**
 * Funciones Col2
 */
const rotateBrujula = async (is3D) => {
  if (is3D) {
    const mapView = await ArcGIS.getMapView()
    /*
    //Esto  es para solucionar el problema de las x y las y, cuando se gira el mapa no son verticales, pueden ser horizontales
    const rotationMap3D = await ArcGIS.rotationMap3D()

    let response1 = ArcGIS.convertirETRS89(rotationMap3D.x[0], rotationMap3D.y[1])
    let response2 = ArcGIS.convertirETRS89(rotationMap3D.x[1], rotationMap3D.y[0])
    let x = [response1[0], response2[0]]
    let y = [response2[1], response1[1]]
    let measureETRS89 = { x, y }
    console.log(measureETRS89) */
    return canvasBrujula(mapView.viewpoint.rotation)
  } else {
    return canvasBrujula(0)
  }
}

function canvasBrujula (grados) {
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')

  // Ajustar el tamaño del canvas
  canvas.width = 60
  canvas.height = 60

  // Convertir grados a radianes
  let radianes = grados * Math.PI / 180

  // Guardar el contexto actual antes de aplicar la rotación
  ctx.save()

  // Mover el origen al centro del canvas
  ctx.translate(canvas.width / 2, canvas.height / 2)

  // Rotar el canvas
  ctx.rotate(radianes)

  // Dibujar la flecha estilo brújula más ancha y centrada con pico en la parte inferior
  ctx.beginPath()
  ctx.moveTo(0, -20) // Punto superior de la flecha (centrado horizontalmente)
  ctx.lineTo(-15, 20) // Punto inferior izquierdo
  ctx.lineTo(0, 10) // Punto de inicio del pico
  ctx.lineTo(15, 20) // Punto inferior derecho
  ctx.closePath()

  // Rellenar la mitad izquierda de la flecha
  ctx.save()
  ctx.clip()
  ctx.fillStyle = 'white'
  ctx.fill()

  // Rellenar la mitad derecha de la flecha
  ctx.restore()
  ctx.beginPath()
  ctx.moveTo(0, -20) // Punto superior de la flecha (centrado horizontalmente)
  ctx.lineTo(-15, 20) // Punto inferior izquierdo
  ctx.lineTo(0, 10) // Punto de inicio del pico
  ctx.lineTo(15, 20) // Punto inferior derecho
  ctx.closePath()

  ctx.save()
  ctx.clip()
  ctx.fillStyle = 'black'
  ctx.fillRect(0, -30, 30, 60) // Rellenar la mitad derecha del canvas con negro

  // Restaurar el contexto original para finalizar la rotación
  ctx.restore()

  ctx.strokeStyle = 'black'
  ctx.lineWidth = 2
  ctx.stroke()

  return canvas.toDataURL()
}

/**
 * Funciones Col3
 */
async function leyendaItems (featuresLayerItems, graphicsLayerItems) {
  let items = []
  const addedItems = new Set()

  for (const feature of featuresLayerItems) {
    let datos = {
      layer: 'features',
      icon: await getImageBase64('/icons/' + feature.icon),
      tipo: feature.TIPO.replaceAll('_', ' ')
    }
    // Generar una clave única basada en icon y TIPO
    const key = `${feature.icon}_${feature.TIPO}`
    // Verificar si el item ya ha sido agregado antes
    if (!addedItems.has(key)) {
    // Agregar datos al arreglo items y al conjunto addedItems
      items.push(datos)
      addedItems.add(key)
    }
  }

  for (const [index, graphic] of graphicsLayerItems.entries()) {
    if (index === 0) { // Perimetros
      for (const perimetro of graphic) {
        perimetro.icon = await getImageBase64(require(`@/assets/new_simbologia/${perimetro.icon}`))// lineaPerimetro(perimetro.color)
        perimetro.icon = await lineaPerimetro(perimetro.icon, perimetro.color)
        const key = `${perimetro.color}_${perimetro.tipo}`
        if (!addedItems.has(key)) {
          items.push(perimetro)
          addedItems.add(key)
        }
      }
    } else if (index === 1) { // Planes operacionales
      for (const plan of graphic) {
        if (plan.plan === 'polygon' || plan.plan === 'polyline') {
          plan.icon = await getImageBase64(require(`@/assets/new_simbologia/${plan.icon}`))
        } else {
          plan.icon = await getImageBase64(plan.icon)
        }

        const key = `${plan.tipo}`
        if (!addedItems.has(key)) {
          items.push(plan)
          addedItems.add(key)
        }
      }
    }
  }

  return items
}

export const getImageBase64 = async (imageUrl) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = reject
    img.src = imageUrl
  })
}

function createTable (leyenda, leyendaFontSize) {
  const maxItemsPerColumn = 9 // Máximo número de ítems por columna
  const numColumns = 3// Math.ceil(leyenda.length / maxItemsPerColumn) // Número de columnas
  const totalCells = numColumns * maxItemsPerColumn

  // Crear un array de celdas con ítems o espacios vacíos si faltan
  const cells = Array.from({ length: totalCells }, (_, index) => {
    const item = leyenda[index]
    if (item) {
      return [
        {
          image: item.icon,
          width: 15,
          height: 15,
          margin: [0, 0, 0, 0]
        },
        {
          text: item.tipo,
          alignment: 'left',
          fontSize: leyendaFontSize,
          margin: [0, 0, 0, 0]
        }
      ]
    } else {
      return [{}, {}] // Espacios vacíos si no hay datos en esta celda
    }
  })

  // Dividir las celdas en filas
  const rows = splitArray(cells, numColumns).map(row =>
    row.flat()
  )

  // Crear los anchos de columnas alternados (16 para impares y '*' para pares)
  const widths = Array.from({ length: numColumns * 2 }, (_, index) =>
    index % 2 === 0 ? 9 : 'auto'
  )

  return {
    table: {
      widths: widths,
      body: rows
    },
    layout: 'noBorders',
    alignment: 'center'
  }
}

export const lineaPerimetro = async (imageUrl, newColor) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = function () {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height

      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      // Obtener los datos de los píxeles de la imagen
      let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)
      let data = imageData.data

      // Cambiar el color de todos los píxeles
      for (let i = 0; i < data.length; i += 4) {
        data[i] = newColor[0] // Rojo
        data[i + 1] = newColor[1]// Verde
        data[i + 2] = newColor[2] // Azul
      }

      // Poner los datos de vuelta en el canvas
      ctx.putImageData(imageData, 0, 0)

      const dataURL = canvas.toDataURL('image/png')
      resolve(dataURL)
    }
    img.onerror = reject
    img.src = imageUrl
  })
}

function splitArray (array, size) {
  const result = []
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size))
  }
  return result
}

function canvasEscala (width) {
  let canvas = document.createElement('canvas')
  let ctx = canvas.getContext('2d')

  // Ajustar el tamaño del canvas
  canvas.width = width + (width * 0.2) + 30
  canvas.height = 5

  // Calcular las dimensiones y posición del rectángulo
  const rectWidth = width + (width * 0.2)
  const rectHeight = 5
  const rectX = 15
  const rectY = 0

  // Dibujar el borde del rectángulo
  ctx.strokeStyle = 'black' // Color del borde
  ctx.lineWidth = 1 // Grosor del borde
  ctx.strokeRect(rectX, rectY, rectWidth, rectHeight)

  // Dibujar las líneas verticales para dividir el rectángulo en 3 partes iguales
  const segmentWidth = rectWidth / 3

  // Rellenar de negro los segmentos 1 y 3
  ctx.fillStyle = 'black' // Color de relleno
  ctx.fillRect(rectX, rectY, segmentWidth, rectHeight) // Rellenar el primer segmento
  ctx.fillRect(rectX + (2 * segmentWidth), rectY, segmentWidth, rectHeight) // Rellenar el tercer segmento

  return canvas.toDataURL()
}
// #endregion
