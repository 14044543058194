var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-snackbar',{attrs:{"bottom":"","timeout":3000,"color":"error"},model:{value:(_vm.showSnackBarError),callback:function ($$v) {_vm.showSnackBarError=$$v},expression:"showSnackBarError"}},[_vm._v(" Este sector tiene medios asignados ")]),_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Sectores")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"secondary","bottom":"","right":"","absolute":""},on:{"click":function($event){_vm.showDialogAdd = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Añadir sector")])])],1),_c('v-card-text',[_c('v-data-table',{staticClass:"pt-2",attrs:{"headers":_vm.headers,"items":_vm.editSectores,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.SECTOR",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.SECTOR)+" ")]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":item.SECTOR === 'GENERAL'},on:{"click":function($event){return _vm.deleteSector(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"400"},model:{value:(_vm.showDialogAdd),callback:function ($$v) {_vm.showDialogAdd=$$v},expression:"showDialogAdd"}},[_c('v-card',[_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Nuevo sector")])],1)],1),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addSector.apply(null, arguments)}},model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-text-field',{attrs:{"label":"Sector","hide-details":"","outlined":"","rules":[_vm.globalRules.required]},model:{value:(_vm.newSector.SECTOR),callback:function ($$v) {_vm.$set(_vm.newSector, "SECTOR", $$v)},expression:"newSector.SECTOR"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cancelAdd}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.isValid},on:{"click":_vm.addSector}},[_vm._v("Aceptar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }