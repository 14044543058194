<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Nivel del incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="valid">
          <v-container fluid v-if="dataModified">
            <!-- Aviso -->
            <v-row class="pt-2">
              <v-col>
                <v-select
                  :items="tiposIncendioNivel" disabled item-text="INCENDIO_NIVEL"
                  v-model="dataModified.TIPO_INCENDIO_NIVEL.INCENDIO_NIVEL" label="Nivel del incendio" outlined hide-details dense
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <vx-date-text-edit
                  v-model="dataModified.FECHA_INICIO" :min="fechaInicioMin" :max="fechaInicioMax" @focus="setCurrentDate(0)"
                  label="Fecha de inicio" not-future outlined required dense
                />
              </v-col>

              <v-col sm="6">
                <vx-date-text-edit
                  v-model="dataModified.FECHA_FIN" :min="fechaFinMin" :max="fechaFinMax" @focus="setCurrentDate(1)" :between="fechaBetween"
                  label="Fecha de fin" not-future outlined dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form> <!-- Llegada -->
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="close">Cancelar</v-btn>
        <v-btn text color="green" :disabled="acceptDisable" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import { mapGetters } from 'vuex'
export default {
  props: {
    show: Boolean,
    idIncendio: String,
    dataNivel: Object
  },

  data: () => ({
    dataModified: null,
    valid: true,
    nivelesPosibles: ['Nivel 1', 'Nivel 2'],
    estadoIncendio: null,
    mask: '##/##/#### ##:##'

  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },
    ...mapGetters('incendio', [
      'tiposIncendioNivel'
    ]),
    fechaFinMax () {
      let fechaMin = null
      if (this.incendio && this.dataModified && this.dataNivel) {
        if (this.dataModified.index !== 0) {
          fechaMin = this.$date.parseDate(this.incendio.NIVELES[this.dataNivel.index - 1].FECHA_INICIO)
        }
      }
      return fechaMin
    },
    fechaInicioMax () {
      let fechaMin = null
      if (this.incendio && this.dataModified && this.dataNivel) {
        if (this.dataModified.index !== 0) {
          fechaMin = this.dataModified.FECHA_FIN
            ? this.$date
              .parseDate(this.dataModified.FECHA_FIN)
              .add(-1, 'minute')
            : this.$date
              .parseDate(this.incendio.NIVELES[this.dataNivel.index - 1].FECHA_INICIO)
              .add(-1, 'minute')
        }
      }
      return fechaMin
    },
    fechaInicioMin () {
      let fechaMin = null

      if (this.incendio && this.dataNivel) {
        if (this.incendio.NIVELES.length === 0 || this.incendio.NIVELES.length === this.dataModified.index + 1) {
          fechaMin = this.$date.parseDate(this.incendio.ESTADOS[this.incendio.ESTADOS.length - 1].FECHA)
        } else {
          fechaMin = this.$date.parseDate(this.incendio.NIVELES[this.dataNivel.index + 1].FECHA_FIN)
        }
      }

      return fechaMin
    },
    fechaFinMin () {
      let fechaMin = null
      let fechaInicio = null

      if (this.incendio && this.dataModified && this.dataNivel) {
        fechaInicio = this.$date.parseDate(this.dataModified.FECHA_INICIO)
        fechaMin = fechaInicio.add(1, 'minute')
      }
      return fechaMin
    },

    fechaBetween () {
      let fechaBetween = null

      if (this.dataModified.index !== 0) {
        fechaBetween = this.$date.parseDate(this.incendio.NIVELES[this.dataNivel.index - 1].FECHA_INICIO)
      }

      return fechaBetween
    },

    acceptDisable () {
      return (!this.valid)
    }

  },

  watch: {
    show (value) {
      if (value && this.dataModified) {
        this.dataModified = null
      }
      if (value && this.dataNivel) {
        this.dataModified = this.dataNivel
        this.dataModified.FECHA_INICIO = this.$date.formatDate(this.dataNivel.FECHA_INICIO, 'DD/MM/YYYY HH:mm')
        this.dataModified.FECHA_FIN = this.$date.formatDate(this.dataNivel.FECHA_FIN, 'DD/MM/YYYY HH:mm')
      }
    },

    dataModified () {
      if (this.dataModified && this.dataModified.FECHA_INICIO && this.dataModified.FECHA_INICIO === this.mask.length) {
        this.fechaInicioMax()
      }
      if (this.dataModified && this.dataModified.FECHA_FIN && this.dataModified.FECHA_FIN === this.mask.length) {
        this.fechaFinMin()
      }
    }
  },

  methods: {
    resetData () {
      this.dataModified = null
    },
    setCurrentDate (pos) {
      const currentDate = this.$date.currentDate()
      if (pos === 0 && (!this.dataModified.FECHA_INICIO || this.dataModified.FECHA_INICIO === '')) {
        this.dataModified.FECHA_INICIO = currentDate
      } else if (pos === 1 && (!this.dataModified.FECHA_FIN || this.dataModified.FECHA_FIN === '')) {
        this.dataModified.FECHA_FIN = currentDate
      }
    },

    close () {
      this.resetData()
      this.$emit('close')
    },

    aceptar () {
      let nivelIncendio = this.incendio.NIVELES.find(x => x.ID_INCENDIO_NIVEL === this.dataModified.ID_INCENDIO_NIVEL)

      if (nivelIncendio) {
        let data = {
          dataEstado: {
            ID_INCENDIO: this.idIncendio,
            ID_TIPO_INCENDIO_NIVEL: nivelIncendio.ID_TIPO_INCENDIO_NIVEL,
            ID_INCENDIO_NIVEL: this.dataModified.ID_INCENDIO_NIVEL,
            FECHA_INICIO: this.$date.parseDate(this.dataModified.FECHA_INICIO),
            FECHA_FIN: this.$date.parseDate(this.dataModified.FECHA_FIN)
          }

        }
        this.$store.dispatch('incendio/editNivelIncendio', data)
        this.resetData()
        this.$emit('redrawIncendio')
        this.$emit('close')
      }
    }
  }
}
</script>

<style scoped>

</style>
