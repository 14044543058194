// Fuego Tecnico
export const fuegoTecnico = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectWave',
              amplitude: 5,
              period: 5,
              waveform: 'Triangle'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Square',
          joinStyle: 'Round',
          width: 1,
          color: [255, 255, 255, 255]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: false,
          width: 2.5,
          color: [255, 0, 0, 255]
        }
      ]
    }
  }
}

// Sector
export const sector = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  // Arriba
                  [
                    [-1, 5],
                    [3, 5]
                  ],
                  // Abajo
                  [
                    [-1, -5],
                    [3, -5]
                  ],
                  // Lateral
                  [
                    [3, 5],
                    [3, -5]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustBegin',
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  // Arriba
                  [
                    [-1, 5],
                    [3, 5]
                  ],
                  // Abajo
                  [
                    [-1, -5],
                    [3, -5]
                  ],
                  // Lateral
                  [
                    [3, 5],
                    [3, -5]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [255, 0, 0, 255]
        }
      ]
    }
  }
}

// Infra Preventiva
export const infraPreventiva = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            endings: 'WithMarkers',
            placementTemplate: [12], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, 8],
                    [3, 0]
                  ],
                  [
                    [-8, -8],
                    [3, 0]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Linea Manual
export const lineaManual = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            endings: 'WithMarkers',
            placementTemplate: [10], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -8],
                    [0, 8],
                    [3, -8],
                    [-8, -8]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Ataque Directo:
export const ataqueDirecto = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustBegin', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 5],
                    [0, -5]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [10, 10, 10, 255]
        }
      ]
    }
  }
}

// Via Comunicacion
export const viaComunicacion = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: 5,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        // LINEA ROJA
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [10, 10, 10, 255]
        }
      ]
    }
  }
}

// Viento General
export const vientoGeneral = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [0, 176, 240, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustBegin', // JustBegin |JustEnd
            angleToLine: false
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-2, 10],
                    [0, 4]
                  ],
                  [
                    [2, 10],
                    [0, 4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [0, 176, 240, 255]
                  }
                ]
              }
            }
          ]
        },
        // LINEA ROJA
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 3.5,
          color: [0, 176, 240, 255]
        }
      ]
    }
  }
}
// Linea Mecanizada
export const lineaMecanizada = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: 5,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: -5,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        // LINEA ROJA
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 5,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            // endings: 'WithMarkers',
            placementTemplate: [19.5], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-10, 8],
                    [3, -8]
                  ],
                  [
                    [3, 8],
                    [-10, -8]
                  ],
                  [
                    [4, 8],
                    [15, -8]
                  ],
                  [
                    [4, -8],
                    [15, 8]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -8],
                    [5, 0]
                  ],
                  [
                    [-8, 8],
                    [5, 0]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Eje principal de propagacion
export const ejePrincipalProp = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: 5,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [255, 10, 10, 255]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: -5,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [255, 10, 10, 255]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, -8],
                    [13, 0]
                  ],
                  [
                    [0, 8],
                    [13, 0]
                  ],
                  [
                    [0, 8],
                    [0, 4]
                  ],
                  [
                    [0, -8],
                    [0, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Zona critica poligono
export const zonaCriticaPolygon = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPolygonSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          size: 500,
          markerPlacement: {
            type: 'CIMMarkerPlacementInsidePolygon',
            // gridType: 'Fixed',
            stepX: 50,
            stepY: 75,
            clipping: 'ClipAtBoundary'
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-25, 32],
                    [0, -32]
                  ],
                  [
                    [25, 32],
                    [0, -32]
                  ]
                ]
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    width: 2,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        },
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectWave',
              amplitude: 5,
              period: 5,
              waveform: 'Triangle'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Square',
          joinStyle: 'Round',
          width: 1,
          color: [255, 255, 255, 255]
        }
      ]
    }
  }
}

// Zona critica poligono
export const zonaOportunidadPolygon = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPolygonSymbol',
      symbolLayers: [
        {
          type: 'CIMVectorMarker',
          enable: true,
          size: 500,
          markerPlacement: {
            type: 'CIMMarkerPlacementInsidePolygon',
            // gridType: 'Fixed',
            stepX: 50,
            stepY: 55,
            clipping: 'ClipAtBoundary'
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-25, 32],
                    [25, -32]
                  ],
                  [
                    [25, 32],
                    [-25, -32]
                  ]
                ]
              },
              symbol: {
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke',
                    enable: true,
                    width: 2,
                    color: [10, 255, 10, 255]
                  }
                ]
              }
            }
          ],
          scaleSymbolsProportionally: true,
          respectFrame: true
        },
        {
          type: 'CIMSolidStroke',
          enable: true,
          width: 2,
          color: [10, 10, 10, 255]
        }
      ]
    }
  }
}

// Perimetro estabilizado poligono
export const perimetroEstabilizadoPolygon = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPolygonSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            endings: 'WithMarkers',
            placementTemplate: [12], // determines space between each arrow
            angleToLine: true, // symbol will maintain its angle to the line when map is rotated
            clipping: 'ClipAtBoundary'
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 8],
                    [0, -8]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          type: 'CIMSolidFill',
          enable: true
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [255, 10, 10, 255]
        }
      ]
    }
  }
}

// Perimetro activo poligono
export const perimetroActivoPolygon = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPolygonSymbol',
      symbolLayers: [
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [255, 10, 10, 255]
        },
        {
          type: 'CIMSolidFill',
          enable: true
        }
      ]
    }
  }
}

// Perimetro activo poligono
export const perimetroPotencialPolygon = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMPolygonSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectDashes',
              dashTemplate: [12, 5], // [ width, space, ..., width, space ]
              lineDashEnding: 'NoConstraint',
              controlPointEnding: 'NoConstraint'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Butt',
          joinStyle: 'Round',
          width: 2.5,
          color: [255, 0, 0, 255]
        },
        {
          type: 'CIMSolidFill',
          enable: true
        }
      ]
    }
  }
}

// Zona critica linea
export const zonaCriticaLinea = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            placementTemplate: [50], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-25, 32],
                    [0, -32]
                  ],
                  [
                    [25, 32],
                    [0, -32]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: 35,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: -35,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd' // JustBegin |JustEnd
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 20],
                    [0, -20]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustBegin' // JustBegin |JustEnd
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 20],
                    [0, -20]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Zona oportunidad linea
export const zonaOportunidadLinea = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            placementTemplate: [50], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-25, 32],
                    [25, -32]
                  ],
                  [
                    [25, 32],
                    [-25, -32]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [10, 255, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: 35,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectOffset',
              offset: -35,
              method: 'Rounded',
              option: 'Fast'
            }
          ],
          enable: 'true',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd' // JustBegin |JustEnd
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 20],
                    [0, -20]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustBegin' // JustBegin |JustEnd
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 20],
                    [0, -20]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Carrera principal
export const carreraPrincipal = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 20,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 6.5,
          color: [255, 10, 10, 255]
        }
      ]
    }
  }
}

// Carrera secundaria
export const carreraSecundaria = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 3,
          color: [255, 10, 10, 255]
        }
      ]
    }
  }
}

// Realizado
export const realizado = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [10, 10, 10, 255]
        }
      ]
    }
  }
}

// Previsto
export const previsto = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectDashes',
              dashTemplate: [10, 5], // [ width, space, ..., width, space ]
              lineDashEnding: 'NoConstraint',
              controlPointEnding: 'NoConstraint'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Butt',
          joinStyle: 'Round',
          width: 2.5,
          color: [10, 10, 10, 255]
        },
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAtExtremities',
            // https://github.com/Esri/cim-spec/blob/master/docs/v2/CIMSymbols.md#enumeration-extremityplacement
            extremityPlacement: 'JustEnd', // JustBegin |JustEnd
            angleToLine: true
          },
          frame: {
            xmin: -3,
            ymin: -3,
            xmax: 3,
            ymax: 3
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-8, -4],
                    [-8, 4],
                    [2, 0],
                    [-8, -4]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidFill', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Cresta
export const cresta = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectDashes',
              dashTemplate: [10, 5, 2, 5], // [ width, space, ..., width, space ]
              lineDashEnding: 'NoConstraint',
              controlPointEnding: 'NoConstraint'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Butt',
          joinStyle: 'Round',
          width: 2.5,
          color: [10, 10, 10, 255]
        }
      ]
    }
  }
}

// Linea electrica
export const lineaElectrica = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            endings: 'WithMarkers',
            placementTemplate: [20], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [-15, 0],
                    [-7, 0]
                  ],
                  [
                    [-2, 2],
                    [2, -2]
                  ],
                  [
                    [-2, -2],
                    [2, 2]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2,
                    color: [10, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        }
      ]
    }
  }
}

// Vaguadas
export const vaguadas = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectDashes',
              dashTemplate: [12, 5], // [ width, space, ..., width, space ]
              lineDashEnding: 'NoConstraint',
              controlPointEnding: 'NoConstraint'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Butt',
          joinStyle: 'Round',
          width: 2.5,
          color: [0, 176, 240, 255]
        }
      ]
    }
  }
}

// Perimetro potencial
export const perimetroPotencial = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // white dashed layer at center of the line
          type: 'CIMSolidStroke',
          effects: [
            {
              type: 'CIMGeometricEffectDashes',
              dashTemplate: [12, 5], // [ width, space, ..., width, space ]
              lineDashEnding: 'NoConstraint',
              controlPointEnding: 'NoConstraint'
            }
          ],
          enable: true, // must be set to true in order for the symbol layer to be visible
          capStyle: 'Butt',
          joinStyle: 'Round',
          width: 2.5,
          color: [255, 0, 0, 255]
        }
      ]
    }
  }
}

// Perimetro activo
export const perimetroActivo = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [255, 10, 10, 255]
        }
      ]
    }
  }
}

// Perimetro estabilizado
export const perimetroEstabilizado = {
  type: 'cim',
  // CIM Line Symbol
  data: {
    type: 'CIMSymbolReference',
    symbol: {
      type: 'CIMLineSymbol',
      symbolLayers: [
        {
          // arrow symbol
          type: 'CIMVectorMarker',
          enable: true,
          size: 10,
          markerPlacement: {
            type: 'CIMMarkerPlacementAlongLineSameSize', // places same size markers along the line
            endings: 'WithMarkers',
            placementTemplate: [12], // determines space between each arrow
            angleToLine: true // symbol will maintain its angle to the line when map is rotated
          },
          frame: {
            xmin: -5,
            ymin: -5,
            xmax: 5,
            ymax: 5
          },
          markerGraphics: [
            {
              type: 'CIMMarkerGraphic',
              geometry: {
                rings: [
                  [
                    [0, 8],
                    [0, -8]
                  ]
                ]
              },
              symbol: {
                // black fill for the arrow symbol
                type: 'CIMPolygonSymbol',
                symbolLayers: [
                  {
                    type: 'CIMSolidStroke', // CIMSolidFill | CIMSolidStroke
                    enable: true,
                    width: 2.5,
                    color: [255, 10, 10, 255]
                  }
                ]
              }
            }
          ]
        },
        {
          // lighter red line layer
          type: 'CIMSolidStroke',
          enable: true,
          width: 2.5,
          color: [255, 10, 10, 255]
        }
      ]
    }
  }
}
