<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Ajustes</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pt-2">
        <v-form>
          <v-expansion-panels focusable>
            <!-- Track de medios -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                Tracks
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="maxPositions" label="Máx. posiciones representadas" type="number" :rules="[rules.min]" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="timeBackHours" label="Horas a pasado representadas" type="number" :rules="[rules.min, rules.maxHoras]" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="timeDiferenceTrackMinutes" label="Diferencia entre tracks (min)" type="number" :rules="[rules.min]" />
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Planificacion MMTT -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                Planificación MMTT
              </v-expansion-panel-header>

              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="tiempoActuacion" label="Tiempo general de actuación" type="number" :rules="[rules.min]" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field v-model="tiempoDescanso" label="Tiempo general de descanso" type="number" :rules="[rules.min]" />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn text color="green" @click="changePlanMMTT" :disabled="!planMMTTConfirm">Confirmar</v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-container>
              <v-row>
                <v-col>
                  <v-switch v-model="verMultiplesDispositivos" label="Últ posición dispositivo/s" color="primary" @change="changeVisibilidadDispositivos" />
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col>
                  <v-switch v-model="verLineas" label="Lineas medios a incendio" color="primary" @change="changeVerLineas" />
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panels>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import constants from '../../helpers/constants'
import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    rules: {
      min: value => value >= 1 || 'Mínimo 1',
      maxHoras: value => value <= 168 || 'Máximo 7 días (168h)'
    },

    maxPositions: constants.track.maxPositions,
    timeBackHours: constants.track.timeBackHours,
    timeDiferenceTrackMinutes: constants.track.timeDiferenceTrackMinutes,
    verMultiplesDispositivos: constants.verMultiplesDispositivos,

    tiempoActuacion: '17',
    tiempoDescanso: '12',
    planMMTTConfirm: false,

    verLineas: true
  }),

  watch: {
    show () {
      if (this.show) {
        this.maxPositions = constants.track.maxPositions
        this.timeBackHours = constants.track.timeBackHours
        this.timeDiferenceTrackMinutes = constants.track.timeDiferenceTrackMinutes
        this.verMultiplesDispositivos = constants.verMultiplesDispositivos

        this.tiempoActuacion = this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION')
        this.tiempoDescanso = this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
        this.planMMTTConfirm = this.tiempoActuacion !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION') || this.tiempoDescanso !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
      }
    },

    tiempoActuacion () {
      this.planMMTTConfirm = this.tiempoActuacion !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION') || this.tiempoDescanso !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
    },

    tiempoDescanso () {
      this.planMMTTConfirm = this.tiempoActuacion !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_ACTUACION') || this.tiempoDescanso !== this.$store.getters['parametro/getParametro']('DEFAULT_TIEMPO_DESCANSO')
    }
  },

  methods: {
    cerrar () {
      if (this.maxPositions > 0) {
        constants.track.maxPositions = this.maxPositions
      }

      if (this.timeBackHours > 0 && this.timeBackHours < 169) {
        constants.track.timeBackHours = this.timeBackHours
      }

      if (this.timeDiferenceTrackMinutes > 0) {
        constants.track.timeDiferenceTrackMinutes = this.timeDiferenceTrackMinutes
      }

      constants.verMultiplesDispositivos = this.verMultiplesDispositivos

      this.$emit('cerrar')
    },

    changeVisibilidadDispositivos () {
      constants.verMultiplesDispositivos = this.verMultiplesDispositivos
      ArcGIS.redrawMedios(true, true)
    },

    changeVerLineas () {
      ArcGIS.drawLineas(this.verLineas)
      ArcGIS.redrawMedios(true, true)
    },

    changePlanMMTT () {
      let data = {
        key: 'DEFAULT_TIEMPO_ACTUACION',
        value: this.tiempoActuacion
      }

      this.$store.dispatch('parametro/changeParametro', data)

      data = {
        key: 'DEFAULT_TIEMPO_DESCANSO',
        value: this.tiempoDescanso
      }

      this.$store.dispatch('parametro/changeParametro', data)
    }

  }
}
</script>

<style scoped>
</style>
