import request from '../request'

export default {
  getImagenesSatelite: function (data) {
    return request.get(`satelite_imagen/${data.ID_INCENDIO}`)
  },
  getImagenSateliteURL: function (data) {
    return `${request.defaults.baseURL}/satelite_imagen/${data.API}/${data.ID_INCENDIO}/${data.ESTADO}/${data.NOMBRE_FICHERO}`
  },
  getImagenSatelite: function (data) {
    return request.get(`satelite_imagen/${data.API}/${data.ID_INCENDIO}/${data.ESTADO}/${data.NOMBRE_FICHERO}`)
  }
}
