var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Editar sectores")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"pa-8"},[_c('v-data-table',{staticStyle:{"direction":"rtl"},attrs:{"headers":_vm.headersLeft,"items":_vm.mediosLeftTable,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"540px"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("No hay medios asignados.")])]},proxy:true},{key:"top",fn:function(){return [_c('v-select',{attrs:{"items":_vm.sectores.filter(function (x) { return x.ID_SECTOR !== _vm.rightSector; }),"prepend-icon":"esri-icon-description","label":"Sector","item-text":"SECTOR","item-value":"ID_SECTOR","single-line":"","autocomplete":""},model:{value:(_vm.leftSector),callback:function ($$v) {_vm.leftSector=$$v},expression:"leftSector"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:(_vm.rowColor(item))},[_c('td',{attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.leftSector === _vm.rightSector || !_vm.rightSector,"fab":"","icon":"","small":""},on:{"click":function($event){return _vm.editMedio(item, 'left')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Cambiar de sector")])])],1),_c('td',{attrs:{"align":"left"}},[_vm._v(" "+_vm._s(item.MEDIO)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMedioFueraHorario(item.ID_MEDIO)),expression:"isMedioFueraHorario(item.ID_MEDIO)"}],staticClass:"font-weight-black"},[_vm._v("*")])])])]}}])})],1),_c('v-col',{staticClass:"pa-8"},[_c('v-data-table',{attrs:{"headers":_vm.headersRight,"items":_vm.mediosRightTable,"disable-pagination":"","hide-default-footer":"","fixed-header":"","height":"540px"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("No hay medios asignados.")])]},proxy:true},{key:"top",fn:function(){return [_c('v-select',{attrs:{"items":_vm.sectores.filter(function (x) { return x.ID_SECTOR !== _vm.leftSector; }),"prepend-icon":"esri-icon-description","label":"Sector","item-text":"SECTOR","item-value":"ID_SECTOR","single-line":"","autocomplete":""},model:{value:(_vm.rightSector),callback:function ($$v) {_vm.rightSector=$$v},expression:"rightSector"}})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{style:(_vm.rowColor(item))},[_c('td',{attrs:{"align":"left"}},[_c('v-layout',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.leftSector === _vm.rightSector || !_vm.leftSector,"fab":"","icon":"","small":""},on:{"click":function($event){return _vm.editMedio(item, 'right')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v("mdi-arrow-left")])],1)]}}],null,true)},[_c('span',[_vm._v("Cambiar de sector")])])],1)],1),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(item.MEDIO)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMedioFueraHorario(item.ID_MEDIO)),expression:"isMedioFueraHorario(item.ID_MEDIO)"}],staticClass:"font-weight-black"},[_vm._v("*")])])])]}}])})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }