import api from '@/api'

let Q = require('q')

const state = {
  organismos: []
}

const getters = {
  organismos: (state) => {
    return state.organismos
  }
}

const mutations = {
  SET_ORGANISMOS (state, organismos) {
    state.organismos = organismos
  }
}

const actions = {
  async fetchOrganismos ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.organismo.getOrganismos()
      commit('SET_ORGANISMOS', response.data)

      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }

    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
