// import store from '@/store/index'
import constants from './constants'
import Vue from '../main'
import * as ArcGIS from './ArcGIS'

let Q = require('q')

// var municipioRequest = null

export const getPointData = async (x, y) => {
  let deferred = Q.defer()
  let servicioMunicio = constants.servicios.consultaMunicipios
  // let USER_COD_CCAA = parseInt(constants.comunidad.CODIGO_INE_CCAA)
  // let USER_COD_PROV = parseInt(constants.comunidad.CODIGO_INE_PROVINCIA)

  servicioMunicio = servicioMunicio.replace('/**Point_X**/', x)
  servicioMunicio = servicioMunicio.replace('/**Point_Y**/', y)

  try {
    let response = await Vue.$http.get(servicioMunicio /* , {
      before (request) {
        if (municipioRequest) {
          municipioRequest.abort()
        }
        municipioRequest = request
      }
    } */)

    let features = response.data.features

    if (features && features[0]) {
      let xyH30 = ArcGIS.convertirETRS89(x, y)
      let latLon = ArcGIS.convertirETRS89ToLatLon(xyH30[0], xyH30[1])
      let lat = latLon[1].toFixed(5)
      let lon = latLon[0].toFixed(5)

      let COD_CCAA = parseInt(features[0].attributes.Cod_CCAA)
      let COD_PROV = parseInt(features[0].attributes.COD_PROV)

      deferred.resolve({
        LATITUD: lat,
        LONGITUD: lon,
        X: Math.round(xyH30[0]),
        Y: Math.round(xyH30[1]),
        MUNICIPIO: features[0].attributes.NOMBRE,
        LOCALIDAD: features[0].attributes.NOMBRE,
        COD_PROV: COD_PROV,
        COD_CCAA: COD_CCAA
      })
    } else {
      deferred.reject()
    }
  } catch (err) {
    deferred.reject(err)
  }

  return deferred.promise
}
