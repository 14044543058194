var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-slide-y-reverse-transition',[_c('v-card',{attrs:{"id":"mapSlider","elevation":"6"}},[_c('v-card-text',[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('span',{staticClass:"font-weight-light black--text",attrs:{"id":"timer"}},[_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.timeString))]),_c('span',{staticClass:"mx-0",attrs:{"id":"fecha"}},[_vm._v(_vm._s(_vm.dateString))]),_c('span',{staticClass:"mx-2",attrs:{"id":"superficie"}},[_vm._v(_vm._s(_vm.superficie)+" ha")])])]),_c('v-col',{attrs:{"align":"right"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-3","small":"","dark":"","depressed":"","fab":""},on:{"click":_vm.centerSimulacion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-image-filter-center-focus")])],1)]}}])},[_c('span',[_vm._v("Centrar simulación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"red darken-3","small":"","dark":"","depressed":"","fab":""},on:{"click":_vm.stopSimulacion}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Cerrar simulación")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue darken-3","small":"","dark":"","depressed":"","fab":""},on:{"click":_vm.autoPlay}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v(_vm._s(_vm.isPlaying ? 'mdi-pause' : 'mdi-play'))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.isPlaying ? 'Pausar simulación' : 'Continuar simulación'))])])],1)],1),_c('v-row',[_c('v-col',[(_vm.simulacion && _vm.simulacion.VIENTOS)?_c('v-switch',{attrs:{"label":'Habilitar vientos'},model:{value:(_vm.vientos),callback:function ($$v) {_vm.vientos=$$v},expression:"vientos"}}):_vm._e()],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-btn-toggle',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.modoSimulacion),callback:function ($$v) {_vm.modoSimulacion=$$v},expression:"modoSimulacion"}},_vm._l((_vm.otrasSimulaciones),function(item){return _c('v-tooltip',{key:item.id,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.nombre))])])}),1)],1),_c('v-col',[_c('v-switch',{directives:[{name:"show",rawName:"v-show",value:(_vm.modoSimulacion === 0),expression:"modoSimulacion === 0"}],attrs:{"label":'Isocronas'},model:{value:(_vm.isocronas),callback:function ($$v) {_vm.isocronas=$$v},expression:"isocronas"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-slider',{directives:[{name:"show",rawName:"v-show",value:(_vm.modoSimulacion === 0 || (_vm.simulacion && _vm.simulacion.VIENTOS)),expression:"modoSimulacion === 0 || (simulacion && simulacion.VIENTOS)"}],attrs:{"color":"blue darken-3","always-dirty":"","step":_vm.step,"tick-size":"2","min":_vm.min,"max":_vm.max,"hide-details":""},model:{value:(_vm.sliderTime),callback:function ($$v) {_vm.sliderTime=$$v},expression:"sliderTime"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }