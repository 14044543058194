var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"650px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Geofencing")])],1),_c('v-card-text',[_c('v-select',{staticClass:"pt-4",attrs:{"items":_vm.incendios,"label":"Incendio","outlined":"","item-text":"LOCALIDAD","item-value":"ID_INCENDIO","return-object":"","rules":[_vm.globalRules.required]},model:{value:(_vm.incendioSelected),callback:function ($$v) {_vm.incendioSelected=$$v},expression:"incendioSelected"}}),_c('v-tabs',{attrs:{"color":"white","background-color":"primaryLight","dark":"","slider-color":"white"},model:{value:(_vm.tabActiva),callback:function ($$v) {_vm.tabActiva=$$v},expression:"tabActiva"}},[_vm._l((_vm.tabs),function(t){return _c('v-tab',{key:t,attrs:{"ripple":""}},[_vm._v(" "+_vm._s(t)+" ")])}),_c('v-tab-item',{key:"Aproximación"},[_c('v-data-table',{staticClass:"scroll",attrs:{"headers":_vm.headers,"items":_vm.mediosGeofencing.filter(function (x) { return x.GEOFENCING_ESTADO_TIPO.ORDEN === 1; }),"item-key":"ID_MEDIO","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.getMedioByID(item.ID_MEDIO).MEDIO))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.isMedioAsignadoIncendio(item.ID_MEDIO)),expression:"!isMedioAsignadoIncendio(item.ID_MEDIO)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.setAproximacionGeofencing(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock-time-four-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Asignar llegada")])])],1)])]}}])})],1),_c('v-tab-item',{key:"Entrada"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.mediosGeofencing.filter(function (x) { return x.GEOFENCING_ESTADO_TIPO.ORDEN === 2; }),"item-key":"ID_MEDIO","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(_vm.getMedioByID(item.ID_MEDIO).MEDIO))]),_c('td',[_vm._v(_vm._s(_vm._f("formatDate")(item.FECHA)))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(!_vm.hasMedioEntradaIncendio(item.ID_MEDIO)),expression:"!hasMedioEntradaIncendio(item.ID_MEDIO)"}],attrs:{"icon":""},on:{"click":function($event){return _vm.setEntradaGeofencing(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clock-time-four-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Asignar llegada")])])],1)])]}}])})],1)],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cerrar}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }