<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Emergencias activas</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table class="scroll" :headers="headers" :items="medios" item-key="ID_MEDIO" disable-pagination hide-default-footer>
          <template #no-data>
            <p>No hay emergencias activas.</p>
          </template>

          <template #item="{ item }">
            <tr>
              <td>{{ item.MEDIO }}</td>
              <td>{{ item.NOMBRE }}</td>
              <td>{{ getFechaEmergencia(item.ID_MEDIO) | formatDate }}</td>
              <td>
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn icon class="mx-0" v-bind="attrs" v-on="on" @click="desactivarEmergencia(item)">
                      <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Desactivar</span>
                </v-tooltip>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cerrar">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true },
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true },
      { text: 'Fecha', value: 'FECHA', align: 'left', sortable: true },
      { text: '', value: 'ACCIONES', align: 'left', sortable: false }
    ]
  }),

  computed: {
    emergencias () {
      return this.$store.getters['medio/emergencias']
    },
    medios () {
      return this.$store.getters['medio/medios'].filter(x => x.EN_EMERGENCIA === true)
    }
  },

  methods: {
    getFechaEmergencia (idMedio) {
      let fecha = ''

      if (this.emergencias.find(x => x.ID_MEDIO === idMedio)) {
        fecha = this.emergencias.find(x => x.ID_MEDIO === idMedio).FECHA_INICIO
      }

      return fecha
    },

    desactivarEmergencia (medio) {
      let emergencia = {
        ID_MEDIO: medio.ID_MEDIO,
        FECHA: this.$date.now(),
        IS_EMERGENCIA: false
      }
      this.$store.dispatch('medio/setEmergenciaMedio', emergencia)
    },

    cerrar () {
      this.$emit('cerrar')
    }
  }
}
</script>

<style scoped>
  td {
    padding-left: 20px!important
  }
</style>
