var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"400px"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primaryLight","dark":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.editMedio.MEDIO))])],1),_c('v-card-text',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.aceptar.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"10"}},[_c('vx-date-text-edit',{attrs:{"label":"Fecha aviso","icon":"esri-icon-calendar","min":_vm.fechaIncendio,"max":_vm.editMedio.FECHA_LLEGADA,"not-future":"","required":""},model:{value:(_vm.editMedio.FECHA_AVISO),callback:function ($$v) {_vm.$set(_vm.editMedio, "FECHA_AVISO", $$v)},expression:"editMedio.FECHA_AVISO"}})],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"10"}},[_c('vx-date-text-edit',{attrs:{"label":"Fecha llegada","icon":"esri-icon-calendar","min":_vm.editMedio.FECHA_AVISO,"max":_vm.editMedio.FECHA_SALIDA,"not-future":""},model:{value:(_vm.editMedio.FECHA_LLEGADA),callback:function ($$v) {_vm.$set(_vm.editMedio, "FECHA_LLEGADA", $$v)},expression:"editMedio.FECHA_LLEGADA"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","disabled":!_vm.editMedio.FECHA_LLEGADA},on:{"click":_vm.removeFechaLlegada}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Eliminar fecha llegada.")])])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"10"}},[_c('vx-date-text-edit',{attrs:{"label":"Fecha salida","icon":"esri-icon-calendar","min":_vm.editMedio.FECHA_LLEGADA,"max":_vm.editMedio.FECHA_LLEGADA_BASE,"not-future":"","disabled":_vm.disableFechaSalida},model:{value:(_vm.editMedio.FECHA_SALIDA),callback:function ($$v) {_vm.$set(_vm.editMedio, "FECHA_SALIDA", $$v)},expression:"editMedio.FECHA_SALIDA"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","disabled":!_vm.editMedio.FECHA_SALIDA},on:{"click":_vm.removeFechaSalida}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Eliminar fecha salida.")])])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"10"}},[_c('vx-date-text-edit',{attrs:{"label":"Fecha llegada base","icon":"esri-icon-calendar","min":_vm.editMedio.FECHA_SALIDA,"max":_vm.editMedio.FECHA_FIN_DESCANSO,"not-future":"","disabled":_vm.disableFechaLlegadaBase},model:{value:(_vm.editMedio.FECHA_LLEGADA_BASE),callback:function ($$v) {_vm.$set(_vm.editMedio, "FECHA_LLEGADA_BASE", $$v)},expression:"editMedio.FECHA_LLEGADA_BASE"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","disabled":!_vm.editMedio.FECHA_LLEGADA_BASE},on:{"click":_vm.removeFechaLlegadaBase}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Eliminar fecha llegada base.")])])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"10"}},[_c('vx-date-text-edit',{attrs:{"label":"Fecha fin descanso","icon":"esri-icon-calendar","min":_vm.editMedio.FECHA_LLEGADA_BASE,"disabled":_vm.disableFechaFinDescanso},model:{value:(_vm.editMedio.FECHA_FIN_DESCANSO),callback:function ($$v) {_vm.$set(_vm.editMedio, "FECHA_FIN_DESCANSO", $$v)},expression:"editMedio.FECHA_FIN_DESCANSO"}})],1),_c('v-col',{attrs:{"sm":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","disabled":!_vm.editMedio.FECHA_FIN_DESCANSO},on:{"click":_vm.removeFechaFinDescanso}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Eliminar fecha fin descanso.")])])],1)],1),_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.sectores,"prepend-icon":"esri-icon-description","label":"Sector","item-text":"SECTOR","item-value":"ID_SECTOR","single-line":"","autocomplete":""},model:{value:(_vm.editMedio.ID_SECTOR),callback:function ($$v) {_vm.$set(_vm.editMedio, "ID_SECTOR", $$v)},expression:"editMedio.ID_SECTOR"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.deleteMedioIncendio}},[_vm._v("Eliminar act.")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"red"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"text":"","color":"green","disabled":!_vm.valid},on:{"click":_vm.aceptar}},[_vm._v("Aceptar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }