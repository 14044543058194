<template>
  <v-dialog v-model="show" persistent max-width="350px">
    <v-card>
      <v-card-title class="px-0 pt-0">
        <v-toolbar color="primaryLight" dark dense>
          <v-toolbar-title>Buscar coordenadas</v-toolbar-title>
        </v-toolbar>
      </v-card-title>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container class="mt-2">
            <v-row>
              <v-select v-model="tipoCoordenadas" :items="['Latitud / Longitud', 'X/Y ETRS89 30N', 'GMS']" label="Tipo coordenadas" outlined dense />
            </v-row>

            <!-- LATITUD LONGITUD -->
            <div v-if="tipoCoordenadas == 'Latitud / Longitud'">
              <v-row dense>
                <v-col>
                  <v-text-field v-model="latitud" prepend-icon="mdi-map-marker" type="number" label="Latitud" outlined dense clearable :rules="[globalRules.required]" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="longitud" prepend-icon="mdi-map-marker" type="number" label="Longitud" outlined dense clearable :rules="[globalRules.required]" />
                </v-col>
              </v-row>
            </div>

            <!-- X Y -->
            <div v-if="tipoCoordenadas == 'X/Y ETRS89 30N'">
              <v-row dense>
                <v-col>
                  <v-text-field v-model="xETRS89" type="number" prepend-icon="mdi-map-marker" label="X" outlined dense clearable :rules="[globalRules.required]" />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-text-field v-model="yETRS89" type="number" prepend-icon="mdi-map-marker" label="Y" outlined dense clearable :rules="[globalRules.required]" />
                </v-col>
              </v-row>
            </div>

            <!-- GMS -->
            <div v-if="tipoCoordenadas == 'GMS'">
              <v-row dense>
                <v-col sm="8">
                  <v-text-field ref="GMSLatRef" v-model="GMSLat" prepend-icon="mdi-map-marker" label="GMS Latitud" outlined dense clearable v-mask="maskGMS" :rules="[globalRules.required]" />
                </v-col>

                <v-col sm="4">
                  <v-select v-model="dirGMSLat" :items="['N', 'S']" label="N/S" outlined dense hide-details />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col sm="8">
                  <v-text-field ref="GMSLonRef" v-model="GMSLon" prepend-icon="mdi-map-marker" label="GMS Longitud" outlined dense clearable v-mask="maskGMS" :rules="[globalRules.required]" />
                </v-col>

                <v-col sm="4">
                  <v-select v-model="dirGMSLon" :items="['W', 'E']" label="W/E" outlined dense hide-details />
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn color="red" text @click="cerrar()">Cerrar</v-btn>
        <v-btn color="primary" text :disabled="!isValid" @click="aceptar()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean
  },

  data: () => ({
    maskGMS: '##º ##\' ##,#\'\'',

    tipoCoordenadas: 'X/Y ETRS89 30N',

    isValid: false,
    latitud: null,
    longitud: null,

    xETRS89: null,
    yETRS89: null,

    GMSLat: '',
    GMSLon: '',
    dirGMSLat: 'N',
    dirGMSLon: 'W'
  }),

  methods: {
    resetVariables () {
      this.tipoCoordenadas = 'X/Y ETRS89 30N'
      this.xETRS89 = null
      this.yETRS89 = null
      this.latitud = null
      this.longitud = null
      this.GMSLat = ''
      this.GMSLon = ''
      this.dirGMSLat = 'N'
      this.dirGMSLon = 'W'
    },

    aceptar () {
      if (this.tipoCoordenadas === 'X/Y ETRS89 30N') {
        if (this.xETRS89 && this.yETRS89) {
          let latLon = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
          this.longitud = latLon[0]
          this.latitud = latLon[1]
        }
      } else if (this.tipoCoordenadas === 'GMS') {
        if (this.GMSLat && this.GMSLon && this.dirGMSLat && this.dirGMSLon) {
          let latLon = ArcGIS.convertirGMSLatLon(this.GMSLat + this.dirGMSLat, this.GMSLon + this.dirGMSLon)
          this.longitud = latLon[1]
          this.latitud = latLon[0]
        }
      }

      if (this.latitud && this.longitud) {
        let latFloat = parseFloat(this.latitud)
        let lonFloat = parseFloat(this.longitud)

        let center = {
          target: [lonFloat, latFloat],
          zoom: 12
        }
        ArcGIS.setCenterMap(center)
        ArcGIS.drawPointCoords(latFloat, lonFloat)

        this.cerrar()
      }
    },
    cerrar () {
      this.resetVariables()
      this.$emit('hide')
    }
  },

  mounted () {
  }
}
</script>

<style scoped>
</style>
