<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ accionDialog }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="nombre" label="Equipo *" :rules="[globalRules.required]" outlined dense />
              </v-col>

              <v-col>
                <v-select v-model="tipoSelected" label="Tipo" :items="tiposEquipo" item-text="NOMBRE" item-value="TIPO_EQUIPO" return-object outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Medios"
                  v-model="mediosSelected" :items="medios" item-text="MEDIO" item-value="ID_MEDIO" return-object
                  dense outlined autocomplete hide-details multiple
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field label="Descripcion" v-model="descripcion" outlined dense />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-menu ref="menuFechaCreacion" v-model="showFechaCreacion" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                  <template #activator="{ on, attrs }">
                    <v-text-field :value="fechaCreacionFormatted" @click:clear="fechaCreacion = null" label="Fecha de creación" prepend-icon="mdi-calendar-outline" readonly v-bind="attrs" v-on="on" outlined dense clearable hide-details />
                  </template>
                  <v-date-picker v-model="fechaCreacion" @input="$refs.menuFechaCreacion.save(fechaCreacion)" locale="es-ES" />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import constants from '../../helpers/constants'

export default {
  props: {
    show: Boolean,
    isEdit: Boolean,
    editedEquipo: Object
  },

  data: () => ({
    isValid: false,

    nombre: null,

    showFechaCreacion: false,
    fechaCreacion: null,

    tipoSelected: null,
    descripcion: null,
    mediosSelected: [],

    numeroComponentes: 0
    // baseSelected: null,
    // personalSelected: null,
    // showCheckboxMedioDespachable: false,
    // showCheckboxGuardiasDinamicas: false,
    // despachableCheckbox: true,
    // guardiasDinamicasCheckbox: true
  }),

  computed: {
    ...mapGetters('medio', [
      'medios'
    ]),
    ...mapGetters('equipo', [
      'tiposEquipo'
    ]),
    accionDialog () {
      return this.isEdit ? 'Editar' : 'Nuevo'
    },
    fechaCreacionFormatted () {
      let date = this.$date.parseDate(this.fechaCreacion)
      return date.isValid() ? this.$date.formatDate(date, 'DD/MM/YYYY') : ''
    }
  },

  watch: {
    show () {
      if (this.show) {
        if (this.isEdit) {
          console.log(this.editedEquipo)
          this.nombre = this.editedEquipo.NOMBRE
          this.tipoSelected = this.editedEquipo.TIPO_EQUIPO
          this.mediosSelected = this.medios.map(medio => this.editedEquipo.MEDIO_EQUIPOs.find(x => x.ID_MEDIO === medio.ID_MEDIO)).filter(x => x)
          this.fechaCreacion = this.$date.parseDate(this.editedEquipo.FECHA_CREACION).format('YYYY-MM-DD HH:mm:ss.SSSZ')
          this.descripcion = this.editedEquipo.DESCRIPCION
        } else {
          this.fechaCreacion = this.$date.parseDate(this.$date.now()).format('YYYY-MM-DD HH:mm:ss.SSSZ')
        }
      }
    }
  },

  methods: {
    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },

    aceptar () {
      const equipo = this.isEdit ? JSON.parse(JSON.stringify(this.editedEquipo)) : {}
      equipo.NOMBRE = this.nombre
      equipo.TIPO_EQUIPO = this.tipoSelected
      // añadimos en otro objeto diferente al de modelo (MEDIO_EQUIPOs)
      equipo.MEDIOS = this.mediosSelected.filter(x => x)
      equipo.FECHA_CREACION = this.fechaCreacion
      equipo.DESCRIPCION = this.descripcion

      if (this.isEdit) {
        this.$emit('editEquipo', equipo)
      } else {
        this.$emit('addEquipo', equipo)
      }

      this.$refs.form.reset()
    }
  },
  mounted () {
  },
  unmounted () {
    this.$refs.form.reset()
  }
}
</script>

<style scoped>
</style>
