<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense style="z-index: 2">
      <v-toolbar-title>Gestión de medios</v-toolbar-title>
      <v-tooltip disabled>
        <template #activator="{ on, attrs }">
          <v-container
            v-on="on"
            v-bind="attrs"
          >
            <v-switch
              v-model="viewSelected"
              hide-details
              inset
              label="Gestión de equipos"
            />
          </v-container>
        </template>
        Cambiar vista
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :disabled="disabledByRol"
            fab
            small
            bottom
            right
            absolute
            color="secondary"
            @click="!viewSelected ? showAddMedioDialog(): showAddEquipoDialog()"
          >
            <v-icon color="black">mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ !viewSelected ? 'Añadir medio' : 'Añadir equipo' }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-card-text>
      <v-container class="py-0 ma-0" fluid v-if="!viewSelected" ref="firstContainer">
        <v-row>
          <v-col cols="10">
            <v-autocomplete
              v-model="tableFilters"
              return-object
              :items="headers.filter(x => x.text !== 'Acciones')"
              :items-text="(h) => {return h.text}"
              item-title="text"
              label="Mostrar campos"
              multiple
              clearable
              dense
              outlined
            >
              <template #selection="{ item, index }">
                <v-chip v-if="index < maxVisible" :key="item.value" small>
                  {{ item.text }}
                </v-chip>
                <span v-else-if="index===maxVisible" :key="index" small>
                  {{ computedHeaders.length - maxVisible - 1 }} más
                </span>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="resetFilters()"
            >
              <v-icon>mdi-cached</v-icon>
            </v-btn>
            <v-btn
              icon
              color="teal"
              @click="mostrarFilAv = !mostrarFilAv;increaseFirstContainerHeight()"
            >
              <v-icon v-if="!mostrarFilAv">mdi-filter-menu</v-icon>
              <v-icon v-else>mdi-filter-minus-outline</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="1">
            <v-btn
              icon
              color="teal"
              @click="downloadExcelMedios()"
            >
              <v-icon>mdi-file-excel-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              color="red"
              @click="exportarMediosPDF()"
            >
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="2">
            <v-text-field class="no-spacing" v-model="search" label="Búsqueda general" clearable />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Medio" v-model="medioSelected" clearable />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              v-model="baseSelected"
              class="no-spacing"
              return-object
              :items="baseItems()"
              :item-text="(base) => {return base.NOMBRE}"
              item-title="NOMBRE"
              item-value="ID_INFRAESTRUCTURA"
              label="Base"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              class="no-spacing"
              return-object
              v-model="tipoSelected"
              :items="tiposItems()"
              :item-text="(tipo) => {return tipo.TIPO}"
              item-title="TIPO"
              item-value="ID_MEDIO_TIPO"
              label="Tipo"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-autocomplete
              class="no-spacing"
              return-object
              v-model="categoriaSelected"
              :items="categoriaItems()"
              :item-text="(categoria) => {return categoria.CATEGORIA}"
              item-title="CATEGORIA"
              item-value="ID_MEDIO_CATEGORIA"
              label="Categoría"
              clearable
            />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="ISSI" v-model="issiSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Teléfono" v-model="telefono" clearable />
          </v-col>
        </v-row>

        <v-row class="mt-0" v-show="mostrarFilAv">
          <v-col cols="2">
            <v-autocomplete
              class="no-spacing"
              return-object
              v-model="organismoSelected"
              :items="organismoItems()"
              :item-text="(organismo) => {return organismo.ORGANISMO}"
              item-title="ORGANISMO"
              item-value="ID_ORGANISMO"
              label="Responsable"
              clearable
            />
          </v-col>
          <v-col cols="2">
            <v-text-field class="no-spacing" label="Alias" v-model="aliasSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Fabricante" v-model="fabricanteSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Nº matrícula" v-model="matriculaSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Correo" v-model="correo" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Nombre" v-model="nombreSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Hora entrada" hint="HH:mm" v-model="hEntradaSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Hora salida" hint="HH:mm" v-model="hSalidaSelected" clearable />
          </v-col>
          <v-col cols="1">
            <v-text-field class="no-spacing" label="Fecha ult. pos." v-model="fechaUltPosicionSelected" hint="DD/MM/YYYY" clearable />
          </v-col>
          <v-col cols="1">
            <v-checkbox class="no-spacing" v-model="activoSelected" label="Activo" />
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid v-if="!viewSelected" ref="secondContainer">
        <v-data-table
          class="table"
          :headers="computedHeaders"
          :items="mediosFilter"
          fixed-header
          disable-pagination
          hide-default-footer
          :style="{ height: `calc(${availableSpace}vh)` }"
        >
          <!-- :items-per-page="30" -->
          <template #no-data>
            <p>Datos no disponibles.</p>
          </template>

          <template #[`item.TELEFONO_INTERNO`]="{ item }">
            <span>{{ item.TELEFONO_INTERNO }}</span>
          </template>

          <template #[`item.TELEFONO_EXTERNO`]="{ item }">
            <a :href="'tel:+34' + item.TELEFONO_EXTERNO">{{
              item.TELEFONO_EXTERNO
            }}</a>
          </template>

          <template #[`item.TELEFONO_EXTERNO_2`]="{ item }">
            <a :href="'tel:+34' + item.TELEFONO_EXTERNO_2">{{
              item.TELEFONO_EXTERNO_2
            }}</a>
          </template>

          <template #[`item.HABILITADO`]="{ item }">
            <span>{{ item.HABILITADO ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.ACTIVO`]="{ item }">
            <span>{{ item.ACTIVO ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.DESPACHABLE`]="{ item }">
            <span>{{ item.DESPACHABLE ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.GUARDIAS_DINAMICAS`]="{ item }">
            <span>{{ item.GUARDIAS_DINAMICAS ? 'Sí' : 'No' }}</span>
          </template>

          <template #[`item.ULT_POSICION`]="{ item }">
            <span>{{ item.ULT_POSICION ? item.ULT_POSICION.FECHA : "" | formatDate }}</span>
          </template>

          <template #[`item.MEDIO_ESTADO_HIST_E`]="{ item }">
            <span>{{ item.MEDIO_ESTADO_HIST ? item.MEDIO_ESTADO_HIST.MEDIO_ESTADO.ESTADO : "" }}</span>
          </template>

          <template #[`item.MEDIO_ESTADO_HIST_F`]="{ item }">
            <span>{{ item.MEDIO_ESTADO_HIST ? item.MEDIO_ESTADO_HIST.FECHA : "" }}</span>
          </template>

          <template #[`item.ACCIONES`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="showEditMedioDialog(item)"
                >
                  <v-icon color="teal">mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="toggleEnableMedio(item)"
                >
                  <v-icon :color="item.HABILITADO ? 'success' :'gray'">{{ item.HABILITADO ? 'mdi-briefcase': 'mdi-briefcase-remove' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.HABILITADO ? 'Deshabilitar': 'Habilitar' }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="disabledByRol"
                  icon
                  @click="toggleActivateMedio(item)"
                >
                  <v-icon :color="item.ACTIVO ? 'success' : 'gray'">{{ item.ACTIVO ? 'mdi-lock-open-check-outline' : 'mdi-lock-off-outline' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.ACTIVO ? 'Desactivar' : 'Activar' }}</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-container>
      <vx-equipos v-if="viewSelected" :showDialogAddEdit="showDialogEquipo" />
    </v-card-text>

    <vx-dialog-add-edit-medio
      :show="showDialog"
      :isEdit="isEdit"
      :editedMedio="editMedio"
      @cancelar="showDialog = false"
      @addMedio="acceptAddMedio"
      @editMedio="acceptEditMedio"
    />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Equipos from './Equipos'
import DialogAddEditMedio from './DialogAddEditMedio'

import constants from '../../helpers/constants'
import * as ExcelHelper from '../../helpers/ExcelHelper'

import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    'vx-equipos': Equipos,
    'vx-dialog-add-edit-medio': DialogAddEditMedio
  },

  data: () => ({
    headers: [
      { text: 'Medio', value: 'MEDIO', align: 'left', sortable: true, initialShow: true },
      { text: 'Alias', value: 'ALIAS', align: 'left', sortable: true, width: '10%', initialShow: false },
      // { text: 'Usuario telegram', value: 'TELEGRAM_USERNAME', align: 'left', sortable: true, initialShow: false },
      { text: 'Tipo', value: 'TIPO', align: 'left', sortable: true, initialShow: true },
      { text: 'Categoría', value: 'CATEGORIA', align: 'left', sortable: true, initialShow: true },
      { text: 'Base', value: 'BASE', align: 'left', initialShow: true },
      { text: 'ISSI', value: 'ISSI', align: 'left', initialShow: true },
      { text: 'ISSI Pos.', value: 'ISSI_POS', align: 'left', initialShow: true },
      { text: 'Nombre', value: 'NOMBRE', align: 'left', sortable: true, initialShow: false },
      { text: 'Tel. interno', value: 'TELEFONO_INTERNO', align: 'left', sortable: true, initialShow: false },
      { text: 'Tel. externo 1', value: 'TELEFONO_EXTERNO', align: 'left', sortable: true, initialShow: true },
      { text: 'Tel. externo 2', value: 'TELEFONO_EXTERNO_2', align: 'left', sortable: true, initialShow: false },
      { text: 'Correo 1', value: 'CORREO_1', align: 'left', sortable: true, initialShow: false },
      { text: 'Correo 2', value: 'CORREO_2', align: 'left', sortable: true, initialShow: false },
      { text: 'Responsable', value: 'ORGANISMO', align: 'left', initialShow: true }, // METER EN GET BACKEND
      { text: 'Vel. max.', value: 'VELOCIDAD_MAX', align: 'left', sortable: true, initialShow: false },
      // { text: 'Zona', value: 'ZONA', align: 'left', sortable: true, initialShow: false },
      { text: 'Guardias', value: 'GUARDIAS_DINAMICAS', align: 'left', sortable: true, initialShow: false }, // fix
      { text: 'Habilitado', value: 'HABILITADO', align: 'left', sortable: true, initialShow: false },
      { text: 'Activo', value: 'ACTIVO', align: 'left', sortable: true, initialShow: false },
      { text: 'Despachable', value: 'DESPACHABLE', align: 'left', sortable: true, initialShow: false },
      {
        text: 'Fecha ult. posicion',
        value: 'ULT_POSICION',
        align: 'left',
        sort: (a, b) => {
          if (!a || !a.FECHA) return -1
          if (!b || !b.FECHA) return 1
          return new Date(a.FECHA) - new Date(b.FECHA)
        },
        initialShow: false
      },
      { text: 'Hora entrada', value: 'HORA_ENTRADA', align: 'left', sortable: true, initialShow: false },
      { text: 'Hora salida', value: 'HORA_SALIDA', align: 'left', sortable: true, initialShow: false },
      { text: 'Nº Matrícula', value: 'NUMERO_MATRICULA', align: 'left', sortable: true, initialShow: false },
      { text: 'Fabricante', value: 'FABRICANTE', align: 'left', sortable: true, initialShow: false },
      { text: 'Descripción', value: 'DESCRIPCION', align: 'left', sortable: true, initialShow: false },
      { text: 'Nº Componentes', value: 'NUMERO_COMPONENTES', align: 'left', sortable: true, initialShow: false },
      { text: 'Estado', value: 'MEDIO_ESTADO_HIST_E', align: 'left', initialShow: false },
      { text: 'Fecha Estado', value: 'MEDIO_ESTADO_HIST_F', align: 'left', initialShow: false },
      { text: 'Acciones', value: 'ACCIONES', align: 'center', width: '10%', sortable: false, initialShow: true }
    ],

    showDialog: false,
    mostrarFilAv: false,

    search: '',
    tableFilters: [],
    medioSelected: '',
    aliasSelected: '',
    tipoSelected: '',
    categoriaSelected: '',
    nombreSelected: '',
    issiSelected: '',
    fechaUltPosicionSelected: '',
    activoSelected: true,
    telefono: '',
    correo: '',
    organismoSelected: '',
    fabricanteSelected: '',
    hEntradaSelected: '',
    hSalidaSelected: '',
    baseSelected: '',
    matriculaSelected: '',
    mediosFilter: [],

    editMedio: {},
    isEdit: false,
    viewSelected: false,

    showDialogEquipo: false,
    availableSpace: 0,
    maxVisible: 8
  }),

  computed: {
    ...mapGetters('medio', [
      'medios', 'tiposMedio', 'categoriasMedio'
    ]),

    ...mapGetters('infraestructura', [
      'infraestructuras'
    ]),

    ...mapGetters('organismo', [
      'organismos'
    ]),

    disabledByRol () {
      // Deshabilita funciones dependiendo del rol
      return !this.$store.getters['usuario/isCurrentUsuarioAdmin']
    },

    estadosMedio () {
      return this.$store.getters['medio/estados']
    },
    computedHeaders () {
      return this.headers.filter(x => (this.tableFilters.length > 0 && x.text === 'Acciones') || this.tableFilters.includes(x))
    }
  },

  watch: {
    search () {
      this.filterMedios()
    },
    medios () {
      this.filterMedios()
    },
    medioSelected () {
      this.filterMedios()
    },
    aliasSelected () {
      this.filterMedios()
    },
    tipoSelected () {
      this.filterMedios()
    },
    categoriaSelected () {
      this.filterMedios()
    },
    nombreSelected () {
      this.filterMedios()
    },
    issiSelected () {

    },
    fechaUltPosicionSelected () {
      this.filterMedios()
    },
    activoSelected () {
      this.filterMedios()
    },
    telefono () {
      this.filterMedios()
    },
    correo () {
      this.filterMedios()
    },
    organismoSelected () {
      this.filterMedios()
    },
    fabricanteSelected () {
      this.filterMedios()
    },
    hEntradaSelected () {
      this.filterMedios()
    },
    hSalidaSelected () {
      this.filterMedios()
    },
    baseSelected () {
      this.filterMedios()
    },
    matriculaSelected () {
      this.filterMedios()
    }

  },

  methods: {
    ...mapActions('medio', ['addMedio', 'updateMedio', 'deleteMedio']),

    resetFilters () {
      this.tableFilters = this.headers.filter(x => x.initialShow)
    },
    showAddMedioDialog () {
      this.editMedio = null
      this.showDialog = true
      this.isEdit = false
    },

    showAddEquipoDialog () {
      this.showDialogEquipo = true
    },

    showEditMedioDialog (item) {
      this.editMedio = Object.assign({}, item)
      this.showDialog = true
      this.isEdit = true
    },

    toggleEnableMedio (medio) {
      if (medio.HABILITADO === true) {
        let msg = 'Deshabilitar el medio: ' + medio.MEDIO
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('medio/disableMedio', medio)
          }
        })
      } else {
        this.$store.dispatch('medio/enableMedio', medio)
      }
    },

    toggleActivateMedio (medio) {
      if (medio.ACTIVO === true) {
        let msg = 'Desactivar el medio: ' + medio.MEDIO
        this.$root.$confirmDialog.open('¿Está seguro?', msg).then((result) => {
          if (result) {
            this.$store.dispatch('medio/deactivateMedio', medio)
          }
        })
      } else {
        this.$store.dispatch('medio/activateMedio', medio)
      }
    },

    acceptAddMedio (medio) {
      this.showDialog = false
      this.addMedio(medio).then(() => {
        this.$store.dispatch('medio/enableMedio', medio)
      })
    },

    acceptEditMedio (medio) {
      this.showDialog = false
      this.updateMedio(medio)
    },

    exportarMediosPDF () {
      // Exporta medios terrestres y fechas a un PDF
      let bodyPdf = []
      bodyPdf.push(['Medio', 'Tipo', 'Fecha'])

      let mediosFechaOk = this.medios.filter((x) =>
        this.$date.parseDate(x.FECHA).isValid()
      )
      let mediosFechaMal = this.medios.filter(
        (x) => !this.$date.parseDate(x.FECHA).isValid()
      )

      let mediosOrdenados = mediosFechaOk.sort((a, b) => {
        let aOrden = this.$date.parseDate(a.FECHA)
        let bOrden = this.$date.parseDate(b.FECHA)

        return bOrden > aOrden ? 1 : -1
      })

      for (let i = 0; i < mediosFechaMal.length; i++) {
        mediosOrdenados.push(mediosFechaMal[i])
      }

      for (let i = 0; i < mediosOrdenados.length; i++) {
        let medio = mediosOrdenados[i]

        let datosMedio = []
        datosMedio.push({ text: medio.MEDIO, bold: true })
        datosMedio.push(medio.TIPO)
        datosMedio.push(this.$date.formatDate(medio.FECHA, 'DD/MM/YYYY HH:mm'))
        bodyPdf.push(datosMedio)
      }

      let docDefinition = {
        content: [],
        defaultStyle: {
          fontSize: 9
        }
      }

      docDefinition.content.push(constants.headerPDF)

      docDefinition.content.push(
        {
          text: [
            { text: 'Fecha/hora informe: ', bold: true },
            this.$date.currentDate()
          ],
          margin: [0, 0, 0, 15]
        },
        {
          layout: 'lightHorizontalLines', // optional
          table: {
            headerRows: 1,
            body: bodyPdf
          },
          margin: [0, 15, 0, 20]
        }
      )

      pdfMake
        .createPdf(docDefinition)
        .download(
          'Medios_Fecha_' + this.$date.now().format('YYYY_MM_DD_hh_mm')
        )
    },
    async filterMedios () {
      let mediosFilter = []
      if (this.medios !== undefined) {
        mediosFilter = JSON.parse(JSON.stringify(this.medios)).filter(x => x.ACTIVO === this.activoSelected)

        if (this.search && this.search !== '') {
          mediosFilter = mediosFilter.filter(x => {
            return (
              (x.MEDIO && x.MEDIO.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.TIPO && x.TIPO.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.CATEGORIA && x.CATEGORIA.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.BASE && x.BASE.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.ISSI && x.ISSI.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.ISSI_POS && x.ISSI_POS.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.TELEFONO_EXTERNO && x.TELEFONO_EXTERNO.toLowerCase().includes(this.search.toLowerCase())) ||
              (x.ORGANISMO && x.ORGANISMO.toLowerCase().includes(this.search.toLowerCase()))
            )
          })
        }

        if (this.medioSelected && this.medioSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.MEDIO.includes(this.medioSelected))
        }

        if (this.aliasSelected && this.aliasSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.ALIAS !== null && x.ALIAS.includes(this.aliasSelected))
        }

        if (this.tipoSelected && this.tipoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.TIPO.includes(this.tipoSelected.TIPO))
        }

        if (this.categoriaSelected && this.categoriaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.CATEGORIA.includes(this.categoriaSelected.CATEGORIA))
        }

        if (this.nombreSelected && this.nombreSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.NOMBRE && x.NOMBRE.toLowerCase().includes(this.nombreSelected.toLowerCase()))
        }

        if (this.issiSelected && this.issiSelected !== '') {
          mediosFilter = mediosFilter.filter(x => (x.ISSI && x.ISSI.includes(this.issiSelected)) || (x.ISSI_POS && x.ISSI_POS.includes(this.issiSelected)))
        }

        if (this.fechaUltPosicionSelected && this.fechaUltPosicionSelected !== '') {
          mediosFilter = mediosFilter.filter(x => {
            // Verificar si la fecha en ULT_POSICION es válida
            if (x.ULT_POSICION && x.ULT_POSICION.FECHA) {
              return this.$date.formatDate(x.ULT_POSICION.FECHA, 'DD/MM/YYYY HH:mm').includes(this.fechaUltPosicionSelected)
            } else {
              return false
            }
          })
        }

        if (this.baseSelected && this.baseSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.BASE === this.baseSelected.NOMBRE)
        }

        if (this.matriculaSelected && this.matriculaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.NUMERO_MATRICULA !== null && x.NUMERO_MATRICULA.includes(this.matriculaSelected))
        }

        if (this.telefono && this.telefono !== '') {
          mediosFilter = mediosFilter.filter(x => (x.TELEFONO_INTERNO !== null && x.TELEFONO_INTERNO.includes(this.telefono)) || (x.TELEFONO_EXTERNO !== null && x.TELEFONO_EXTERNO.includes(this.telefono)) || (x.TELEFONO_EXTERNO_2 !== null && x.TELEFONO_EXTERNO_2.includes(this.telefono)))
        }

        if (this.correo && this.correo !== '') {
          mediosFilter = mediosFilter.filter(x => (x.CORREO_1 !== null && x.CORREO_1.includes(this.correo)) || (x.CORREO_2 !== null && x.CORREO_2.includes(this.correo)))
        }

        if (this.organismoSelected && this.organismoSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.ORGANISMO !== null && x.ORGANISMO.includes(this.organismoSelected.ORGANISMO))
        }

        if (this.fabricanteSelected && this.fabricanteSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.FABRICANTE !== null && x.FABRICANTE.includes(this.fabricanteSelected))
        }

        if (this.hEntradaSelected && this.hEntradaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.HORA_ENTRADA !== null && x.HORA_ENTRADA.includes(this.hEntradaSelected))
        }

        if (this.hSalidaSelected && this.hSalidaSelected !== '') {
          mediosFilter = mediosFilter.filter(x => x.HORA_SALIDA !== null && x.HORA_SALIDA.includes(this.hSalidaSelected))
        }

        this.mediosFilter = mediosFilter
      } else {
        await new Promise((resolve, reject) => setTimeout(resolve, 500))
        this.filterMedios()
      }
    },

    tiposItems () {
      let tipos = JSON.parse(JSON.stringify(this.tiposMedio))

      tipos = tipos.sort((a, b) => constants.collator.compare(a.TIPO, b.TIPO))
      return tipos
    },

    categoriaItems () {
      let categorias = JSON.parse(JSON.stringify(this.categoriasMedio))

      categorias = categorias.sort((a, b) => constants.collator.compare(a.CATEGORIA, b.CATEGORIA))
      return categorias
    },

    organismoItems () {
      let organismos = JSON.parse(JSON.stringify(this.organismos))

      organismos = organismos.sort((a, b) => constants.collator.compare(a.ORGANISMO, b.ORGANISMO))
      return organismos
    },

    baseItems () {
      let baseMedios = JSON.parse(JSON.stringify(this.infraestructuras.filter(infra => this.isBase(infra))))
      baseMedios = baseMedios.sort((a, b) => constants.collator.compare(a.NOMBRE, b.NOMBRE))
      return baseMedios
    },

    isBase (infraestructura) {
      if (constants.TIPOS_INFRAESTRUCTURAS_IS_BASE.some(x => infraestructura.ID_INFRAESTRUCTURA_TIPO === x.ID_INFRAESTRUCTURA_TIPO)) {
        return true
      }
      return false
    },

    increaseFirstContainerHeight () {
      // Forzar una actualización del DOM para reflejar la nueva altura
      this.$nextTick(() => {
        this.calculateAvailableSpace()
      })
    },
    calculateAvailableSpace () {
      // Obtener la posición y altura del primer v-container
      const firstContainer = this.$refs.firstContainer
      const firstContainerRect = firstContainer.getBoundingClientRect()
      const firstContainerBottom = firstContainerRect.top + firstContainerRect.height

      // Obtener la altura del viewport
      const viewportHeight = window.innerHeight

      // Calcular el espacio disponible en vh
      this.availableSpace = ((viewportHeight - firstContainerBottom) / viewportHeight) * 100
    },

    downloadExcelMedios () {
      ExcelHelper.downloadJSON2CSV(this.computedHeaders.filter(x => x.value !== 'ACCIONES'), this.mediosFilter)
    }
  },
  mounted () {
    this.filterMedios()
    this.maxVisible = this.headers.filter(x => x.initialShow).length - 1
    this.resetFilters()

    // Calcular el espacio disponible al montar el componente
    this.calculateAvailableSpace()
    // También puedes actualizar el espacio disponible si la ventana se redimensiona
    window.addEventListener('resize', this.calculateAvailableSpace)
  },
  beforeDestroy () {
    // Limpiar el event listener para evitar fugas de memoria
    window.removeEventListener('resize', this.calculateAvailableSpace)
  }

}
</script>

<style scoped>
/* Estilos para que la tabla tenga un max-height y pueda crecer */
.table {
  width: 100vw;
}
.v-data-table {
  overflow: auto;
}
.v-data-table >>> .v-data-table__wrapper {
  /* Header fijo */
  overflow: unset;
}
.no-spacing {
  margin: 0 !important;
  padding: 0 !important;
}
/* Estilo para limitar la altura del contenedor de selecciones y agregar scroll */
.v-input__selections {
  max-height: 20px; /* Ajusta este valor según tus necesidades */
  overflow-y: auto;
}
/* .v-data-table >>> .v-data-footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
  } */
</style>
