<template>
  <div>
    <vx-share-map-select-user-dialog :show="showDialogShareMapSelectUser" @cerrar="showDialogShareMapSelectUser = false" />
    <vx-share-map-received-request-dialog />
    <vx-share-map-users-connected-list />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as ArcGIS from '../../../helpers/ArcGIS'

import SelectUserDialog from './SelectUserDialog'
import ReceivedRequestDialog from './ReceivedRequestDialog'
import UsersConnectedList from './UsersConnectedList'

export default {
  components: {
    'vx-share-map-select-user-dialog': SelectUserDialog,
    'vx-share-map-received-request-dialog': ReceivedRequestDialog,
    'vx-share-map-users-connected-list': UsersConnectedList
  },

  data: () => ({
    showDialogShareMapSelectUser: false
  }),

  computed: {
    ...mapGetters('shareMap', [
      'isSharingMap',
      'graphics',
      'mapExtent',
      'visibility',
      'opacity',
      'simulation',
      'simulationTime',
      'vientosSimulacion',
      'planOperaciones',
      'alineacionesCampbell',
      'is2D'
    ])
  },

  watch: {
    isSharingMap () {
      // console.log('IS SHARING MAP:', this.isSharingMap)
      if (this.isSharingMap) {
        ArcGIS.initShareMapSketchViewModel()
      } else {
        ArcGIS.stopShareMapSketchViewModel()
      }
    },

    graphics () { // Re-dibujar los dibujos si cambia la lista
      ArcGIS.drawShareMapGraphics()
    },

    mapExtent () { // Mover mapa al recibir compartir pantalla
      ArcGIS.setExtent(this.mapExtent)
    },

    visibility () { // Recibir layer para mostrar u ocultar en compartir pantalla
      if (this.visibility.idPadre) {
        let sublay = {
          title: this.visibility.title
        }
        ArcGIS.setVisibilitySubLayer(this.visibility.idPadre, sublay, this.visibility.visible)
      } else {
        ArcGIS.setVisibilityLayer(this.visibility.id, this.visibility.visible)
      }
    },

    opacity () { // Recibir layer para cambiar la opacidad en compartir pantalla
      ArcGIS.setOpacityLayer(this.opacity.id, this.opacity.opacity)
    },

    simulation () { // Recibir simulacion al recibir compartir pantalla
      ArcGIS.shareMapSimulacion(this.simulation)
      if (this.simulation.simulacion.id === 'simulacion') {
        ArcGIS.updateSimulacionTime(this.simulationTime)
      }
    },

    simulationTime () { // Recibir momento de simulacion al recibir compartir pantalla
      ArcGIS.updateSimulacionTime(this.simulationTime)
    },

    vientosSimulacion () { // Recibir vientos de la simulacion al recibir compartir pantalla
      ArcGIS.paintVientoLayer(this.vientosSimulacion)
    },

    planOperaciones () { // Recibir plan de operaciones al recibir compartir pantalla
      ArcGIS.drawGraphicsPlanOperaciones(this.planOperaciones)
    },

    alineacionesCampbell () { // Recibir alineaciones de campbell al recibir compartir pantalla
      ArcGIS.drawGraphicsAlineacionesCampbell(this.alineacionesCampbell)
    },

    is2D () {
      this.is2D ? ArcGIS.use2D() : ArcGIS.use3D()
      this.$eventHub.$emit('change2D', this.is2D)
    }
  },

  methods: {
    toggleShareMap (isShareMap) {
      this.$store.dispatch('shareMap/setShareMap', isShareMap) // TODO: simplificar el proceso
      this.showDialogShareMapSelectUser = isShareMap
    }
  },

  mounted () {
    this.$eventHub.$on('shareMap', this.toggleShareMap)
  }
}
</script>

<style scoped>

</style>
