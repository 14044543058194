import api from '@/api'
import Vue from 'vue'

let Q = require('q')

const state = {
  personales: []
}

const getters = {
  personales: (state) => {
    return state.personales
  }
}

const mutations = {
  SET_PERSONAL (state, personal) {
    state.personales = personal
  },
  SOCKET_addPersonal (state, personal) {
    state.personales.push(personal)
    this.notify('Personal creado', `Se ha creado el personal ${personal.NOMBRE} ${personal.APELLIDOS}`)
  },
  SOCKET_updatePersonal (state, data) {
    let positionPersonal = state.personales.findIndex(x => x.ID_PERSONAL.equalsIgnoreCase(data.ID_PERSONAL))

    if (positionPersonal !== -1) {
      state.personales[positionPersonal] = data
      this.notify('Personal actualizada', `Se ha actualizado el personal ${data.NOMBRE} ${data.APELLIDOS}`)
    }
    Vue.set(state.personales, positionPersonal, data)
  }
}

const actions = {
  async fetchPersonal ({ commit }) {
    let deferred = Q.defer()
    try {
      const response = await api.personal.getPersonal()
      commit('SET_PERSONAL', response.data)
      deferred.resolve()
    } catch (err) {
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async addPersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.personal.addPersonal(data)
      if (response.status === 200) {
        commit('SOCKET_addPersonal', data)
        // to do añadir socket en backend
        this._vm.$socket.emit('addPersonal', data)
      }
      deferred.resolve()
    } catch (err) {
      this.notify('Error al añadir personal', err, 'error')
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async updatePersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      const response = await api.personal.updatePersonal(data)
      if (response.status === 200) {
        commit('SOCKET_updatePersonal', data)
        this._vm.$socket.emit('updatePersonal', data)
      }
      deferred.resolve()
    } catch (err) {
      this.notify('Error al editar personal', err, 'error')
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async disablePersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.ACTIVO = true
      const response = await api.personal.updatePersonal(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updatePersonal', dataCopy)
        this._vm.$socket.emit('updatePersonal', dataCopy)
      }
      deferred.resolve()
    } catch (err) {
      this.notify('Error al desactivar personal', err, 'error')
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  },
  async enablePersonal ({ commit }, data) {
    let deferred = Q.defer()
    try {
      let dataCopy = JSON.parse(JSON.stringify(data))
      dataCopy.ACTIVO = false
      const response = await api.personal.updatePersonal(dataCopy)
      if (response.status === 200) {
        commit('SOCKET_updatePersonal', dataCopy)
        this._vm.$socket.emit('updatePersonal', dataCopy)
      }
      deferred.resolve()
    } catch (err) {
      this.notify('Error al activar personal', err, 'error')
      console.error(err)
      deferred.reject(err)
    }
    return deferred.promise
  }
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
