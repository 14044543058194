var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"sm":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Búsqueda","outlined":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.equipos,"search":_vm.search,"fixed-header":"","disable-pagination":"","hide-default-footer":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('p',[_vm._v("Datos no disponibles.")])]},proxy:true},{key:"item.TIPO_EQUIPO",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.TIPO_EQUIPO ? item.TIPO_EQUIPO.NOMBRE : ""))])]}},{key:"item.FECHA_CREACION",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$date.formatDate(item.FECHA_CREACION,'DD/MM/YYYY')))])]}},{key:"item.MEDIOS",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.MEDIO_EQUIPOs ? _vm.mediosEquipos(item.MEDIO_EQUIPOs) : ""))])]}},{key:"item.ACCIONES",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledByRol,"icon":""},on:{"click":function($event){return _vm.showEditEquipoDialog(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}}],null,true)})],1)],1),_c('vx-dialog-add-edit-equipo',{attrs:{"show":_vm.showDialog,"isEdit":_vm.isEdit,"editedEquipo":_vm.editEquipo},on:{"cancelar":function($event){_vm.showDialog = false},"addEquipo":_vm.acceptAddEquipo,"editEquipo":_vm.acceptEditEquipo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }