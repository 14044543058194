// eslint-disable-next-line no-extend-native
String.prototype.equalsIgnoreCase = function (compareString) {
  let string = this.toString()

  if (string === compareString) {
    return true
  }

  if ((!string && compareString) || (string && !compareString)) {
    return false
  }

  return string.toUpperCase() === compareString.toUpperCase()
}
