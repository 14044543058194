import request from '../request'

export default {
  deleteImagenInfraestructura: function (data) {
    return request.delete('infraestructura_imagen/' + data.ID_INFRAESTRUCTURA + '/' + data.ID_INFRAESTRUCTURA_IMAGEN)
  },
  getImagenesInfraestructura: function (data) {
    return request.get('infraestructura_imagen/' + data)
  },
  setImagenesInfraestructura: function (data) {
    return request.post('infraestructura_imagen/' + data.ID_INFRAESTRUCTURA, data.imagenes)
  }
}
