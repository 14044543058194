import Vue from 'vue'
import HttpService from '../helpers/HttpService'

Object.defineProperties(Vue.prototype, {
  $http: {
    get () {
      return HttpService
    }
  }
})
