<template>
  <v-card tile height="100%">
    <v-toolbar color="primaryLight" dark dense>
      <v-toolbar-title style="width: 100%;">
        <v-container class="pa-0 ma-0">
          <v-row class="ma-0">
            <v-col cols="2">Cuadrante de guardias</v-col>
            <v-col cols="2">
              <v-switch
                v-model="viewSelected"
                hide-details
                inset
                :label="viewSelected ? 'Vista de personales' : 'Vista de medios'"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text class="pa-0 ma-0">
      <v-container class="pa-1 ma-0" fluid>
        <vx-guardias-medio v-if="!viewSelected" />
        <vx-guardias-personal v-if="viewSelected" />
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import GuardiasPorMedio from './GuardiasPorMedio.vue'
import GuardiasPorPersonal from './GuardiasPorPersonal.vue'

export default {
  components: {
    'vx-guardias-medio': GuardiasPorMedio,
    'vx-guardias-personal': GuardiasPorPersonal
  },
  data () {
    return {
      viewSelected: true
    }
  }
}
</script>

<style>
</style>
