<template>
  <v-btn icon @click="clickBtn()">
    <v-icon :class="{toggled: !isVisible}">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
// import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    icon: String,
    layerId: String
  },

  data: () => ({
    toggled: true
  }),

  computed: {
    ...mapGetters('map', [
      'layers',
      'etiquetasVisibles'
    ]),

    isVisible () {
      return this.getVisibility()
    }
  },

  methods: {
    clickBtn () {
      this.toggled = !this.toggled

      this.$store.dispatch('map/setVisibilityLayer', {
        id: this.layerId,
        visible: this.toggled
      })
    },

    getVisibility () {
      let visible = false

      if (this.layerId === 'layer-etiquetas') {
        visible = this.etiquetasVisibles
        this.toggled = visible
        return visible
      }

      if (this.layerId === 'layer-medios-inactivos') {
        let l = this.layers.find(x => x.id === 'layer-medios')
        visible = l && l.definitionExpression.includes(' OR ACTIVO = 0 ')
        this.toggled = visible
        return visible
      }

      if (this.layerId === 'layer-medios') {
        let l = this.layers.find(x => x.id === 'layer-medios')
        visible = l && l.definitionExpression.includes(' OR ACTIVO = 1 ')
        this.toggled = visible
        return visible
      }

      let l = this.layers.find(x => x.id === this.layerId)
      visible = l ? l.visible : false

      this.toggled = visible
      return visible
    }
  },

  mounted () {
    this.getVisibility()
  }
}
</script>

<style scoped>
  .toggled {
    color:  rgba(255, 255, 255, 0.521) !important;
  }
</style>
