<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Añadir medio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form v-model="isValid">
            <v-row>
              <v-col>
                <v-text-field label="Medio" :value="medioSelected? medioSelected.MEDIO : ''" disabled outlined hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="sectorSelected" :items="sectores" label="Sector" outlined item-text="SECTOR" item-value="ID_SECTOR" return-object hide-details :rules="[globalRules.required]" />
              </v-col>

              <v-col>
                <vx-date-text-edit v-model="horaSalidaBase" label="Hora salida base" outlined :min="horaSalidaMin" :max="horaSalidaMax" required />
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col>
                <v-switch v-model="vueltaAgoncillo" label="Vuelta a Agoncillo"/>
              </v-col>
            </v-row> -->
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    idIncendio: String,
    horaSalidaMin: String,
    horaSalidaMax: String,
    medioSelected: Object
  },

  data: () => ({
    isValid: false,

    // vueltaAgoncillo: false,
    sectorSelected: null,

    horaSalidaBase: null
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },

    sectores () {
      let sectores = []

      if (this.incendio) {
        sectores = this.incendio.SECTORES
      }

      return sectores
    }
  },

  watch: {
    show () {
      if (this.show) {
        this.horaSalidaBase = this.$date.currentDate()
      }
    },

    sectores () {
      this.sectorSelected = this.sectores[0]
    }
  },

  methods: {
    cancelar () {
      this.$emit('cancelar')
    },

    aceptar () {
      let dataMedio = {
        MEDIO: this.medioSelected,
        AGONCILLO: false, // this.vueltaAgoncillo,
        HORA_SALIDA_BASE: this.horaSalidaBase
      }

      let medioSector
      let mediosIncendio = {
        ID_INCENDIO: this.idIncendio,
        ID_SECTOR: this.sectorSelected.ID_SECTOR,
        MEDIOS: []
      }

      medioSector = {
        ID_MEDIO_SECTOR: this.$uuid.createUUID(),
        ID_SECTOR: this.sectorSelected.ID_SECTOR,
        ID_MEDIO: this.medioSelected.ID_MEDIO,
        FECHA_AVISO: this.$date.parseDate(this.horaSalidaBase)
      }

      // TODO: !!!! planificacion!!!!!

      mediosIncendio.MEDIOS.push(medioSector)

      let data = {
        dataMedio: dataMedio,
        mediosIncendio: mediosIncendio
      }

      this.$emit('aceptar', data)
    }
  }
}
</script>

<style scoped>
</style>
