import Vue from 'vue'

Vue.mixin({
  data: () => ({
    globalRules: {
      required: v => !!v || 'Campo obligatorio',

      fileInput: [
        v => !!v || 'File is required',
        v => (v && v.size > 0) || 'File is required'
      ]
    }
  }) /* ,

  methods: {
    isDateValid (v) {
      return this.$date.isValid(v) || 'Fecha inválida.'
    },

    isDateFuture (v) {
      let now = this.$date.now()
      let date = this.$date.parseDate(v)
      return date <= now || 'La fecha no puede ser a futuro.'
    },
  } */
})
