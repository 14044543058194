<template>
  <v-dialog v-model="showDialog" persistent width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Añadir incendio</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-form v-model="isValid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field v-model="municipio" label="Municipio" placeholder="Municipio" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field v-model="localidad" label="Localidad" placeholder="Localidad" prepend-icon="mdi-terrain" hide-details disabled />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <vx-date-text-edit v-model="fechaInicio" label="Fecha de inicio" icon="esri-icon-calendar" not-future outlined required />
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="latitud" label="Latitud" dense outlined prepend-icon="mdi-map-marker" @blur="updatedLatLon()" v-mask="'##.#####'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="longitud" label="Longitud" dense outlined @blur="updatedLatLon()" v-mask="'-#.#####'" hide-details /> <!-- TODO: en alguna ocasion el - no sera obligatorio -->
              </v-col>

              <v-col sm="1">
                <v-btn icon color="blue" @click="editLocation">
                  <v-icon>mdi-map-marker-star</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row align="center">
              <v-col sm="6">
                <v-text-field v-model="xETRS89" label="X" dense outlined prepend-icon="mdi-map-marker" @blur="updatedXY()" v-mask="'######'" hide-details />
              </v-col>

              <v-col sm="5">
                <v-text-field v-model="yETRS89" label="Y" dense outlined @blur="updatedXY()" v-mask="'#######'" hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-select v-model="idTipoDeteccion" :items="tiposDeteccion" label="Detección" dense outlined prepend-icon="esri-icon-hollow-eye" item-text="DETECCION" item-value="ID_TIPO_DETECCION" placeholder="Detección" single-line autocomplete hide-details />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-textarea v-model="observaciones" label="Observaciones" dense outlined placeholder="Observaciones" prepend-icon="esri-icon-comment" auto-grow hide-details />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar()">Cancelar</v-btn>
        <v-btn text color="green darken-1" :disabled="!isValid" @click="aceptar()">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as ArcGIS from '../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean,
    pointData: Object
  },

  data: () => ({
    showDialog: false,

    isValid: true,

    municipio: null,
    localidad: null,
    codProv: null,
    codCCAA: null,
    latitud: null,
    longitud: null,
    xETRS89: null,
    yETRS89: null,

    fechaInicio: null,
    idTipoDeteccion: 5,
    observaciones: null,

    isShowNewCoordenadas: false
  }),

  computed: {
    ...mapGetters('incendio', [
      'tiposDeteccion'
    ]),

    ...mapGetters('map', [
      'gettingNewCoordenadas',
      'newCoordenadas'
    ])
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        this.codProv = this.pointData.COD_PROV
        this.codCCAA = this.pointData.COD_CCAA

        this.municipio = this.pointData.MUNICIPIO
        this.localidad = this.pointData.LOCALIDAD

        this.fechaInicio = this.$date.currentDate()

        this.latitud = this.pointData.LATITUD
        this.longitud = this.pointData.LONGITUD
        this.xETRS89 = this.pointData.X
        this.yETRS89 = this.pointData.Y

        this.paintIncendio()
      }
    },

    gettingNewCoordenadas () {
      if (!this.gettingNewCoordenadas && this.isShowNewCoordenadas && this.show) { // Volver a mostrar el dialogo tras capturar el punto
        this.showDialog = true
      }
    },

    newCoordenadas () {
      if (this.show) {
        this.codProv = this.newCoordenadas.COD_PROV
        this.codCCAA = this.newCoordenadas.COD_CCAA

        this.municipio = this.newCoordenadas.MUNICIPIO
        this.localidad = this.newCoordenadas.LOCALIDAD

        this.latitud = this.newCoordenadas.LATITUD
        this.longitud = this.newCoordenadas.LONGITUD

        this.xETRS89 = this.newCoordenadas.X.toFixed(2)
        this.yETRS89 = this.newCoordenadas.Y.toFixed(2)

        this.paintIncendio()
        this.show = true
        this.isShowNewCoordenadas = false
      }
    }
  },

  methods: {
    ...mapActions('map', [
      'startNewCoordenadas'
    ]),

    editLocation () {
      this.showDialog = false
      this.isShowNewCoordenadas = true
      this.startNewCoordenadas()
    },

    clearIncendio () {
      ArcGIS.paintPositionIncendio(null)
    },
    paintIncendio () {
      ArcGIS.paintPositionIncendio({ LATITUD: this.latitud, LONGITUD: this.longitud })
    },

    updatedLatLon () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let xy = ArcGIS.convertirLatLonToETRS89(this.latitud, this.longitud)
        this.xETRS89 = xy[0].toFixed(2)
        this.yETRS89 = xy[1].toFixed(2)

        this.paintIncendio()
      }
    },

    updatedXY () {
      if (this.latitud && this.longitud && this.xETRS89 && this.yETRS89) {
        let latLon = ArcGIS.convertirETRS89ToLatLon(this.xETRS89, this.yETRS89)
        this.longitud = latLon[0].toFixed(5)
        this.latitud = latLon[1].toFixed(5)

        this.paintIncendio()
      }
    },

    cancelar () {
      this.clearIncendio()
      this.$emit('cancelar')

      this.idTipoDeteccion = 5
      this.observaciones = null
    },

    aceptar () {
      let incendio = {}
      let tipoIncendioEstado = this.$store.getters['incendio/getTipoIncendioEstadoByValue'](0)

      this.clearIncendio()

      let fechaCreacion = this.$date.parseDate(this.fechaInicio)

      incendio.ID_INCENDIO = this.$uuid.createUUID()
      incendio.ID_TIPO_DETECCION = this.idTipoDeteccion
      incendio.MUNICIPIO = this.municipio
      incendio.LOCALIDAD = this.localidad
      incendio.COD_PROV = this.codProv
      incendio.COD_CCAA = this.codCCAA
      incendio.LATITUD = this.latitud
      incendio.LONGITUD = this.longitud
      incendio.OBSERVACIONES = this.observaciones
      incendio.FECHA_CREACION = fechaCreacion
      incendio.ESTADOS = [
        {
          ID_INCENDIO_ESTADO: this.$uuid.createUUID(),
          ID_INCENDIO: incendio.ID_INCENDIO,
          ID_TIPO_INCENDIO_ESTADO: tipoIncendioEstado.ID_TIPO_INCENDIO_ESTADO,
          FECHA: fechaCreacion,
          TIPO_INCENDIO_ESTADO: tipoIncendioEstado
        }
      ]
      incendio.NIVELES = []
      incendio.SECTORES = [
        {
          ID_SECTOR: this.$uuid.createUUID(),
          ID_INCENDIO: incendio.ID_INCENDIO,
          SECTOR: 'GENERAL',
          MEDIOS: []
        }
      ]

      this.$emit('aceptar', incendio)
      this.idTipoDeteccion = 5
      this.observaciones = null
    }
  }
}
</script>

<style scoped>
</style>
