import Vue from 'vue'
import UUIDService from '../helpers/UUIDService'

Object.defineProperties(Vue.prototype, {
  $uuid: {
    get () {
      return UUIDService
    }
  }
})
