<template>
  <v-container id="mainContent" fluid fill-height pa-0 class="grey lighten-1">
    <!-- <vx-left-menu/> -->

    <main-header />
    <main-content />
  </v-container>
</template>

<script>
// import LeftMenu from './LeftMenu'

import MainHeader from './MainHeader'
import MainContent from './MainContent'

export default {
  components: {
    // 'vx-left-menu': LeftMenu,
    'main-header': MainHeader,
    'main-content': MainContent
  }
}
</script>

<style scoped>
  #mainContent { /* Fill-height centra los items verticalmente */
    align-items: unset !important
  }
</style>
