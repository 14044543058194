import { render, staticRenderFns } from "./SimulacionManager.vue?vue&type=template&id=82061e7a&scoped=true&"
import script from "./SimulacionManager.vue?vue&type=script&lang=js&"
export * from "./SimulacionManager.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "82061e7a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VBtn,VIcon,VProgressCircular,VSnackbar})
