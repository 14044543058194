<template>
  <v-dialog v-model="showDialog" width="400px" persistent>
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>{{ isEdit ? 'Editar' : 'Crear' }} plan de operaciones</v-toolbar-title>
      </v-toolbar>

      <v-card-text class="pt-4">
        <v-form v-model="isValid">
          <v-text-field v-model="nombre" id="nombre" name="nombre" label="Nombre" outlined :rules="[globalRules.required]" />
          <v-textarea v-model="descripcion" label="Descripción" outlined :rules="[globalRules.required]" />

          <vx-date-text-edit v-model="fecha" label="Fecha" outlined required />
        </v-form>

        <v-btn text color="primary" @click="drawGraphicsOnMap">
          {{ isEdit ? 'Editar' : 'Añadir' }} gráficos
          <v-icon>mdi-map-marker-star</v-icon>
        </v-btn>

        <v-container class="mt-4">
          <v-row>
            Gráficos:
          </v-row>
          <v-row>
            &nbsp;-Símbolos: {{ nSymbols }}
          </v-row>
          <v-row>
            &nbsp;-Polígonos: {{ nPolygons }}
          </v-row>
          <v-row>
            &nbsp;-Líneas: {{ nPolylines }}
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn text color="red" v-if="isEdit" @click="deletePlan">Borrar</v-btn>
        <v-spacer />
        <v-btn text color="red" @click="closeDialog">Cancelar</v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  props: {
    show: Boolean,
    idIncendio: String,
    planSelected: Object,
    isEdit: Boolean
  },

  data: () => ({
    showDialog: false,

    isValid: false,

    nombre: null,
    descripcion: null,
    fecha: null,

    graphicList: []
  }),

  computed: {
    nSymbols () { // TODO: cambiar type por TIPO
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type !== 'polygon' && x.type !== 'polyline').length
      } else return 0
    },

    nPolygons () {
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type === 'polygon').length
      } else return 0
    },

    nPolylines () {
      if (this.graphicList) {
        return this.graphicList.filter(x => x.type === 'polyline').length
      } else return 0
    }
  },

  watch: {
    show () {
      this.showDialog = this.show

      if (this.show) {
        if (this.isEdit) { // Editar
          this.nombre = this.planSelected.NOMBRE
          this.descripcion = this.planSelected.DESCRIPCION
          this.fecha = this.$date.formatDate(this.planSelected.FECHA, 'DD/MM/YYYY HH:mm')

          this.graphicList = [...this.planSelected.GRAFICOS] // Clonar lista de graficos
        } else {
          this.fecha = this.$date.currentDate()
        }
      }
    }
  },

  methods: {
    drawGraphicsOnMap () {
      this.showDialog = false

      this.$eventHub.$emit('showSymbology', {
        callback: this.onDrawGraphicsEnded,
        graphics: JSON.parse(JSON.stringify(this.graphicList)) // Clonar lista
      })
    },

    onDrawGraphicsEnded (graphics) {
      this.showDialog = true

      this.graphicList = graphics
    },

    aceptar () {
      if (!this.isEdit) {
        this.addPlan()
      } else {
        this.editPlan()
      }
    },

    addPlan () {
      let date = this.$date.parseDate(this.fecha)
      let newPlan = {
        ID_PLAN_OPERACIONES: this.$uuid.createUUID(),
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS: this.graphicList,
        FECHA: date
      }

      // Convertir datos a modelo BD
      newPlan = this.modelGraphicsList(newPlan)

      this.$store.dispatch('incendio/addPlanOperaciones', newPlan)
      this.closeDialog()
    },

    editPlan () {
      let date = this.$date.parseDate(this.fecha)
      let editPlan = {
        ID_PLAN_OPERACIONES: this.planSelected.ID_PLAN_OPERACIONES,
        ID_INCENDIO: this.idIncendio,
        NOMBRE: this.nombre,
        DESCRIPCION: this.descripcion,
        GRAFICOS: this.graphicList,
        FECHA: date
      }

      // Convertir datos a modelo BD
      editPlan = this.modelGraphicsList(editPlan)

      this.$store.dispatch('incendio/editPlanOperaciones', editPlan)
      // this.$emit('mostrarPlan', editPlan)

      this.closeDialog()
    },

    modelGraphicsList (plan) {
      // Convertir datos a modelo BD
      for (let i = 0; i < plan.GRAFICOS.length; i++) {
        let g = plan.GRAFICOS[i]
        g.ID_GRAFICO = g.attributes.ID_GRAFICO // TODO: attributes -> ATRIBUTOS
        g.ID_PLAN_OPERACIONES = plan.ID_PLAN_OPERACIONES

        // FIX Parseo de colores
        if (g.symbol.color && g.symbol.color.toRgba) { // TODO: symbol -> SIMBOLO
          g.symbol.color = g.symbol.color.toRgba()
        }
        if (g.symbol.outline && g.symbol.outline.color.toRgba) {
          g.symbol.outline.color = g.symbol.outline.color.toRgba()
        }
      }

      return plan
    },

    deletePlan () {
      let msg = 'Borrando plan: ' + this.planSelected.NOMBRE

      this.$root.$confirmDialog.open('¿Está seguro?', msg).then(result => {
        if (result) {
          this.$store.dispatch('incendio/deletePlanOperaciones', this.planSelected)

          this.cerrar()
        }
      })
    },

    closeDialog () {
      this.cerrar()

      this.nombre = null
      this.descripcion = null
      this.fecha = null
      this.graphicList = []
    },

    cerrar () {
      this.$emit('close')
      ArcGIS.drawGraphicsPlanOperaciones([])
    }
  },

  mounted () {
    this.$eventHub.$on('showAlineacionesCampbell', this.cerrar)
  }
}
</script>

<style scoped>

</style>
