<template>
  <v-dialog v-model="show" persistent max-width="20%">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Editar Estado</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="isValid">
          <v-container>
            <v-row>
              <v-autocomplete
                v-model="estadoSelected"
                return-object
                :items="estadosPosibles()"
                :item-text="(estado) => {return estado.NOMBRE}"
                item-title="NOMBRE"
                item-value="ID_MEDIO_ESTADO"
                label="Estado"
              />
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text color="red" @click="cancelar">Cancelar </v-btn>
        <v-btn text color="green" :disabled="!isValid" @click="aceptar">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    show: Boolean,
    editedPersonal: Object
  },
  computed: {
    ...mapGetters('estadosMedio', [
      'estados'
    ])
  },
  watch: {
    show () {
      if (this.show) {
        this.estadoSelected = this.editedPersonal.MEDIO_ESTADO
      }
    }

  },
  data () {
    return {
      estadoSelected: null,
      isValid: false
    }
  },
  methods: {
    estadosPosibles () {
      let estados = JSON.parse(JSON.stringify(this.estados))
      return estados.filter(x => x.MEDIO_CLASE.NOMBRE === 'PERSONAL')
    },

    cancelar () {
      this.$refs.form.reset()
      this.$emit('cancelar')
    },
    aceptar () {
      let personal = JSON.parse(JSON.stringify(this.editedPersonal))

      personal.MEDIO_ESTADO = this.estadoSelected
      personal.ID_MEDIO_ESTADO = this.estadoSelected.ID_MEDIO_ESTADO
      // Si el estado es ACTIVO => campo de activo=true (personal) - cualquier otro estado => activo=false
      if (personal.MEDIO_ESTADO.NOMBRE === 'ACTIVO') {
        personal.ACTIVO = true
      } else {
        personal.ACTIVO = false
      }
      this.$emit('editEstado', personal)
      this.$refs.form.reset()
    }
  }
}
</script>

<style>

</style>
