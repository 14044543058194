<template>
  <div>
    <v-snackbar v-model="isPaintingSimulacionSketch" bottom :timeout="-1">
      Dibuja la zona de simulación
      <template #action="{ attrs }">
        <v-btn text v-on="attrs" @click="stopSimulacionSketch">Cancelar</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar v-model="isLoadingSimulacion" bottom :timeout="-1">
      Cargando simulación...
      <template #action="{ attrs }">
        <v-progress-circular v-on="attrs" indeterminate />
      </template>
    </v-snackbar>

    <v-snackbar v-model="isErrorSimulacion" :color="errorSimulacion === 406 ? 'warning' : 'error'" bottom :timeout="3000">
      <v-icon dark>mdi-alert-circle</v-icon>
      Error en la simulación: {{ errorSimulacion === 404 ? 'no existe' : errorSimulacion === 406 ? 'en progreso' : 'error interno' }}
    </v-snackbar>

    <vx-dialog-simulacion
      :show="showDialogSimulacion"
      @deleteSimulacion="deleteSimulacion"
      @initSimulacionSketch="initSimulacionSketch"
      @paintSimulationSketch="paintSimulationSketch"
      @addPointSimulation="addPointSimulation"
      @redrawSimulationElements="redrawSimulationElements"
      @stopSimulacionSketch="stopSimulacionSketch"
      @endSimulacionSketch="endSimulacionSketch"
      @paintSimulacion="paintSimulacion"
      @paintViento="paintViento"
      @paintSimulacionEstadoFinal="paintSimulacionEstadoFinal"
      @open="openDialog" @close="closeDialog"
    />
    <vx-simulacion-slider
      v-show="isPaintingSimulacion"
      :simulacion="simulacion"
      @paintSimulacion="paintSimulacion"
      @updateSimulacionTime="updateSimulacionTime"
      @stopSimulacion="stopSimulacion"
      @updateViento="updateViento"
    />
  </div>
</template>

<script>
import DialogSimulacion from './DialogSimulacion'
import SimulacionSlider from './SimulacionSlider'

import * as ArcGIS from '../../../helpers/ArcGIS'
import api from '@/api'

export default {
  props: {
    showDialog: Boolean
  },

  components: {
    'vx-dialog-simulacion': DialogSimulacion,
    'vx-simulacion-slider': SimulacionSlider
  },

  data: () => ({
    showDialogSimulacion: false,

    isPaintingSimulacionSketch: false,

    isLoadingSimulacion: false,

    isPaintingSimulacion: false,
    simulacion: null,

    isErrorSimulacion: false,
    errorSimulacion: null,

    ultimo: null
  }),

  computed: {
  },

  watch: {
    showDialog () {
      this.showDialogSimulacion = this.showDialog
    }
  },

  methods: {
    openDialog () {
      this.$emit('openDialog')
      // this.showDialogSimulacion = true
    },
    closeDialog () {
      this.$emit('closeDialog')
      // this.showDialogSimulacion = false
    },

    initSimulacionSketch (data) {
      // Clear SVM y capa de dibujos anteriores
      ArcGIS.clearSimulacionSketch()

      this.isPaintingSimulacionSketch = true
      ArcGIS.initSimulacionSketchViewModel(data)
    },

    paintSimulationSketch (data) {
      this.isPaintingSimulacionSketch = true
      ArcGIS.initSimulacionSketchViewModel(data)
    },

    addPointSimulation (data) {
      ArcGIS.addPointElement(data)
    },

    redrawSimulationElements (data) {
      ArcGIS.drawSimulationElements(data)
    },

    stopSimulacionSketch () { // Finaliza el sketch
      this.isPaintingSimulacionSketch = false
      ArcGIS.stopSimulacionSketchViewModel()
      // ArcGIS.clearSimulacionSketch()
      this.openDialog()
    },

    endSimulacionSketch () { // Finaliza el sketch
      this.isPaintingSimulacionSketch = false
      // ArcGIS.stopSimulacionSketchViewModel()
      ArcGIS.clearSimulacionSketch()
      // this.closeDialog()
    },

    paintSimulacion (simulacion) {
      this.isLoadingSimulacion = true

      // ArcGIS.clearSimulacion()

      ArcGIS.paintSimulacion(simulacion).then((error) => {
        if (error) {
          this.isErrorSimulacion = true
          this.errorSimulacion = error
          return
        }

        // TODO: Probablemente comentar mayor ayustes.
        this.closeDialog()

        this.isPaintingSimulacion = true
        this.simulacion = simulacion.sim
      }).catch((err) => {
        this.isErrorSimulacion = true
        this.errorSimulacion = err // TODO: leer el mensaje del error, aqui llega el http code
      }).finally(() => {
        this.isLoadingSimulacion = false
      })
    },

    paintViento (simulacion) {
      ArcGIS.paintViento({ sim: simulacion }).then((error) => {
        if (error) {
          this.isErrorSimulacion = true
          this.errorSimulacion = error
        }
      })
    },

    async deleteSimulacion (data) {
      let simulacion = data.simulacion
      try {
        await api.simulacion.deleteSimulacionAPI(
          simulacion.ID_SIMULACION
        )
      } catch (error) {
        if (error.toJSON().status !== 404) {
          this.isErrorSimulacion = true
          this.errorSimulacion = error.toJSON().status // TODO: leer el mensaje del error, aqui llega el http code
          return
        }
      }

      await api.simulacion.deleteSimulacionBDD(
        simulacion.ID_SIMULACION
      )

      // Si lo haces con la store en el front se ve mal
      // this.$store.dispatch('simulacion/deleteSimulacion', simulacion)

      data.callback()
    },

    async paintSimulacionEstadoFinal (data) {
      ArcGIS.clearSimulacion()
      let informe
      try {
        informe = await api.simulacion.getInformeSimulacion(
          data.simulacion.ID_SIMULACION
        )
      } catch (err) {
        console.error(err)
        this.isErrorSimulacion = true
        this.errorSimulacion = err // TODO: leer el mensaje del error, aqui llega el http code
        return
      }

      ArcGIS.paintSimulacionFinal(data.simulacion).then(async (error) => {
        if (error) {
          this.isErrorSimulacion = true
          this.errorSimulacion = error
          return
        }

        this.closeDialog()

        data.callback(informe.data)

        ArcGIS.clearSimulacion()
      }).catch((err) => {
        console.error(err)
        this.isErrorSimulacion = true
        this.errorSimulacion = err // TODO: leer el mensaje del error, aqui llega el http code
      }).finally(() => {
        this.isLoadingSimulacion = false
      })
    },

    updateSimulacionTime (elapsedMin) {
      this.$store.dispatch('shareMap/updateSimulationTime', elapsedMin)
      ArcGIS.updateSimulacionTime(elapsedMin)
    },

    async updateViento (data) {
      if (this.ultimo) clearTimeout(this.ultimo)
      this.ultimo = setTimeout(() => {
        ArcGIS.paintViento(data)
      }, 50)
    },

    stopSimulacion () {
      clearTimeout(this.ultimo)
      this.isPaintingSimulacion = false
      this.simulacion = null
      this.$store.dispatch('shareMap/clearSimulation')
      ArcGIS.clearSimulacion()
    }
  },

  mounted () {
    // this.$eventHub.$on('showPopup', this.onShowPopup)
  }
}
</script>

<style scoped>

</style>
