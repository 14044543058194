<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Extender guardias</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-4">
        <v-container>
          <v-form ref="formExtendGuardia">
            <v-row>
              <v-col>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      :value="fechaExtendFormatted"
                      label="Fecha extensión de guardia"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    />
                  </template>
                  <v-date-picker
                    v-model="fechaExtend"
                    no-title
                    scrollable
                    :min="minDate"
                    :max="maxDate"
                    :show-current="actualDate"
                    show-adjacent-months
                    locale="es-ES"
                  >
                    <v-spacer />
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu.save(fechaExtend)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn text small color="red" @click="cancelar">Cancelar</v-btn>
        <v-btn text small color="green" @click="aceptar" :disabled="menu" type="submit">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    show: Boolean,
    isGuardiaPersonal: Boolean,
    medioPersonalSelected: Object
  },
  data: () => ({
    isValid: false,
    menu: false,
    fechaExtend: null,
    actualDate: null,
    minDate: null,
    maxDate: null
  }),
  watch: {
    show () {
      if (this.show) {
        this.fechaExtend = this.$date.parseDate(this.medioPersonalSelected.FECHA).add(7, 'day').format('YYYY-MM-DD')
        this.actualDate = this.$date.parseDate(this.medioPersonalSelected.FECHA).format('YYYY-MM-DD')
        this.minDate = this.$date.parseDate(this.medioPersonalSelected.FECHA).add(1, 'day').format('YYYY-MM-DD')
        this.maxDate = this.$date.parseDate(this.medioPersonalSelected.FECHA).add(1, 'year').format('YYYY-MM-DD')
      }
    }
  },
  methods: {
    aceptar () {
      let mediosPersonales = this.generarMediosPersonal()
      this.$emit('accept', mediosPersonales)
    },
    /**
     * Genera array de las guardias a extender
     * Si no existe una guardia se crea un ID_MEDIO_PERSONAL nuevo
     * Si ya existe una guardia se le pasa el ID_MEDIO_PERSONAL para que el bulk insert or update (addMedioPersonal([items])), haga update en vez de insert
     */
    generarMediosPersonal () {
      const numDiasExtender = this.$date.parseDate(this.fechaExtend).diff(this.$date.parseDate(this.actualDate), 'days')
      let mediosPersonales = []
      for (let i = 1; i <= numDiasExtender; i++) {
        let medioPersonal = JSON.parse(JSON.stringify(this.medioPersonalSelected))
        let futureMatch = this.findFutureMatch(i)
        medioPersonal.ID_MEDIO_PERSONAL = futureMatch ? futureMatch.ID_MEDIO_PERSONAL : this.$uuid.createUUID()
        medioPersonal.state = futureMatch ? 'update' : 'insert'
        medioPersonal.BORRADO = 0
        medioPersonal.FECHA = this.$date.parseDate(this.medioPersonalSelected.FECHA, 'YYYY-MM-DD HH:mm:ss.SSS').add(i, 'day').startOf('day')
        medioPersonal.FECHA_ENTRADA = this.$date.parseDate(this.medioPersonalSelected.FECHA_ENTRADA, 'YYYY-MM-DD HH:mm:ss.SSS').add(i, 'day')
        medioPersonal.FECHA_SALIDA = this.$date.parseDate(this.medioPersonalSelected.FECHA_SALIDA, 'YYYY-MM-DD HH:mm:ss.SSS').add(i, 'day')
        mediosPersonales.push(JSON.parse(JSON.stringify(medioPersonal)))
      }
      return mediosPersonales
    },

    /**
     * Busca en las fechas que se van a extender las guardias si ya existen guardias con el ID_PERSONAL O ID_MEDIO
     * según de que vista venga:
     * GuardiasPorPersonal check coincidencia ID_PERSONAL
     * GuardiasPorMedio check coincidencia ID_MEDIO
     * @param {Object} medioPersonal - Copia guardia pasada por props
     * @param {Number} i - número del día a futuro a comprobar tomando como referencia la fecha traida por la prop medioPersonalSelected
     * @returns {Object} Guardia encontrada en el futuro. (Se tendrá que asignar el ID_MEDIO_PERSONAL para updatear en vez de insert)
    */
    findFutureMatch (i) {
      let medioPersonalInDate = this.$store.getters['medioPersonal/getMediosPersonalesByDate'](this.$date.parseDate(this.medioPersonalSelected.FECHA, 'YYYY-MM-DD HH:mm:ss.SSS').add(i, 'days'))
      if (this.isGuardiaPersonal) {
        return medioPersonalInDate.find(guardia => guardia.ID_MEDIO === this.medioPersonalSelected.ID_MEDIO && !guardia.BORRADO)
      }
      return medioPersonalInDate.find(guardia => guardia.ID_PERSONAL === this.medioPersonalSelected.ID_PERSONAL && !guardia.BORRADO)
    },

    cancelar () {
      this.$emit('cancelar')
    }
  },
  computed: {
    fechaExtendFormatted () {
      let date = this.$date.parseDate(this.fechaExtend)
      return this.$date.formatDate(date, 'DD/MM/YYYY')
    }
  }
}
</script>
<style scoped>
</style>
