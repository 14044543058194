import request from '../request'

function getConfig (url) {
  return {
    headers: {
      'custom-url': url
    }
  }
}

export default {
  get: function (url, params) {
    return request.get(`proxy/${params}`, getConfig(url))
  },

  post: function (url, params, body) {
    return request.post(`proxy/${params}`, body, getConfig(url))
  },

  delete: function (url, params) {
    return request.delete(`proxy/${params}`, getConfig(url))
  }
}
