<template>
  <div>
    <v-card elevation="5">
      <v-toolbar color="primaryLight" dark dense>
        <v-toolbar-title>Imagenes Satelite</v-toolbar-title>
      </v-toolbar>
      <v-tabs v-model="tabSateliteSelected" slider-color="secondary">
        <v-tab>Iniciales</v-tab>
        <v-tab>Finales</v-tab>
        <v-tab>Comparaciones</v-tab>
      </v-tabs>
      <v-container v-if="tabSateliteSelected == 0">
        <v-row>
          <v-col v-for="(item, index) in buttonsIniciales" :key="index">
            <v-card
              v-model.lazy="item.visible"
              class="pa-2"
              @click="showImage(item.nombre, 'inicial', index)"
              v-if="item.visible"
            >
              {{ item.label }}
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="tabSateliteSelected == 1">
        <v-row>
          <v-col v-for="(item, index) in buttonsFinales" :key="index">
            <v-card
              class="pa-2"
              @click="showImage(item.nombre, 'final', index)"
              v-if="item.visible"
            >
              {{ item.label }}
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container v-if="tabSateliteSelected == 2">
        <v-row>
          <v-col v-for="(item, index) in buttonsComparacion" :key="index">
            <v-card
              class="pa-2"
              @click="showImage(item.nombre, 'comparacion', index)"
              v-if="item.visible"
            >
              {{ item.label }}
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import api from '@/api'
import { mapActions } from 'vuex'
import * as ArcGIS from '../../../helpers/ArcGIS'

export default {
  components: {},

  props: {
    idIncendio: String
  },

  data: () => ({
    buttonsIniciales: [
      { label: 'NBR', visible: false, nombre: null, activate: false },
      { label: 'NDVI', visible: false, nombre: null, activate: false },
      { label: 'RGB', visible: false, nombre: null, activate: false }
    ],
    buttonsFinales: [
      { label: 'NBR', visible: false, nombre: null, activate: false },
      { label: 'NDVI', visible: false, nombre: null, activate: false },
      { label: 'RGB', visible: false, nombre: null, activate: false }
    ],
    buttonsComparacion: [
      { label: 'Raster NBR', visible: false, nombre: null, activate: false },
      { label: 'Raster NDVI', visible: false, nombre: null, activate: false },
      { label: 'Vector NBR', visible: false, nombre: null, activate: false },
      { label: 'Vector NDVI', visible: false, nombre: null, activate: false }
    ],
    tabSateliteSelected: 0, // 0: Inicial, 1: Final, 2: Comparacion
    apiSelected: 'ARCGIS'
  }),

  computed: {
    incendio () {
      return this.$store.getters['incendio/getIncendioByID'](this.idIncendio)
    },
    allImagenSatelite () {
      return this.$store.getters['incendio/imagenesSatelite']
    }
  },

  watch: {
    async idIncendio () {
      if (this.idIncendio != null) {
        await this.reset()
        await this.fetchImagenesSatelite({ ID_INCENDIO: this.idIncendio })

        this.tabSateliteSelected = 0

        this.imagenesIniciales(this.apiSelected) // "COPERNICUS"
      }
    },
    async tabSateliteSelected () {
      await this.reset()

      this.hideImageRaster()

      switch (this.tabSateliteSelected) {
        case 0:
          this.imagenesIniciales(this.apiSelected)
          break
        case 1:
          this.imagenesFinales(this.apiSelected)
          break
        case 2:
          this.imagenesComparacion(this.apiSelected)
          break
        default:
          break
      }
    }
  },

  methods: {
    ...mapActions('incendio', ['fetchImagenesSatelite']),

    imagenesIniciales (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_INICIAL_' + api
        ) {
          this.buttonsIniciales[0].visible = true
          this.buttonsIniciales[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_INICIAL_' + api
        ) {
          this.buttonsIniciales[1].visible = true
          this.buttonsIniciales[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'RGB_INICIAL_' + api
        ) {
          this.buttonsIniciales[2].visible = true
          this.buttonsIniciales[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },
    imagenesFinales (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_FINAL_' + api
        ) {
          this.buttonsFinales[0].visible = true
          this.buttonsFinales[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_FINAL_' + api
        ) {
          this.buttonsFinales[1].visible = true
          this.buttonsFinales[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'RGB_FINAL_' + api
        ) {
          this.buttonsFinales[2].visible = true
          this.buttonsFinales[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },
    imagenesComparacion (api) {
      for (let i = 0; i < this.allImagenSatelite.length; i++) {
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_COMPARACION_' + api
        ) {
          this.buttonsComparacion[0].visible = true
          this.buttonsComparacion[0].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_COMPARACION_' + api
        ) {
          this.buttonsComparacion[1].visible = true
          this.buttonsComparacion[1].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }

        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NBR_COMP_' + api + '_SHP'
        ) {
          this.buttonsComparacion[2].visible = true
          this.buttonsComparacion[2].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
        if (
          this.allImagenSatelite[i].ID_TIPO_IMAGEN_SATELITE ===
            this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE
              .ID_TIPO_IMAGEN_SATELITE &&
          this.allImagenSatelite[i].TIPO_IMAGEN_SATELITE.CODIGO ===
            'NDVI_COMP_' + api + '_SHP'
        ) {
          this.buttonsComparacion[3].visible = true
          this.buttonsComparacion[3].nombre =
            this.allImagenSatelite[i].NOMBRE_FICHERO
        }
      }
    },

    async showImage (idImagen, estado, i) {
      if (estado === 'inicial') {
        if (this.buttonsIniciales[i].activate === true) {
          this.hideImageRaster()
          this.buttonsIniciales[i].activate = false
          return
        } else {
          this.buttonsIniciales[i].activate = true

          for (let j = 0; j < this.buttonsIniciales.length; j++) {
            if (j !== i) {
              this.buttonsIniciales[j].activate = false
            }
          }
        }
      } else if (estado === 'final') {
        if (this.buttonsFinales[i].activate === true) {
          this.hideImageRaster()
          this.buttonsFinales[i].activate = false
          return
        } else {
          this.buttonsFinales[i].activate = true

          for (let j = 0; j < this.buttonsFinales.length; j++) {
            if (j !== i) {
              this.buttonsFinales[j].activate = false
            }
          }
        }
      } else {
        if (this.buttonsComparacion[i].activate === true) {
          if (i > 1) {
            this.hideImageVector()
          } else {
            this.hideImageRaster()
          }
          this.buttonsComparacion[i].activate = false
          return
        } else {
          this.buttonsComparacion[i].activate = true

          if (i < 2) {
            this.buttonsComparacion[i === 1 ? 0 : 1].activate = false
          } else {
            this.buttonsComparacion[i === 2 ? 3 : 2].activate = false
          }
        }
      }

      // Elimina la extension del nombre de la imagen
      if (idImagen.includes('.tiff')) {
        idImagen = idImagen.substring(0, idImagen.length - 5)
      } else {
        // Caso de ".geojson"
        idImagen = idImagen.substring(0, idImagen.length - 8)
      }

      let data = {
        ID_INCENDIO: this.idIncendio,
        NOMBRE_FICHERO: idImagen,
        ESTADO: estado,
        API: this.apiSelected.toLowerCase()
      }

      // console.log("Data: ", data)

      if ((estado === 'comparacion') & (i > 1)) {
        let response = await api.imagenSatelite.getImagenSatelite(data)
        // ArcGIS.drawGeoJsonImagenSatelite(response.data)
      } else {
        let url = api.imagenSatelite.getImagenSateliteURL(data)
        ArcGIS.drawImagenSatelite(
          {
            ID_INCENDIO: this.idIncendio,
            ID_IMAGEN: idImagen,
            LATITUD: this.incendio.LATITUD,
            LONGITUD: this.incendio.LONGITUD
          },
          url
        )
      }
    },

    // Reset de los botones
    reset () {
      this.buttonsIniciales.forEach((elem) => {
        elem.activate = false
        elem.visible = false
        elem.nombre = null
      })

      this.buttonsFinales.forEach((elem) => {
        elem.activate = false
        elem.visible = false
        elem.nombre = null
      })

      this.buttonsComparacion.forEach((elem) => {
        elem.activate = false
        elem.visible = false
        elem.nombre = null
      })
    },

    hideImageRaster () {
      // Oculta las imagenes de la capa
      ArcGIS.hideImagenSatelite()
    }
    // hideImageVector () {
    //   // Oculta las imagenes de la capa
    //   ArcGIS.hideImagenSateliteGeoJSON()
    // }
  },
  created () {}
}
</script>

<style scoped></style>
